import * as React from 'react';
import { Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

interface IArchiveErrorModalProps {
    hidden: boolean,
    organizationName: string,
    eventClusters: any[],
    closeModal: () => void,
    // changePage: (page: string) => void,
}

export class ArchiveErrorModal extends React.Component<IArchiveErrorModalProps> {
    public static defaultProps: Partial<IArchiveErrorModalProps> = {
        organizationName: "",
    }

    render() {
        return (
            <div>
                <Dialog
                    onClose={this.props.closeModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="genericConfirmationModal"
                    open={this.props.hidden == false}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Confirmation</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.props.closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>Could not delete "{this.props.organizationName}". These Event Groups have assigned the Organization</div>
                        <ul>
                            {this.props.eventClusters.map((cluster: any) => {
                                return <li >
                                    <Link href={"/event-group?id=" + cluster.eventClusterId}>{cluster.eventClusterName}</Link>
                                </li>;
                            })}
                        </ul>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.props.closeModal} className='one-action-button'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}