import * as React from 'react';
import {
    MuiPickersUtilsProvider,
    DatePicker 
  } from '@material-ui/pickers';
  import DateFnsUtils from '@date-io/date-fns';
  import CancelIcon from '@material-ui/icons/Cancel';

interface IDateFilterCellProps{
    value: any,
    field: any,
    onChange: (field: any, date: any) => void
}

export class DateFilterCell extends React.Component<IDateFilterCellProps> {

    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}> 
                <DatePicker
                    margin="dense"
                    size="small"
                    id="date-picker-dialog"
                    label="Start Date"
                    inputVariant="outlined"
                    value={this.props.value}
                    onChange={(date) => this.props.onChange(this.props.field, date)}
                    format="MM/dd/yyyy"
                    style={this.props.value != null && this.props.value != "" ? {width: "80%", paddingRight: "3%"} : {width: "100%"}}
                />
                {this.props.value && <button onClick={() => this.props.onChange(this.props.field, null)} style={{width: "15%", border: "0px", background: "white", paddingTop: "17px", cursor: "pointer"}}><CancelIcon/></button>}
            </MuiPickersUtilsProvider>
        );
    }
}