/// <reference path="../../../data/DataModels/types.d.ts" />
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import '../../Styles/EventPrimaryFormSection.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EventClusterDropdown } from '../SubComponents/EventClusterDropdown';
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import { debounceAutocompleteAddress, geocodeAddress } from '../../../data/HereAPISearchRepository';
import { debounceSearchVenueAddress } from '../../../data/LocationsRepository';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import DateFnsUtils from '@date-io/date-fns';
import { searchKeywords } from '../../../data/KeywordsRepository';
import { getAllEventClusters } from '../../../data/EventClusterRepository';
import { getAllAgeRestrictions } from '../../../data/AgeRestrictionsRepository';
import { cancelEvent, createEvent, updateEvent } from '../../../data/EventRepository';
import { uploadEventFiles } from '../../../data/EventFileRepository';
import { getEvent } from '../../../data/EventRepository';
import ClearIcon from '@material-ui/icons/Clear';
import { EventFileTypeCode } from "../../../data/Models/EventFileTypeCode";
import { SalesTaxFlagCode } from "../../../data/Models/SalesTaxFlagCode";
import WarningIcon from '@material-ui/icons/Warning';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { EmbedEventsHtmlModal } from '../Embed/EmbedEventsHtmlModal';
import Moment from 'moment';
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    DateTimePicker
} from '@material-ui/pickers';
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { size } from 'lodash';
import { MarkdownEditor } from './MarkdownEditor';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

var Enumerable = require('linq');

interface IEventPrimaryFormSectionProps {
    userPermissions?: any[],
    isClusterOwner?: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    handleChangesPendingChange: (changesPending: boolean) => void,
    changePage: (page: string) => void
}

const Map = withScriptjs(withGoogleMap((props: any) =>
    <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.center}
        zoom={props.zoom}
        center={props.center}
    >
        {props.mapCoord != null && <Marker position={props.mapCoord} />}
    </GoogleMap>
));


export class EventPrimaryFormSection extends React.Component<IEventPrimaryFormSectionProps> {
    state = {
        eventId: '',
        copyEventId: '',
        eventClusterId: '',
        eventClusterIdError: '',
        eventClusters: [],
        eventName: '',
        eventNameError: '',
        isPrivateEvent: false,
        ageRestrictions: [],
        ageRestrictionId: 0,
        ageRestrictionError: '',
        venueName: '',
        venueNameError: '',
        venueSaved: false,
        address: '',
        searchAddressFocused: false,
        salesTax: 0,
        salesTaxError: '',
        venueAddressSelected: false,
        latitude: 0,
        longitude: 0,
        zipCode: '',
        addressError: '',
        startDateTime: undefined as Date | null | undefined,
        startDateTimeError: '',
        finishDateTime: undefined as Date | null | undefined,
        finishDateTimeError: '',
        filteredKeywords: [] as string[],
        selectedKeywords: [] as string[],
        keywordsError: '',
        description: '',
        descriptionError: '',
        uploadFiles: [],
        uploadFilePreviews: [],
        uploadFilesError: '',
        savedUploadFiles: [],
        deleteUploadFileIds: [],
        venueAddressResults: [],
        addressResults: [],
        mapCoord: undefined,
        mapZoom: 1,
        mapCenter: { lat: 39.8283, lng: -98.5795 },
        validationErrors: [],
        saveSuccess: false,
        pendingChanges: false,
        eventDeepLink: '',
        eventWebsiteLink: '',
        saveError: '',
        serverError: '',
        published: false,
        cancelled: false,
        cancelEventModalVisible: false,
        cancelEventSuccess: false,
        allOrdersRefundedSuccessfully: true,
        canShowHtmlModal: false,
        htmlModalVisible: false,
        salesTaxRateFlagged: false,
        salesTaxRate: 0,
        salesTaxRateAdjustment: null,
        salesTaxFlagCode: SalesTaxFlagCode.None,
        flaggedLocationId: '',
        salesTaxFlagConditions: '',
        loaded: false,
        editor: null
    }

    async componentDidMount() {
        await this.LoadPage(false, '');
    }

    LoadPage = async (saveSuccess: boolean, saveError: any) => {
        this.props.loadingOn();

        const query = new URLSearchParams(window.location.search);

        let id = query.get('id');
        let eventClusterId: string = query.get('eventGroupId') ?? "";
        let eventClusterName: string = '';
        let eventName = '';
        let isPrivateEvent = false;
        let ageRestrictionId = 0;
        let venueName = '';
        let address = '';
        let zipCode = '';
        let salesTax: number = 0;
        let salesTaxFlagCode: SalesTaxFlagCode = SalesTaxFlagCode.None;
        let venueAddressSelected = false;
        let startDateTime = undefined;
        let finishDateTime = undefined;
        let selectedKeywords: string[] = [];
        let description = '';
        let savedUploadFiles: any[] = [];
        let latitude = 0;
        let longitude = 0;
        let mapZoom = 1;
        let mapCoord = undefined;
        let mapCenter = { lat: 39.8283, lng: -98.5795 };
        let eventWebsiteLink: string | undefined = "";
        let eventDeepLink: string | undefined = "";
        let serverError = "";
        let published = false;
        let cancelled = false;
        let canShowHtmlModal = false;
        let loaded = false;


        let copyEventId = query.get('copyEventId') ?? "";

        if ((id != '' && id != undefined) || (copyEventId != '' && copyEventId != undefined)) {
            var eventResponse = await getEvent(id ?? copyEventId);

            if (eventResponse != null && eventResponse.status == 401) {
                this.RedirectToLogin(eventClusterId, id, copyEventId);
            }
            else if (eventResponse != null && eventResponse.status == 200) {
                if (!eventResponse.data.success) {
                    serverError = eventResponse.data.errorMsg;
                }
                else {
                    var event = eventResponse.data.event;

                    if (id != '' && id != undefined) {
                        id = event.id;
                        salesTaxFlagCode = event.salesTaxFlagCode;
                    }
                    else {
                        copyEventId = event.id;
                        salesTaxFlagCode = SalesTaxFlagCode.None;
                    }

                    savedUploadFiles = event.eventImageFiles;
                    eventClusterId = event.eventClusterId;
                    eventClusterName = event.eventClusterName;
                    eventName = event.name;
                    isPrivateEvent = event.isPrivateEvent;
                    ageRestrictionId = event.ageRestrictionCode;
                    venueName = event.venueName;
                    address = event.address;
                    zipCode = event.zipCode;
                    salesTax = event.salesTax;
                    venueAddressSelected = true;
                    startDateTime = new Date(event.startDateTimeLocal);
                    finishDateTime = new Date(event.finishDateTimeLocal);
                    selectedKeywords = event.keywords as string[];
                    description = event.description;
                    loaded = true;
                    latitude = event.latitude;
                    longitude = event.longitude;
                    mapZoom = 14;
                    mapCoord = { lat: latitude, lng: longitude };
                    mapCenter = { lat: latitude, lng: longitude };
                    published = (copyEventId != '' && copyEventId != undefined) ? false : event.published === true;
                    cancelled = event.cancelled;
                    
                    eventWebsiteLink = process.env.REACT_APP_NRBY_EVENT_TICKETPURCHASE_LINK?.replace("{id}", event.id);
                    eventDeepLink = process.env.REACT_APP_NRBY_EVENT_DEEPLINK?.replace("{id}", event.id);
                    canShowHtmlModal = published;
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }
        }
        else {
            loaded = true;
        }

        if (copyEventId != '' && copyEventId != undefined){
            cancelled = false;
        }

        let eventClusters: any[] = [];

        if (serverError == "") {
            var response = await getAllEventClusters();

            if (response != null && response.status == 401) {
                this.RedirectToLogin(eventClusterId, id, copyEventId);
            }
            else if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = response.data.errorMsg;
                }
                else {
                    let codeValuePermissions = id == "" || id == undefined ? EventClusterPermissionCode.CreateEvents : EventClusterPermissionCode.EditEvents;

                    response.data.eventClusters.forEach((c: any) => {
                        var hasPermission = c.role.isOwnerRole || Enumerable.from(c.role.eventClusterPermissions).any((p: EventClusterPermission) => p.id == codeValuePermissions);

                        if (hasPermission == true || c.eventClusterId == eventClusterId) {
                            eventClusters.push(c);
                        }
                    });
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }
        }
        let ageRestrictions: any[] = [];

        if (serverError == "") {
            var ageRestrictionsResponse = await getAllAgeRestrictions();

            if (ageRestrictionsResponse != null && ageRestrictionsResponse.status == 401) {
                this.RedirectToLogin(eventClusterId, id, copyEventId);
            }
            else if (ageRestrictionsResponse != null && ageRestrictionsResponse.status == 200) {
                if (!ageRestrictionsResponse.data.success) {
                    serverError = ageRestrictionsResponse.data.errorMsg;
                }
                else {
                    let ageRestrictionTemp: any[] = ageRestrictionsResponse.data.ageRestrictions;

                    ageRestrictions = Enumerable.from(ageRestrictionTemp).select((r: any) => ({ id: r.code, name: r.name })).toArray();
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }
        }

        // let editor = document.getElementsByClassName('k-editor')[0];
        // let iFrame = editor.querySelector('iframe');

        // if (typeof iFrame !== 'undefined') {
        //     const contentDocument = iFrame!.contentDocument;
        //     if (typeof iFrame !== 'undefined') {
        //         const document: Document = contentDocument!;
        //         var canEdit = id == undefined || id == "" || this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditEvents);
        //         document.querySelector('.k-content')?.setAttribute('contenteditable', canEdit);
        //     }
        // }

        this.setState({
            ...this.state,
            eventClusterId: eventClusterId,
            eventClusters: eventClusters,
            ageRestrictions: ageRestrictions,
            eventId: id,
            copyEventId: copyEventId,
            eventName: eventName,
            isPrivateEvent: isPrivateEvent,
            ageRestrictionId: ageRestrictionId,
            venueName: venueName,
            address: address,
            zipCode: zipCode,
            salesTax: salesTax,
            salesTaxFlagCode: salesTaxFlagCode,
            venueAddressSelected: venueAddressSelected,
            startDateTime: startDateTime,
            finishDateTime: finishDateTime,
            selectedKeywords: selectedKeywords,
            description: description,
            savedUploadFiles: savedUploadFiles,
            uploadFiles: [],
            uploadFilePreviews: [],
            uploadFilesError: '',
            latitude: latitude,
            longitude: longitude,
            mapZoom: mapZoom,
            mapCoord: mapCoord,
            mapCenter: mapCenter,
            saveSuccess: saveSuccess,
            eventWebsiteLink: eventWebsiteLink,
            eventDeepLink: eventDeepLink,
            saveError: saveError,
            serverError: serverError,
            published: published,
            cancelled: cancelled,
            canShowHtmlModal: canShowHtmlModal,
            loaded: loaded
        });

        this.props.loadingOff();
    }

    RedirectToLogin = (eventClusterId: any, eventId: any, copyEventId: any) => {
        let eventClusterParam = "";

        if (eventId != '' && eventId != undefined) {
            eventClusterParam += `event?id=${eventId}`;
        }
        else {
            eventClusterParam += "create-event";

            if (copyEventId != '' && copyEventId != undefined) {
                eventClusterParam += `?copyEventId=${copyEventId}`;
            }
        }

        if (eventClusterParam.indexOf("?") > 0) {
            eventClusterParam += eventClusterId != null && eventClusterId != "" ? `%26eventGroupId=${eventClusterId}` : "";
        }
        else {
            eventClusterParam += eventClusterId != null && eventClusterId != "" ? `?eventGroupId=${eventClusterId}` : "";
        }

        this.props.changePage("/login?redirect=" + eventClusterParam);
    }

    HandleIsPrivateEventChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.setState({ ...this.state, isPrivateEvent: checked, pendingChanges: true });
    }

    HandleEventClusterChange = (eventClusterId: any) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, eventClusterId: eventClusterId, pendingChanges: true });
    }

    HandleEventNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, eventName: event.currentTarget.value, pendingChanges: true });
    }

    HandleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, zipCode: event.currentTarget.value, pendingChanges: true });
    }

    HandleVenueNameChange = async (event: any) => {
        let venue = event.currentTarget.value;

        let venueAddressResults: any[] = [];

        this.UpdateVenue(venue);

        var response = await debounceSearchVenueAddress(venue);

        if (response != null && response.status == 200) {
            venueAddressResults = response.data.venueAddresses;
        }

        this.setState({ ...this.state, venueAddressResults: venueAddressResults });
    }

    UpdateVenue = (venue: any) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, venueName: venue, pendingChanges: true });
    }

    HandleAddressInputChange = async (address: any) => {
        let addressResults: any[] = [];

        if (address != undefined && address != "") {
            var response = await debounceAutocompleteAddress(address);

            if (response != null && response.status == 200) {
                addressResults = response.data.items;
            }

        }

        this.setState({ ...this.state, addressResults: addressResults });
    }

    ClearAddress = async () => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, address: '', venueAddressSelected: false, searchAddressFocused: true, pendingChanges: true });
    }

    SelectAddress = async (value: any) => {
        let address = value != null ? value.address.label : "";

        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, address: address, pendingChanges: true });

        this.MapAddress(address);
    }

    MapAddress = async (address: string) => {
        let zoom = 1;
        let center = { lat: 39.8283, lng: -98.5795 };

        let mapCoord = undefined;
        let latitude = 0;
        let longitude = 0;
        let postalCode = '';
        let salesTaxFlagCode = this.state.salesTaxFlagCode;
        let salesTaxRateAdjustment = this.state.salesTaxRateAdjustment;
        let flaggedLocationId = this.state.flaggedLocationId;
        let salesTaxFlagConditions = this.state.salesTaxFlagConditions;

        if (address.length > 0) {
            var response = await geocodeAddress(address);

            if (response != null && response.status == 200) {
                if (response.data.items.length > 0) {
                    postalCode = response.data.items[0].address.postalCode;
                    mapCoord = response.data.items[0].position;
                    zoom = 14;
                    center = mapCoord;
                    latitude = mapCoord.lat;
                    longitude = mapCoord.lng;
                }
            }
        }

        if (postalCode != this.state.zipCode) {
            salesTaxFlagCode = SalesTaxFlagCode.None;
            salesTaxRateAdjustment = null;
            flaggedLocationId = '';
            salesTaxFlagConditions = '';
        }

        this.setState({
            ...this.state,
            mapCoord: mapCoord,
            mapZoom: zoom,
            mapCenter: center,
            latitude: latitude,
            longitude: longitude,
            salesTaxFlagCode: salesTaxFlagCode,
            salesTaxRateAdjustment: salesTaxRateAdjustment,
            flaggedLocationId: flaggedLocationId,
            salesTaxFlagConditions: salesTaxFlagConditions
        });
    }

    SelectVenueAddress = async (value: any) => {
        let address = value != null ? value.address : "";
        let venue = value != null ? value.venue : "";

        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, venueName: venue, address: address, venueAddressSelected: true, addressResults: [], pendingChanges: true });

        this.MapAddress(address);
    }

    HandleStartDateTimeChange = async (startDateTime: Date | null) => {
        this.props.handleChangesPendingChange(true);

        if (startDateTime != null && startDateTime < new Date())
            return;

        let finishDateTime: Date | null = this.state.finishDateTime ?? null;

        if (finishDateTime == null && startDateTime != null) {
            var tempDateTime = new Date(Date.parse(startDateTime?.toString() ?? ""));
            tempDateTime?.setHours(tempDateTime?.getHours() + 2);
            finishDateTime = tempDateTime;
        }

        this.state.startDateTime = startDateTime;
        this.state.finishDateTime = finishDateTime;
        let validationErrors:string[] = [];
        this.ValidateDateTime(validationErrors);
    }

    HandleFinishDateTimeChange = (finishDateTime: Date | null) => {
        this.props.handleChangesPendingChange(true);
        this.state.finishDateTime = finishDateTime;
        let validationErrors:string[] = [];
        this.ValidateDateTime(validationErrors);
    }

    HandleAgeRestrictionChange = (ageRestrictionId: any) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, ageRestrictionId: ageRestrictionId, pendingChanges: true });
    }

    HangleKeywordMultiSelectChange = (selected: string[]) => {
        this.props.handleChangesPendingChange(true);
        this.setState({
            ...this.state,
            selectedKeywords: selected,
            pendingChanges: true
        });
    }

    OnKeywordFilter = async (newValue: string | null) => {

        let keywords: any[] = [];

        if (newValue != null && newValue != "") {
            var response = await searchKeywords(newValue);

            if (response != null && response.status == 200) {
                keywords = response.data.keywords;
            }
            //keywords = filterBy(keywords.slice(), event.filter); 
        }

        this.setState({ ...this.state, filteredKeywords: keywords });
    }

    OnDescriptionChange = async (value: any) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, description: value, pendingChanges: true });
    }

    OnAddFiles = (value: ImageListType) => {

        const afterStateChange = () => {
            value
                .forEach((image: any) => {
                    const reader = new FileReader();

                    reader.onloadend = (ev: any) => {
                        this.AddUploadFilePreview(image.file, ev.target.result);
                    };

                    reader.readAsDataURL(image.file);
                });
        };

        this.AddUploadFiles(value, afterStateChange);
    }

    AddUploadFiles = (files: ImageListType, callback?: (() => void)) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, uploadFiles: files, pendingChanges: true }, callback);
    }

    AddUploadFilePreview = (file: any, contentDisposition: string) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, uploadFilePreviews: { ...this.state.uploadFilePreviews, [file.uid]: contentDisposition }, pendingChanges: true });
    }

    CancelEvent = async () => {
        this.setState({ ...this.state, cancelEventModalVisible: true });
    }

    OnCloseCancelModal = () => {
        this.setState({ ...this.state, cancelEventModalVisible: false });
    }

    HandleCancelEvent = async () => {
        this.props.loadingOn();

        var response = await cancelEvent(this.state.eventId);
        let serverError = '';
        let cancelEventSuccess = false;

        let allOrdersRefundedSuccessfully = true;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=all-events");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                cancelEventSuccess = true;
                allOrdersRefundedSuccessfully = response.data.allOrdersRefundedSuccessfully;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({ ...this.state, serverError: serverError, cancelEventSuccess: cancelEventSuccess, allOrdersRefundedSuccessfully: allOrdersRefundedSuccessfully, cancelEventConfirmationDialogOpen: false, cancelEventModalVisible: false });

        if (serverError === '') {
            await this.LoadPage(false, '');
        }

        this.props.loadingOff();

    }

    CloseSuccessModal = () => {
        this.setState({ ...this.state, deleteEventSuccess: false, cancelEventSuccess: false, allOrdersRefundedSuccessfully: true });
    }

    SaveEvent = async (salesTaxFlagCode: SalesTaxFlagCode) => {
        this.props.loadingOn();

        let eventClusterIdError = '';
        let eventNameError = '';
        let venueNameError = '';
        let addressError = '';
        let ageRestrictionsError = '';
        let errored = false;

        let validationErrors = [];

        let startDateTime: Date = this.state.startDateTime ?? new Date();
        let finishDateTime: Date = this.state.finishDateTime ?? new Date();

        if (!this.state.published) {
            if (this.state.eventClusterId == undefined || this.state.eventClusterId == '') {
                eventClusterIdError = 'Event Group selection required.';
                validationErrors.push(eventClusterIdError);
                errored = true;
            }

            if (this.state.eventName == undefined || this.state.eventName == '') {
                eventNameError = 'Event name required.';
                validationErrors.push(eventNameError);
                errored = true;
            }

            if (this.state.venueName == undefined || this.state.venueName == '') {
                venueNameError = 'Venue name required.';
                validationErrors.push(venueNameError);
                errored = true;
            }

            if (this.state.address == undefined || this.state.address == '') {
                addressError = 'Address required.';
                validationErrors.push(addressError);
                errored = true;
            }

            errored = errored || this.ValidateDateTime(validationErrors);

            if (this.state.ageRestrictionId == undefined) {
                ageRestrictionsError = 'Age Restrictions required.';
                validationErrors.push(ageRestrictionsError);
                errored = true;
            }
        }
        let latitude = this.state.latitude;
        let longitude = this.state.longitude;

        //get longitude and latitude for address
        if (latitude == 0 && longitude == 0) {
            if (this.state.address.length > 0) {
                var response = await geocodeAddress(this.state.address);

                if (response != null && response.status == 200) {
                    if (response.data.items.length > 0) {
                        latitude = response.data.items[0].position.lat;
                        longitude = response.data.items[0].position.lng;
                    }
                }
            }
        }

        let saveEventResponse: SaveEventResponse = {} as SaveEventResponse;
        let serverError = "";

        if (errored == false) {

            if (this.state.eventId == undefined || this.state.eventId == "") {
                saveEventResponse = await this.CreateEvent(latitude, longitude, startDateTime, finishDateTime, salesTaxFlagCode);
            }
            else {
                saveEventResponse = await this.UpdateEvent(latitude, longitude, startDateTime, finishDateTime, salesTaxFlagCode);
            }

            serverError = saveEventResponse.errorMsg;
        }

        this.props.handleChangesPendingChange(serverError != undefined && serverError != '');

        this.setState({
            ...this.state,
            eventClusterIdError: eventClusterIdError,
            eventNameError: eventNameError,
            venueNameError: venueNameError,
            addressError: addressError,
            ageRestrictionsError: ageRestrictionsError,
            validationErrors: validationErrors,
            serverError: serverError,
            salesTaxRateFlagged: saveEventResponse.salesTaxRateFlagged,
            salesTaxRate: saveEventResponse.salesTaxRate,
            salesTaxRateAdjustment: saveEventResponse.salesTaxRateAdjustment,
            flaggedLocationId: saveEventResponse.flaggedLocationId,
            salesTaxFlagConditions: saveEventResponse.salesTaxFlagConditions,
            pendingChanges: serverError != undefined && serverError != ''
        });

        this.props.loadingOff();
    }

    ValidateDateTime = (validationErrors: string[]) => {
        let startDateTimeError = '';
        let finishDateTimeError = '';
        let errored = false;

        let startDateTime: Date = this.state.startDateTime ?? new Date();
        let finishDateTime: Date = this.state.finishDateTime ?? new Date();

        if (this.state.startDateTime == undefined) {
            startDateTimeError = 'Start Date and Time required.';
            validationErrors.push(startDateTimeError);
            errored = true;
        }
        else if (startDateTime < new Date()) {
            startDateTimeError = 'Start Date and Time can not be in the past.';
            validationErrors.push(startDateTimeError);
            errored = true;
        }
        else if (startDateTime > finishDateTime) {
            startDateTimeError = 'Start Date and Time must be before Finish Date and Time.';
            validationErrors.push(startDateTimeError);
            errored = true;
        }

        if (this.state.finishDateTime == undefined) {
            finishDateTimeError = 'Finish Date and Time required.';
            validationErrors.push(finishDateTimeError);
            errored = true;
        }
        else if (finishDateTime < new Date()) {
            finishDateTimeError = 'Finish Date and Time can not be in the past.';
            validationErrors.push(finishDateTimeError);
            errored = true;
        }
        else if (startDateTime > finishDateTime) {
            finishDateTimeError = 'Finish Date and Time must be after Start Date and Time.';
            validationErrors.push(finishDateTimeError);
            errored = true;
        }
        else {
            var startDate = new Date(startDateTime.getTime());
            startDate.setHours(0, 0, 0, 0);

            var finishDate = new Date(finishDateTime.getTime());
            finishDate.setHours(0, 0, 0, 0);

            debugger

            if (finishDate > startDate && (finishDateTime.getHours() > 2 || (finishDateTime.getHours() == 2 && finishDateTime.getMinutes() > 0))) {
                finishDateTimeError = 'Finish Date and Time must not be after 2AM the following day.';
                validationErrors.push(finishDateTimeError);
                errored = true;
            }
        }
        this.setState({
            ...this.state,
            startDateTimeError: startDateTimeError,
            finishDateTimeError: finishDateTimeError
        });
        return errored;
    }

    CreateEvent = async (
        latitude: number,
        longitude: number,
        startDateTime: Date,
        finishDateTime: Date,
        salesTaxFlagCode: SalesTaxFlagCode
    ) => {
        var startDateTimeString = Moment(startDateTime).format('MM/DD/yyyy HH:mm:ss');
        var finishDateTimeString = Moment(finishDateTime).format('MM/DD/yyyy HH:mm:ss');

        let request: ICreateEventRequest = {
            eventClusterId: this.state.eventClusterId,
            eventName: this.state.eventName,
            isPrivateEvent: this.state.isPrivateEvent,
            ageRestrictionId: this.state.ageRestrictionId,
            venueName: this.state.venueName,
            address: this.state.address,
            latitude: latitude,
            longitude: longitude,
            startDateTime: startDateTimeString,
            finishDateTime: finishDateTimeString,
            keywords: this.state.selectedKeywords,
            description: this.state.description,
            salesTaxFlagCode: salesTaxFlagCode
        };

        var response = await createEvent(request);

        let responseData: SaveEventResponse = {} as SaveEventResponse;

        if (response != null && response.status == 200) {
            responseData = response.data;

            if (responseData.success && !responseData.salesTaxRateFlagged) {
                if (this.state.uploadFiles.length > 0 || (this.state.copyEventId != undefined && this.state.copyEventId != "" &&
                    this.state.savedUploadFiles != undefined && this.state.savedUploadFiles.length > 0)) {
                    responseData.errorMsg = await this.UploadFiles(responseData.eventId, true);
                }
                else {
                    this.props.changePage(`event?id=${responseData.eventId}&eventGroupId=${this.state.eventClusterId}&createEventSuccess=true`);
                }
            }
        }
        else {
            responseData.errorMsg = "An unknown error occured. Please try again later";
        }

        return responseData;
    }

    UpdateEvent = async (
        latitude: number,
        longitude: number,
        startDateTime: Date,
        finishDateTime: Date,
        salesTaxFlagCode: SalesTaxFlagCode
    ) => {
        var startDateTimeString = Moment(startDateTime).format('MM/DD/yyyy HH:mm:ss');
        var finishDateTimeString = Moment(finishDateTime).format('MM/DD/yyyy HH:mm:ss');

        let request: IUpdateEventRequest = {
            eventId: this.state.eventId,
            eventClusterId: this.state.eventClusterId,
            eventName: this.state.eventName,
            isPrivateEvent: this.state.isPrivateEvent,
            ageRestrictionId: this.state.ageRestrictionId,
            venueName: this.state.venueName,
            address: this.state.address,
            latitude: latitude,
            longitude: longitude,
            startDateTime: startDateTimeString,
            finishDateTime: finishDateTimeString,
            keywords: this.state.selectedKeywords,
            description: this.state.description,
            salesTaxFlagCode: salesTaxFlagCode,
            acknowledgedSalesTaxRateAdjustment: this.state.salesTaxRateAdjustment,
            flaggedLocationId: this.state.flaggedLocationId,
            deleteUploadFileIds: this.state.deleteUploadFileIds
        };

        var response = await updateEvent(request);
        let responseData: SaveEventResponse = {} as SaveEventResponse;

        if (response != null && response.status == 200) {
            responseData = response.data;

            if (responseData.success && !responseData.salesTaxRateFlagged) {
                if (this.state.uploadFiles.length > 0) {
                    responseData.errorMsg = await this.UploadFiles(this.state.eventId, false);
                }
                else {
                    await this.RefreshWithSuccess(this.state.eventId);
                }
            }
        }
        else {
            responseData.errorMsg = "An unknown error occured. Please try again later";
        }

        return responseData;
    }

    UploadFiles = async (eventId: any, isNewEvent: boolean) => {
        let serverError = '';

        let files = Enumerable.from(this.state.uploadFiles).select((f: any) => f.file).toArray();

        let copyFileIds = this.state.copyEventId != undefined && this.state.copyEventId != "" ? Enumerable.from(this.state.savedUploadFiles).select((f: any) => f.id).toArray() : [];

        var fileUploadResponse = await uploadEventFiles(files, copyFileIds, eventId, EventFileTypeCode.EventImage);

        if (fileUploadResponse != null && fileUploadResponse.status == 200) {
            if (!fileUploadResponse.data.success) {
                this.RefreshWithError(eventId, true, "File failed to upload. Please try again later.");
            }
            else {
                if (isNewEvent == true) {
                    this.props.changePage(`event?id=${eventId}&eventGroupId=${this.state.eventClusterId}&createEventSuccess=true`);
                }
                else {
                    this.RefreshWithSuccess(eventId);
                }
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        return serverError;
    }

    RefreshWithSuccess = async (eventId: any) => {
        await this.LoadPage(true, '');
    }

    RefreshWithError = async (eventId: any, saveSuccess: boolean, error: any) => {
        await this.LoadPage(saveSuccess, error);
    }

    RemoveSavedFile = (index: number) => {
        let savedUploadFiles: any = this.state.savedUploadFiles;

        let savedUploadFilesNew: any = [];
        let deleteUploadFilesIds: any = this.state.deleteUploadFileIds;

        savedUploadFiles.forEach((file: any, i: number) => {
            if (index != i) {
                savedUploadFilesNew.push(file);
            }
            else {
                deleteUploadFilesIds.push(file.id);
            }
        });

        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, savedUploadFiles: savedUploadFilesNew, deleteUploadFilesIds: deleteUploadFilesIds, pendingChanges: true });
    }

    CloseSaveSuccessModal = () => {
        this.setState({ ...this.state, saveSuccess: false });
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, saveError: '', serverError: '' });
    }

    CloseRefundErrorModal = () => {
        this.setState({ ...this.state, allOrdersRefundedSuccessfully: true });
    }

    ShowHtmlModal = () => {
        this.setState({ ...this.state, htmlModalVisible: true });
    }

    CloseHtmlModal = () => {
        this.setState({ ...this.state, htmlModalVisible: false });
    }

    CloseEventSalesTaxModal = () => {
        this.setState({
            ...this.state,
            salesTaxRateFlagged: false,
            salesTaxRateAdjustment: null,
            flaggedLocationId: '',
            salesTaxFlagConditions: ''
        });
    }

    GetImageFileSize = (sizeBytes: number) => {
        var size = "";

        if (sizeBytes < (1000 * 1000)) {
            size = (sizeBytes / 1000).toFixed(3).toString() + " kb";
        }
        else {
            size = (sizeBytes / (1000 * 1000)).toFixed(3).toString() + " mb";
        }

        return size;
    }

    SetEditorRef = (editor: any) => {

        this.setState({ ...this.state, editor: editor })
    }

    render() {
        var canEdit = this.state.eventId == undefined || this.state.eventId == "" || this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditEvents);

        return (
            <div style={{ height: '90%', width: "100%" }}>
                <div style={{ width: "100%", maxHeight: "100%", overflowY: "scroll" }}>
                    <Card style={{ height: '90%', backgroundColor: "#ededed" }}>
                        <CardContent >
                            <div style={{ marginBottom: "10px" }}>
                                {this.state.eventId != undefined && this.state.eventId != '' && this.state.pendingChanges && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "10px" }}>
                                        <WarningIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "5px" }}>
                                        Changes Pending Save
                                    </span>
                                </span>}
                                {this.state.eventId != undefined && this.state.eventId != '' && this.state.pendingChanges == false && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "10px" }}>
                                        <DoneOutlineIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "5px" }}>
                                        Up to date
                                    </span>
                                </span>}
                                {this.state.published && !this.state.cancelled && <div>
                                    <Button variant="contained" className="btn-primary" onClick={this.CancelEvent}>
                                        Cancel Event
                                    </Button>
                                </div>}
                                {this.state.cancelled && <div style={{fontSize: "20px", color: "red"}}>
                                    EVENT CANCELLED
                                </div>}
                            </div>

                            {this.state.canShowHtmlModal &&
                                <div className="pull-right">
                                    <Button variant="contained" className="btn-primary" onClick={this.ShowHtmlModal} >
                                        Events Widget
                                    </Button>
                                </div>
                            }
                            {this.state.eventWebsiteLink != null && this.state.eventWebsiteLink != "" &&
                                <div>
                                    <div>
                                        Note: Event must be published in order for Event Website Link to work
                                    </div>
                                    <div style={{ marginBottom: "20px", fontSize: "20px" }}>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Event Website Link:</td>
                                            <td><a href={this.state.eventWebsiteLink}>{this.state.eventWebsiteLink}</a></td>
                                        </tr>
                                    </div>
                                </div>
                            }
                            {this.state.eventDeepLink != null && this.state.eventDeepLink != "" &&
                                <div>
                                    <div>
                                        Note: Event must be published in order for Event App Link to work
                                    </div>
                                    <div style={{ marginBottom: "20px", fontSize: "20px" }}>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Event App Link:</td>
                                            <td><a href={this.state.eventDeepLink}>{this.state.eventDeepLink}</a></td>
                                        </tr>
                                    </div>
                                </div>
                            }
                            <EventClusterDropdown
                                eventClusterId={this.state.eventClusterId}
                                eventClusters={this.state.eventClusters}
                                eventClusterIdError={this.state.eventClusterIdError}
                                onChange={this.HandleEventClusterChange}
                                disabled={this.state.published || !canEdit}
                            />
                            <FormControlLabel
                                control={<Switch color="secondary" checked={this.state.isPrivateEvent} onChange={this.HandleIsPrivateEventChange} />}
                                label="Private event" />
                            <Tooltip title="Private events are only visible to users that have the event URL link.">
                                <IconButton>
                                    <ErrorOutlineIcon />
                                </IconButton>
                            </Tooltip>
                            <TextField
                                error={this.state.eventNameError !== ""}
                                style={{ width: '100%' }}
                                id="eventName"
                                label="Event Name"
                                placeholder="Event Name"
                                margin="normal"
                                helperText={this.state.eventNameError}
                                FormHelperTextProps={{ color: "red" }}
                                value={this.state.eventName}
                                onChange={(e) => this.HandleEventNameChange(e)}
                                inputProps={{ maxLength: 200 }}
                                variant="outlined"
                                disabled={this.state.published || !canEdit}
                            />
                            {this.state.eventName.length}/200
                            <div style={{ width: "100%" }}>
                                <Autocomplete
                                    options={this.state.venueAddressResults}
                                    filterOptions={(x) => x}
                                    disabled={this.state.published || !canEdit}
                                    getOptionLabel={(option: any) => option.venue}
                                    renderOption={(option: any) => option.venueAddress}
                                    style={{ width: "100%" }}
                                    value={{ venue: this.state.venueName, venueAddress: this.state.venueName }}
                                    onChange={(e, v) => this.SelectVenueAddress(v)}
                                    //onInputChange={_.debounce((value: any) => this.HandleVenueNameChange(value), 500)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        error={this.state.venueNameError !== ""}
                                        style={{ width: '100%', backgroundColor: "white" }}
                                        id="venueName"
                                        label="Venue Name"
                                        placeholder="Venue Name"
                                        margin="normal"
                                        helperText={this.state.venueNameError}
                                        FormHelperTextProps={{ color: "red" }}
                                        onChange={(e) => this.HandleVenueNameChange(e)}
                                        variant="outlined"
                                    />}
                                />

                                <strong>Note: Must select address option from the autosuggestion list to get correct format for our system</strong>
                                {this.state.venueAddressSelected == false && <Autocomplete
                                    options={this.state.addressResults}
                                    filterOptions={(x) => x}
                                    disabled={this.state.published || !canEdit}
                                    getOptionLabel={(option: any) => option.address != undefined ? option.address.label : ""}
                                    style={{ width: "100%" }}
                                    onChange={(e, v) => this.SelectAddress(v)}
                                    onInputChange={(e, v) => this.HandleAddressInputChange(v)}
                                    clearOnBlur={true}
                                    renderInput={(params) => <TextField {...params}
                                        error={this.state.addressError !== ""}
                                        id="address"
                                        label="Search Address"
                                        placeholder="Search Address"
                                        margin="normal"
                                        helperText={this.state.addressError}
                                        FormHelperTextProps={{ color: "red" }}
                                        variant="outlined"
                                        style={{ width: '100%', backgroundColor: "white" }}
                                        autoComplete="off"
                                        autoFocus={this.state.searchAddressFocused}
                                        onBlur={() => this.setState({ ...this.state, searchAddressFocused: false })}
                                    />}
                                />}

                                {this.state.venueAddressSelected == true && <TextField
                                    error={this.state.addressError !== ""}
                                    id="address"
                                    label="Address"
                                    placeholder="Address"
                                    margin="normal"
                                    helperText={this.state.addressError}
                                    value={this.state.address}
                                    onChange={this.ClearAddress}
                                    style={{ width: "100%" }}
                                    FormHelperTextProps={{ color: "red" }}
                                    variant="outlined"
                                    disabled={this.state.published || !canEdit}
                                />}
                            </div>
                            <div>
                                <TextField
                                    error={this.state.salesTaxError !== ""}
                                    style={{ width: '100%' }}
                                    id="salesTax"
                                    label="Sales Tax Applied to Tickets (Sales Tax Rate + Adjustment)"
                                    placeholder="Sales Tax Applied to Tickets (Sales Tax Rate + Adjustment)"
                                    margin="normal"
                                    helperText=''
                                    FormHelperTextProps={{ color: "red" }}
                                    value={(this.state.salesTax * 100).toFixed(3) + "%"}
                                    disabled={true}
                                    variant="outlined"
                                />
                            </div>
                            <div style={{ width: "100%", alignContent: "center" }}>
                                <Map
                                    mapCoord={this.state.mapCoord}
                                    zoom={this.state.mapZoom}
                                    center={this.state.mapCenter}
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC5ep6mbe8H3qCiyFgLd_JJLc_57OObSU4&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div className="eventGoogleMap" />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </div>
                            <div style={{ height: "auto" }}>
                                <div style={{ width: "100%", float: "left" }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Start Date Time"
                                            inputVariant="outlined"
                                            value={this.state.startDateTime ? this.state.startDateTime : null}
                                            onChange={this.HandleStartDateTimeChange}
                                            error={this.state.startDateTimeError !== ""}
                                            helperText={this.state.startDateTimeError}
                                            FormHelperTextProps={{ color: "red" }}
                                            format="MM/dd/yyyy hh:mm a"
                                            className="startDateTime"
                                            disabled={this.state.published || !canEdit}
                                            disablePast={!this.state.published && canEdit}
                                        />
                                        <DateTimePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Finish Date Time"
                                            inputVariant="outlined"
                                            value={this.state.finishDateTime ? this.state.finishDateTime : null}
                                            onChange={this.HandleFinishDateTimeChange}
                                            error={this.state.finishDateTimeError !== ""}
                                            helperText={this.state.finishDateTimeError}
                                            FormHelperTextProps={{ color: "red" }}
                                            format="MM/dd/yyyy hh:mm a"
                                            className="finishStartTime"
                                            disabled={this.state.published || !canEdit}
                                            disablePast={!this.state.published && canEdit}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                            <div style={{ width: "100%", paddingTop: "15px" }}>
                                <GenericDropdown
                                    label="Age Restrictions"
                                    value={this.state.ageRestrictionId}
                                    error={this.state.ageRestrictionError}
                                    data={this.state.ageRestrictions}
                                    disabled={this.state.published || !canEdit}
                                    onChange={this.HandleAgeRestrictionChange}
                                />
                            </div>

                            <div style={{ width: "100%", marginTop: "10px" }}>

                                <Autocomplete
                                    multiple
                                    options={this.state.filteredKeywords.map((keyword) => keyword)}
                                    onChange={(e, newValue) => { this.HangleKeywordMultiSelectChange(newValue) }}
                                    value={this.state.selectedKeywords}
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((keyword: string, index: number) => (
                                            <Chip variant="outlined" label={keyword} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Keywords (Please select at least 3 for best results)"
                                            placeholder="Keywords (Please select at least 3 for best results)"
                                            onChange={(e) => { this.OnKeywordFilter(e.currentTarget.value) }}
                                        />
                                    )}
                                />
                            </div>
                            <h4>Description</h4>

                            {this.state.loaded && <MarkdownEditor value={this.state.description} onChange={(value) => this.OnDescriptionChange(value)} >

                            </MarkdownEditor>}

                            {this.state.description.length}/2000

                            <div>
                                <h4>Upload Event Image (Limit 1)</h4>
                                <div><i>For best results use an image that is a rectangle with a greater width than height. A 3:2 aspect ratio and at least 200 pixels across is ideal.</i></div>
                                <br />
                                {this.state.savedUploadFiles.length == 0 && <ImageUploading
                                    value={this.state.uploadFiles}
                                    onChange={(files: ImageListType) => this.OnAddFiles(files)}
                                    maxNumber={1}
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps
                                    }) => (
                                        // write your building UI
                                        <div className="upload__image-wrapper">
                                            {imageList.length == 0 && <button
                                                style={isDragging ? { color: "red", border: "0.5px solid red", width: "100%", height: "200px", backgroundColor: "white", cursor: "pointer" } : { color: "blue", border: "0.5px solid blue", width: "100%", height: "200px", backgroundColor: "white", cursor: "pointer" }}
                                                onClick={onImageUpload}
                                                type="button"
                                                {...dragProps}

                                            >
                                                Click or drop image here
                                            </button>}
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <div className="image-item__btn-wrapper" style={{ paddingBottom: "20px" }}>
                                                        <Button onClick={() => onImageUpdate(index)} style={{ marginRight: "20px", width: "200px", height: "50px" }} className="btn-secondary">Click to replace image</Button>
                                                    </div>
                                                    <div>
                                                        {(image?.file != undefined) ? image.file.name : ""} {this.GetImageFileSize(image?.file!.size ?? 0)}
                                                    </div>
                                                    <img
                                                        src={image?.dataURL}
                                                        alt={'image preview'}
                                                        style={{ width: 200, margin: 10 }}
                                                    />
                                                    <div style={{ width: "200px", textAlign: "center" }}>
                                                        <button onClick={() => onImageRemove(index)} style={{ cursor: "pointer" }}>
                                                            <ClearIcon />
                                                        </button>
                                                    </div>
                                                </div>))}
                                        </div>
                                    )}
                                </ImageUploading>}
                                {/* {this.state.uploadFiles.length > 0 ?
                                    <div className={'img-preview example-config'}>
                                        <h3>Preview selected images</h3>
                                        {
                                            Object.keys(this.state.uploadFilePreviews)
                                                .map((fileKey: any) => (
                                                    <div>
                                                        {(fileKey?.file != undefined) ? fileKey.file.name : ""} {this.GetImageFileSize(fileKey?.file!.size ?? 0)}
                                                        <img
                                                        src={this.state.uploadFilePreviews[fileKey]}
                                                        alt={'image preview'}
                                                        style={{ width: 200, margin: 10 }}
                                                        />
                                                    </div>
                                                ))
                                        }
                                    </div> : undefined
                                } */}
                                {this.state.savedUploadFiles.length > 0 && <div style={{ width: "100%" }}>
                                    {
                                        this.state.savedUploadFiles.map((file: any, index: number) => (
                                            <span style={{ width: "200px" }}>
                                                <div style={{ width: "200px", textAlign: "center" }}>
                                                    FileName: {file.fileName}
                                                </div>
                                                <div>
                                                    <img
                                                        src={file.fileUrl}
                                                        alt={'image preview'}
                                                        style={{ width: 200, margin: 10 }}
                                                    />
                                                </div>
                                                <div style={{ width: "200px", textAlign: "center" }}>
                                                    <button onClick={() => this.RemoveSavedFile(index)} title={`Remove "${file.fileName}"`}>
                                                        <ClearIcon />
                                                    </button>
                                                </div>
                                            </span>
                                        ))}
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <Button variant="contained" className="btn-primary" style={{ width: "50%", minWidth: "100px", marginLeft: "25%" }} onClick={() => this.SaveEvent(this.state.salesTaxFlagCode)} disabled={!canEdit}>
                                    Save Event
                                </Button>
                                {this.state.eventId != undefined && this.state.eventId != '' && this.state.pendingChanges && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "10px" }}>
                                        <WarningIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "5px" }}>
                                        Changes Pending Save
                                    </span>
                                </span>}
                                {this.state.eventId != undefined && this.state.eventId != '' && this.state.pendingChanges == false && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "10px" }}>
                                        <DoneOutlineIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "5px" }}>
                                        Up to date
                                    </span>
                                </span>}
                            </div>

                            {this.state.validationErrors.length > 0 && <div style={{ color: 'red', paddingTop: "10px" }}>
                                {this.state.validationErrors.map((value) => {
                                    return <div>{value}</div>
                                })}
                            </div>
                            }

                        </CardContent>
                    </Card>
                </div>
                <GenericSuccessModal
                    hidden={this.state.saveSuccess == false}
                    message="Your event saved successfully."
                    closeModal={this.CloseSaveSuccessModal}
                />
                <GenericErrorModal hidden={this.state.serverError == undefined || this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
                <GenericErrorModal hidden={this.state.saveError == undefined || this.state.saveError == ''}
                    message={this.state.saveError}
                    closeModal={this.CloseErrorModal} />
                <GenericSuccessModal
                    hidden={this.state.cancelEventSuccess == false}
                    message={"The event was cancelled successfully."}
                    closeModal={this.CloseSuccessModal}
                />
                <GenericErrorModal hidden={this.state.allOrdersRefundedSuccessfully}
                    message="Some orders didn't get refunded successfully. Please contact us at support@nrby.io to get the remaining orders refunded."
                    closeModal={this.CloseRefundErrorModal} />
                <GenericConfirmationModal hidden={!this.state.cancelEventModalVisible}
                    cancelButtonText="Cancel" confirmButtonText="Ok"
                    onConfirm={this.HandleCancelEvent}
                    closeModal={this.OnCloseCancelModal}
                    message="Are you sure that you want to cancel this event? Any tickets currently purchased for this event will be automatically refunded without the Processing Fee we charge to cover the cost Stripe Processing Fees and Account Payout Fees. This Processing Fee equates to $0.30 plus 3.25% * (Ticket Price + Service Fee + Sales Tax) per Order."
                />
                <Dialog
                    onClose={this.CloseEventSalesTaxModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="genericConfirmationModal"
                    open={this.state.salesTaxRateFlagged}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Confirmation</Typography>
                    </DialogTitle>
                    <DialogContent dividers style={{ maxWidth: "500px" }}>
                        There is a special tax of {((this.state.salesTaxRateAdjustment ?? 0) * 100).toFixed(3)}% in addition to the {(this.state.salesTaxRate * 100).toFixed(3)}% sales tax in your region for events that meet the following conditions: <strong>{this.state.salesTaxFlagConditions}</strong>
                        <p>
                            If your event meets these conditions, please select "<strong>yes.</strong>"
                        </p>
                        <p>
                            You are liable for any sales tax inaccuracies for your event, so please verify your sales tax responsibility to the best of your ability.
                        </p>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={() => this.SaveEvent(SalesTaxFlagCode.AdjustmentNotAccepted)} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={() => this.SaveEvent(SalesTaxFlagCode.AdjustmentAccepted)} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <EmbedEventsHtmlModal eventIds={[this.state.eventId]} closeModal={this.CloseHtmlModal}
                    visible={this.state.htmlModalVisible}
                />
            </div>
        );
    }
}