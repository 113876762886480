import React from 'react';
import '../App.css';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import { signUpNewUser } from '../../data/PortalUserRepository';
import Link from '@material-ui/core/Link';
import validator from 'email-validator';
import { validPhoneNumber, validUsername } from '../Shared/ValidationFunctions';
import { GenericErrorModal } from '../Components/GenericErrorModal';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
/// <reference path="./models/RequestModels.d.ts" />

interface ISignUpPageProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class SignUpPage extends React.Component<ISignUpPageProps> {

    state = {
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        phoneNumber: '',
        password: '',
        passwordConfirm: '',
        firstNameError: '',
        lastNameError: '',
        usernameError: '',
        emailError: '',
        phoneNumberError: '',
        passwordError: '',
        passwordConfirmError: '',
        serverError: '',
        passwordRequirementsModalOpen: false,
    }

    HandleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, firstName: event.currentTarget.value })
    }

    HandleLastNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, lastName: event.currentTarget.value })
    }

    HandleUsernameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, username: event.currentTarget.value })
    }

    HandleOrganizationNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, organizatioName: event.currentTarget.value })
    }

    HandleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, email: event.currentTarget.value })
    }

    HandlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, phoneNumber: event.currentTarget.value })
    }

    HandlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, password: event.currentTarget.value })
    }

    HandlePasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, passwordConfirm: event.currentTarget.value })
    }

    HandleSignUp = async () => {
        this.props.loadingOn();

        let firstNameError = "";
        let lastNameError = "";
        let usernameError = "";
        let emailError = "";
        let phoneNumberError = "";
        let passwordError = "";
        let passwordConfirmError = "";
        let errored = false;

        if (this.state.firstName.length == 0) {
            firstNameError = "First name required.";
            errored = true;
        }

        if (this.state.lastName.length == 0) {
            lastNameError = "Last name required";
            errored = true;
        }

        if (this.state.username.length == 0) {
            usernameError = "Username required.";
            errored = true;
        }

        if (!validUsername(this.state.username)) {
            usernameError = "A username can only contain alphanumeric characters (letters A-Z, numbers 0-9), dash and underscore";
            errored = true;
        }

        if (this.state.email.length == 0) {
            emailError = "Email required.";
            errored = true;
        }
        else if (validator.validate(this.state.email) == false) {
            emailError = `"${this.state.email}" is not a valid email address.`;
            errored = true;
        }

        if (!this.state.phoneNumber || this.state.phoneNumber.length == 0){
            phoneNumberError = "Phone number required";
            errored = true;
        }

        if (this.state.phoneNumber.length > 0 && validPhoneNumber(this.state.phoneNumber) == false) {
            phoneNumberError = `"${this.state.phoneNumber}" is not a valid 10 digit phone number.`;
            errored = true;
        }

        if (this.state.password.length == 0) {
            passwordError = "Password required.";
            errored = true;
        }

        if (this.state.passwordConfirm.length == 0) {
            passwordConfirmError = "Password confirmation required.";
            errored = true;
        }

        if (this.state.password.length > 0 && this.state.passwordConfirm.length > 0 && this.state.password != this.state.passwordConfirm) {
            passwordError = "Password and password confirmation must match.";
            passwordConfirmError = "Password and password confirmation must match.";
            errored = true;
        }

        let serverErrorText = "";

        if (!errored) {

            let request: ISignUpRequest = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                username: this.state.username,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                password: this.state.password
            };

            var response = await signUpNewUser(request);

            if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverErrorText = response.data.errorMsg;
                }
                else {
                    this.props.changePage("/sign-up-success");
                }
            }
            else {
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            usernameError: usernameError,
            emailError: emailError,
            phoneNumberError: phoneNumberError,
            passwordError: passwordError,
            passwordConfirmError: passwordConfirmError,
            serverError: serverErrorText,
        });

        this.props.loadingOff();
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: "" });
    };

    OpenPasswordRequirementsModal = () => {
        this.setState({
            ...this.state,
            passwordRequirementsModalOpen: true
        });
    }

    ClosePasswordRequirementsModal = () => {
        this.setState({
            ...this.state,
            passwordRequirementsModalOpen: false
        });
    }

    render() {
        const classes = {
            loginBtn: {
                //marginTop: theme.spacing(2),
                flexGrow: 1
            }
        };

        return (
            <div>
                <Card className='signupCard' >
                    <CardHeader style={{ textAlign: 'center', background: '#212121', color: '#fff' }} title="Event Creator Portal" />
                    <CardContent>
                        <div>
                            <TextField
                                error={this.state.firstNameError !== ""}
                                fullWidth
                                id="firstName"
                                type="text"
                                label="First Name"
                                placeholder="First Name"
                                margin="normal"
                                helperText={this.state.firstNameError}
                                FormHelperTextProps={{ color: "red" }}
                                onChange={(e) => this.HandleFirstNameChange(e)}
                                variant="outlined"
                            />
                            <TextField
                                error={this.state.lastNameError !== ""}
                                fullWidth
                                id="lastName"
                                type="text"
                                label="Last Name"
                                placeholder="Last Name"
                                margin="normal"
                                helperText={this.state.lastNameError}
                                FormHelperTextProps={{ color: "red" }}
                                onChange={(e) => this.HandleLastNameChange(e)}
                                variant="outlined"
                            />
                            <TextField
                                error={this.state.usernameError !== ""}
                                fullWidth
                                id="username"
                                type="text"
                                label="Username"
                                placeholder="Username"
                                margin="normal"
                                helperText={this.state.usernameError}
                                FormHelperTextProps={{ color: "red" }}
                                onChange={(e) => this.HandleUsernameChange(e)}
                                variant="outlined"
                            />
                            <TextField
                                error={this.state.emailError !== ""}
                                fullWidth
                                id="email"
                                type="email"
                                label="Email"
                                placeholder="Email"
                                margin="normal"
                                helperText={this.state.emailError}
                                FormHelperTextProps={{ color: "red" }}
                                onChange={(e) => this.HandleEmailChange(e)}
                                variant="outlined"
                            />
                            <TextField
                                error={this.state.phoneNumberError !== ""}
                                fullWidth
                                id="phone"
                                type="tel"
                                label="Phone Number"
                                placeholder="Phone Number"
                                margin="normal"
                                helperText={this.state.phoneNumberError}
                                FormHelperTextProps={{ color: "red" }}
                                onChange={(e) => this.HandlePhoneNumberChange(e)}
                                variant="outlined"
                            />
                            <a href="#" onClick={this.OpenPasswordRequirementsModal}>Password Requirements</a>
                            <TextField
                                error={this.state.passwordError !== ""}
                                fullWidth
                                id="password"
                                type="password"
                                label="Password"
                                placeholder="Password"
                                margin="normal"
                                helperText={this.state.passwordError}
                                FormHelperTextProps={{ color: "red" }}
                                onChange={(e) => this.HandlePasswordChange(e)}
                                variant="outlined"
                            />
                            <TextField
                                error={this.state.passwordConfirmError !== ""}
                                fullWidth
                                id="passwordConfirm"
                                type="password"
                                label="Password Confirmation"
                                placeholder="Password Confirmation"
                                margin="normal"
                                helperText={this.state.passwordConfirmError}
                                FormHelperTextProps={{ color: "red" }}
                                onChange={(e) => this.HandlePasswordConfirmChange(e)}
                                variant="outlined"
                            />
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            size="large"
                            className='btn-primary'
                            style={classes.loginBtn}
                            onClick={this.HandleSignUp}
                        >
                            Sign Up
                        </Button>
                    </CardActions>
                    <CardContent>
                        <div style={{ width: "100%" }}>
                            <div>
                                By clicking Sign Up, you agree to our&nbsp;
                                <Link href="https://nrby.io/terms/" target="_blank">
                                    Terms
                                </Link>
                                ,&nbsp;
                                <Link href="https://nrby.io/privacy/" target="_blank">
                                    Data Policy & Cookies Policy
                                </Link>
                                . An email will be sent out to you to confirm your signup.
                            </div>
                        </div>
                    </CardContent>
                    <CardContent>
                        <div>
                            <Link href="#" onClick={() => this.props.changePage('/resend-confirmation')}>
                                Resend Confirmation Email
                            </Link>
                        </div>
                    </CardContent>
                </Card>
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
                <Dialog
                    onClose={this.ClosePasswordRequirementsModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="genericConfirmationModal"
                    open={this.state.passwordRequirementsModalOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-danger'>
                        <Typography variant="h6">Password Requirements</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.ClosePasswordRequirementsModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <ul>
                            <li>
                                Must be at least eight characters long
                            </li>
                            <li>
                                Contain at least one NonAlphanumeric (Special) character. For example %, #, $ or !.
                            </li>
                            <li>
                                Contain at least one number/digit
                            </li>
                        </ul>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.ClosePasswordRequirementsModal} className='action-button'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}