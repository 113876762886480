/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { deleteEvent, cancelEvent, getEventClusterEvents } from '../../../data/EventRepository';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { EventStatusCode } from '../../../data/Models/Enums';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { EventFinancialsModal } from '../SubComponents/EventFinancialsModal';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import { GenericDisplayTrueFalseIconCell } from '../SubComponents/GenericDisplayTrueFalseIconCell';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DateFilterCell } from '../SubComponents/DateFilterCell';

var Enumerable = require('linq');

interface IEventClusterEventsSectionProps {
    userPermissions: EventClusterPermission[],
    isClusterOwner: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IEventClusterEventsSectionState {
    eventClusterId: string,
    events: any[],
    eventsTotal: number,
    pageable: any,
    tableDef: ITableDef,
    serverError: string,
    deleteEventConfirmationDialogOpen: boolean,
    cancelEventConfirmationDialogOpen: boolean,
    cancelEventWithOrdersConfirmationDialogOpen: boolean,
    activeEventId: string,
    deleteEventSuccess: boolean,
    cancelEventSuccess: boolean,
    financialsModalOpen: boolean,
    totalIncome: number,
    totalFees: number,
    totalTicketsPurchased: number,
    payoutDateTime: Date | undefined,
    payoutAttempts: number,
    payoutSuccessful: boolean,
    mobileFiltersVisible: boolean
}

const getInitTableDef = () => {
    let sortDef: ISortDef = {
        field: "StartDateTime",
        dir: "desc"
    };

    let filterArray = [
        {
            field: "name",
            operator: "eq",
            value: ""
        },
        {
            field: "venue",
            operator: "eq",
            value: ""
        },
        {
            field: "address",
            operator: "eq",
            value: ""
        },
        {
            field: "published",
            operator: "eq",
            value: null
        },
        {
            field: "startDateTimeLocal",
            operator: "eq",
            value: null
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let gridState: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return gridState;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class EventClusterEventsSection extends React.Component<IEventClusterEventsSectionProps, IEventClusterEventsSectionState> {

    state = {
        eventClusterId: '',
        events: [],
        eventsTotal: 0,
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'venue',
                label: 'Venue',
            },
            {
                id: 'address',
                label: 'Address',
            },
            {
                id: 'published',
                label: 'Published'
            },
            {
                id: 'startDateTimeLocal',
                label: 'Start Date/Time'
            }
        ],
        serverError: '',
        deleteEventConfirmationDialogOpen: false,
        cancelEventConfirmationDialogOpen: false,
        cancelEventWithOrdersConfirmationDialogOpen: false,
        activeEventId: '',
        deleteEventSuccess: false,
        cancelEventSuccess: false,
        financialsModalOpen: false,
        totalIncome: 0,
        totalFees: 0,
        totalTicketsPurchased: 0,
        payoutDateTime: undefined,
        payoutAttempts: 0,
        payoutSuccessful: false,
        mobileFiltersVisible: false
    }

    async componentDidMount() {
        this.props.loadingOn();
        const query = new URLSearchParams(window.location.search);

        var eventClusterId: string = query.get('id') ?? "";

        await this.LoadEventClusterEvents(eventClusterId, this.state.tableDef);
        this.props.loadingOff();
    }

    LoadEventClusterEvents = async (eventClusterId: any, tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let events: any = [];
        let eventsTotal = 0;
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "") || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getEventClusterEvents(eventClusterId, tempTableDef);

        if (response != null && response.status == 401) {
            let eventClusterParam = eventClusterId != null && eventClusterId != "" ? `?id=${eventClusterId}` : "";
            this.props.changePage("/login?redirect=event-group" + eventClusterParam);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                events = response.data.events;
                eventsTotal = response.data.eventClusterEventsTotal;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({ ...this.state, eventClusterId: eventClusterId, events: events, eventsTotal: eventsTotal, tableDef: tableDef, serverError: serverError });
    }

    CreateEventClick = (eventId: string) => {
        var createEventUrl = `/create-event?eventGroupId=${this.state.eventClusterId}`;

        if (eventId != undefined && eventId != "") {
            createEventUrl += `&copyEventId=${eventId}`;
        }

        this.props.changePage(createEventUrl);
    }

    EditEventClick = (eventId: any) => {
        this.props.changePage(`/event?id=${eventId}&eventGroupId=${this.state.eventClusterId}`);
    }

    DeleteEventClick = (eventId: any) => {
        this.setState({ ...this.state, deleteEventConfirmationDialogOpen: true, activeEventId: eventId });
    }

    CancelEventClick = (eventId: any, hasOrders: boolean) => {
        if (hasOrders)
            this.setState({ ...this.state, cancelEventWithOrdersConfirmationDialogOpen: true, activeEventId: eventId });
        else
            this.setState({ ...this.state, cancelEventConfirmationDialogOpen: true, activeEventId: eventId });
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadEventClusterEvents(this.state.eventClusterId, tableDef);
        }

        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        if (filter == "published") {
            if (value == "false") {
                filterDef.f.value = false;
            }
            else if (value == "true") {
                filterDef.f.value = true;
            }
            else {
                filterDef.f.value = null;
            }
        }
        else if (filter == "startDateTimeLocal" && value != null && value != "") {
            filterDef.f.value = value.toLocaleDateString();
        }
        else {
            filterDef.f.value = value;
        }

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadEventClusterEvents(this.state.eventClusterId, tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadEventClusterEvents(this.state.eventClusterId, tableDef);
        this.props.loadingOff();
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadEventClusterEvents(this.state.eventClusterId, tableDef);

        this.props.loadingOff();
    };

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: "" });
    };

    CloseDeleteEventModal = () => {
        this.setState({ ...this.state, deleteEventConfirmationDialogOpen: false });
    }

    DeleteEvent = async () => {
        this.props.loadingOn();
        var response = await deleteEvent(this.state.activeEventId);
        let serverError = '';

        let deleteEventSuccess = false;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group" + this.state.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                deleteEventSuccess = true;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({ ...this.state, serverError: serverError, deleteEventConfirmationDialogOpen: false });
        await this.LoadEventClusterEvents(this.state.eventClusterId, this.state.tableDef);
        this.props.loadingOff();
    }

    CancelEvent = async () => {
        this.props.loadingOn();
        var response = await cancelEvent(this.state.activeEventId);
        let serverError = '';

        let cancelEventSuccess = false;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group" + this.state.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                cancelEventSuccess = true;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state, serverError: serverError,
            cancelEventConfirmationDialogOpen: false,
            cancelEventWithOrdersConfirmationDialogOpen: false,
            cancelEventSuccess: cancelEventSuccess
        });
        if (serverError === '') {
            await this.LoadEventClusterEvents(this.state.eventClusterId, this.state.tableDef);
        }

        this.props.loadingOff();
    }

    CloseCancelEventModal = () => {
        this.setState({ ...this.state, cancelEventConfirmationDialogOpen: false, cancelEventWithOrdersConfirmationDialogOpen: false });
    }

    CloseSuccessModal = () => {
        this.setState({ ...this.state, deleteEventSuccess: false, cancelEventSuccess: false });
    }

    OpenFinancialsModal = (eventFinancials: any) => {
        this.setState({
            ...this.state,
            financialsModalOpen: true,
            totalIncome: eventFinancials.totalIncome,
            totalFees: eventFinancials.totalFees,
            totalTicketsPurchased: eventFinancials.totalTicketsPurchased,
            payoutDateTime: eventFinancials.payoutDateTime,
            payoutAttempts: eventFinancials.payoutAttempts,
            payoutSuccessful: eventFinancials.payoutSuccessful
        });
    }

    CloseFinancialsModal = () => {
        this.setState({
            ...this.state,
            financialsModalOpen: false,
            totalIncome: 0,
            totalFees: 0,
            totalTicketsPurchased: 0,
            payoutDateTime: undefined,
            payoutAttempts: 0,
            payoutSuccessful: false
        });
    }

    ToggleFilterVisibility = () => {
        this.setState({ ...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible });
    }

    render() {
        let createEventsAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.CreateEvents);
        let editEventsAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditEvents);
        let deleteEventAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.DeleteOrCancelEvents);

        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "StartDateTimeLocal";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{ width: "100%" }}>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <CardHeader title="Events" />
                    <CardContent style={{ paddingTop: '10px' }}>
                        {createEventsAccess && <Button variant="contained" className='btn-primary' onClick={() => this.CreateEventClick("")}>
                            Create Event
                        </Button>}
                    </CardContent>
                    <CardContent>
                        <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility} />
                        <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className={this.state.mobileFiltersVisible ? "table-filters-visible organizations-table" : "table-filters-hidden"}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        <TableSortLabel
                                                            active={currentSortName === headCell.id}
                                                            direction={currentSortName === headCell.id ? currentSortDir : null}
                                                            hideSortIcon={currentSortName !== headCell.id}
                                                            onClick={() => this.OnSortChange(headCell.id)}
                                                            style={{ width: "100%" }}
                                                        >
                                                            {headCell.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                                <TableCell className="table-header-border no-show-on-mobile"
                                                >
                                                    Financials
                                                </TableCell>
                                                <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                                    <TableCell className="table-header-border show-on-mobile" >
                                                        {headCell.field != "published" && headCell.field != "startDateTimeLocal" &&
                                                            <TextField
                                                                margin="dense"
                                                                fullWidth
                                                                size="small"
                                                                onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                                value={headCell.value}
                                                                variant="outlined"
                                                            />
                                                        }
                                                        {headCell.field == "published" &&

                                                            <FormControl variant="filled" size="small" fullWidth>
                                                                <Select
                                                                    value={headCell.value}
                                                                    label="Published"
                                                                    onChange={(e) => this.OnFilterChange(headCell.field, e.target.value)}
                                                                    variant="outlined"
                                                                    style={{ marginTop: "5px" }}
                                                                    margin="dense"

                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">Both</MenuItem>
                                                                    <MenuItem value="false">False</MenuItem>
                                                                    <MenuItem value="true">True</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                        {headCell.field == "startDateTimeLocal" &&
                                                            <DateFilterCell value={headCell.value} field={headCell.field} onChange={this.OnFilterChange} />
                                                        }
                                                    </TableCell>
                                                ))}
                                                <TableCell className="table-header-border no-show-on-mobile">

                                                </TableCell>
                                                <TableCell className="table-header-border no-show-on-mobile">

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.events.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                    >
                                                        <TableCell align="right"><GenericDisplayCell title="Name" value={row.name} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Venue" value={row.venue} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Address" value={row.address} /></TableCell>
                                                        <TableCell align="right">
                                                            <GenericDisplayTrueFalseIconCell title="Published" isTrue={row.published} />
                                                            {row.statusCode == EventStatusCode.Cancelled && <Chip label="Cancelled"  color='secondary' size="small"/>}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <GenericDisplayCell title="Start Date/Time" value={row.startEventDateTimeLocal} />
                                                        </TableCell>
                                                        <TableCell style={{ color: "#1976d2", textDecoration: "underline" }} align="center">
                                                            {row.eventFinancials != null && <Typography><Link href="#" onClick={() => this.OpenFinancialsModal(row.eventFinancials)}>View Financials</Link></Typography>}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>
                                                            {row.copyable && <Button variant="contained" className="btn-copy" style={{ marginRight: "5px", marginBottom: "5px" }} size="small" onClick={() => this.CreateEventClick(row.id)}>
                                                                Copy
                                                            </Button>}
                                                            <Button variant="contained" className="btn-secondary" style={{ marginRight: "5px", marginBottom: "5px" }} size="small" onClick={() => this.EditEventClick(row.id)}>
                                                                {editEventsAccess ? "Edit" : "View"}
                                                            </Button>
                                                            {deleteEventAccess && !row.published &&
                                                                <Button variant="contained" className="btn-delete" style={{ marginRight: "5px", marginBottom: "5px" }} size="small" onClick={() => this.DeleteEventClick(row.id)}>
                                                                    Delete
                                                                </Button>}
                                                            {deleteEventAccess && row.published && row.cancelable &&
                                                                row.statusCode != EventStatusCode.Cancelled &&
                                                                <Button variant="contained" className="btn-delete" size="small" style={{ marginRight: "5px", marginBottom: "5px" }}
                                                                    onClick={() => this.CancelEventClick(row.id, row.hasOrders)}>
                                                                    Cancel
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={this.state.eventsTotal}
                                    rowsPerPage={this.state.tableDef.take!}
                                    page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                    onChangePage={(e, page) => this.PageChange(page)}
                                    onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                                />
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
                <Dialog
                    onClose={this.CloseDeleteEventModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.deleteEventConfirmationDialogOpen}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Delete Event</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseDeleteEventModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this event?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseDeleteEventModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.DeleteEvent} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={this.CloseCancelEventModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.cancelEventConfirmationDialogOpen}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Cancel Event</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseCancelEventModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to cancel this event?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseCancelEventModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.CancelEvent} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <GenericConfirmationModal hidden={!this.state.cancelEventWithOrdersConfirmationDialogOpen}
                    cancelButtonText="Cancel" confirmButtonText="Ok"
                    onConfirm={this.CancelEvent}
                    closeModal={this.CloseCancelEventModal}
                    message="Are you sure that you want to cancel this event? Any tickets currently purchased for this event will be automatically refunded without the Processing Fee we charge to cover the cost Stripe Processing Fees and Account Payout Fees. This Processing Fee equates to $0.30 plus 3.25% * (Ticket Price + Service Fee + Sales Tax) per Order."
                />
                <EventFinancialsModal
                    totalIncome={this.state.totalIncome}
                    totalFees={this.state.totalFees}
                    totalTicketsPurchased={this.state.totalTicketsPurchased}
                    modalOpen={this.state.financialsModalOpen}
                    payoutDateTime={this.state.payoutDateTime}
                    payoutAttempts={this.state.payoutAttempts}
                    payoutSuccessful={this.state.payoutSuccessful}
                    closeModal={this.CloseFinancialsModal}
                />
                <GenericSuccessModal
                    hidden={this.state.deleteEventSuccess == false}
                    message="The event was deleted successfully."
                    closeModal={this.CloseSuccessModal}
                />
                <GenericSuccessModal
                    hidden={this.state.cancelEventSuccess == false}
                    message="The event was cancelled successfully."
                    closeModal={this.CloseSuccessModal}
                />
            </div>
        )
    }
}