import * as React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { resetOnboarding } from '../../../data/OnboardingRepository';

export class SetupFailedStage extends React.Component<IOnboardingStageProps> {
    public static defaultProps: Partial<IOnboardingStageProps> = {
    }

    HandleNextButtonClick = async () => {
        var response = await resetOnboarding();
        if (response == null || response.status != 200) {

        } else {
            var newState = response.data;
            this.props.onNextStage(newState);
        }
    }

    render() {
        return (
            <React.Fragment>
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">Onboarding</Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ textAlign: "center" }}>
                        <h2>
                            Stripe could not Activate your organization.
                            <br />
                            Please try again.
                        </h2>
                    </div>
                </DialogContent>
                <DialogActions disableSpacing={true} style={{ textAlign: "center", display: "block" }}>
                    <Button className="btn-primary" variant="contained"
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                        size="large" onClick={this.HandleNextButtonClick}>
                        Create New Organization
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}