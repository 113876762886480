import React from 'react';
import {MarkdownExtension, BoldExtension, ItalicExtension, UnderlineExtension, HeadingExtension, BulletListExtension, OrderedListExtension } from 'remirror/extensions';
import { Remirror, EditorComponent, useRemirror, Toolbar, ComponentItem, ThemeProvider } from '@remirror/react';
import { ToolbarGroupItem, ToolbarButtonItem } from '@remirror/react-components'
import type { ToolbarItemUnion } from "@remirror/react";
import { AllStyledComponent } from "@remirror/styles/emotion";
import { useHelpers } from '@remirror/react';
import {debounce} from 'lodash';
const extensions = () => [
    new BoldExtension(),
    new ItalicExtension(),
    new HeadingExtension(),
    new BulletListExtension(),
    new OrderedListExtension(),
    new MarkdownExtension(),
];

const toolbarItems: ToolbarItemUnion[] = [
    {
        type: ComponentItem.ToolbarGroup,
        label: "Simple Formatting",
        items: [
            { type: ComponentItem.ToolbarCommandButton, commandName: "toggleBold", display: "icon" },
            { type: ComponentItem.ToolbarCommandButton, commandName: "toggleItalic", display: "icon" },
        ],
        separator: "none",
    },
    {
        type: ComponentItem.ToolbarGroup,
        label: "Heading Formatting",
        items: [
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: "toggleHeading",
                display: "icon",
                attrs: { level: 1 },
            },
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: "toggleHeading",
                display: "icon",
                attrs: { level: 2 },
            },
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: "toggleHeading",
                display: "icon",
                attrs: { level: 3 },
            },
        ],
        separator: "none",
    },
    {
        type: ComponentItem.ToolbarGroup,
        label: "Lists",
        items: [
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: "toggleBulletList",
                display: "icon",
                attrs: { level: 1 },
            },
            {
                type: ComponentItem.ToolbarCommandButton,
                commandName: "toggleOrderedList",
                display: "icon",
                attrs: { level: 2 },
            },
        ],
        separator: "none",
    },
];

interface MarkdownPreviewProps {
    // 👇️ turn off type checking
    children?: never[];
    value: any;
    onChange: (value: any) => void;
}

export function MarkdownEditor({value, onChange}: MarkdownPreviewProps) {

    const { manager, state } = useRemirror({
        extensions,
        content: value,
        selection: "start",
        stringHandler: "markdown"
      });

    return (<AllStyledComponent>
        <ThemeProvider>
            <Remirror manager={manager} initialContent={state} >
                <Toolbar items={toolbarItems} refocusEditor label="Top Toolbar" />
                <EditorComponent  />
                <MarkdownPreview value={value} onChange={onChange} />
            </Remirror>
        </ThemeProvider></AllStyledComponent>)
};

const debouncedChange = debounce(ChangeMarkdown, 500);

function ChangeMarkdown({value, onChange}: MarkdownPreviewProps) {
    onChange(value);
}
function MarkdownPreview({value, onChange}: MarkdownPreviewProps) {

    const { getMarkdown } = useHelpers(true);
  
    var markdown = getMarkdown();

    if(value != markdown){
        debouncedChange({value: markdown, onChange: onChange});
    }
    return (
      <span></span>
    );
  }
  

