import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import '../App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../../Images/logo.png';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SearchBar from "material-ui-search-bar";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { Avatar, DialogTitle, Divider, Drawer, List, ListItem, ListItemAvatar, Typography } from '@material-ui/core';
import { OrganizationPendingStatusNotification } from './SubComponents/Notifications/OrganizationPendingStatusNotification';
import { EventGroupInvitationNotification } from './SubComponents/Notifications/EventGroupInvitationNotification';
import { dismissPortalUserNotification, getPortalUserNotifications, getPortalUserNotificationsResponse, getPortalUserNotificationsReviewedResponse } from '../../data/PortalUserRepository';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationTypeCode } from '../../data/Models/NotificationTypeCode';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import { EventGroupInviteDeclinedNotification } from './SubComponents/Notifications/EventGroupInviteDeclinedNotification';
import { EventGroupInviteAcceptedNotification } from './SubComponents/Notifications/EventGroupInviteAcceptedNotification';
import { EventSalesTaxAdjustmentNotification } from './SubComponents/Notifications/EventSalesTaxAdjustmentNotification';
import { EventPayoutSuccessfulNotification } from './SubComponents/Notifications/EventPayoutSuccessfulNotification';
import { EventPayoutFailedNotification } from './SubComponents/Notifications/EventPayoutFailedNotification';
import { toDate } from 'date-fns';

interface IHeaderBarProps {
    authenticated: boolean,
    newNotifications: boolean,
    changePage: (page: string) => void,
    onLogoutClick: () => void,
    mobileNavButtonClick: () => void,
    onRequestSearch: (searchTerm: string) => void
}

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export class HeaderBar extends React.Component<IHeaderBarProps> {

    state = {
        anchorEl: null,
        searchTerm: '',
        notificationsAnchorEl: null,
        notifications: [] as any[],
        notificationsSpinner: false,
        notificationsReviewed: false,
    }

    HandleProfileButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ ...this.state, anchorEl: event.currentTarget });
    };

    HandleProfileButtonClose = () => {
        this.setState({ ...this.state, anchorEl: null });
    }

    HandleShowNotificationsClick = async (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            ...this.state,
            notificationsAnchorEl: event.currentTarget,
            notificationsSpinner: true,
        });

        let notifications = null;

        var response = await getPortalUserNotifications();

        if (response != null && response.status == 401) {
            this.props.changePage(`/login`);
        }
        else if (response != null && response.status == 200) {
            if (response.data.success) {
                const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                notifications = response.data.notifications;
                notifications.forEach((notification: any) => {
                    var utcTime = notification.createOnDateTimeUTC;
                    var localTime = new Date((typeof utcTime === "string" ? new Date(utcTime) : utcTime).toLocaleString("en-US", { timeZone: tz }));
                    notification.createdAtLocal = localTime;
                });
                setTimeout(this.NotificationsReviewed, 5000);
            }
            else {

            }
        }


        this.setState({
            ...this.state,
            notifications: notifications,
            notificationsSpinner: false,
            //      notificationsAnchorEl: event.currentTarget
        });
    };

    NotificationsReviewed = async () => {
        if (this.state.notificationsAnchorEl == null)
            return;

        var response = await getPortalUserNotificationsReviewedResponse();
        if (response != null && response.status == 401) {
            this.props.changePage(`/login`);
        }

        this.setState({ ...this.state, notificationsReviewed: true });
    }

    HandleHideNotificationsClick = () => {
        this.setState({ ...this.state, notificationsAnchorEl: null });
    }

    HandleDismissNotification = async (notificationId: string) => {
        await dismissPortalUserNotification(notificationId);
    }


    render() {
        return (
            <div className="header">
                <AppBar position="static" style={{ backgroundColor: '#ededed' }} >
                    <Toolbar style={{ color: 'black' }}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <table style={{width: "100%", display: "table"}} >
                                <tr style={{display: "table-row"}}>
                                {this.props.authenticated && <td id="mobileNavButton" className="primary-header-table-cell" style={{width: "10%"}}>
                                    <Button style={{ backgroundColor: 'transparent', padding: '0' }}
                                        onClick={this.props.mobileNavButtonClick}>
                                        <MenuIcon />
                                    </Button>
                                </td>}
                                <td id={this.props.authenticated ? "header-logo-span-logged-in" : "header-logo-span-logged-out"} className="primary-header-table-cell" >
                                    <Button style={{ backgroundColor: 'transparent' }} onClick={() => this.props.changePage("/")} >
                                        <img src={logo} style={{ maxHeight: '55px',  objectFit: 'contain' }} />
                                    </Button>
                                </td>
                                {this.props.authenticated && <td id="header-create-event" className="primary-header-table-cell" style={{ marginLeft: "60px" }}>
                                    <Button className="btn-primary" style={{ borderRadius: "20px", fontSize: "11px" }} onClick={() => this.props.changePage("/create-event")}>
                                        Create Event
                                    </Button>
                                </td>}
                                <td id="header-search-bar" className="primary-header-table-cell" style={{ width: "20%"}} hidden={!this.props.authenticated}>
                                <SearchBar onChange={(newValue) => this.setState({ searchTerm: newValue })}
                                    onRequestSearch={() => this.props.onRequestSearch(this.state.searchTerm)} />

                                </td>
                                {this.props.authenticated && <td className="primary-header-table-cell" id="notifications-header-cell"><IconButton edge="start" className="" color="inherit" aria-label="menu" onClick={this.HandleShowNotificationsClick} style={{width: "10%"}}>
                                    {this.props.newNotifications && !this.state.notificationsReviewed && <NotificationImportantOutlinedIcon fontSize="large" style={{ color: "#e02d75" }} />}
                                    {(!this.props.newNotifications || this.state.notificationsReviewed) && <NotificationsNoneOutlinedIcon fontSize="large" />}
                                </IconButton></td>}
                                <td className="primary-header-table-cell" id="right-header-menu"><IconButton edge="start" className="" color="inherit" aria-label="menu" onClick={this.HandleProfileButtonClick} >
                                    <PersonOutlineIcon fontSize="large" />
                                </IconButton>
                                <StyledMenu
                                    id="customized-menu"
                                    anchorEl={this.state.anchorEl}
                                    keepMounted
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.HandleProfileButtonClose}
                                >
                                    <div hidden={this.props.authenticated} >
                                        <StyledMenuItem onClick={() => this.props.changePage('/login')} >
                                            <ListItemIcon>
                                                <VpnKeyIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Login" />
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={() => this.props.changePage('/contact')} >
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText primary="Contact" />
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={() => this.props.changePage('/sign-up')} >
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText primary="Sign Up" />
                                        </StyledMenuItem>
                                    </div>
                                    <div hidden={!this.props.authenticated}>
                                        <StyledMenuItem onClick={() => this.props.changePage("/account-info")}>
                                            <ListItemIcon>
                                                <AccountCircleIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Account Info" />
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={() => this.props.changePage('/contact')} >
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText primary="Contact" />
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={this.props.onLogoutClick}>
                                            <ListItemIcon>
                                                <ExitToAppIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Logout" />
                                        </StyledMenuItem>
                                    </div>
                                </StyledMenu>
                                </td>
                                </tr>
                            </table>
                            <div>
                                <Drawer
                                    anchor={'right'} className="notifications-drawer"
                                    open={this.state.notificationsAnchorEl != null}
                                    onClose={(a: any) => this.HandleHideNotificationsClick()}
                                >
                                    <div className='notifications-drawer-container'>
                                        <DialogTitle  >
                                            Notifications
                                            <IconButton
                                                aria-label="close"
                                                onClick={this.HandleHideNotificationsClick}
                                                style={{
                                                    position: 'absolute',
                                                    right: 8,
                                                    top: 8,
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </DialogTitle>
                                    </div>
                                    {this.state.notificationsSpinner && <div style={{ minWidth: '300px', textAlign: 'center' }}>
                                        <AutorenewIcon className='spinning-icon' style={{ marginTop: '20px' }} />
                                    </div>}
                                    {!this.state.notificationsSpinner &&
                                        <List >
                                            {
                                                this.state.notifications?.map((notification: any) => {
                                                    switch (notification.type) {
                                                        case NotificationTypeCode.OrganizationPendingStatus:
                                                            return <OrganizationPendingStatusNotification
                                                                notificationId={notification.id}
                                                                entityId={notification.entityId}
                                                                title={notification.title}
                                                                description={notification.description}
                                                                url={notification.url}
                                                                createdAt={notification.createdAtLocal}
                                                                dismissNotification={this.HandleDismissNotification}
                                                                changePage={this.props.changePage} />
                                                        case NotificationTypeCode.EventGroupInvitation:
                                                            return <EventGroupInvitationNotification
                                                                notificationId={notification.id}
                                                                entityId={notification.entityId}
                                                                title={notification.title}
                                                                description={notification.description}
                                                                url={notification.url}
                                                                createdAt={notification.createdAtLocal}
                                                                dismissNotification={this.HandleDismissNotification}
                                                                changePage={this.props.changePage}
                                                            />
                                                        case NotificationTypeCode.EventGroupInviteAccepted:
                                                            return <EventGroupInviteAcceptedNotification
                                                                notificationId={notification.id}
                                                                entityId={notification.entityId}
                                                                title={notification.title}
                                                                description={notification.description}
                                                                url={notification.url}
                                                                createdAt={notification.createdAtLocal}
                                                                dismissNotification={this.HandleDismissNotification}
                                                                changePage={this.props.changePage}
                                                            />
                                                        case NotificationTypeCode.EventGroupInviteDeclined:
                                                            return <EventGroupInviteDeclinedNotification
                                                                notificationId={notification.id}
                                                                entityId={notification.entityId}
                                                                title={notification.title}
                                                                description={notification.description}
                                                                url={notification.url}
                                                                createdAt={notification.createdAtLocal}
                                                                dismissNotification={this.HandleDismissNotification}
                                                                changePage={this.props.changePage}
                                                            />
                                                        case NotificationTypeCode.EventSalesTaxAdjustment:
                                                            return <EventSalesTaxAdjustmentNotification
                                                                notificationId={notification.id}
                                                                entityId={notification.entityId}
                                                                title={notification.title}
                                                                description={notification.description}
                                                                url={notification.url}
                                                                createdAt={notification.createdAtLocal}
                                                                dismissNotification={this.HandleDismissNotification}
                                                                changePage={this.props.changePage}
                                                            />
                                                        case NotificationTypeCode.EventPayoutSuccessful:
                                                            return <EventPayoutSuccessfulNotification
                                                                notificationId={notification.id}
                                                                entityId={notification.entityId}
                                                                title={notification.title}
                                                                description={notification.description}
                                                                url={notification.url}
                                                                createdAt={notification.createdAtLocal}
                                                                dismissNotification={this.HandleDismissNotification}
                                                                changePage={this.props.changePage}
                                                            />
                                                        case NotificationTypeCode.EventPayoutFailed:
                                                            return <EventPayoutFailedNotification
                                                                notificationId={notification.id}
                                                                entityId={notification.entityId}
                                                                title={notification.title}
                                                                description={notification.description}
                                                                url={notification.url}
                                                                createdAt={notification.createdAtLocal}
                                                                dismissNotification={this.HandleDismissNotification}
                                                                changePage={this.props.changePage}
                                                            />
                                                    }
                                                })}
                                        </List>
                                    }
                                </Drawer>

                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}