import * as React from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { EventFinancialsTable } from './EventFinancialsTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

interface IEventFinancialsModalProps {
    totalIncome: number,
    totalFees: number,
    totalTicketsPurchased: number,
    payoutDateTime: Date | undefined,
    payoutAttempts: number,
    payoutSuccessful: boolean,
    modalOpen: boolean,
    closeModal: () => void
}

export class EventFinancialsModal extends React.Component<IEventFinancialsModalProps> {

    render() {
        return (
            <div>
                <Dialog
                    className="financialsModal"
                    onClose={this.props.closeModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.modalOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Financial Details</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.props.closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <EventFinancialsTable
                            totalIncome={this.props.totalIncome}
                            totalFees={this.props.totalFees}
                            totalTicketsPurchased={this.props.totalTicketsPurchased}
                            payoutDateTime={this.props.payoutDateTime}
                            payoutAttempts={this.props.payoutAttempts}
                            payoutSuccessful={this.props.payoutSuccessful}
                        />
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.props.closeModal} className='action-button'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}