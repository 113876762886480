import React from "react";
import {
  MuiPickersUtilsProvider,
  DatePicker 
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface IDateRangeFilterProps{
    min?: Date,
    max?: Date,
    onDateFilterChange: (eventData: any) => void,
}

export class DateRangeFilter extends React.Component<IDateRangeFilterProps> {
    render() 
    {
      return (
        
        <div className="k-filtercell">
          <MuiPickersUtilsProvider utils={DateFnsUtils}> 
                <DatePicker
                    margin="dense"
                    size="small"
                    label="Start Date"
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    value={this.props.min != undefined ? this.props.min : null}
                    onChange={(date) =>this.props.onDateFilterChange({value: date, operator: "gt"})}
                    format="MM/dd/yyyy"
                    style={{width: "45%", marginRight: "5%"}}
                />
               
                <DatePicker
                    margin="dense"
                    size="small"
                    label="Finish Date"
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    value={this.props.max != undefined ? this.props.max : null}
                    defaultValue={null}
                    onChange={(date) =>this.props.onDateFilterChange({value: date, operator: "lt"})}
                    format="MM/dd/yyyy"
                    style={{width: "45%", marginRight: "5%"}}
                />
                
            </MuiPickersUtilsProvider>
        </div>
      );
    }
  }