/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import NumberFormat from 'react-number-format';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButton } from '@material-ui/core';
import "./Stripe.css";
import { Grid } from '@material-ui/core';
import Moment from 'moment';

interface ITicketOptionSelectionSectionProps {
    gaTicketOptions: any[],
    email: string,
    emailError: string,
    emailVerify: string,
    emailVerifyError: string,
    firstName: string,
    firstNameError: string,
    lastName: string,
    lastNameError: string,
    phoneNumber: string,
    phoneNumberError: string,
    couponCode: string,
    updateGATicketOptions: (qtySelected: number, ticketOptionId: string) => void,
    handleUserInfoInputChange: (value: string, fieldName: string) => void,
    checkoutButtonClick: () => void
}

export class TicketOptionSelectionSection extends React.Component<ITicketOptionSelectionSectionProps> {

    UpdateGATicketOptions = (value: any, ticketOption: any) => {

        var qtySelected = value != null && value != "" ? value : 0;

        qtySelected = parseInt(qtySelected);

        if (qtySelected < 0) {
            qtySelected = 0;
        }
        else {
            qtySelected = qtySelected > ticketOption.qty ? ticketOption.qty : qtySelected;
        }

        this.props.updateGATicketOptions(qtySelected, ticketOption.id);
    }

    HandleUserInfoInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fieldName: string) => {
        var value = e.currentTarget.value;

        this.props.handleUserInfoInputChange(value, fieldName)
    }

    RemoveTicketOption = (ticketOption: any) => {
        var qtySelected = ticketOption.qtySelected > 0 ? ticketOption.qtySelected - 1: 0;
        this.props.updateGATicketOptions(qtySelected, ticketOption.id);
    }

    AddTicketOption = (ticketOption: any) => {
        var qtySelected = ticketOption.qtySelected > 0 ? ticketOption.qtySelected + 1 : 1;
        this.props.updateGATicketOptions(qtySelected, ticketOption.id);
    }

    render() {
        let currentDateTimeUTC =  Moment().utc();
        return (
            <div style={{ width: "100%" }}>
                {this.props.gaTicketOptions.length > 0 &&
                    <Card style={{ width: "100%", backgroundColor: "#ededed" }}>
                        <CardContent>
                            <table>
                                {

                                    this.props.gaTicketOptions.map((ticketOption: any) => (
                                        <tr>
                                            <td>
                                                <IconButton aria-label="close"
                                                    className='close-button'
                                                    style={ticketOption.qty <= 0  ? {backgroundColor: "#727271", padding: "20px", margin: "20px", opacity: 0.1} : {backgroundColor: "#727271", padding: "20px", margin: "20px"}  }
                                                    onClick={() => this.RemoveTicketOption(ticketOption)}
                                                    disabled={ticketOption.qty <= 0 }
                                                    >
                                                    <RemoveIcon className="md-48" style={{color: "white"}}/>
                                                </IconButton>
                                                <span style={ticketOption.qty <= 0  ? {fontSize: "20px", opacity: 0.1} : {fontSize: "20px"}}>{ticketOption.qtySelected.toString()}</span>
                                                <IconButton aria-label="close"
                                                    className='close-button'
                                                    style={ticketOption.qty <= 0  ? {backgroundColor: "#036bdf", padding: "20px", margin: "20px", opacity: 0.1} : {backgroundColor: "#036bdf", padding: "20px", margin: "20px"} }
                                                    onClick={() => this.AddTicketOption(ticketOption)}
                                                    disabled={ticketOption.qty <= 0 }
                                                    >
                                                    <AddIcon className="md-48" style={{color: "white"}}/>
                                                </IconButton>   
                                            </td>
                                            <td>
                                                <div className='sale-prices-row'>
                                                    {ticketOption.name + " - "}
                                                    <NumberFormat value={ticketOption.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} className='sale-price'/>
                                                    {(!ticketOption.saleStartsDateTimeUTC || ticketOption.saleStartsDateTimeUTC < currentDateTimeUTC) &&
                                                    (!ticketOption.saleEndsDateTimeUTC || ticketOption.saleEndsDateTimeUTC > currentDateTimeUTC) &&
                                                    (
                                                        <span>
                                                            {ticketOption.qty <= 0 && <span> - <span className='soldOutText'>Sold Out</span></span>}
                                                            {ticketOption.qty > 0 && ticketOption.qty < 10 && <span> - <span className='almostSoldOutText'>Almost Sold Out</span></span>}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className='sale-dates-row'>
                                                    {ticketOption.saleStartsDateTime && <span>Sales Start <span className='saleStartDate'>{Moment(ticketOption.saleStartsDateTime).format('MM/DD hh:mm a')}</span></span>}
                                                    {ticketOption.saleStartsDateTime && ticketOption.saleEndsDateTime && <span className='saleDatesDivider'> - </span>}
                                                    {ticketOption.saleEndsDateTime && <span className='saleEndText'>Sales End <span className='saleEndDate'>{Moment(ticketOption.saleEndsDateTime).format('MM/DD hh:mm a')}</span></span>}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </table>
                        </CardContent>
                        <CardContent>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        error={this.props.emailError !== "" && this.props.emailError !== null}
                                        style={{ width: '100%' }}
                                        label="Email"
                                        placeholder="Email"
                                        margin="normal"
                                        helperText={this.props.emailError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.props.email}
                                        onChange={(e) => this.HandleUserInfoInputChange(e, "email")}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                    // disabled={published || !canEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        error={this.props.emailVerifyError !== "" && this.props.emailVerifyError !== null}
                                        style={{ width: '100%' }}
                                        label="Verify Email"
                                        placeholder="Verify Email"
                                        margin="normal"
                                        helperText={this.props.emailVerifyError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.props.emailVerify}
                                        onChange={(e) => this.HandleUserInfoInputChange(e, "emailVerify")}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                    // disabled={published || !canEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        error={this.props.firstNameError !== "" && this.props.firstNameError !== null}
                                        style={{ width: '100%' }}
                                        label="First Name"
                                        placeholder="First Name"
                                        margin="normal"
                                        helperText={this.props.firstNameError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.props.firstName}
                                        onChange={(e) => this.HandleUserInfoInputChange(e, "firstName")}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                    // disabled={published || !canEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        error={this.props.lastNameError !== "" && this.props.lastNameError !== null}
                                        style={{ width: '100%' }}
                                        label="Last Name"
                                        placeholder="Last Name"
                                        margin="normal"
                                        helperText={this.props.lastNameError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.props.lastName}
                                        onChange={(e) => this.HandleUserInfoInputChange(e, "lastName")}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                    // disabled={published || !canEdit}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                        error={this.props.phoneNumberError !== "" && this.props.phoneNumberError !== null}
                                        style={{ width: '100%' }}
                                        label="Phone Number"
                                        placeholder="Phone Number"
                                        margin="normal"
                                        helperText={this.props.phoneNumberError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.props.phoneNumber}
                                        onChange={(e) => this.HandleUserInfoInputChange(e, "phoneNumber")}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                    // disabled={published || !canEdit}
                                    />
                                    <strong style={{fontSize: "18px"}}>Phone number will automatically save your tickets to your Nrby.io mobile app account. Tickets will also be emailed to you.</strong>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                        // error={this.props.couponError !== "" && this.props.couponError !== null}
                                        style={{ width: '100%' }}
                                        label="Coupon Code"
                                        placeholder="Coupon Code"
                                        margin="normal"
                                        // helperText={this.props.phoneNumberError}
                                        // FormHelperTextProps={{ color: "red" }}
                                        value={this.props.couponCode}
                                        onChange={(e) => this.HandleUserInfoInputChange(e, "couponCode")}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardContent>
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Button className="btn-primary" style={{ width: "80%" }} onClick={this.props.checkoutButtonClick}>Checkout</Button>
                            </div>
                            <div style={{float: "right", margin: "10px"}}>
                                Powered by <a href="https://stripe.com" target='_blank'>Stripe</a>
                            </div>
                        </CardContent>
                    </Card>
                }
            </div>
        )
    }
}