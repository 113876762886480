import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig => {
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    return axiosConfig;
}

export const getOnboardingData = async (): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get<IOnboardingState>(`${baseAPIUrl}/api/onboarding/onboardingState`, tokenConfig);
}

export const savePaidTicketing = async (paidTicketing: boolean): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data = {
        paidTicketing: paidTicketing
    };

    return await axios.post(`${baseAPIUrl}/api/onboarding/paidTicketing`, data, tokenConfig);
}

export const saveOrganizationBasicInfo = async (data: IOnboardingSaveOrganizationBasicInfo): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post<IOnboardingState>(`${baseAPIUrl}/api/onboarding/saveOrganizationBasicInfo`, data, tokenConfig);
}

export const saveOrganizationBankInfo = async (data: IOnboardingSaveOrganizationBankInfo): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post<IOnboardingState>(`${baseAPIUrl}/api/onboarding/saveOrganizationBankInfo`, data, tokenConfig);
}

export const saveCreatedOrganizationInfo = async (data: IOnboardingSaveCreatedOrganizationInfo): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post<IOnboardingState>(`${baseAPIUrl}/api/onboarding/saveCreatedOrganizationInfo`, data, tokenConfig);
}

export const getOrganizationStatus = async (organizationId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data = {
        organizationId: organizationId,
    };

    return await axios.post(`${baseAPIUrl}/api/onboarding/getOrganizationStatus`, data, tokenConfig);
}

export const createDefaultEventCluster = async (organizationId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data = {
        organizationId: organizationId,
    };

    return await axios.post(`${baseAPIUrl}/api/onboarding/createDefaultEventCluster`, data, tokenConfig);
}

export const onboardingComplete = async (): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data = {
        
    };

    return await axios.post(`${baseAPIUrl}/api/onboarding/onboardingComplete`, data, tokenConfig);
}

export const resetOnboarding = async (): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var data = {
        
    };

    return await axios.post(`${baseAPIUrl}/api/onboarding/resetOnboarding`, data, tokenConfig);
}