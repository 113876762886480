import React from 'react';
import '../App.css';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { resendConfirmationEmail } from '../../data/PortalUserRepository';
import { GenericSuccessModal } from '../Components/GenericSuccessModal';
import { GenericErrorModal } from '../Components/GenericErrorModal';

interface IResendRegsitrationConfirmationProps{
    loadingOn: () => void,
    loadingOff: () => void
}

export class ResentRegistrationConfirmationPage extends React.Component<IResendRegsitrationConfirmationProps> {
    
    state = {
        username: '',
        usernameError: '',
        serverError: '',
        success: false
    }

    HandleUsernameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, username: event.currentTarget.value})
    }

    HandleSendEmail = async () => 
    {
        this.props.loadingOn();

        let usernameError = "";
        let errored = false;
        let success = false;

        if(this.state.username.length == 0)
        {
            usernameError = "Username/Email required.";
            errored = true;
        }

        let serverErrorText = "";

        if(!errored){
            var response = await resendConfirmationEmail(this.state.username);
            
            if(response != null && response.status == 200)
            {
                if(response.data.success)
                {
                    success = true;
                }
                else{
                    serverErrorText = response.data.errorMsg;
                }
            }
            else{
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            usernameError: usernameError,
            serverError: serverErrorText,
            success
        });

        this.props.loadingOff();
    }

    CloseSuccessModal = () => {
        this.setState({...this.state, success: false});
    };

    CloseErrorModal = () => {
        this.setState({...this.state, serverError: ""});
    };

    render(){
        const classes = {
            loginBtn: {
            //marginTop: theme.spacing(2),
            flexGrow: 1
            }
          };

        return (
            <form noValidate autoComplete="off"  >
                <Card className='cardOverwrite' >
                    <CardHeader style={{ textAlign: 'center', background: '#212121', color: '#fff'}} title="Resend Confirmation Email" />
                    <CardContent>
                    <div>
                        <TextField
                        error={this.state.usernameError !== ""}
                        fullWidth
                        id="username"
                        type="email"
                        label="Username/Email"
                        placeholder="Username/Email"
                        margin="normal"
                        helperText={this.state.usernameError}
                        FormHelperTextProps={{ color: "red" }}
                        onChange={(e) => this.HandleUsernameChange(e)}
                        variant="outlined"
                        />
                    </div>
                    </CardContent>
                    <CardActions>
                    <Button
                        variant="contained"
                        size="large"
                        className='btn-primary'
                        style={classes.loginBtn}
                        onClick={this.HandleSendEmail}
                        >
                        Send Email
                    </Button>
                    </CardActions>
                </Card>
                <GenericSuccessModal 
                    hidden={this.state.success == false}
                    message="Your confirmation email was resent successfully. Please check your inbox for the email and click on the provided link."
                    closeModal={this.CloseSuccessModal}
                    />
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
            </form>
        );
    }
}