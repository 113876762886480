import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise';

declare interface IAddZipCodeSalesTax{
    zipCode: string;
    salesTaxRateAdjustment: number;
    conditions: string;
}

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
      };
      
    return axiosConfig;
}

const getZipCodeSalesTaxesResponse = async(gridState: ITableDef): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/zipcodesalestax/list`, gridState, tokenConfig);
}

export const getZipCodeSalesTaxes = AwesomeDebouncePromise(getZipCodeSalesTaxesResponse, 500);

export const updateZipCodeSalesTaxRate = async(zipCodeData: any): Promise<AxiosResponse>=>{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/zipcodesalestax`, zipCodeData, tokenConfig);
}

export const addZipCodeSalesTaxRate = async(zipCode: string, salesTaxRateAdjustment: number, conditions: string): Promise<AxiosResponse>=>{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var zipCodeData: IAddZipCodeSalesTax={
        salesTaxRateAdjustment : salesTaxRateAdjustment,
        zipCode : zipCode,
        conditions: conditions
    };
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/zipcodesalestax`, zipCodeData, tokenConfig);
}
