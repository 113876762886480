import React from 'react';
import '../App.css';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { optoutPaymentConfirmationEmailToCreator } from '../../data/OptoutRepository';

interface IOptoutPageProps {
    loadingOn: () => void,
    loadingOff: () => void
}

export class OptoutPage extends React.Component<IOptoutPageProps> {

    state = {
        optoutSuccessful: false,
        alreadyOptedOut: false,
        serverError: '',
    }

    async componentDidMount() {
        await this.HandleOptout();
    }

    HandleOptout = async () => {
        this.props.loadingOn();
        
        let serverError = "";
        let alreadyOptedOut = false;

        const query = new URLSearchParams(window.location.search);

        var code: string = query.get('code') ?? "";

        var response = await optoutPaymentConfirmationEmailToCreator(code);

        if (response != null && response.status == 200) {
            alreadyOptedOut = response.data.alreadyOptedOut;
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            alreadyOptedOut: alreadyOptedOut,
            serverError: serverError,
        });

        this.props.loadingOff();
    }

    render() {
        return (
            <Card className='cardOverwrite' >
                <CardHeader style={{ textAlign: 'center', background: '#212121', color: '#fff' }} title="Opt Out" />
                <CardContent>
                    {this.state.serverError == '' && !this.state.alreadyOptedOut &&
                        <h1 style={{textAlign: "center"}}>
                            You've been successfully opted out of notifications for payments.
                        </h1>
                    }
                    {this.state.serverError == '' && this.state.alreadyOptedOut &&
                        <h1 style={{textAlign: "center"}}>
                            You already opted out of notifications for payments.
                        </h1>
                    }
                </CardContent>
            </Card>
        );
    }
}