import * as React from 'react';
import { Avatar, Divider, Grid, Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';


export class EventGroupInvitationNotification extends React.Component<IPortalUserNotificationProps> {
    state = {
        dismissed: false,
    }

    HandleDismissClick = async () => {
        this.props.dismissNotification(this.props.notificationId);
        this.setState({...this.state, dismissed: true});
    }

    HandleChangePage =  ()=>{
        this.props.changePage("/event-group-dashboard");
    }

    render() {
        return (
            <div>
                {!this.state.dismissed && <div>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar>I</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Link href="#" onClick={this.HandleChangePage}>
                                        {this.props.title}</Link>
                                    <div style={{ float: 'right', fontSize: 'smaller' , marginLeft:'5px'}}>
                                        {this.props.createdAt.toDateString()}
                                    </div>
                                </React.Fragment>}
                            secondary={
                                <React.Fragment>
                                    {"you have beed invited. "}
                                    <Grid container justify="flex-end">
                                        <Link href="#" onClick={this.HandleDismissClick}>Dismiss</Link>
                                    </Grid>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </div>}
            </div>
        );
    }
}