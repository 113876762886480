/// <reference path="../../../data/DataModels/types.d.ts" />
import '../../Styles/EventClusterRolesSection.css';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import { EditDeleteActionCell } from '../SubComponents/EditDeleteActionCell';
import { CheckboxGridCell } from '../SubComponents/CheckboxGridCell';
import { getEventClusterRoles, updateEventClusterRole, deleteEventClusterRole } from '../../../data/EventClusterRoleRepository';
import { getAllEventClusterPermissions } from '../../../data/EventClusterPermissionRepository';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AddRoleDialog } from './AddRoleDialog';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

var Enumerable = require('linq');

interface IEventClusterRolesSectionProps {
    userPermissions: EventClusterPermission[],
    isClusterOwner: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class EventClusterRolesSection extends React.Component<IEventClusterRolesSectionProps> {

    state = {
        eventClusterId: '',
        roles: [],
        currentRoleId: '',
        currentIsOwnerRole: false,
        currentRoleName: '',
        currentRoleNameError: '',
        permissions: [],
        headCells: [
            {
                id: 'name',
                label: 'Name',
            }
        ],
        permissionsHeadCells: [
            {
                id: 'selected',
                label: 'Selected'
            },
            {
                id: 'permission',
                label: 'Permission'
            }
        ],
        currentPermissions: [],
        allPermissionsChecked: false,
        eventClusterRolesServerError: '',
        eventClusterPermissionsServerError: '',
        newRoleModalOpen: false,
        editRoleModalOpen: false,
        deleteRoleModalOpen: false,
        deleteRoleId: '',
        currentRoleServerError: '',
        serverSuccessMsg: ''
    }

    async componentDidMount() {
        this.props.loadingOn();

        var permissions = [];

        let eventClusterRolesServerError = '';
        let eventClusterPermissionsServerError = '';

        const query = new URLSearchParams(window.location.search);

        var eventClusterId: string = query.get('id') ?? "";

        var permissionsResponse = await getAllEventClusterPermissions();

        if (permissionsResponse != null && permissionsResponse.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + eventClusterId);
        }
        else if (permissionsResponse != null && permissionsResponse.status == 200) {
            if (!permissionsResponse.data.success) {
                eventClusterPermissionsServerError = permissionsResponse.data.errorMsg;
            }
            else {
                permissions = permissionsResponse.data.eventClusterPermissions;
            }
        }
        else {
            eventClusterPermissionsServerError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            eventClusterId: eventClusterId,
            permissions: permissions,
            eventClusterRolesServerError: eventClusterRolesServerError,
            eventClusterPermissionsServerError: eventClusterPermissionsServerError
        });

        await this.LoadRoles();

        this.props.loadingOff();
    }

    LoadRoles = async () => {
        var roles = [];
        let eventClusterRolesServerError = '';

        var response = await getEventClusterRoles(this.state.eventClusterId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.state.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterRolesServerError = response.data.errorMsg;
            }
            else {
                roles = response.data.eventClusterRoles;
            }
        }
        else {
            eventClusterRolesServerError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            roles: roles,
            eventClusterRolesServerError: eventClusterRolesServerError,
        });
    }

    OpenNewRoleModal = () => {
        this.setState({
            ...this.state,
            newRoleModalOpen: true,
        });
    }

    CloseNewRoleModal = () => {
        this.setState({
            ...this.state,
            newRoleModalOpen: false,
            currentRoleNameError: "",
            currentRoleServerError: ""
        });
    }

    OnRoleAdded = async () => {
        this.setState({
            ...this.state,
            newRoleModalOpen: false,
            currentRoleNameError: "",
            currentRoleServerError: ""
        });
        await this.LoadRoles();
    }

    OpenEditRoleModal = (dataIndex: number) => {
        let currentRole: any = this.state.roles[dataIndex];

        let currentPermissions: any = [];

        let allPermissions: any = this.state.permissions;

        let allPermissionsChecked = true;

        allPermissions.forEach((p: any) => {
            var permissionSelected = Enumerable.from(currentRole.eventClusterPermissions).where((sp: any) => sp.id == p.id).any();

            if (permissionSelected == false) {
                allPermissionsChecked = false;
            }

            currentPermissions.push({ id: p.id, name: p.name, selected: permissionSelected });
        });

        this.setState({ ...this.state, editRoleModalOpen: true, currentRoleId: currentRole.id, currentIsOwnerRole: currentRole.isOwnerRole, currentRoleName: currentRole.name, currentPermissions: currentPermissions, allPermissionsChecked: allPermissionsChecked });
    }

    OnDeleteRoleButtonClick = (dataIndex: number) => {
        let role: any = this.state.roles[dataIndex];

        let deleteRoleId = role.id;

        this.setState({ ...this.state, deleteRoleId: deleteRoleId, deleteRoleModalOpen: true });
    }

    DeleteRoleClick = async () => {
        this.props.loadingOn();

        let currentRoleServerError = '';

        var response = await deleteEventClusterRole(this.state.deleteRoleId);

        let roles: any[] = this.state.roles;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.state.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                currentRoleServerError = response.data.errorMsg;
            }
            else {
                roles = Enumerable.from(roles).where((r: any) => r.id != this.state.deleteRoleId).toArray();
            }
        }
        else {
            currentRoleServerError = "An unknown error occured. Please try again later";
        }

        this.setState({ ...this.state, roles: roles, deleteRoleId: '', deleteRoleModalOpen: false, currentRoleServerError: currentRoleServerError });
        this.props.loadingOff();
    }

    CloseDeleteRoleModal = () => {
        this.setState({ ...this.state, deleteRoleId: '', deleteRoleModalOpen: false });
    }

    CloseEditRoleModal = () => {
        this.setState({
            ...this.state,
            editRoleModalOpen: false,
            currentRole: undefined,
            currentPermissions: [],
            currentRoleNameError: "",
            currentRoleServerError: ""
        });
    }

    OnPermissionCheckboxClick = (dataIndex: number) => {

        let currentPermissions: any[] = this.state.currentPermissions;
        let permission: any = currentPermissions[dataIndex];

        permission.selected = !permission.selected;

        currentPermissions[dataIndex] = permission;

        var allPermissionsChecked = Enumerable.from(currentPermissions).any((p: any) => p.selected == false) == false;

        this.setState({ ...this.state, currentPermissions: currentPermissions, allPermissionsChecked: allPermissionsChecked })
    }

    OnAllPermissionCheckboxClick = () => {

        let currentPermissions: any[] = this.state.currentPermissions;

        var newAllPermissionsChecked = !this.state.allPermissionsChecked;

        currentPermissions.forEach((p: any) => {
            p.selected = newAllPermissionsChecked;
        });

        this.setState({ ...this.state, currentPermissions: currentPermissions, allPermissionsChecked: newAllPermissionsChecked })
    }

    HandleRoleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, currentRoleName: event.currentTarget.value });
    }

    UpdateRoleClick = async () => {

        this.props.loadingOn();

        let currentRoleNameError = '';
        let error = false;
        let currentRoleServerError = '';
        let serverSuccessMsg = '';

        let currentRoleName = this.state.currentRoleName;

        if (currentRoleName == "") {
            currentRoleNameError = "Role name required.";
            error = true;
        }

        let currentPermissions: any = this.state.currentPermissions;

        let roles: any[] = this.state.roles;

        let roleExistsAlready = Enumerable.from(roles).where((r: any) => r.name == currentRoleName && r.id != this.state.currentRoleId).any();

        if (roleExistsAlready) {
            currentRoleNameError = `The name "${currentRoleName}" exists for another role already.`;
            error = true;
        }

        if (error == false) {
            let eventClusterPermissionIds = Enumerable.from(currentPermissions).where((p: any) => p.selected == true).select((p: any) => p.id).toArray();

            let response = await updateEventClusterRole(this.state.currentRoleId, currentRoleName, eventClusterPermissionIds);

            if (response != null && response.status == 401) {
                this.props.changePage("/login?redirect=event-group?id=" + this.state.eventClusterId);
            }
            else if (response != null && response.status == 200) {
                if (!response.data.success) {
                    currentRoleServerError = response.data.errorMsg;
                }
                else {
                    let eventClusterPermissions = Enumerable.from(currentPermissions).where((p: any) => p.selected == true).toArray();

                    for (var i = 0; i < roles.length; i++) {
                        let role: any = roles[i];

                        if (role.id == this.state.currentRoleId) {
                            role.name = currentRoleName;

                            role.eventClusterPermissions = eventClusterPermissions;

                            roles[i] = role;
                            break;
                        }
                    }

                    serverSuccessMsg = `"${currentRoleName}" role updated successfully`;

                    currentRoleNameError = "";
                    currentRoleName = "";
                    currentPermissions = [];
                }
            }
            else {
                currentRoleServerError = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            roles: roles,
            currentRoleName: currentRoleName,
            currentPermissions: currentPermissions,
            currentRoleNameError: currentRoleNameError,
            currentRoleServerError: currentRoleServerError,
            editRoleModalOpen: error,
            serverSuccessMsg: serverSuccessMsg
        });

        this.props.loadingOff();
    }

    MockSave = () => { }

    CloseSuccessModal = () => {
        this.setState({ ...this.state, serverSuccessMsg: "" });
    };

    CloseErrorModal = () => {
        this.setState({ ...this.state, eventClusterRolesServerError: "", eventClusterPermissionsServerError: "", currentRoleServerError: "" });
    };

    render() {
        let editRolesAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditRoles);
        let deleteRolesAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.DeleteRoles);
        let addRolesAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.AddRoles);

        let actionColumnVisible = editRolesAccess || deleteRolesAccess;

        return (
            <div style={{ width: "100%" }}>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <CardHeader title="User Roles" />
                    {addRolesAccess && <CardContent style={{ paddingBottom: '10px' }}>
                        <Button variant="contained" className='btn-primary' onClick={this.OpenNewRoleModal}  >
                            Add New Role
                        </Button>
                    </CardContent>}
                    <CardContent>   
                    <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className="event-cluster-roles-table table-filters-hidden"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                                {actionColumnVisible && <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.roles.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="right" className="event-cluster-role-table-row"><GenericDisplayCell title="Name" value={row.name} /></TableCell>
                                                        {actionColumnVisible && <TableCell align="right" className="event-cluster-role-table-row">
                                                        <EditDeleteActionCell 
                                                            dataIndex={index} 
                                                            onEditButtonClick={this.OpenEditRoleModal} 
                                                            onSaveButtonClick={this.MockSave}
                                                            editMode={false}
                                                            hideEditButton={editRolesAccess == false}
                                                            hideDeleteButton={deleteRolesAccess == false || row.isOwnerRole}
                                                            onDeleteButtonClick={this.OnDeleteRoleButtonClick} />    
                                                        </TableCell>}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>

                <AddRoleDialog
                    visible={this.state.newRoleModalOpen}
                    eventClusterId={this.state.eventClusterId}
                    loadingOff={this.props.loadingOff}
                    loadingOn={this.props.loadingOn}
                    changePage={this.props.changePage}
                    onCloseDialog={this.CloseNewRoleModal}
                    onRoleAdded={this.OnRoleAdded}
                />

                <Dialog
                    onClose={this.CloseEditRoleModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.editRoleModalOpen}
                    fullWidth
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Edit Role</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseEditRoleModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                <CardContent>
                    <TextField 
                        error={this.state.currentRoleNameError !== ""}
                        style={{width: '100%'}}
                        disabled={this.state.currentIsOwnerRole}
                        id="roleName"
                        label={this.state.currentIsOwnerRole ? "Role Name (Disabled for Owner role)" : "Role Name"}
                        placeholder="Role Name"
                        margin="normal"
                        helperText={this.state.currentRoleNameError}
                        FormHelperTextProps={{ color: "red" }}
                        value={this.state.currentRoleName}
                        onChange={(e) => this.HandleRoleNameChange(e)}
                        variant="outlined"
                    />
                </CardContent>
                <CardContent>
                    <div className="select-all-permissions">
                        <FormControlLabel
                            control={
                                <Checkbox color="default" checked={this.state.allPermissionsChecked} onClick={this.OnAllPermissionCheckboxClick} title="Select All Permissions"/>
                                }
                                label="Select All Permissions"
                            />
                    </div>
                    <div >
                         <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className="permission-select-table table-filters-hidden"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.permissionsHeadCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center" className="table-header-border show-on-mobile" >
                                                    <Checkbox color="default" checked={this.state.allPermissionsChecked} onClick={this.OnAllPermissionCheckboxClick}/>
                                                </TableCell>
                                            <TableCell align="center" className="table-header-border no-show-on-mobile">
                                                <div>All Permissions Selected</div>
                                            </TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.currentPermissions.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                    >
                                                        <TableCell align="center" className="event-cluster-role-table-row"><CheckboxGridCell dataIndex={index} checked={row.selected} onClick={this.OnPermissionCheckboxClick}/></TableCell>
                                                        <TableCell align="right" className="event-cluster-role-table-row">
                                                            <GenericDisplayCell title="Name" value={row.name} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </div>
                </CardContent>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseEditRoleModal} className='two-action-buttons'>
                            Close
                        </Button>
                        <Button onClick={this.UpdateRoleClick} className='two-action-buttons'>
                            Update Role
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.CloseDeleteRoleModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.deleteRoleModalOpen}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Delete Role</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseDeleteRoleModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this role?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseDeleteRoleModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.DeleteRoleClick} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <GenericSuccessModal
                    hidden={this.state.serverSuccessMsg == ""}
                    message={this.state.serverSuccessMsg}
                    closeModal={this.CloseSuccessModal}
                />
                <GenericErrorModal hidden={this.state.eventClusterRolesServerError == ''}
                    message={this.state.eventClusterRolesServerError}
                    closeModal={this.CloseErrorModal} />
                <GenericErrorModal hidden={this.state.eventClusterPermissionsServerError == ''}
                    message={this.state.eventClusterPermissionsServerError}
                    closeModal={this.CloseErrorModal} />
                <GenericErrorModal hidden={this.state.currentRoleServerError == ''}
                    message={this.state.currentRoleServerError}
                    closeModal={this.CloseErrorModal} />
            </div>
        )
    }
}