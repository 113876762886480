/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import { Card } from '@material-ui/core';
import { CardHeader } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Button, Chip } from '@material-ui/core';
import { SimilarEventsTable } from '../Events/SimilarEventsTable';
import { EventSuccessPredictionSection } from '../Events/EventSuccessPredictionSection';
import { getEventSuccessPrediction } from '../../../data/EventSuccessPredictionRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import { getSimilarEvents } from '../../../data/AnalyticsRepository';
import { searchKeywords } from '../../../data/KeywordsRepository';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { debounceAutocompleteAddress, geocodeAddress} from '../../../data/HereAPISearchRepository';
import Moment from 'moment';
import {
    MuiPickersUtilsProvider,
    DateTimePicker 
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

var Enumerable = require('linq');

interface IGeneralPredictiveAnalyticsSectionProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class GeneralPredictiveAnalyticsSection extends React.Component<IGeneralPredictiveAnalyticsSectionProps> {
    
    state = {
        eventId: '',
        eventClusterId: '',
        similarEvents: [],
        keywordsList: [],
        adjustedProbabilityOfSuccess: 0,
        predictionCalendar: [],
        keywordOccurances: [],
        dateRange: 2,
        dateRanges: [],
        address: '',
        addressResults: [],
        latitude: 0,
        longitude: 0,
        searchAddressFocused: false,
        addressError: '',
        startDateTime: new Date(),
        startDateTimeError: '',
        finishDateTime: undefined,
        finishDateTimeError: '',
        filteredKeywords: [] as string[],
        selectedKeywords: [] as string[],
        keywordsError: '',
        serverError: ''
    }

    async componentDidMount(){
        var dateRanges = [];

        dateRanges.push({ name: "+/- 1 day", id: 1});
        dateRanges.push({ name: "+/- 2 days", id: 2});
        dateRanges.push({ name: "+/- 3 days", id: 3});
        dateRanges.push({ name: "+/- 4 days", id: 4});
        dateRanges.push({ name: "+/- 5 days", id: 5});
        dateRanges.push({ name: "+/- 6 days", id: 6});
        dateRanges.push({ name: "+/- 7 days", id: 7});

        this.setState({...this.state, 
            dateRanges: dateRanges});
    }

    Search = async () => {
        this.props.loadingOn();

        let addressError = '';
        let startDateTimeError = '';
        let keywordsError = '';
        let errored = false;

        let startDateTime: Date = this.state.startDateTime ?? new Date();

        var serverError = "";
        var adjustedProbabilityOfSuccess = 0;
        var keywordOccurances: any[] = [];

        if(this.state.address == undefined || this.state.address == ''){
            addressError = 'Address required.';
            errored = true;
        }

        if(this.state.startDateTime == undefined){
            startDateTimeError = 'Start Date and Time required.';
            errored = true;
        }
        else if(startDateTime < new Date()) 
        {
            startDateTimeError = 'Start Date and Time can not be in the past.';
            errored = true;
        }

        if(this.state.selectedKeywords.length == 0){
            keywordsError = 'Please select at least one keyword.';
            errored = true;
        }

        if(!errored){
            var startDateTimeString = Moment(startDateTime).format('MM/DD/yyyy HH:mm:ss');

            await this.LoadPredictivePredictiveScore(startDateTimeString);

            await this.LoadSimilarEvents(undefined, undefined, "");
        }

        this.setState({...this.state, 
            addressError: addressError,
            startDateTimeError: startDateTimeError,
            keywordsError: keywordsError});

        this.props.loadingOff();
    }

    LoadPredictivePredictiveScore = async (startDateTimeString: string) => {
        var serverError = "";
        var adjustedProbabilityOfSuccess = 0;
        var keywordOccurances: any[] = [];
        var predictionCalendar: any[] = [];

        var eventSuccessPredictionResponse = await getEventSuccessPrediction("", this.state.latitude, this.state.longitude, startDateTimeString, this.state.selectedKeywords, this.state.dateRange);

        if(eventSuccessPredictionResponse != null && eventSuccessPredictionResponse.status == 401){
            this.RedirectToLogin();
        }
        else if(eventSuccessPredictionResponse != null && eventSuccessPredictionResponse.status == 200)
        {
            if(!eventSuccessPredictionResponse.data.success)
            {
                serverError = eventSuccessPredictionResponse.data.errorMsg;
            }
            else{
                adjustedProbabilityOfSuccess = eventSuccessPredictionResponse.data.eventSuccessPrediction?.adjustedProbability ?? 0;
                keywordOccurances = eventSuccessPredictionResponse.data.eventSuccessPrediction?.keywordOccurences ?? 0;
                predictionCalendar = eventSuccessPredictionResponse.data.eventSuccessPredictionCalendar ?? [];
                predictionCalendar.forEach((e:any)=>e.labelData = e);
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, 
            adjustedProbabilityOfSuccess: adjustedProbabilityOfSuccess,
            keywordOccurances: keywordOccurances,
            predictionCalendar: predictionCalendar,
            serverError: serverError});
    }

    LoadSimilarEvents = async (startDateRange: any, finishDateRange: any, filterByKeyword: string) =>{
        let serverError = '';
        let similarEvents = [];

        var startDateTimeString = Moment(this.state.startDateTime).format('MM/DD/yyyy HH:mm:ss');
        
        var similarEventsResponse = await getSimilarEvents("", startDateRange, finishDateRange, filterByKeyword, this.state.latitude, this.state.longitude, startDateTimeString, this.state.selectedKeywords, this.state.dateRange);
        var keywordsList: string[]= [];
        keywordsList.push('');

        if(similarEventsResponse != null && similarEventsResponse.status == 401){
            this.RedirectToLogin();
        }
        else if(similarEventsResponse != null && similarEventsResponse.status == 200)
        {
            if(!similarEventsResponse.data.success)
            {
                serverError = similarEventsResponse.data.errorMsg;
            }
            else{
                similarEvents = similarEventsResponse.data.similarEvents;
                similarEvents.forEach((event:any) => {
                    if (event.keywords != null)
                        event.keywords.forEach((keyword : any)=>{
                            if (keywordsList.indexOf(keyword.keyword) == -1)
                                keywordsList.push(keyword.keyword);
                        })
                });
                this.setState({...this.state, 
                    similarEvents: similarEvents,
                    keywordsList: keywordsList});
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, 
            serverError: serverError});
    }

    RedirectToLogin = () => {

        this.props.changePage("/login?redirect=analytics");
    }

    CloseErrorModal = () => {
        this.setState({...this.state, serverError: ''});
    }

    SelectAddress = async (value: any) => {
        let address = value != null ? value.address.label : "";

        this.setState({...this.state, address: address});

        this.MapAddress(address);
    }

    MapAddress = async (address: string) => {
        let mapCoord = undefined;
        let latitude = 0;
        let longitude = 0;

        if(address.length > 0){
            var response = await geocodeAddress(address);
            
            if(response != null && response.status == 200){
                if(response.data.items.length > 0){
                    mapCoord = response.data.items[0].position;
                    latitude = mapCoord.lat;
                    longitude = mapCoord.lng;
                }
            }
        }
        this.setState({
            ...this.state, 
            latitude: latitude, 
            longitude: longitude
        });
    }

    HandleAddressInputChange = async (address: any) => {
        let addressResults: any[] = [];

        if(address != undefined && address != ""){
            var response = await debounceAutocompleteAddress(address);
                
            if(response != null && response.status == 200){
                addressResults = response.data.items;
            }

        }

        this.setState({...this.state, addressResults: addressResults});
    }

    HandleStartDateTimeChange = (startDateTime: Date | null) => {

        if (startDateTime != null && startDateTime < new Date())
            return;

        let finishDateTime: Date | null = this.state.finishDateTime ?? null;

        if(finishDateTime == null || finishDateTime == "" && startDateTime != null){
            var tempDateTime = new Date(Date.parse(startDateTime?.toString() ?? ""));
            tempDateTime?.setHours(tempDateTime?.getHours() + 2);
            finishDateTime = tempDateTime;
        }
        
        this.setState({...this.state, startDateTime: startDateTime, finishDateTime: finishDateTime});
    }

    OnKeywordFilter = async (newValue: string | null) => {

        let keywords: any[] = [];

    
        if(newValue != null && newValue != ""){
            var response = await searchKeywords(newValue);

            if(response != null && response.status == 200)
            {
                keywords = response.data.keywords;
            } 
        }

        this.setState({...this.state, filteredKeywords: keywords});
    }

    HangleKeywordMultiSelectChange = (selected: string[]) => {
        this.setState({...this.state, selectedKeywords: selected});
    }

    HandleDateRangeChange = (dateRange: any) => {
        this.setState({...this.state, dateRange: dateRange});
    }

    render()
    {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <div style={{height: "100%"}}>
                    <Card style={{marginBottom: "20px"}}>
                        <CardContent>
                        <table width="100%" >
                            <tr>
                                <td><strong>Note: Must select address option from the autosuggestion list to get correct format for our system</strong></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td >
                                        <Autocomplete
                                            options={this.state.addressResults}
                                            filterOptions={(x) => x}
                                            getOptionLabel={(option: any) => option.address != undefined ? option.address.label : ""}
                                            style={{ width: "100%" }}
                                            onChange={(e, v) => this.SelectAddress(v)}
                                            onInputChange={(e,v) => this.HandleAddressInputChange(v)}
                                            clearOnBlur={true}
                                            renderInput={(params) => <TextField {...params} 
                                                                        error={this.state.addressError !== ""}
                                                                        label="Search Address"
                                                                        placeholder="Search Address"
                                                                        margin="normal"
                                                                        helperText={this.state.addressError}
                                                                        FormHelperTextProps={{ color: "red" }}
                                                                        variant="outlined" 
                                                                        style={{width: '100%', backgroundColor: "white"}}
                                                                        autoComplete="off"
                                                                        autoFocus={this.state.searchAddressFocused}
                                                                        onBlur={() => this.setState({...this.state, searchAddressFocused: false})}
                                                                        />}
                                            />
                                    </td>
                                    <td >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DateTimePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="Start Date Time"
                                                inputVariant="outlined"
                                                value={this.state.startDateTime ? this.state.startDateTime : null}
                                                onChange={this.HandleStartDateTimeChange}
                                                error={this.state.startDateTimeError !== ""}
                                                helperText={this.state.startDateTimeError}
                                                fullWidth={true}
                                                FormHelperTextProps={{ color: "red" }}
                                                format="MM/dd/yyyy hh:mm a"
                                                disablePast={ true }
                                            />
                                        </MuiPickersUtilsProvider>
                                    </td>
                                    </tr>
                                </table>
                                <div>
                                <GenericDropdown 
                                    label="Comparison Date Range"
                                    value={this.state.dateRange}
                                    data={this.state.dateRanges}
                                    onChange={this.HandleDateRangeChange}
                                />     
                                </div>
                                <div style={{width: "100%", marginTop: "10px"}}>
                                <Autocomplete
                                    multiple
                                    options={this.state.filteredKeywords.map((keyword) => keyword)}
                                    value={this.state.selectedKeywords}
                                    onChange={(e, newValue) => { this.HangleKeywordMultiSelectChange(newValue) }}
                                    renderTags={(value: readonly string[], getTagProps) =>
                                        value.map((keyword: string, index: number) => (
                                            <Chip variant="outlined" label={keyword} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Keywords (Please select at least 3 for best results)"
                                            placeholder="Keywords (Please select at least 3 for best results)"
                                            error={this.state.keywordsError !== ""}
                                            helperText={this.state.keywordsError}
                                            onChange={(e) => { this.OnKeywordFilter(e.currentTarget.value) }}
                                        />
                                    )}
                                />
                                </div>

                                <div style={{borderRadius: "20px", fontSize: "11px", paddingTop: "20px"}}>
                                <Button className="btn-primary" style={{borderRadius: "20px", fontSize: "11px"}} onClick={() => this.Search()}>
                                    Search
                                </Button>
                                </div>
                        </CardContent>
                    </Card>
                    <EventSuccessPredictionSection 
                        adjustedProbabilityOfSuccess={this.state.adjustedProbabilityOfSuccess}
                        keywordOccurances={this.state.keywordOccurances}
                        predictionCalendar={this.state.predictionCalendar}
                        currentDate={this.state.startDateTime}
                    />
                    <SimilarEventsTable
                        similarEvents={this.state.similarEvents}
                        keywordsList={this.state.keywordsList}
                        loadSimilarEvents={this.LoadSimilarEvents}
                        changePage={this.props.changePage}
                    /> 
                </div>
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />   
            </div>
        )
    }
}