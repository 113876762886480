import * as React from 'react';
import { Link, CircularProgress } from '@material-ui/core';
import { createDefaultEventCluster, getOrganizationStatus } from '../../../data/OnboardingRepository';
import { StripeAccountStatusCode } from '../../../data/Models/StripeAccountStatusCode';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

interface IActivateOrganizationStageState {
    pollingTimer: number | null,
    checkingState: boolean,
}
export class ActivateOrganizationStage extends React.Component<IOnboardingStageProps, IActivateOrganizationStageState> {
    public static defaultProps: Partial<IOnboardingStageProps> = {
    }

    state: IActivateOrganizationStageState = {
        pollingTimer: null,
        checkingState: false,
    }

    componentDidMount() {
        this.state.pollingTimer = window.setInterval(() => this.checkOrganizationStatus(), 5000);
    }

    componentWillUnmount() {
        this.stopCheckingState();
    }

    stopCheckingState = () => {
        if (this.state.pollingTimer != null)
            window.clearInterval(this.state.pollingTimer);

        this.state.pollingTimer = null;
    }

    checkOrganizationStatus = async () => {
        if (this.state.checkingState)
            return;

        this.state.checkingState = true;

        var response = await getOrganizationStatus(this.props.onboardingState.organization.id);

        if (response != null && response.status == 401) {
            this.props.changePage("/login");
        }
        else if (response == undefined || response.status != 200) {
            this.stopCheckingState();
        }
        else {
            if (response.data.organizationStatus == StripeAccountStatusCode.Inactive || response.data.organizationStatus == StripeAccountStatusCode.Pending) {
                this.props.onPrevStage(this.props.onboardingState);
            }
            else if (response.data.organizationStatus == StripeAccountStatusCode.Active) {
                var createClusterResponse = await createDefaultEventCluster(this.props.onboardingState.organization.id);
                if (createClusterResponse == undefined || createClusterResponse.status != 200) {
                    this.stopCheckingState();
                }
                else {
                    var eventClusterId = createClusterResponse.data.eventClusterId;
                    this.props.changePage(`/event-group?id=${eventClusterId}&tab=1&checkOnboarding=true`);
                }
            } else { // failed or unknown
                this.props.onPrevStage(this.props.onboardingState);
            }

        }

        this.state.checkingState = false;
    }

    render() {
        return (
            <React.Fragment>
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">Onboarding</Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ textAlign: "center" }}>
                        <CircularProgress />
                        <h2>
                            Waiting for Stripe
                        </h2>
                        <Link href={this.props.onboardingState.organization.stripeAccountSetupUrl}>
                            Click Here To Activate Your Stripe Connect Account
                        </Link>
                    </div>
                </DialogContent>
            </React.Fragment>
        );
    }
}