/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import { SimilarEventsTable } from './SimilarEventsTable';
import { EventSuccessPredictionSection } from './EventSuccessPredictionSection';
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import { getEventSuccessPrediction } from '../../../data/EventSuccessPredictionRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { getSimilarEvents } from '../../../data/AnalyticsRepository';

var Enumerable = require('linq');

interface IPredictiveAnalyticsSectionProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class PredictiveAnalyticsSection extends React.Component<IPredictiveAnalyticsSectionProps> {

    state = {
        eventId: '',
        dateRange: 2,
        dateRanges: [],
        eventClusterId: '',
        similarEvents: [],
        keywordsList: [],
        adjustedProbabilityOfSuccess: 0,
        predictionCalendar: [],
        keywordOccurances: [],
        serverError: '',
        currentDate: new Date(),
    }

    async componentDidMount() {
        this.props.loadingOn();
        const query = new URLSearchParams(window.location.search);

        var eventId: string = query.get('id') ?? "";
        this.setState({ ...this.state, eventId: eventId });

        let eventClusterId: string = query.get('eventGroupId') ?? "";

        await this.LoadPredictivePredictiveScore(eventId, eventClusterId);

        await this.LoadSimilarEvents(eventId, eventClusterId, undefined, undefined, "");

        var dateRanges = [];

        dateRanges.push({ name: "+/- 1 day", id: 1 });
        dateRanges.push({ name: "+/- 2 days", id: 2 });
        dateRanges.push({ name: "+/- 3 days", id: 3 });
        dateRanges.push({ name: "+/- 4 days", id: 4 });
        dateRanges.push({ name: "+/- 5 days", id: 5 });
        dateRanges.push({ name: "+/- 6 days", id: 6 });
        dateRanges.push({ name: "+/- 7 days", id: 7 });

        this.setState({
            ...this.state,
            dateRanges: dateRanges
        });

        this.props.loadingOff();
    }

    LoadPredictivePredictiveScore = async (eventId: any, eventClusterId: any, dateRange: any = 2) => {

        var serverError = "";
        var adjustedProbabilityOfSuccess = 0;
        var keywordOccurances: any[] = [];
        var predictionCalendar: any[] = [];
        var currentDate = new Date();

        var eventSuccessPredictionResponse = await getEventSuccessPrediction(eventId, undefined, undefined, undefined, undefined, dateRange);

        if (eventSuccessPredictionResponse != null && eventSuccessPredictionResponse.status == 401) {
            this.RedirectToLogin(eventClusterId, eventId);
        }
        else if (eventSuccessPredictionResponse != null && eventSuccessPredictionResponse.status == 200) {
            if (!eventSuccessPredictionResponse.data.success) {
                serverError = eventSuccessPredictionResponse.data.errorMsg;
            }
            else {
                adjustedProbabilityOfSuccess = eventSuccessPredictionResponse.data.eventSuccessPrediction?.adjustedProbability ?? 0;
                keywordOccurances = eventSuccessPredictionResponse.data.eventSuccessPrediction?.keywordOccurences ?? 0;
                currentDate = eventSuccessPredictionResponse.data.eventSuccessPrediction?.dateLocal ?? new Date();
                predictionCalendar = eventSuccessPredictionResponse.data.eventSuccessPredictionCalendar ?? [];
                predictionCalendar.forEach((e:any)=>e.labelData = e);
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            eventId: eventId,
            eventClusterId: eventClusterId,
            adjustedProbabilityOfSuccess: adjustedProbabilityOfSuccess,
            keywordOccurances: keywordOccurances,
            predictionCalendar: predictionCalendar,
            currentDate: currentDate,
            serverError: serverError
        });
    }

    LoadSimilarEvents = async (eventId: any, eventClusterId: any, startDateRange: any, finishDateRange: any, filterByKeyword: string, dateRange: any = 2) => {
        let serverError = '';
        let similarEvents = [];
        var similarEventsResponse = await getSimilarEvents(eventId, startDateRange, finishDateRange, filterByKeyword, undefined, undefined, undefined, undefined, dateRange);
        var keywordsList: string[] = [];
        keywordsList.push('');

        if (similarEventsResponse != null && similarEventsResponse.status == 401) {
            this.RedirectToLogin(eventId, eventClusterId);
        }
        else if (similarEventsResponse != null && similarEventsResponse.status == 200) {
            if (!similarEventsResponse.data.success) {
                serverError = similarEventsResponse.data.errorMsg;
            }
            else {
                similarEvents = similarEventsResponse.data.similarEvents;
                similarEvents.forEach((event: any) => {
                    if (event.keywords != null)
                        event.keywords.forEach((keyword: any) => {
                            if (keywordsList.indexOf(keyword.keyword) == -1)
                                keywordsList.push(keyword.keyword);
                        })
                });
                this.setState({
                    ...this.state,
                    similarEvents: similarEvents,
                    keywordsList: keywordsList
                });
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
    }

    LoadSimilarEventsPropFunc = async (startDateRange: any, finishDateRange: any, filterByKeyword: string) => {
        await this.LoadSimilarEvents(this.state.eventId, this.state.eventClusterId, startDateRange, finishDateRange, filterByKeyword);
    }

    RedirectToLogin = (eventClusterId: any, eventId: any) => {
        let eventClusterParam = eventClusterId != null && eventClusterId != "" ? `?eventGroupId=${eventClusterId}` : "";

        if (eventId != '' && eventId != undefined) {
            eventClusterParam += `%26id=${eventId}`;
        }

        this.props.changePage("/login?redirect=event" + eventClusterParam);
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: '' });
    }

    HandleDateRangeChange = async (dateRange: any) => {
        this.props.loadingOn();

        await this.LoadPredictivePredictiveScore(this.state.eventId, this.state.eventClusterId, dateRange);

        await this.LoadSimilarEvents(this.state.eventId, this.state.eventClusterId, undefined, undefined, "", dateRange);

        this.setState({ ...this.state, dateRange: dateRange });

        this.props.loadingOff();
    }

    render() {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <div style={{ height: "100%" }}>
                    <div style={{ paddingBottom: "20px" }}>
                        <GenericDropdown
                            label="Comparison Date Range"
                            value={this.state.dateRange}
                            data={this.state.dateRanges}
                            onChange={this.HandleDateRangeChange}
                        />
                    </div>
                    <EventSuccessPredictionSection
                        adjustedProbabilityOfSuccess={this.state.adjustedProbabilityOfSuccess}
                        keywordOccurances={this.state.keywordOccurances}
                        predictionCalendar={this.state.predictionCalendar}
                        currentDate ={this.state.currentDate}
                    />
                    <SimilarEventsTable
                        similarEvents={this.state.similarEvents}
                        keywordsList={this.state.keywordsList}
                        loadSimilarEvents={this.LoadSimilarEventsPropFunc}
                        changePage={this.props.changePage}
                    />
                </div>
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
            </div>
        )
    }
}