/// <reference path="../../../data/DataModels/types.d.ts" />
/// <reference path="../../../data/Models/TicketOption.d.ts" />
import React from 'react';
import NumberFormat from 'react-number-format';


interface ITicketPurchaseTotalsReviewSectionProps{
    basePriceTotal: number,
    couponCodeDiscountAmount: number,
    serviceFeeTotal: number,
    salesTaxTotal: number,
    processingFeeTotal: number,
    totalPrice: number
}
export default class TicketPurchaseTotalsReviewSection extends React.Component<ITicketPurchaseTotalsReviewSectionProps> {

    render()
    {
        return (
            
            <div style={{width: "100%"}}>
                <table>
                    <tr>
                        <td>Base Price:</td>
                        <td><NumberFormat value={this.props.basePriceTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                    {this.props.couponCodeDiscountAmount > 0 && <tr>
                        <td>Coupon Code Discount:</td>
                        <td>(<NumberFormat value={this.props.couponCodeDiscountAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />)</td>
                    </tr>}
                    <tr>
                        <td>Service Fee:</td>
                        <td><NumberFormat value={this.props.serviceFeeTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                    <tr>
                        <td>Sales Tax:</td>
                        <td><NumberFormat value={this.props.salesTaxTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                    <tr><td>Processing Fee (Non-Refundable):</td>
                        <td><NumberFormat value={this.props.processingFeeTotal.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td><NumberFormat value={this.props.totalPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                    </tr>
                </table>
            </div>
        )
    }
}