import React from 'react';

import { FinancialsChart } from './FinancialsChart';
interface IAdminFinancialsDashboardProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class AdminFinancialsDashboard extends React.Component<IAdminFinancialsDashboardProps> {
    render()
    {
        return (
            <div style={{height: '100%', width: "100%"}}>
                <div style={{width: "100%", marginTop: "10px"}}  className="admin-financials-dashboard">
                    <FinancialsChart changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}/>
                </div>
            </div>
        );
    }
}