import * as React from 'react';
import Link from '@material-ui/core/Link';

interface ITicketDetailsLinkCellProps{
    id: string,
    value: any,
    title: string,
    onClick: (id: string) => void
}

export class TicketDetailsLinkCell extends React.Component<ITicketDetailsLinkCellProps> {

    render() {
        return (
            <td>
                <div className="genericDisplayCellTitle">
                    {this.props.title}:
                </div>
                <div className="genericDisplayCellLink">
                    <Link href="#" onClick={() => this.props.onClick(this.props.id)}>
                        {this.props.value}
                    </Link>
                </div>
            </td>
        );
    }
}