/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { PricingAdjustmentDetails } from './PricingAdjustmentDetails';
import '../../Styles/EventTicketingFormSection.css';
import CurrencyInput from 'react-currency-input-field';
import '../../Styles/EventTicketingFormSection.css';
import { CheckboxGridCell } from '../SubComponents/CheckboxGridCell';
import { CheckboxGridFilterCell } from '../SubComponents/CheckboxGridFilterCell';
import { PricingAdjustmentFieldsContainer } from './PricingAdjustmentFieldsContainer';
import { TicketOptionType } from "../../../data/Models/Enums"
import Moment from 'moment';
import Link from '@material-ui/core/Link';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
    MuiPickersUtilsProvider,
    DateTimePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import { AddEditPricingAdjustmentDialog } from './AddEditPricingAdjustmentDialog';
/// <reference path="../../../data/Models/Enums.ts" />

var Enumerable = require('linq');

interface IGeneralAdmissionSubSectionProps {
    disabled: boolean,
    ticketOptions: any[],
    pricingAdjustments: any[],
    focusedId: string,
    inputCursorStart: number | null,
    freeTicketsOnly: boolean,
    ownerUserRole: number,
    isClusterOwner: boolean,
    eventClusterId: string,
    finishEventDateTime: Date,
    addTicketOption: (ticketOptionType: TicketOptionType) => void,
    updateTicketOptions: (ticketOptions: any[], ticketOptionType: TicketOptionType, updatePendingChanges: boolean) => void,
    updatePricingAdjustments: (pricingAdjustments: any[]) => void,
    handleTicketOptionNameChange: (dataIndex: number, v: any, id: string, cursorStart: number | null) => void,
    handleTicketOptionPriceChange: (dataIndex: number, v: any, ticketOptionType: TicketOptionType) => void,
    handleTicketOptionQtyChange: (dataIndex: number, v: any, id: string, cursorStart: number | null, ticketOptionType: TicketOptionType) => void,
    handleTicketOptionSaleEndsDateTimeChange: (dataIndex: number, v: Date | null, ticketOptionType: TicketOptionType) => void,
    handleTicketOptionSaleStartsDateTimeChange: (dataIndex: number, v: Date | null, ticketOptionType: TicketOptionType) => void,
    updateFocusId: (id: string, cursorStart: number | null) => void,
    addPricingAdjustment: (pricingAdjustment: any, ticketOptionType: TicketOptionType) => void,
    removeFocusedId: () => void
}

export class GeneralAdmissionSubSection extends React.Component<IGeneralAdmissionSubSectionProps> {

    state = {
        headCells: [
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'price',
                label: 'Price',
            },
            {
                id: 'quantity',
                label: 'Quantity',
            },
            {
                id: 'saleStartsDateTime',
                label: 'Sale Start Date/Time',
            },
            {
                id: 'saleEndsDateTime',
                label: 'Sale End Date/Time',
            }
        ],
        pricingAdjustmentAmountCodes: [],
        pricingAdjustmentCodes: [],
        deleteTicketOptionModalOpen: false,
        pricingAdjustmentModalOpen: false,
        currentPricingAdjustmentIndex: -1,
        currentDeleteIndex: undefined
    }

    async componentDidMount() {
        let pricingAdjustmentAmountCodes: any[] = [{ id: 0, name: "Dollars" }, { id: 1, name: "Percent" }];
        let pricingAdjustmentCodes: any[] = [{ id: 0, name: "Date" }, { id: 1, name: "Coupon" }];

        this.setState({ ...this.state, pricingAdjustmentAmountCodes: pricingAdjustmentAmountCodes, pricingAdjustmentCodes: pricingAdjustmentCodes });
    }

    AddTicketOption = () => {
        this.props.addTicketOption(TicketOptionType.GeneralAdmission);
    }

    HandleTicketOptionNameChange = (dataIndex: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.handleTicketOptionNameChange(dataIndex, event.target.value, event.target.id, event.target.selectionStart);
    }

    HandleTicketOptionQtyChange = (dataIndex: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.handleTicketOptionQtyChange(dataIndex, event.target.value, event.target.id, event.target.selectionStart, TicketOptionType.GeneralAdmission);
    }

    HandleTicketOptionSaleEndsDateTimeChange = (dataIndex: number, date: MaterialUiPickersDate) => {
        this.props.handleTicketOptionSaleEndsDateTimeChange(dataIndex, date, TicketOptionType.GeneralAdmission);
    }

    HandleTicketOptionSaleStartsDateTimeChange = (dataIndex: number, date: MaterialUiPickersDate) => {
        this.props.handleTicketOptionSaleStartsDateTimeChange(dataIndex, date, TicketOptionType.GeneralAdmission);
    }

    OpenPricingAdjustmentModal = (currentIndex: any) => {
        let ticketOptions: any[] = this.props.ticketOptions;
        if(currentIndex >= 0){
            let pricingAdjustment: any = this.props.pricingAdjustments[currentIndex];
            ticketOptions.forEach((o: any) =>{
                o.selected = pricingAdjustment.generalAdmissionTicketOptionIds != null && pricingAdjustment.generalAdmissionTicketOptionIds.indexOf(o.id) >= 0;
            });
        }
        this.props.updateTicketOptions(ticketOptions, TicketOptionType.GeneralAdmission, false);

        this.setState({ ...this.state, pricingAdjustmentModalOpen: true, currentPricingAdjustmentIndex: currentIndex });
    }

    ClosePricingAdjustmentModal = (updatePendingChanges: boolean) => {
        let ticketOptions: any[] = this.props.ticketOptions;

        ticketOptions.forEach((o: any) => {
            o.selected = false;
        });

        this.props.updateTicketOptions(ticketOptions, TicketOptionType.GeneralAdmission, updatePendingChanges);

        this.setState({
            ...this.state,
            pricingAdjustmentModalOpen: false,
            currentPricingAdjustmentIndex: -1
        });
    }

    HandlePricingAdjustmentNameChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentName: value });
    }

    HandlePricingAdjustmentAmountChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentAmount: value });
    }

    HandlePricingAdjustmentCodeChange = (value: any, pricingAdjustmentCoupon: any) => {
        this.setState({ ...this.state, pricingAdjustmentCode: value, pricingAdjustmentCoupon: pricingAdjustmentCoupon });
    }

    HandleAdjustmentStartDateChange = (date: MaterialUiPickersDate) => {
        this.setState({ ...this.state, pricingAdjustmentStartDate: date });
    }

    HandleAdjustmentEndDateChange = (date: MaterialUiPickersDate) => {
        this.setState({ ...this.state, pricingAdjustmentEndDate: date });
    }

    HandlePricingAdjustmentCouponChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentCoupon: value });
    }

    OnTicketOptionCheckboxClick = (dataIndex: number, ticketOptionId: string) => {

        let pricingAdjustments: any[] = this.props.pricingAdjustments;

        let pricingAdjustment: any = pricingAdjustments[dataIndex]

        if(pricingAdjustment.generalAdmissionTicketOptionIds.indexOf(ticketOptionId) > -1)
        {
            pricingAdjustment.generalAdmissionTicketOptionIds = Enumerable.from(pricingAdjustment.generalAdmissionTicketOptionIds).where((id: any) => id != ticketOptionId).toArray();
        }
        else{
            pricingAdjustment.generalAdmissionTicketOptionIds.Add(ticketOptionId);
        }

        pricingAdjustment[dataIndex] = pricingAdjustment;

        this.props.updatePricingAdjustments(pricingAdjustment);
    }

    HandlePricingAdjustmentAmountCodeChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentAmountCode: value, pricingAdjustmentAmount: 0 });
    }

    RemoveFocusedId = () => {
        this.setState({ ...this.state, localFocusId: '', localCursorStart: 0 });
    }

    UpdatePricingAdjustments = (pricingAdjustments: any[]) => {

        this.props.updatePricingAdjustments(pricingAdjustments);
    }

    OnDeleteTicketOptionButtonClick = (dataIndex: number) => {
        this.setState({ ...this.state, deleteTicketOptionModalOpen: true, currentDeleteIndex: dataIndex });
    }

    CloseDeleteTicketOptionModal = () => {
        this.setState({ ...this.state, deleteTicketOptionModalOpen: false, currentDeleteIndex: undefined })
    }

    DeleteTicketOptionClick = () => {
        let ticketOptions: any[] = this.props.ticketOptions;

        let ticketOptionsNew: any[] = [];
        ticketOptions.forEach((v: any, i) => {
            if (i != this.state.currentDeleteIndex) {
                ticketOptionsNew.push(v);
            }
        });

        this.props.updateTicketOptions(ticketOptionsNew, TicketOptionType.GeneralAdmission, true);

        this.setState({ ...this.state, deleteTicketOptionModalOpen: false, currentDeleteIndex: undefined });
    }

    render() {
        return (
            <CardContent style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
                <div className="addTicketOptionContainer">
                    <Button variant="contained" className='btn-primary' onClick={this.AddTicketOption} disabled={this.props.disabled}>
                        Add Ticket Option
                    </Button>
                    {/* TODO: Add back */}
                    <Button variant="contained" className='btn-secondary' style={{marginLeft: "10px"}} onClick={() => this.OpenPricingAdjustmentModal(-1)}  >
                        Add Pricing Adjustment
                    </Button>
                </div>
                <h3>General Admissions - Ticket Options</h3>
                {
                    (this.props.ownerUserRole == 1 || this.props.ownerUserRole == 0) &&
                    <p style={{ color: "red" }}>
                         <strong>
                            {this.props.isClusterOwner ?
                                    <span>
                                        You have not been approved for paid ticketing at this time. Please email us at support@nrby.io if you have any questions.
                                    </span>
                                    :
                                    <span>
                                        The creator of this event's group has not been approved for paid ticketing.  
                                    </span>
                                }
                        </strong>
                    </p>
                }
                {this.props.freeTicketsOnly &&
                    <p style={{ color: "red" }}>
                        <strong>
                            This event is assigned to a default Organization,
                            which does not support paid ticketing options. You may create free tickets, or you may click the "Event Has Free Admission" checkbox above.

                            {this.props.isClusterOwner ?
                                <span>
                                    &nbsp;
                                    To used paid ticketing, please reassign your Event Group to an "Individual" or "Company" organization
                                    and be sure to activate it with Stripe.
                                </span>
                                :
                                <span>
                                    &nbsp;Please reach out to your Event Group owner to ask them to change their Event Group organization if this event needs to have paid ticketing enabled.
                                </span>
                            }
                        </strong>
                    </p>}
                <div style={{ height: "75%" }}>
                    <Box style={{ width: '100%' }}>
                        <Paper style={{ width: '100%' }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    style={{ marginLeft: "0.5%", width: "99%" }}
                                    className="TicketOptionsGrid general-admissions-table table-filters-hidden"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {this.state.headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    className="table-header-border show-on-mobile"
                                                >
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                            <TableCell
                                                className="table-header-border no-show-on-mobile"
                                            >
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.ticketOptions.map((row: any, index: any) => {
                                            return (
                                                <><TableRow
                                                    hover
                                                    tabIndex={-1}
                                                >
                                                    <TableCell align="right">
                                                        <TextField
                                                            id={'optionName-' + index}
                                                            error={row.nameError !== ""}
                                                            style={{ width: '90%' }}
                                                            label="Option Name"
                                                            placeholder="Option Name"
                                                            margin="normal"
                                                            disabled={this.props.disabled}
                                                            helperText={row.nameError}
                                                            FormHelperTextProps={{ color: "red" }}
                                                            value={row.name}
                                                            autoFocus={this.props.focusedId == ("optionName-" + index)}
                                                            onBlur={this.props.removeFocusedId}
                                                            onFocus={(e) => {
                                                                e.target.selectionStart = this.props.inputCursorStart;
                                                                e.target.selectionEnd = this.props.inputCursorStart;
                                                            } }
                                                            onChange={(e) => this.HandleTicketOptionNameChange(index, e)}
                                                            variant="outlined"
                                                            className="textFieldInput" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <CurrencyInput
                                                            id={'optionPrice-' + index}
                                                            placeholder="Price"
                                                            defaultValue={0.00}
                                                            decimalsLimit={2}
                                                            step={1}
                                                            decimalScale={2}
                                                            style={{ height: "50px", width: "100%", marginTop: "10px", fontSize: "1rem" }}
                                                            prefix="$"
                                                            className="CurrencyInput"
                                                            disabled={this.props.disabled || this.props.freeTicketsOnly || this.props.ownerUserRole == 1 || this.props.ownerUserRole == 0}
                                                            value={row.price}
                                                            onValueChange={(value) => this.props.handleTicketOptionPriceChange(index, value, TicketOptionType.GeneralAdmission)} />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <TextField
                                                            id={'optionQty-' + index}
                                                            error={row.qtyError !== ""}
                                                            style={{ width: '90%' }}
                                                            label="Quantity"
                                                            placeholder="Quantity"
                                                            margin="normal"
                                                            disabled={this.props.disabled}
                                                            helperText={row.qtyError}
                                                            FormHelperTextProps={{ color: "red" }}
                                                            value={row.qty}
                                                            autoFocus={this.props.focusedId == ("optionQty-" + index)}
                                                            onBlur={this.props.removeFocusedId}
                                                            onFocus={(e) => {
                                                                e.target.selectionStart = this.props.inputCursorStart;
                                                                e.target.selectionEnd = this.props.inputCursorStart;
                                                            } }
                                                            onChange={(e) => this.HandleTicketOptionQtyChange(index, e)}
                                                            variant="outlined"
                                                            className="textFieldInput" />
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DateTimePicker
                                                                margin="normal"
                                                                id={'saleStartsDateTime-' + index}
                                                                label="Sales Starts Date Time"
                                                                inputVariant="outlined"
                                                                value={row.saleStartsDateTime}
                                                                onChange={(e) => this.HandleTicketOptionSaleStartsDateTimeChange(index, e)}
                                                                error={row.saleStartsDateTimeError !== ""}
                                                                helperText={row.saleStartsDateTimeError}
                                                                FormHelperTextProps={{ color: "red" }}
                                                                format="MM/dd/yyyy hh:mm a"
                                                                className="saleStartsDateTime"
                                                                fullWidth={true}
                                                                disabled={false}
                                                                disablePast={false}
                                                                clearable={true} />
                                                        </MuiPickersUtilsProvider>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DateTimePicker
                                                                margin="normal"
                                                                id={'saleEndsDateTime-' + index}
                                                                label="Sales Ends Date Time"
                                                                inputVariant="outlined"
                                                                value={row.saleEndsDateTime}
                                                                onChange={(e) => this.HandleTicketOptionSaleEndsDateTimeChange(index, e)}
                                                                error={row.saleEndsDateTimeError !== ""}
                                                                helperText={row.saleEndsDateTimeError}
                                                                FormHelperTextProps={{ color: "red" }}
                                                                format="MM/dd/yyyy hh:mm a"
                                                                className="saleEndsDateTime"
                                                                fullWidth={true}
                                                                disabled={false}
                                                                disablePast={true}
                                                                clearable={true} />
                                                        </MuiPickersUtilsProvider>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Button variant="contained" className="btn-delete" onClick={() => this.OnDeleteTicketOptionButtonClick(index)} disabled={this.props.disabled}>
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                    <Box>
                        <Paper>
                            <TableContainer>
                                <PricingAdjustmentDetails 
                                    pricingAdjustments={this.props.pricingAdjustments} 
                                    ticketOptions={this.props.ticketOptions}
                                    pricingAdjustmentAmountCodes={this.state.pricingAdjustmentAmountCodes}
                                    pricingAdjustmentCodes={this.state.pricingAdjustmentCodes}
                                    openPricingAdjustmentModal={this.OpenPricingAdjustmentModal}
                                    updatePricingAdjustments={this.props.updatePricingAdjustments}
                                    /> 
                            </TableContainer>
                        </Paper>
                    </Box>
                </div>
                    <AddEditPricingAdjustmentDialog
                        open={this.state.pricingAdjustmentModalOpen}
                        currentIndex={this.state.currentPricingAdjustmentIndex}
                        pricingAdjustments={this.props.pricingAdjustments}
                        ticketOptions={this.props.ticketOptions}
                        pricingAdjustmentAmountCodes={this.state.pricingAdjustmentAmountCodes}
                        pricingAdjustmentCodes={this.state.pricingAdjustmentCodes}
                        finishEventDateTime={this.props.finishEventDateTime}
                        closePricingAdjustmentModal={this.ClosePricingAdjustmentModal}
                        updateTicketOptions={(ticketOptions) => this.props.updateTicketOptions(ticketOptions, TicketOptionType.GeneralAdmission, true)}
                        updatePricingAdjustments={this.UpdatePricingAdjustments}
                    />
                    <Dialog
                        onClose={this.CloseDeleteTicketOptionModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.deleteTicketOptionModalOpen}
                    >
                        <DialogTitle disableTypography>
                            <Typography variant="h6">Delete Pricing Adjustment</Typography>
                            <IconButton aria-label="close"
                                className='close-button'
                                onClick={this.CloseDeleteTicketOptionModal}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            Are you sure you want to delete this ticket option?
                        </DialogContent>
                        <DialogActions disableSpacing={true}>
                            <Button onClick={this.CloseDeleteTicketOptionModal} className='two-action-buttons'>
                                No
                            </Button>
                            <Button onClick={this.DeleteTicketOptionClick} className='two-action-buttons'>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
            </CardContent>
        );
    }
}