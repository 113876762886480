import React from 'react';
import { getOrganizationPublicProfile } from '../../../data/OrganizationRepository'
import Card from '@material-ui/core/Card';
/// <reference path="./models/RequestModels.d.ts" />

interface IPublicOrganizationProfileProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class PublicOrganizationProfile extends React.Component<IPublicOrganizationProfileProps> {
    
    state = {
        organizationName: "",
        contactPhone: "",
        contactEmail: "",
        streetAddress: "",
        city: "",
        state: "",
        country: "",
        postalCode: ""
    }

    async componentDidMount(){
        this.props.loadingOn();

        await this.LoadOrganization();

        this.props.loadingOff();
    }

    LoadOrganization = async () => {
        const query = new URLSearchParams(window.location.search);

        var organizationId: string = query.get('id') ?? "";

        let organizationName = "";
        let contactPhone = "";
        let contactEmail = "";
        let streetAddress = "";
        let city = "";
        let state = "";
        let country = "";
        let postalCode = "";

        let serverError = "";

        if(organizationId == undefined || organizationId == ""){
            serverError = "Valid organization Id not provided.";
        }
        else{
            var response = await getOrganizationPublicProfile(organizationId);

            if(response != null && response.status == 401){
                this.props.changePage("/login?redirect=organizations");
            }
            else if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverError = response.data.errorMsg;
                }
                else{
                    organizationName = response.data.name;
                    contactEmail = response.data.email;
                    contactPhone = response.data.phone;
                    streetAddress = response.data.streetAddress;
                    city = response.data.city;
                    state = response.data.state;
                    country = response.data.country;
                    postalCode = response.data.postalCode;
                }
            }
            else{
                serverError = "An unknown error occured. Please try again later";
            }
        }

        this.setState({...this.state, 
            organizationName: organizationName,
            contactEmail: contactEmail,
            contactPhone: contactPhone,
            streetAddress: streetAddress,
            city: city,
            state: state,
            country: country,
            postalCode: postalCode,
            serverError: serverError});
    }

    render(){
        return (
            <div className="businessProfileContainer">
                <div style={{fontSize: "20px", marginBottom: "15px"}}>
                    Business: <strong>{this.state.organizationName}</strong>
                </div>
                <div>
                    <div style={{fontSize: "15px", textDecoration: "underline"}}>
                        <strong>Contact Information</strong>
                    </div>
                    <table>
                        <tr>
                            <td>Email: </td>
                            <td>{this.state.contactEmail}</td>
                        </tr>
                        <tr>
                            <td>Phone: </td>
                            <td>{this.state.contactPhone}</td>
                        </tr>
                    </table>
                    <div style={{marginTop: "15px", marginBottom: "15px"}}>
                        <div style={{marginBottom: "10px"}}>
                            Address: 
                        </div>
                        {this.state.streetAddress}<br/>
                            {this.state.city + ", " + this.state.state + " " + this.state.postalCode}<br/>
                            {this.state.country}
                    </div>
                </div>
                <div>
                    <div style={{fontSize: "15px", textDecoration: "underline"}}>
                        <strong>Business Policies</strong>
                    </div>
                    <div>
                        <div style={{marginTop: "15px"}}>
                            We facilitate the purchase of a ticket or tickets that allow access to a single event at a set time.
                        </div>
                        <div style={{marginTop: "15px"}}>
                            All transactions are done in USD.
                        </div>
                        <div style={{marginTop: "15px"}}>
                            For any customer service issue in regards to ticketing issues or purchase problems please email directly <a href="mailto:support@nrby.io">support@nrby.io</a>. Please allow up to 24 hours for a response.
                        </div>
                        <div style={{marginTop: "15px"}}>
                            Refund policy - All sales final. No refunds allowed unless an event is cancelled, in which case a full refund will be issued within 7-10 business days back to the original payment method. 
                        </div>
                        <div>
                            Delivery policy - QR Code Tickets will be sent directly into the user’s NrBy app account and can be accessed at any time after 60 minutes from time of purchase.
                        </div>
                        <div>
                            Return policy - Tickets are not able to be returned once purchased.
                        </div>
                        <div>
                            Cancellation policy - Users are not required to have any subscriptions or reservations.
                        </div>
                        <div style={{marginTop: "15px"}}>
                            Nrby Privacy Policy can be viewed at <a href="https://www.nrby.io/privacy">https://www.nrby.io/privacy</a> 
                        </div>
                        <div style={{marginTop: "15px"}}>
                            Nrby and all event creators comply with the PCI standards. HTTPS and other security best practices are being used in all transactions. 
                            Any and all customer’s payment information is being handled safely by Stripe directly when doing business with NrBy or when making any purchases on the Nrby app.
                        </div>
                        <div style={{marginTop: "15px"}}>
                            <img alt="Credit Card Logos" title="Credit Card Logos" src="http://www.credit-card-logos.com/images/multiple_credit-card-logos-1/credit_card_logos_16.gif" width="336" height="50"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}