import React from 'react';
import '../App.css';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { signUpNewUser } from '../../data/PortalUserRepository';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import validator  from 'email-validator';
import { validPhoneNumber, validUsername } from '../Shared/ValidationFunctions';
import { GenericSuccessModal } from '../Components/GenericSuccessModal';
import { GenericErrorModal } from '../Components/GenericErrorModal';
/// <reference path="./models/RequestModels.d.ts" />

interface IContactPageProps{
}

export class ContactPage extends React.Component<IContactPageProps> {
    

    render(){
        return (
            <div>
                For any questions, please email our support line at <a href="mailto:support@nrby.io">support@nrby.io</a>. We will email you back as soon as possible. Thanks!
            </div>
        );
    }
}