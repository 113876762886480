import * as React from 'react';
import { onboardingComplete } from '../../../data/OnboardingRepository';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

export class SetupSuccessfulStage extends React.Component<IOnboardingStageProps> {
    public static defaultProps: Partial<IOnboardingStageProps> = {
    }

    HandleNextButtonClick = async () => {
        await onboardingComplete();
        this.props.changePage(`create-event?eventGroupId=${this.props.onboardingState.defaultEventClusterId}`);
    }

    render() {
        return (
            <React.Fragment>
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">Onboarding</Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <h2>
                        Congratulations for completing the setup of your account.
                        <br />
                        You are now able to create events and charge for ticketing!
                    </h2>
                    {this.props.onboardingState.hostRoleRequested && 
                    <div style={{marginTop: "20px"}}>
                        <h2>
                            Please allow up to 24 hours for account approval. To expedite the process please email us at support@nrby.io.
                        </h2>
                    </div>
                    }
                </DialogContent>
                <DialogActions disableSpacing={true}  style={{ textAlign: "center", display: "block"}}>
                    <Button className="btn-primary" variant="contained"
                    style={{marginTop: "10px", marginBottom: "10px"}}
                        size="large" onClick={this.HandleNextButtonClick}>
                        Create Event
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}