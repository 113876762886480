import React from 'react';
import './App.css';
import { SideBar } from './Components/SideBar';
import { HeaderBar } from './Components/HeaderBar';
import { AuthCheck } from './Components/AuthCheck';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PortalUserRoleCode } from "../data/Models/PortalUserRoleCode";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RouterSection } from './Components/RouterSection';
import { OnboardingModal } from './Components/Onboarding/OnboardingModal';
import { flatMap } from 'lodash';
import { OnboardingStageCode } from '../data/Models/OnboardingStageCode';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const reload = () => window.location.reload();

export class App extends React.Component {

  state = {
    authenticated: false,
    newNotifications: false,
    loading: false,
    portalUserRole: PortalUserRoleCode.None,
    mobileNavBarVisible: false,
    isWidget: false,
    onboardingComplete: false,
    onboardingCompletedCount: 0,
    onboardingModalVisible: false,
    onboardingOverride: false
  }

  componentDidMount(){
    const query = new URLSearchParams(window.location.search);
    
    let isWidget = query.get('isWidget') ?? false;


    var onboardingOverride = query.get("checkOnboarding") == "true";
    let onboardingDialogWasClosed = sessionStorage.getItem("onboardingModalClosed") == "true";

    this.setState({...this.state,
      isWidget: isWidget,
      onboardingOverride: onboardingOverride,
      onboardingModalVisible: !onboardingDialogWasClosed
  });
}

  SetAuthorization = (authenticated: boolean, portalUserRole: PortalUserRoleCode, onboardingComplete: boolean, onboardingCompletedCount: number, onboardingCurrentStage: OnboardingStageCode, newNotifications: boolean) => {
    let onboardingDialogWasClosed = sessionStorage.getItem("onboardingModalClosed") == "true";
    let onboardingModalVisible = !onboardingComplete && authenticated && !onboardingDialogWasClosed && (onboardingCompletedCount == 0 || this.state.onboardingOverride);

    this.setState({
      ...this.state,
      authenticated: authenticated,
      newNotifications: newNotifications,
      portalUserRole: portalUserRole,
      onboardingComplete: onboardingComplete,
      onboardingModalVisible: onboardingModalVisible,
      onboardingCompletedCount: onboardingCompletedCount
    });
  }

  ChangePage = (page: string) => {
    window.location.href = page;
  }

  LogOut = () => {
    sessionStorage.removeItem("onboardingModalClosed");
    localStorage.removeItem("accessToken");
    this.ChangePage("/login");
  }

  LoadingOn = () => {
    this.setState({ ...this.state, loading: true });
  }

  LoadingOff = () => {
    this.setState({ ...this.state, loading: false });
  }

  MobileNavButtonClick = () => {
    this.setState({ ...this.state, mobileNavBarVisible: !this.state.mobileNavBarVisible });
  }

  OnRequestSearch = (searchTerm: string) => {
    this.ChangePage(`/all-events?name=${searchTerm}`);
  }

  HandleShowOnboarding = () => {
    sessionStorage.removeItem("onboardingModalClosed");
    this.setState({ ...this.state, onboardingModalVisible: true });
  }

  HandleOnCloseOnboarding = (stage: OnboardingStageCode) => {
    sessionStorage.setItem("onboardingModalClosed", "true");

    var onboardingComplete = stage == OnboardingStageCode.SetupSuccessful;

    this.setState({ ...this.state, onboardingModalVisible: false, onboardingComplete: onboardingComplete });
  }

  render() {

    var currentUrlPath = window.location.pathname;

    var ticketPurchasePage = currentUrlPath === '/event/tickets' || currentUrlPath === '/order/confirmation';

    return (
      <Router>
        <Switch>
        <Route path="/.well-known/apple-developer-merchantid-domain-association" onEnter={reload}/>
        <Route path="/">
                            
          <div className="page" style={ticketPurchasePage ? {backgroundColor: "#e3d8df"} : {backgroundColor: "white"}}>

            <AuthCheck setAuthorization={this.SetAuthorization} />

            <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
            {currentUrlPath !== '/event-public-details' && !ticketPurchasePage && !this.state.isWidget ?
              <div style={{ height: "100%" }}>
                <TableContainer style={{ height: "100%" }}>
                  <Table aria-label="simple table" style={{ height: "100%" }}>
                    <TableBody>
                      <TableRow style={{ flex: "0 1 66px;", width: "100%" }}>

                        <HeaderBar authenticated={this.state.authenticated}
                          onLogoutClick={this.LogOut}
                          changePage={this.ChangePage}
                          mobileNavButtonClick={this.MobileNavButtonClick}
                          onRequestSearch={this.OnRequestSearch}
                          newNotifications={this.state.newNotifications}
                        />
                      </TableRow>
                      <TableRow className="page" >
                        <TableCell className="sideBarCell" hidden={!this.state.authenticated}>
                          <SideBar authenticated={this.state.authenticated}
                            changePage={this.ChangePage}
                            portalUserRole={this.state.portalUserRole}
                            onRequestSearch={this.OnRequestSearch}
                            showOnboarding={!this.state.onboardingComplete && !this.state.onboardingModalVisible}
                            onboardingCompletedCount={this.state.onboardingCompletedCount}
                            onShowOnboarding={this.HandleShowOnboarding}
                          />
                        </TableCell>
                        <TableCell className={this.state.authenticated ? "routerCellLoggedIn" : "routerCellLoggedOut"}  >
                          {this.state.mobileNavBarVisible && <div className="mobileNavBar">
                            <SideBar authenticated={this.state.authenticated}
                              changePage={this.ChangePage}
                              portalUserRole={this.state.portalUserRole}
                              onRequestSearch={this.OnRequestSearch}
                              showOnboarding={!this.state.onboardingComplete && !this.state.onboardingModalVisible}
                              onboardingCompletedCount={this.state.onboardingCompletedCount}
                              onShowOnboarding={this.HandleShowOnboarding} />
                          </div>}
                          <div style={{ height: "100%" }}>
                            <RouterSection
                              showOnboarding={!this.state.onboardingComplete && !this.state.onboardingModalVisible}
                              authenticated={this.state.authenticated}
                              portalUserRole={this.state.portalUserRole}
                              onShowOnboarding={this.HandleShowOnboarding}
                              loadingOn={this.LoadingOn}
                              loadingOff={this.LoadingOff}
                              changePage={this.ChangePage}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {this.state.authenticated && this.state.onboardingModalVisible && <OnboardingModal
                  loadingOn={this.LoadingOn}
                  loadingOff={this.LoadingOff}
                  changePage={this.ChangePage}
                  onCloseOnboarding={this.HandleOnCloseOnboarding}
                  visible={this.state.onboardingModalVisible}
                />}
              </div> :
              <div style={{ height: "100%", width: "100%" }}>
              <div className={ticketPurchasePage ? "routerCellLoggedOutHideHeader" : "routerCellLoggedOut" }>
                <RouterSection
                  showOnboarding={!this.state.onboardingComplete && !this.state.onboardingModalVisible}
                  authenticated={this.state.authenticated}
                  portalUserRole={this.state.portalUserRole}
                  onShowOnboarding={this.HandleShowOnboarding}
                  loadingOn={this.LoadingOn}
                  loadingOff={this.LoadingOff}
                  changePage={this.ChangePage}
                />
              </div>
          </div>
            }

          </div>
          </Route>
          </Switch>
      </Router>
    );
  }
}
