import React from 'react';
import '../App.css';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { sendPasswordResetEmail } from '../../data/PortalUserRepository';

interface IForgotPasswordPageProps{
    loadingOn: () => void,
    loadingOff: () => void
}

export class ForgotPasswordPage extends React.Component<IForgotPasswordPageProps> {

    state = {
        username: '',
        usernameError: '',
        error: '',
        resetEmailSuccess: false
    }

    HandleUsernameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, username: event.currentTarget.value})
    }

    HandleSubmit = async () => {
        this.props.loadingOn();

        let usernameError = "";
        let passwordError = "";
        let errored = false;

        if(this.state.username.length == 0)
        {
            usernameError = "Username required.";
            errored = true;
        }

        let serverErrorText = "";

        var resetEmailSuccess = false;

        if(!errored){
            var response = await sendPasswordResetEmail(this.state.username);
            
            if(response != null && response.status == 200)
            {
                var validUsername = response.data.validUsername;

                if(response.data.success == false)
                {
                    serverErrorText = response.data.errorMsg;
                }
                else{
                    resetEmailSuccess = true;
                }
            }
            else{
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            usernameError: usernameError,
            passwordError: passwordError,
            error: serverErrorText,
            resetEmailSuccess: resetEmailSuccess
        });

        this.props.loadingOff();
    }

    render()
    {
        return (
            <form noValidate autoComplete="off"  >
            <Card className='cardOverwrite' >
                <CardHeader style={{ textAlign: 'center', background: '#212121', color: '#fff'}} title="Password Forgotten" />
                <CardContent>
                <div>
                    <TextField
                    error={this.state.usernameError !== ""}
                    fullWidth
                    id="username"
                    type="email"
                    label="Username"
                    placeholder="Username"
                    margin="normal"
                    helperText={this.state.usernameError}
                    FormHelperTextProps={{ color: "red" }}
                    onChange={(e) => this.HandleUsernameChange(e)}
                    variant="outlined"
                    />
                </div>
                </CardContent>
                <CardActions>
                <Button
                    variant="contained"
                    size="large"
                    className='btn-primary'
                    style={{flexGrow: 1}}
                    onClick={this.HandleSubmit}
                    >
                    Submit
                </Button>
                </CardActions>
                <CardContent>
                    <div style={{color: 'red'}} hidden={this.state.error == ''}>
                        {this.state.error}
                    </div>
                    
                    <div style={{color: 'limegreen'}} hidden={!this.state.resetEmailSuccess} >
                        An email was sent to you with a new temporary password. 
                        
                        Click  <a href="/login"  >here</a> to navigate back to the login page.
                    </div>
                </CardContent>
            </Card>
            
        </form>
        );
    }
}