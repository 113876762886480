/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import { StripeAccountStatusCode } from '../../../data/Models/StripeAccountStatusCode';
import { createOrganization } from '../../../data/OrganizationRepository';
import { getAccountInfo } from '../../../data/PortalUserRepository';
import { StripeAccountTypeCode } from '../../../data/Models/StripeAccountTypeCode';
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import { getStates } from '../../../data/StatesRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { GenericSuccessModal } from '../GenericSuccessModal';
import Link from '@material-ui/core/Link';
import { FormControlLabel, Grid } from '@material-ui/core';
import { saveCreatedOrganizationInfo, saveOrganizationBankInfo } from '../../../data/OnboardingRepository';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

var Enumerable = require('linq');

export class OrganizationBankInfoStage extends React.Component<IOnboardingStageProps> {
    public static defaultProps: Partial<IOnboardingStageProps> = {
    }

    state = {
        states: [],
        stripeAccountTypes: [],
        stripeAccountTypeCodeError: '',

        stripeAccountStatus: '',
        stripeAccountStatusCode: StripeAccountStatusCode.Unknown,

        ssn: '',
        ssnError: '',
        businessTaxId: '',
        businessTaxIdError: '',

        supportFieldsSameAsBusinessFields: false,
        supportEmail: "",
        supportEmailError: '',
        supportPhone: '',
        supportPhoneError: '',
        supportAddressStreet: '',
        supportAddressStreetError: '',
        supportAddressCity: '',
        supportAddressCityError: '',
        supportAddressState: '',
        supportAddressStateError: '',
        supportAddressPostalCode: '',
        supportAddressPostalCodeError: '',
        accountNumber: '',
        accountNumberError: '',
        routingNumber: '',
        routingNumberError: '',
        stripeAccountSetupUrl: '',
        stripeAccountUrl: '',
        organizationPublicUrl: '',
        createConfirmModalOpen: false,
        serverError: '',
        successMessage: '',
        taxFormSignatureExpected: '',
        taxFormSignature: '',
        taxFormSignatureError: '',
        taxFormSignedAt: new Date(),
        loading: false
    }

    async componentDidMount() {
        this.props.loadingOn();
        let stripeAccountTypes = [
            { id: StripeAccountTypeCode.Individual, name: "Individual" },
            { id: StripeAccountTypeCode.Company, name: "Company" }
        ];

        let states = [];
        let serverError = "";

        let response = await getStates();

        if (response != null && response.status == 401) {
            this.props.changePage(`/login?redirect=dashboard`);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                states = Enumerable.from(response.data.states).select((o: any) => ({ id: o.abbreviation, name: o.abbreviation })).toArray();
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        let organizationPublicUrl = process.env.REACT_APP_NRBY_ORG_PUBLICLINK;

        this.setState({
            ...this.state,
            stripeAccountTypes: stripeAccountTypes,
            states: states,
            organizationPublicUrl: organizationPublicUrl,
            serverError: serverError,

            accountNumber: this.props.onboardingState.organization.bankAccountNumber ?? "",
            routingNumber: this.props.onboardingState.organization.bankRoutingNumber ?? "",
            supportEmail: this.props.onboardingState.organization.supportEmail ?? "",
            supportPhone: this.props.onboardingState.organization.supportPhone ?? "",
            supportAddressStreet: this.props.onboardingState.organization.supportStreetAddress ?? "",
            supportAddressCity: this.props.onboardingState.organization.supportCity ?? "",
            supportAddressState: this.props.onboardingState.organization.supportState ?? "",
            supportAddressPostalCode: this.props.onboardingState.organization.supportPostalCode ?? "",
        });
        this.props.loadingOff();
    }

    HandleSsnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, ssn: event.currentTarget.value })
    }

    HandleBusinessTaxIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, businessTaxId: event.currentTarget.value })
    }

    HandleSupportEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, supportEmail: event.currentTarget.value })
        // this.props.onboardingState.organization.supportEmail = event.currentTarget.value
    }

    HandleSupportPhoneChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, supportPhone: event.currentTarget.value })
        // this.props.onboardingState.organization.supportPhone = event.currentTarget.value
    }

    HandleSupportAddressStreetChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, supportAddressStreet: event.currentTarget.value })
        // this.props.onboardingState.organization.supportStreetAddress = event.currentTarget.value
    }

    HandleSupportAddressCityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, supportAddressCity: event.currentTarget.value })
        // this.props.onboardingState.organization.supportCity = event.currentTarget.value
    }

    HandleSupportAddressStateChange = (value: any) => {
        this.setState({ ...this.state, supportAddressState: value })
        // this.props.onboardingState.organization.supportState = value
    }

    HandleSupportAddressPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, supportAddressPostalCode: event.currentTarget.value })
        // this.props.onboardingState.organization.supportPostalCode = event.currentTarget.value
    }

    HandleAccountNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, accountNumber: event.currentTarget.value })
        // this.props.onboardingState.organization.bankAccountNumber = event.currentTarget.value
    }

    HandleFocusAccountNumber = () => {
        if (this.IsNullOrWhiteSpace(this.props.onboardingState.organization.bankAccountNumber))
            this.setState({ ...this.state, accountNumber: '' })
        // this.props.onboardingState.organization.bankAccountNumber= "";
    }

    HandleRoutingNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, routingNumber: event.currentTarget.value })
        // this.props.onboardingState.organization.bankRoutingNumber = event.currentTarget.value
    }

    IsNullOrWhiteSpace = (str: string) => {
        return !(str && str.trim());
    }

    HandleTaxFormSignatureChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value != this.state.taxFormSignatureExpected) {
            let taxFormSignatureError = "Wrong signature";
            this.setState({ ...this.state, taxFormSignature: event.currentTarget.value, taxFormSignatureError: taxFormSignatureError })
            return;
        }

        this.setState({ ...this.state, taxFormSignature: event.currentTarget.value, taxFormSignatureError: "" })
    }

    HandleSaveOrganization = async () => {
        await this.LoadingOn();
        let serverError = "";

        let errored = false;

        var organization = this.props.onboardingState.organization;

        organization.supportEmail = this.state.supportEmail;
        organization.supportPhone = this.state.supportPhone;
        organization.supportStreetAddress = this.state.supportAddressStreet;
        organization.supportCity = this.state.supportAddressCity;
        organization.supportState = this.state.supportAddressState;
        organization.supportPostalCode = this.state.supportAddressPostalCode;
        organization.bankAccountNumber = this.state.accountNumber;
        organization.bankRoutingNumber = this.state.routingNumber;

        let taxFormSignature = this.state.taxFormSignature;

        let taxFormSignatureError = "";

        if (taxFormSignature != this.state.taxFormSignatureExpected) {
            taxFormSignatureError = "Wrong signature";
            errored = true;
        }

        if (errored == false) {

            let supportAddress: IStripeAccountAddress = {
                streetAddress: organization.supportStreetAddress,
                city: organization.supportCity,
                state: organization.supportState,
                postalCode: organization.supportPostalCode
            }

            let address: IStripeAccountAddress = {
                streetAddress: organization.streetAddress,
                city: organization.city,
                state: organization.state,
                postalCode: organization.postalCode
            }

            serverError = await this.CreateOrganization(address, supportAddress);
        }
        else {
            taxFormSignature = "";
        }

        this.setState({
            ...this.state,
            createConfirmModalOpen: taxFormSignatureError != "",
            taxFormSignature: taxFormSignature,
            taxFormSignatureError: taxFormSignatureError,
            serverError: serverError
        });
        await this.LoadingOff();
    }

    CreateOrganization = async (address: IStripeAccountAddress, supportAddress: IStripeAccountAddress) => {
        let serverError = "";

        var organization = this.props.onboardingState.organization;

        let request: ICreateOrganization = {
            stripeAccountTypeCode: organization.stripeAccountTypeCode,
            organizationName: organization.name,
            firstName: organization.stripeAccountTypeCode == StripeAccountTypeCode.Individual ? organization.firstName : undefined,
            lastName: organization.stripeAccountTypeCode == StripeAccountTypeCode.Individual ? organization.lastName : undefined,
            ssn: organization.stripeAccountTypeCode == StripeAccountTypeCode.Individual ? this.state.ssn : undefined,
            email: organization.email,
            phone: organization.phone,
            birthDay: organization.stripeAccountTypeCode == StripeAccountTypeCode.Individual ? organization.birthday : undefined,
            businessTaxId: organization.stripeAccountTypeCode == StripeAccountTypeCode.Company ? this.state.businessTaxId : undefined,
            address: address,
            supportEmail: organization.supportEmail,
            supportPhone: organization.supportPhone,
            supportAddress: supportAddress,
            accountNumber: organization.bankAccountNumber,
            routingNumber: organization.bankRoutingNumber,
            taxFormSignature: this.state.taxFormSignature,
            taxFormSignedAt: this.state.taxFormSignedAt
        };

        var response = await createOrganization(request);

        if (response != null && response.status == 401) {
            this.props.changePage("/login");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                var setupUrl = response.data.stripeAccountSetupUrl;
                var organizationId = response.data.organizationId;

                var saveRequest: IOnboardingSaveCreatedOrganizationInfo = {
                    organizationId: organizationId
                }
                await saveCreatedOrganizationInfo(saveRequest);

                await this.props.changePage(setupUrl);
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        return serverError;
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: "" });
    };

    CloseSuccessModal = () => {
        this.setState({ ...this.state, successMessage: "" });
    };

    OpenCreateConfirmModal = async () => {

        var serverError = "";

        var taxFormSignatureExpected = "";

        let organizationNameError = "";
        let firstNameError = "";
        let lastNameError = "";
        let ssnError = "";
        let addressStreetError = "";
        let addressCityError = "";
        let addressStateError = "";
        let addressPostalCodeError = "";

        let emailError = "";
        let phoneError = "";
        let birthDayError = "";
        let businessTaxIdError = "";
        let accountNumberError = "";
        let routingNumberError = "";
        let errored = false;

        let supportEmailError = "";
        let supportPhoneError = "";

        let supportAddressStreetError = "";
        let supportAddressCityError = "";
        let supportAddressStateError = "";
        let supportAddressPostalCodeError = "";

        var organization = this.props.onboardingState.organization;

        organization.supportEmail = this.state.supportEmail;
        organization.supportPhone = this.state.supportPhone;
        organization.supportStreetAddress = this.state.supportAddressStreet;
        organization.supportCity = this.state.supportAddressCity;
        organization.supportState = this.state.supportAddressState;
        organization.supportPostalCode = this.state.supportAddressPostalCode;
        organization.bankAccountNumber = this.state.accountNumber;
        organization.bankRoutingNumber = this.state.routingNumber;

        if (this.IsNullOrWhiteSpace(organization.email)) {
            emailError = "Email required.";
            errored = true;
        }

        if (this.IsNullOrWhiteSpace(organization.phone)) {
            phoneError = "Phone number required.";
            errored = true;
        }

        if (this.IsNullOrWhiteSpace(organization.streetAddress)) {
            addressStreetError = "Street Address required.";
            errored = true;
        }

        if (this.IsNullOrWhiteSpace(organization.city)) {
            addressCityError = "City required.";
            errored = true;
        }

        if (this.IsNullOrWhiteSpace(organization.state)) {
            addressStateError = "State required.";
            errored = true;
        }

        if (this.IsNullOrWhiteSpace(organization.postalCode)) {
            addressPostalCodeError = "Zip code required.";
            errored = true;
        }

        if (organization.stripeAccountTypeCode == StripeAccountTypeCode.Individual) {
            if (this.IsNullOrWhiteSpace(organization.firstName)) {
                firstNameError = "First Name required.";
                errored = true;
            }

            if (this.IsNullOrWhiteSpace(organization.lastName)) {
                lastNameError = "Last Name required.";
                errored = true;
            }

            if (this.state.ssn == undefined || this.state.ssn.length == 0) {
                ssnError = "SSN required.";
                errored = true;
            }

            var todaysDate = new Date();
            var todaysDay = todaysDate.getDate();
            var todaysMonth = todaysDate.getMonth();
            var todaysYear = todaysDate.getFullYear();

            var birthday = new Date(organization.birthday);

            if ((birthday.getDate() == todaysDay &&
                birthday.getMonth() == todaysMonth && birthday.getFullYear() == todaysYear
            )) {
                birthDayError = "Invalid Date of Birth.";
                errored = true;
            }
        }

        if (organization.stripeAccountTypeCode == StripeAccountTypeCode.Company) {
            if (this.state.businessTaxId == undefined || this.state.businessTaxId.length == 0) {
                businessTaxIdError = "Tax Id required";
                errored = true;
            }
        }

        if (this.IsNullOrWhiteSpace(organization.bankAccountNumber)) {
            accountNumberError = "Account Number required.";
            errored = true;
        }

        if (this.IsNullOrWhiteSpace(organization.bankRoutingNumber)) {
            routingNumberError = "Routing Number required.";
            errored = true;
        }

        if (organization.stripeAccountTypeCode != StripeAccountTypeCode.None) {
            if (this.IsNullOrWhiteSpace(organization.supportEmail)) {
                supportEmailError = "Support Email required.";
                errored = true;
            }

            if (this.IsNullOrWhiteSpace(organization.supportPhone)) {
                supportPhoneError = "Support Phone number required.";
                errored = true;
            }

            if (this.IsNullOrWhiteSpace(organization.supportStreetAddress)) {
                supportAddressStreetError = "Support Street Address required.";
                errored = true;
            }

            if (this.IsNullOrWhiteSpace(organization.supportCity)) {
                supportAddressCityError = "Support City required.";
                errored = true;
            }

            if (this.IsNullOrWhiteSpace(organization.supportState)) {
                supportAddressStateError = "Support State required.";
                errored = true;
            }

            if (this.IsNullOrWhiteSpace(organization.supportPostalCode)) {
                supportAddressPostalCodeError = "Support Zip code required.";
                errored = true;
            }
        }

        if (errored == false) {
            if (this.props.onboardingState.organization.stripeAccountTypeCode == StripeAccountTypeCode.Company) {
                var result = await this.GetAccountInfoFullName();
                serverError = result.serverError;

                if (serverError == null || serverError == "") {
                    taxFormSignatureExpected = result.firstName + " " + result.lastName;
                }
            }
            else {
                taxFormSignatureExpected = this.props.onboardingState.organization.firstName + " " + this.props.onboardingState.organization.lastName;
            }

            this.setState({
                ...this.state,
                createConfirmModalOpen: true,
                taxFormSignatureExpected: taxFormSignatureExpected,
                serverError: serverError
            });
        }
        else {
            this.setState({
                ...this.state,
                organizationNameError: organizationNameError,
                firstNameError: firstNameError,
                lastNameError: lastNameError,
                emailError: emailError,
                phoneError: phoneError,
                birthdayError: birthDayError,
                businessTaxIdError: businessTaxIdError,
                ssnError: ssnError,
                addressStreetError: addressStreetError,
                addressCityError: addressCityError,
                addressStateError: addressStateError,
                addressPostalCodeError: addressPostalCodeError,
                supportEmailError: supportEmailError,
                supportPhoneError: supportPhoneError,
                supportAddressStreetError: supportAddressStreetError,
                supportAddressCityError: supportAddressCityError,
                supportAddressStateError: supportAddressStateError,
                supportAddressPostalCodeError: supportAddressPostalCodeError,
                accountNumberError: accountNumberError,
                routingNumberError: routingNumberError
            });
        }
    }

    GetAccountInfoFullName = async () => {
        var accountInfoResponse = await getAccountInfo();

        var firstName = "";
        var lastName = "";
        var serverError = "";

        if (accountInfoResponse != null && accountInfoResponse.status == 401) {
            this.props.changePage(`/login`);
        }
        else if (accountInfoResponse != null && accountInfoResponse.status == 200) {
            if (!accountInfoResponse.data.success) {
                serverError = accountInfoResponse.data.errorMsg;
            }
            else {
                firstName = accountInfoResponse.data.firstName;
                lastName = accountInfoResponse.data.lastName;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        return {
            firstName: firstName,
            lastName: lastName,
            serverError: serverError
        };
    }

    CloseCreateModal = () => {
        this.setState({ ...this.state, createConfirmModalOpen: false, taxFormSignatureExpected: "" });
    }

    HandlePrevStepClick = () => {
        this.props.onPrevStage(this.props.onboardingState);
    }

    HandleSaveDraftClick = async () => {
        var serverError = "";
        var successMessage = "";

        var organization = this.props.onboardingState.organization;

        organization.supportEmail = this.state.supportEmail;
        organization.supportPhone = this.state.supportPhone;
        organization.supportStreetAddress = this.state.supportAddressStreet;
        organization.supportCity = this.state.supportAddressCity;
        organization.supportState = this.state.supportAddressState;
        organization.supportPostalCode = this.state.supportAddressPostalCode;
        organization.bankAccountNumber = this.state.accountNumber;
        organization.bankRoutingNumber = this.state.routingNumber;

        let supportAddress: IStripeAccountAddress = {
            streetAddress: organization.supportStreetAddress,
            city: organization.supportCity,
            state: organization.supportState,
            postalCode: organization.supportPostalCode
        }

        let request: IOnboardingSaveOrganizationBankInfo = {
            supportEmail: organization.supportEmail,
            supportPhone: organization.supportPhone,
            supportAddress: supportAddress,
            accountNumber: organization.bankAccountNumber,
            routingNumber: organization.bankRoutingNumber,
        };


        var response = await saveOrganizationBankInfo(request);
        if (response != null && response.status == 401) {
            this.props.changePage(`/login`);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                successMessage = "Saved successfuly"
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            successMessage: successMessage,
            serverError: serverError
        });
    }

    HandleCopyBusinessFieldsClick = () => {
        var organization = this.props.onboardingState.organization;

        if (!this.state.supportFieldsSameAsBusinessFields) {
            // organization.supportEmail = organization.email;
            // organization.supportPhone = organization.phone;
            // organization.supportStreetAddress = organization.streetAddress;
            // organization.supportCity = organization.city;
            // organization.supportState = organization.state;
            // organization.supportPostalCode = organization.postalCode;

            this.setState({
                ...this.state,
                supportFieldsSameAsBusinessFields: !this.state.supportFieldsSameAsBusinessFields,
                supportEmail: this.props.onboardingState.organization.email,
                supportPhone: this.props.onboardingState.organization.phone,
                supportAddressStreet: this.props.onboardingState.organization.streetAddress,
                supportAddressCity: this.props.onboardingState.organization.city,
                supportAddressState: this.props.onboardingState.organization.state,
                supportAddressPostalCode: this.props.onboardingState.organization.postalCode
            });
        }
        else {
            // organization.supportEmail = "";
            // organization.supportPhone = "";
            // organization.supportStreetAddress = "";
            // organization.supportCity = "";
            // organization.supportState = "";
            // organization.supportPostalCode = "";

            this.setState({
                ...this.state,
                supportFieldsSameAsBusinessFields: !this.state.supportFieldsSameAsBusinessFields,
                supportEmail: "",
                supportPhone: "",
                supportAddressStreet: "",
                supportAddressCity: "",
                supportAddressState: "",
                supportAddressPostalCode: ""
            });
        }
    }

    LoadingOn = async () => {
        this.setState({ ...this.state, loading: true });
    }

    LoadingOff = async () => {
        this.setState({ ...this.state, loading: false });
    }

    render() {
        var organization = this.props.onboardingState.organization;
        return (
            <React.Fragment>
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">
                        Onboarding - {this.props.onboardingState.timesCompleted > 0 ? "Organization" : "Create Organization"}
                    </Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Card style={{ backgroundColor: "#ededed", marginTop: "10px" }}>
                        <CardContent>
                            <div style={{ color: "red", marginBottom: "5px" }}>
                                {this.state.stripeAccountUrl != null && this.state.stripeAccountUrl != "" && <span>
                                    <strong>To edit your banking information please login into Stripe using the following link: </strong><br />
                                    <a href={this.state.stripeAccountUrl}>{this.state.stripeAccountUrl}</a></span>}
                                {(this.state.stripeAccountUrl == null || this.state.stripeAccountUrl == "") &&
                                    this.state.stripeAccountSetupUrl != null && this.state.stripeAccountSetupUrl != "" &&
                                    <span><strong>Finish setting up your Stripe account here so you activate your account: </strong><br /><a href={this.state.stripeAccountSetupUrl}>{this.state.stripeAccountSetupUrl}</a></span>}
                            </div>
                            <p style={{ marginTop: "0" }}>
                                <strong>
                                    Many of the fields below are used on for the generation of a 1099K form. 1099K details include First Name, Last Name, Tax Id (SSN or ITIN), Street Address, City, State, and Zip Code.
                                </strong>
                            </p>

                            <Grid container spacing={1}>
                                <Grid item sm={4}>
                                    <GenericDropdown
                                        label="Account Type"
                                        value={this.props.onboardingState?.organization?.stripeAccountTypeCode ?? 0}
                                        error={this.state.stripeAccountTypeCodeError}
                                        data={this.state.stripeAccountTypes}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        fullWidth
                                        id="organizationName"
                                        type="text"
                                        label="Organization Name (Stripe Required)"
                                        placeholder="Organization Name (Stripe Required)"
                                        margin="normal"
                                        disabled={true}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.props.onboardingState.organization.name}
                                        // onChange={(e) => this.HandleOrganizationNameChange(e)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    {organization.stripeAccountTypeCode == StripeAccountTypeCode.Company &&
                                        <TextField
                                            error={this.state.businessTaxIdError !== ""}
                                            fullWidth
                                            id="businessTaxId"
                                            type="text"
                                            label="Tax Id (can be either your SSN or ITIN)"
                                            placeholder="Tax Id"
                                            margin="normal"
                                            helperText={this.state.businessTaxIdError}
                                            FormHelperTextProps={{ color: "red" }}
                                            value={this.state.businessTaxId}
                                            onChange={(e) => this.HandleBusinessTaxIdChange(e)}
                                            variant="outlined"
                                        />
                                    }
                                    {organization.stripeAccountTypeCode == StripeAccountTypeCode.Individual &&
                                        <TextField
                                            error={this.state.ssnError !== ""}
                                            fullWidth
                                            id="ssn"
                                            type="text"
                                            label="Social Security Number"
                                            placeholder="SSN (###-##-#### or 9 digits)"
                                            margin="normal"
                                            helperText={this.state.ssnError}
                                            FormHelperTextProps={{ color: "red" }}
                                            value={this.state.ssn}
                                            onChange={(e) => this.HandleSsnChange(e)}
                                            variant="outlined"
                                            disabled={false}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            {organization.stripeAccountTypeCode != StripeAccountTypeCode.None &&
                                <div>
                                    <div>
                                        <div style={{ color: "red" }}><strong>Note: All support fields are visible for the purpose of business verification for Stripe's 3rd party payment system. </strong></div>
                                        <span> Once saved, these details will be visible at url format: {this.state.organizationPublicUrl}. "id" will be visible once saved.</span>
                                    </div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox onChange={this.HandleCopyBusinessFieldsClick} />
                                        }
                                        label="Support Email, Phone, and Address are the same as above"
                                    />
                                    <Grid container spacing={1}>
                                        <Grid item sm={6}>
                                            <TextField
                                                error={this.state.supportEmailError !== ""}
                                                fullWidth
                                                id="email"
                                                type="text"
                                                label="Support Email (Stripe Required)"
                                                placeholder="Support Email (Stripe Required)"
                                                margin="normal"
                                                helperText={this.state.supportEmailError}
                                                FormHelperTextProps={{ color: "red" }}
                                                value={this.state.supportEmail}
                                                onChange={(e) => this.HandleSupportEmailChange(e)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextField
                                                error={this.state.supportPhoneError !== ""}
                                                fullWidth
                                                id="supportPhone"
                                                type="text"
                                                label="Support Phone (Stripe Required)"
                                                placeholder="Support Phone(Stripe Required)"
                                                margin="normal"
                                                helperText={this.state.supportPhoneError}
                                                FormHelperTextProps={{ color: "red" }}
                                                value={this.state.supportPhone}
                                                onChange={(e) => this.HandleSupportPhoneChange(e)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        error={this.state.supportAddressStreetError !== ""}
                                        fullWidth
                                        id="supportAddressStreet"
                                        type="text"
                                        label="Support Street Address (Stripe Required)"
                                        placeholder="Support Street Address (Stripe Required)"
                                        margin="normal"
                                        helperText={this.state.supportAddressStreetError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.state.supportAddressStreet}
                                        onChange={(e) => this.HandleSupportAddressStreetChange(e)}
                                        variant="outlined"
                                    />
                                    <Grid container spacing={1}>
                                        <Grid item sm={4}>
                                            <TextField
                                                error={this.state.supportAddressCityError !== ""}
                                                fullWidth
                                                id="supportAddressCity"
                                                type="text"
                                                label="Support City (Stripe Required)"
                                                placeholder="Support City (Stripe Required)"
                                                margin="normal"
                                                helperText={this.state.supportAddressCityError}
                                                FormHelperTextProps={{ color: "red" }}
                                                value={this.state.supportAddressCity}
                                                onChange={(e) => this.HandleSupportAddressCityChange(e)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <GenericDropdown
                                                label="Support State (Stripe Required)"
                                                value={this.state.supportAddressState}
                                                error={this.state.supportAddressStateError}
                                                data={this.state.states}
                                                onChange={this.HandleSupportAddressStateChange}
                                            />
                                        </Grid>
                                        <Grid item sm={4}>
                                            <TextField
                                                error={this.state.supportAddressPostalCodeError !== ""}
                                                fullWidth
                                                id="supportAddressPostalCode"
                                                type="text"
                                                label="Support Zip code  (Stripe Required)"
                                                placeholder="Support Zip code  (Stripe Required)"
                                                margin="normal"
                                                helperText={this.state.supportAddressPostalCodeError}
                                                FormHelperTextProps={{ color: "red" }}
                                                value={this.state.supportAddressPostalCode}
                                                onChange={(e) => this.HandleSupportAddressPostalCodeChange(e)}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextField
                                                error={this.state.accountNumberError !== ""}
                                                fullWidth
                                                id="accountNumber"
                                                type="text"
                                                label="Account Number"
                                                placeholder="Account Number"
                                                margin="normal"
                                                helperText={this.state.accountNumberError}
                                                FormHelperTextProps={{ color: "red" }}
                                                value={this.state.accountNumber}
                                                onChange={(e) => this.HandleAccountNumberChange(e)}
                                                onFocus={() => this.HandleFocusAccountNumber()}
                                                variant="outlined"
                                                disabled={false}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextField
                                                error={this.state.routingNumberError !== ""}
                                                fullWidth
                                                id="routingNumber"
                                                type="text"
                                                label="Routing Number"
                                                placeholder="Routing Number"
                                                margin="normal"
                                                helperText={this.state.routingNumberError}
                                                FormHelperTextProps={{ color: "red" }}
                                                value={this.state.routingNumber}
                                                onChange={(e) => this.HandleRoutingNumberChange(e)}
                                                variant="outlined"
                                                disabled={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions disableSpacing={false} style={{ textAlign: "center", display: "block" }}>
                    <Button
                        variant="contained"
                        size="large"
                        className='btn-default'
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                        onClick={this.HandlePrevStepClick}
                    >
                        Prev
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        className='btn-default'
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                        onClick={this.HandleSaveDraftClick}
                    >
                        Save Draft
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        className='btn-primary'
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                        onClick={this.OpenCreateConfirmModal}
                    >
                        Next
                    </Button>
                </DialogActions>

                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
                <GenericSuccessModal hidden={this.state.successMessage == ''}
                    message={this.state.successMessage}
                    closeModal={this.CloseSuccessModal} />

                <Dialog
                    onClose={this.CloseCreateModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{ maxHeight: "100%" }}
                    open={this.state.createConfirmModalOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Create Organization - WARNING</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.props.onCloseModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        The following fields are no longer editable after you save. Any changes to these fields will require that you create a new organization.
                        <ul>
                            <li>
                                Account Type
                            </li>
                            <li>
                                Email
                            </li>
                            <li>
                                Phone
                            </li>
                            <li>
                                First Name
                            </li>
                            <li>
                                Last Name
                            </li>
                            <li>
                                Birthday
                            </li>
                            <li>
                                Street Address
                            </li>
                            <li>
                                City
                            </li>
                            <li>
                                State
                            </li>
                            <li>
                                Zip code
                            </li>
                        </ul>

                        <div>
                            <h2>IRS Reporting Form 1099-K</h2>
                            <p>
                                The following form is required by the U.S. Internal Revenue Service and is only available in U.S. English. Please complete in U.S. English. If you need help,&nbsp;
                                <Link target="_blank" href="https://www.irs.gov/businesses/understanding-your-form-1099-k">
                                    view IRS 1099-K Details.
                                </Link>
                            </p>
                            <p>
                                Under the penalties of perjury, I hereby certify that:
                            </p>
                            <ol>
                                <li>The number shown on this form is my true and accurate taxpayer identification number (or I am otherwise waiting for a number to be issued to me); and</li>
                                <li>I am not subject to backup withholding because:
                                    <ul>
                                        <li>(a) I am exempt from backup withholding, or </li>
                                        <li>(b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or </li>
                                        <li>(c) the IRS has notified me that I am no longer subject to backup withholding; and</li>
                                    </ul>

                                </li>
                                <li>I am a U.S. citizen or other valid U.S. person; and</li>
                                <li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.</li>
                            </ol>
                            <p>
                                The Internal Revenue Service does not require your consent to any of the provisions of this document other than the certifications required to avoid backup withholding of funds.
                            </p>
                            <div><strong>Typing your full name acts as your signature: {this.state.taxFormSignatureExpected}</strong></div>
                            <TextField
                                error={this.state.taxFormSignatureError !== ""}
                                fullWidth
                                id="taxFormSignature"
                                type="text"
                                placeholder="Signature"
                                margin="normal"
                                helperText={this.state.taxFormSignatureError}
                                FormHelperTextProps={{ color: "red" }}
                                value={this.state.taxFormSignature}
                                onChange={(e) => this.HandleTaxFormSignatureChange(e)}
                                disabled={false}
                            />
                            <p>
                                Sign date: {this.state.taxFormSignedAt.toDateString()}
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseCreateModal}
                            className="two-action-buttons"
                            disabled={this.state.loading}
                        >Cancel</Button>
                        <Button onClick={this.HandleSaveOrganization}
                            className="two-action-buttons"
                            disabled={this.state.taxFormSignatureError != "" || this.state.taxFormSignature == '' || this.state.loading}>
                            {!this.state.loading
                                ? "Ok"
                                : <CircularProgress style={{ width: "15px", height: "15px" }} color="inherit" />}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}