import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />
import {EventFileTypeCode} from "./Models/EventFileTypeCode";

export const GetMultiPartTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
      };
      
    return axiosConfig;
}

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const uploadEventFiles = async(files: any[], copyFileIds: any[], eventId: any, eventFileTypeCode: EventFileTypeCode): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetMultiPartTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    const formData = new FormData()

    files.forEach(file=>{
        formData.append("files", file);
      });

    copyFileIds.forEach(fileId => {
        formData.append('copyFileId', fileId);
    });

    formData.append('eventId', eventId);
    formData.append('eventFileTypeCode', eventFileTypeCode.toString());
    
    return await axios.post(`${baseAPIUrl}/api/eventfiles`, formData, tokenConfig);
}

export const deleteEventFiles = async(fileIds: any[]): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    
    return await axios.post(`${baseAPIUrl}/api/eventfiles/delete`, {EventFileIds: fileIds}, tokenConfig);
}