import React from 'react';
import '../App.css';
import List from '@material-ui/core/List';
import { PortalUserRoleCode } from "../../data/Models/PortalUserRoleCode";
import SearchBar from "material-ui-search-bar";
import { resetOnboarding } from '../../data/OnboardingRepository';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error';

interface ISideBarProps {
    authenticated: boolean,
    portalUserRole: PortalUserRoleCode,
    showOnboarding: boolean,
    onboardingCompletedCount: number,
    changePage: (page: string) => void,
    onRequestSearch: (searchTerm: string) => void,
    onShowOnboarding: () => void,
}

export class SideBar extends React.Component<ISideBarProps> {

    state = {
        searchTerm: '',
        currentUrl: '',
        mainExt: "/dashboard",
        instructionsExt: "/instructions",
        organizationExt: "/organizations",
        eventGroupsExt: "/event-group-dashboard",
        eventsExt: "/all-events",
        analyticsExt: "/analytics",
        portalUsersExt: "/portal-users-dashboard",
        eventGroupPermissionsExt: "/permissions-dashboard",
        adminAllEventsExt: "/admin-all-events",
        salesTaxesExt: "/admin-sales-tax",
        adminFinancialsExt: "/admin-financials",
        organizationSubMenuVisible: false,
        createOrganizationConfirmVisible: false
    }

    componentDidMount() {
        let currentUrl = window.location.href.toLowerCase();

        this.setState({
            ...this.state,
            currentUrl: currentUrl
        });
    }

    HandleShowOnboarding = () => {
        sessionStorage.removeItem("onboardingModalClosed");

        this.setState({
            ...this.state,
            createOrganizationConfirmVisible: false
        });

        this.props.onShowOnboarding();
    }

    CreateOrganization = async (override: boolean = false) => {
        if (this.props.showOnboarding && override == false) {
            this.setState({
                ...this.state,
                createOrganizationConfirmVisible: true
            });
        }
        else {
            this.setState({
                ...this.state,
                createOrganizationConfirmVisible: false
            });

            var response = await resetOnboarding();
            if (response == null || response.status != 200) {

            } else {
                sessionStorage.removeItem("onboardingModalClosed");
                this.props.onShowOnboarding();
            }
        }
    }

    HandleOrganizationButtonHover = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ ...this.state, organizationSubMenuVisible: true });
    };

    HandleOrganizationButtonUnHover = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ ...this.state, organizationSubMenuVisible: false });
    };

    CloseCreateOrganizationConfirmModal = () => {
        this.setState({
            ...this.state,
            createOrganizationConfirmVisible: false
        });
    }

    render() {

        var organizationButtonStyle = this.state.organizationSubMenuVisible ? 'sidebar-list-item-submenu-visible' : 'sidebar-list-item-submenu-hidden';

        return (
            <div
                className="sidebar"
                role="presentation"
                hidden={!this.props.authenticated}
            >
                {this.props.portalUserRole != PortalUserRoleCode.None && <List style={{ paddingBottom: "0px" }}>
                    <div className="mobile-search-bar" hidden={!this.props.authenticated}>
                        <SearchBar onChange={(newValue) => this.setState({ searchTerm: newValue })}
                            onRequestSearch={() => this.props.onRequestSearch(this.state.searchTerm)}
                        />
                    </div>
                    <Button disabled={true} className="sidebar-list-item-top siderbar-list-item-category">
                        Dashboards
                    </Button>
                    {this.props.showOnboarding && this.props.onboardingCompletedCount == 0 &&
                        <Button title="Main" className="sidebar-list-item" style={{ borderBottomColor: "transparent" }} onClick={this.HandleShowOnboarding}>
                            Onboarding
                            <ErrorIcon style={{color: "#e02d75", position:"absolute", right: "5px"}}/>
                        </Button>
                    }
                    <Button title="Main" className={this.state.currentUrl.indexOf(this.state.mainExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} style={{ borderBottomColor: "transparent" }} onClick={() => this.props.changePage(this.state.mainExt)}>
                        Main
                    </Button>
                    {/* <Button title="Instructions" className={this.state.currentUrl.indexOf(this.state.instructionsExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} style={{ borderBottomColor: "transparent" }} onClick={() => this.props.changePage(this.state.instructionsExt)}>
                        Instructions
                    </Button> */}

                    <Button title="Organizations" className={this.state.currentUrl.indexOf(this.state.organizationExt) >= 0 ? organizationButtonStyle + " sidebar-cell-highlighted" : organizationButtonStyle} style={{ borderBottomColor: "transparent", width: "100%" }} onClick={() => this.props.changePage(this.state.organizationExt)}
                        onMouseEnter={this.HandleOrganizationButtonHover}
                        onMouseLeave={this.HandleOrganizationButtonUnHover}
                    >
                        Organizations
                        {this.props.showOnboarding && this.props.onboardingCompletedCount > 0 &&  
                        <ErrorIcon style={{color: "#e02d75", position:"absolute", right: "5px"}}/>}
                    </Button>
                    {this.props.onboardingCompletedCount > 0 && <Button title="Main" className={this.state.organizationSubMenuVisible ? "sidebar-sub-list-item" : "sidebar-sub-list-item-hidden"} style={{ borderBottomColor: "transparent" }} onClick={() => this.CreateOrganization(false)} onMouseEnter={this.HandleOrganizationButtonHover} onMouseLeave={this.HandleOrganizationButtonUnHover} >
                        Create
                    </Button>
                    }
                    {this.props.showOnboarding && this.props.onboardingCompletedCount > 0 &&
                        <Button title="Main" className={this.state.organizationSubMenuVisible ? "sidebar-sub-list-item-bottom-red" : "sidebar-sub-list-item-hidden-bottom-red"} onClick={this.HandleShowOnboarding}
                            onMouseEnter={this.HandleOrganizationButtonHover}
                            onMouseLeave={this.HandleOrganizationButtonUnHover} >
                            Continue Draft
                        </Button>
                    }
                    <Button title="Event Groups" className={this.state.currentUrl.indexOf(this.state.eventGroupsExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} style={{ borderBottomColor: "transparent" }} onClick={() => this.props.changePage(this.state.eventGroupsExt)}>
                        Event Groups
                    </Button>
                    <Button className={this.state.currentUrl.indexOf(this.state.eventsExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} onClick={() => this.props.changePage(this.state.eventsExt)}>
                        Events
                    </Button>
                    <Button title="Analytics" className={this.state.currentUrl.indexOf(this.state.analyticsExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} onClick={() => this.props.changePage(this.state.analyticsExt)}>
                        Analytics
                    </Button>
                </List>}
                {this.props.portalUserRole == PortalUserRoleCode.Admin && <List style={{ paddingTop: "15px" }}>
                    <Button disabled={true} className="sidebar-list-item siderbar-list-item-category"  >
                        Admin
                    </Button>
                    <Button className={this.state.currentUrl.indexOf(this.state.portalUsersExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} onClick={() => this.props.changePage(this.state.portalUsersExt)}>
                        Users
                    </Button>
                    <Button className={this.state.currentUrl.indexOf(this.state.eventGroupPermissionsExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} onClick={() => this.props.changePage(this.state.eventGroupPermissionsExt)}>
                        Permissions
                    </Button>
                    <Button className={this.state.currentUrl.indexOf(this.state.adminAllEventsExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} onClick={() => this.props.changePage(this.state.adminAllEventsExt)}>
                        All events
                    </Button>
                    <Button className={this.state.currentUrl.indexOf(this.state.salesTaxesExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} onClick={() => this.props.changePage(this.state.salesTaxesExt)}>
                        Sales Taxes
                    </Button>
                    <Button className={this.state.currentUrl.indexOf(this.state.adminFinancialsExt) >= 0 ? "sidebar-list-item sidebar-cell-highlighted" : "sidebar-list-item"} onClick={() => this.props.changePage(this.state.adminFinancialsExt)}>
                        Financials
                    </Button>
                </List>
                }
                <Dialog
                    onClose={this.CloseCreateOrganizationConfirmModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="genericConfirmationModal"
                    open={this.state.createOrganizationConfirmVisible}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Confirmation</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseCreateOrganizationConfirmModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure that you would like to create a new organization? 
                        <br/>
                        You are already have a pending organization draft.
                        <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
                            <Button className="btn-primary" onClick={this.HandleShowOnboarding}>Continue Pending</Button>
                            <span style={{ paddingLeft: "20px" }} ></span><Button className="btn-secondary" onClick={() => this.CreateOrganization(true)}>Start New</Button>
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseCreateOrganizationConfirmModal} className='action-button'>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}