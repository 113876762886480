import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface ITabPanelProps{
    children?: React.ReactNode;
    index: number;
    value: number;
}

export class TabPanel extends React.Component<ITabPanelProps> {

    render() {
        const { children, value, index, ...other } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                >
                {value === index && (
                    <Box >
                    <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
}