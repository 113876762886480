import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import {TicketingChartDataTypeCode} from './Models/TicketingChartDataTypeCode';
import {ChartDateRangeTypeCode} from './Models/ChartDateRangeTypeCode';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const getAdminFinancialsChart = async(date: Date, state: string, zipcode: string, chartDateRangeTypeCode: ChartDateRangeTypeCode, 
    ticketingChartDataTypeCode: TicketingChartDataTypeCode, eventClusterId: string, portalUserId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var timezoneOffsetMinutes = -1 * (new Date().getTimezoneOffset());//If local time is behind utc time this is positive. Need to make it negative.

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var url = `${baseAPIUrl}/api/userEventTicket/adminFinancialsChart?date=${date}&chartDateRangeTypeCode=${chartDateRangeTypeCode}&ticketingChartDataTypeCode=${ticketingChartDataTypeCode}&timezoneOffsetMinutes=${timezoneOffsetMinutes}&eventClusterId=${eventClusterId}&portalUserId=${portalUserId}`;
    if(state != null && state != ""){
        url += `&state=${state}`;
    }

    if(zipcode != null && zipcode != ""){
        url += `&zipcode=${zipcode}`;
    }

    return await axios.get(url, tokenConfig);
}

export const getTicketingChart = async(date: Date, chartDateRangeTypeCode: ChartDateRangeTypeCode, ticketingChartDataTypeCode: TicketingChartDataTypeCode, eventClusterId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var timezoneOffsetMinutes = -1 * (new Date().getTimezoneOffset());//If local time is behind utc time this is positive. Need to make it negative.

    return await axios.get(`${baseAPIUrl}/api/userEventTicket/ticketingChart?date=${date}&chartDateRangeTypeCode=${chartDateRangeTypeCode}&ticketingChartDataTypeCode=${ticketingChartDataTypeCode}&timezoneOffsetMinutes=${timezoneOffsetMinutes}&eventClusterId=${eventClusterId}`, tokenConfig);
}

export const getTicketingReportYears = async(eventClusterId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/userEventTicket/getTicketingReportYears?eventClusterId=${eventClusterId}`, tokenConfig);
}

export const getTicketingChartForEvent = async(date: Date, chartDateRangeTypeCode: ChartDateRangeTypeCode, ticketingChartDataTypeCode: TicketingChartDataTypeCode, eventId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var timezoneOffsetMinutes = -1 * (new Date().getTimezoneOffset());//If local time is behind utc time this is positive. Need to make it negative.

    return await axios.get(`${baseAPIUrl}/api/userEventTicket/ticketingChartForEvent?date=${date}&chartDateRangeTypeCode=${chartDateRangeTypeCode}&ticketingChartDataTypeCode=${ticketingChartDataTypeCode}&timezoneOffsetMinutes=${timezoneOffsetMinutes}&eventId=${eventId}`, tokenConfig);
}

export const getEventFinancials = async(eventId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/userEventTicket/event/financials?eventId=${eventId}`, tokenConfig);
}


const getCustomersResponse = async (gridState: ITableDef, eventId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/UserEventTicket/customers`,  { eventId: eventId, gridState: gridState}, tokenConfig);
}

export const getCustomers = AwesomeDebouncePromise(getCustomersResponse, 500);

const getEventUserEventTicketsResponse = async(eventId: string, gridState: ITableDef): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/userEventTicket/list`, { eventId: eventId, gridState: gridState}, tokenConfig);
}


export const getEventUserEventTickets = AwesomeDebouncePromise(getEventUserEventTicketsResponse, 500);

export const previewUserEventTicketsOrder = async(request: IPreviewUserEventTicketOrderRequest): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_MOBILE_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/userEventTicket/v2/previewOrder`, request);
}

export const confirmPaymentSuccessful = async(request: IFinalizeUserEventTicketOrderRequest): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_MOBILE_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/userEventTicket/v2/order`, request);
}

export const getAdminFinancialsFile = async(year: string, eventClusterId: string, portalUserId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/userEventTicket/adminFinancialsFile?year=${year}&eventClusterId=${eventClusterId}&portalUserId=${portalUserId}`, tokenConfig);
}

export const getUserFinancialsFile = async(year: string, eventClusterId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/userEventTicket/userFinancialsFile?year=${year}&eventClusterId=${eventClusterId}`, tokenConfig);
}

export const getCustomersFile = async(gridState: ITableDef, eventId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/UserEventTicket/customersFile`,  { eventId: eventId, gridState: gridState}, tokenConfig);
}