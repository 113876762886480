import * as React from 'react';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { GenericErrorModal } from '../GenericErrorModal';
import { Alert } from '@material-ui/lab';
interface IQRCodeScanModalProps {
    hidden: boolean,
    changePage: (page: string) => void
    closeModal: () => void
}

export class QRCodeScanModal extends React.Component<IQRCodeScanModalProps> {

    state = {
        qrCodeError: "",
    }

    CloseErrorModal = () =>{
        this.setState({...this.state, qrErrorModal: ""});
    }

    HandleQrReaderOnResult=(result:any, error:any)=>{
        {
            if (!!result) {
                var text = result.getText();
                if (text && text.startsWith(process.env.REACT_APP_BASE_URL ?? "http")){
                    this.props.changePage(text);
                }
                else{
                    this.setState({...this.state, qrCodeError: "Incorrect QR code"});
                    console.info("Incorrect QR Code");
                }
            }

            if (!!error) {
                console.info(error);
                this.setState({...this.state, qrCodeError: ""});
            }
        }
    }

    render() {
            return (
            <div style={{ width: "100%" }}>
                <Dialog
                    onClose={this.props.closeModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="qrScannerModal"
                    open={this.props.hidden == false}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Scan QR Code</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.props.closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <div style={{ height: "70%", minWidth: "250px" }}>
                                <QrReader
                                    onResult={(result, error) => this.HandleQrReaderOnResult(result, error)}
                                    constraints={{
                                        facingMode: 'environment'
                                    }}
                                />
                            </div>
                            {this.state.qrCodeError != "" && 
                                <Alert severity="error">{this.state.qrCodeError}</Alert>}
                            {this.state.qrCodeError == "" &&
                                <Alert severity="info">QR Code Not Found</Alert>}
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.props.closeModal} className='action-button'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}