/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { becomeUser, getAllPortalUsers  } from '../../../data/PortalUserRepository';
import { getAllPortalUserRoles } from "../../../data/PortalUserRolesRepository";
import { PortalUserRoleDropdownCell } from '../SubComponents/PortalUserRoleDropdownCell';
import { EditDeleteActionCell } from '../SubComponents/EditDeleteActionCell'
import { updatePortalUserRole } from '../../../data/PortalUserRepository';
import {PortalUserRoleCode} from "../../../data/Models/PortalUserRoleCode";
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import { getAuthStatus } from '../../../data/PortalUserRepository';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

var Enumerable = require('linq');

interface IAdminPortalUserDashboardProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IAdminPortalUserDashboardState {
    portalUserRoles: any[],
    portalUsers: any[],
    portalUsersTotal: number,
    editModePortalUserIds: any[],
    pageable: any,
    tableDef: ITableDef,
    mobileFiltersVisible: boolean,
    serverError: string,
    serverSuccessMsg: string
}

const getInitTableDef = () =>{
    let sortDef: ISortDef = {
        field: "Username",
        dir: "asc"
    };

    let filterArray = [
        {
            field: "id",
            operator: "eq",
            value: ""
        },
        {
            field: "username",
            operator: "eq",
            value: ""
        },
        {
            field: "email",
            operator: "eq",
            value: ""
        },
        {
            field: "firstName",
            operator: "eq",
            value: ""
        },
        {
            field: "lastName",
            operator: "eq",
            value: ""
        },
        {
            field: "phoneNumber",
            operator: "eq",
            value: ""
        },
        {
            field: "role",
            operator: "eq",
            value: ""
        },
        {
            field: "userConfirmedRegistration",
            operator: "eq",
            value: null
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 20,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class AdminPortalUserDashboard extends React.Component<IAdminPortalUserDashboardProps, IAdminPortalUserDashboardState> {

    state = {
        portalUserRoles: [],
        portalUsers: [],
        portalUsersTotal: 0,
        editModePortalUserIds: [],
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
              id: 'id',
              label: 'Id',
            },
            {
              id: 'username',
              label: 'Username',
            },
            {
              id: 'email',
              label: 'Email',
            },
            {
              id: 'firstName',
              label: 'First Name',
            },
            {
                id: 'lastName',
                label: 'Last Name'
            },
            {
                id: 'phoneNumber',
                label: 'Phone Number'
            },
            {
                id: 'role',
                label: 'Role'
            },
            {
                id: 'userConfirmedRegistration',
                label: 'Registration Confirmed'
            },
            {
                id: 'modifiedOnDateTimeUTC',
                label: 'Modified On Date/Time UTC'
            }
          ],
        mobileFiltersVisible: false,
        serverError: '',
        serverSuccessMsg: ''
    }

    async componentDidMount(){
        this.props.loadingOn();

        let role: PortalUserRoleCode = PortalUserRoleCode.None;

        const getAuthResponse = await getAuthStatus();

        if(getAuthResponse == undefined || getAuthResponse.status != 200){
            this.props.changePage("/login?redirect=portal-users-dashboard");
        }

        role = getAuthResponse.data.role;

        if(role == PortalUserRoleCode.Admin){
            await this.LoadPortalUsers(this.state.tableDef);

            let serverError = '';
            let portalUserRoles: any = [];

            var response = await getAllPortalUserRoles();

            if(response != null && response.status == 401){
                this.props.changePage("/login?redirect=portal-users-dashboard");
            }
            else if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverError = response.data.errorMsg;
                }
                else{
                    portalUserRoles = Enumerable.from(response.data.codeValues).select((v: any) => ({id: v.code, name: v.name})).toArray();
                }
            }
            else{
                serverError = "An unknown error occured. Please try again later";
            }

            this.setState({...this.state, portalUserRoles: portalUserRoles, serverError: serverError});
        }
        else{
            this.props.changePage("/dashboard");
        }

        this.props.loadingOff();
    }

    LoadPortalUsers = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let portalUsers: any = [];
        let portalUsersTotal = 0;
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != ""  && f.value != '') || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getAllPortalUsers(tempTableDef);

        if(response != null && response.status == 401){
            this.props.changePage("/login?redirect=portal-users-dashboard");
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                portalUsers = response.data.portalUsers;
                portalUsersTotal = response.data.portalUsersTotal;
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({...this.state, portalUsers: portalUsers, portalUsersTotal: portalUsersTotal, tableDef: tableDef, serverError: serverError});
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        if(tableDef != null && tableDef.sort!.length > 0){
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadPortalUsers(tableDef);
        }
    
        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({f, index})).firstOrDefault((fi: any) => fi.f.field == filter);

        if(filter == "userConfirmedRegistration"){
            if(value == "false"){
                filterDef.f.value = false;
            }
            else if(value == "true"){
                filterDef.f.value = true;
            }
            else {
                filterDef.f.value = null;
            }
        }
        else{
            filterDef.f.value = value;
        }
        
        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadPortalUsers(tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadPortalUsers(tableDef);
        this.props.loadingOff();
    }

    CheckIfInEditMode = (id: string) => {
        let editModePortalUserIds: string[] = this.state.editModePortalUserIds;
        return editModePortalUserIds.indexOf(id) > -1;
    }

    OnUserRoleDropdownChange = (dataIndex: number, value: any) =>
    {
        var portalUsers: any[] = this.state.portalUsers;
        var portalUser: any = portalUsers[dataIndex];

        portalUser.roleCode = value;

        portalUsers[dataIndex] = portalUser;
        
        this.setState({...this.state, portalUsers: portalUsers});
    }

    OpenPortalUserEditMode = (dataIndex: number) => {
        let portalUser: any = this.state.portalUsers[dataIndex];
        
        let editModePortalUserIds: any[] = this.state.editModePortalUserIds;

        editModePortalUserIds.push(portalUser.id);

        this.setState({...this.state, editModePortalUserIds: editModePortalUserIds});
    }

    CancelEditingPortalUser = async (dataIndex: number) =>{
        let portalUser: any = this.state.portalUsers[dataIndex];

        let editModePortalUserIds: any[] = this.state.editModePortalUserIds;

        editModePortalUserIds = editModePortalUserIds.filter(obj => obj !== portalUser.id);

        this.setState({...this.state, editModePortalUserIds: editModePortalUserIds});
    }

    SaveEditedPortalUser = async (dataIndex: number) => {
        this.props.loadingOn();

        let portalUsers: any[] = this.state.portalUsers;
        let portalUser: any = portalUsers[dataIndex];

        let eventClusterPortalUsersServerError = '';
        let serverSuccessMsg = '';

        let editModePortalUserIds = this.state.editModePortalUserIds;

        var response = await updatePortalUserRole(portalUser.id, portalUser.roleCode);

        let roles = this.state.portalUserRoles;

        if(response != null && response.status == 401){
            this.props.changePage("/login?redirect=portal-users-dashboard");
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                eventClusterPortalUsersServerError = response.data.errorMsg;
            }
            else{
                editModePortalUserIds = Enumerable.from(editModePortalUserIds).where((id: any) => id != portalUser.id).toArray();

                let role = Enumerable.from(roles).where((r: any) => r.id == portalUser.roleCode).firstOrDefault();

                portalUser.role = role.name;

                portalUsers[dataIndex] = portalUser;

                serverSuccessMsg = `Event group member with email "${portalUser.email}" updated successfully.`;
            }
        }
        else{
            eventClusterPortalUsersServerError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, portalUsers: portalUsers, editModePortalUserIds: editModePortalUserIds, serverSuccessMsg: serverSuccessMsg, serverError: eventClusterPortalUsersServerError});

        this.props.loadingOff();
    }

    ToggleFilterVisibility = () => {
        this.setState({...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible});
    }

    MockDelete = (dataIndex: number) => {

    }

    CloseSuccessModal = () => {
        this.setState({...this.state, serverSuccessMsg: ""});
    };

    CloseErrorModal = () => {
        this.setState({...this.state, serverError: ""});
    };

    BecomeUser = async (dataIndex: number) => {
        let portalUser: any = this.state.portalUsers[dataIndex];

        let serverErrorText = "";

        var response = await becomeUser(portalUser.username);
            
        if(response != null && response.status == 200)
        {
            if(response.data.success)
            {
                localStorage.setItem("accessToken", response.data.token);
                
                var redirectPage = "/dashboard";
                this.props.changePage(redirectPage);
            }
            else{
                serverErrorText = response.data.errorMsg;
            }
        }
        else{
            serverErrorText = "An unknown error occured. Please try again later";
        }
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadPortalUsers(tableDef);

        this.props.loadingOff();
    };

    render()
    {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "Username";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{width: "100%"}}>
                <Card style={{backgroundColor: "#ededed"}}>
                    <CardHeader title="Portal Users" />
                    <CardContent>
                        <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility}/>
                       <Box style={{ width: '100%' }}>
                        <Paper style={{ width: '100%'}}>
                            <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                style={{marginLeft: "0.5%", width: "99%"}}
                                className={this.state.mobileFiltersVisible ? "table-filters-visible admin-portal-users" : "table-filters-hidden"}
                            >
                                <TableHead>
                                <TableRow>
                                    {this.state.headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                        className="table-header-border show-on-mobile"
                                    >
                                        <TableSortLabel
                                        active={currentSortName === headCell.id }
                                        direction={currentSortName === headCell.id  ? currentSortDir : null}
                                        hideSortIcon={currentSortName !== headCell.id}
                                        onClick={() => this.OnSortChange(headCell.id)}
                                        style={{width: "100%"}}
                                        >
                                        {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                    ))}
                                    <TableCell className="table-header-border no-show-on-mobile"
                                    >
                                    </TableCell>
                                    <TableCell className="table-header-border no-show-on-mobile"
                                    >
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                    <TableCell className="table-header-border show-on-mobile" >
                                        {headCell.field != "role" && headCell.field != "userConfirmedRegistration" &&
                                            <TextField
                                            fullWidth
                                            margin="dense"
                                            size="small"
                                            onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                            value={headCell.value}
                                            variant="outlined"
                                            />
                                            } 
                                        {headCell.field == "userConfirmedRegistration" &&
                                        <FormControl variant="filled" size="small" fullWidth>
                                             <Select
                                                value={headCell.value}
                                                label="Registration Confirmed"
                                                onChange={(e) => this.OnFilterChange(headCell.field, e.target.value)}
                                                variant="outlined"
                                                margin="dense"
                                                style={{marginTop: "5px"}}
                                                fullWidth
                                            >
                                                <MenuItem value="">Both</MenuItem>
                                                <MenuItem value="false">False</MenuItem>
                                                <MenuItem value="true">True</MenuItem>
                                            </Select>
                                         </FormControl>
                                        }
                                    </TableCell>
                                    ))}
                                    <TableCell className="table-header-border no-show-on-mobile">

                                    </TableCell>
                                    <TableCell className="table-header-border no-show-on-mobile">

                                    </TableCell>
                                    <TableCell className="table-header-border no-show-on-mobile">

                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.portalUsers.map((row: any, index: any) => {

                                    return (
                                        <TableRow
                                        hover
                                        >
                                            <TableCell align="right"><GenericDisplayCell title="Id" value={row.id} /></TableCell>
                                            <TableCell align="right"><GenericDisplayCell title="Username" value={row.username} /></TableCell>
                                            <TableCell align="right"><GenericDisplayCell title="Email" value={row.email} /></TableCell>
                                            <TableCell align="right"><GenericDisplayCell title="First Name" value={row.firstName} /></TableCell>
                                            <TableCell align="right"><GenericDisplayCell title="Last Name" value={row.lastName} /></TableCell>
                                            <TableCell align="right"><GenericDisplayCell title="Phone Number" value={row.phoneNumber} /></TableCell>
                                            <TableCell align="right"><PortalUserRoleDropdownCell 
                                                        title="Role"
                                                        dataItem={row} 
                                                        dataIndex={index} 
                                                        onChange={this.OnUserRoleDropdownChange} 
                                                        roles={this.state.portalUserRoles} 
                                                        editMode={this.CheckIfInEditMode(row.id)} />
                                                    </TableCell>
                                            <TableCell align="right"><GenericDisplayCell title="Registration Confirmed" value={String(row.userConfirmedRegistration)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <GenericDisplayCell title="Modified On Date/Time UTC" value={row.modifiedOnDateTimeUTC} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <EditDeleteActionCell 
                                                    dataIndex={index} 
                                                    onEditButtonClick={this.OpenPortalUserEditMode} 
                                                    onSaveButtonClick={this.SaveEditedPortalUser}
                                                    onDeleteButtonClick={this.MockDelete} 
                                                    onCancelButtonClick={this.CancelEditingPortalUser}
                                                    hideEditButton={false}
                                                    hideDeleteButton={true}
                                                    hideCancelButton={false}
                                                    editMode={this.CheckIfInEditMode(row.id)}/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" className="btn-secondary" onClick={() => this.BecomeUser(index)}>
                                                    Become
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={this.state.portalUsersTotal}
                            rowsPerPage={this.state.tableDef.take!}
                            page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                            onChangePage={(e, page) => this.PageChange(page)}
                            onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                            />
                        </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <GenericSuccessModal 
                    hidden={this.state.serverSuccessMsg == ""}
                    message={this.state.serverSuccessMsg}
                    closeModal={this.CloseSuccessModal}
                    />
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
            </div>
        )
    }
}