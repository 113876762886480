import React from 'react';
import { getOnboardingData, onboardingComplete } from '../../../data/OnboardingRepository';
import { OnboardingStageCode } from '../../../data/Models/OnboardingStageCode';
import { PaidTicketingStage } from './PaidTicketingStage';
import { OrganizationBasicInfoStage } from './OrganizationBasicInfoStage';
import { OrganizationBankInfoStage } from './OrganizationBankInfoStage';
import { SetupSuccessfulStage } from './SetupSuccessfulStage';
import { ActivateOrganizationStage } from './ActivateOrganization';
import { SetupFailedStage } from './SetupFailedStage';
import { GenericErrorModal } from '../GenericErrorModal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog } from '@material-ui/core';

interface IOnboardingDialogProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void,
    onCloseOnboarding: (stage: OnboardingStageCode) => void,
    visible: boolean
}

interface IOnboardingDialogState {
    onboardingState: IOnboardingState,
    stage: OnboardingStageCode,
    onboardingComplete: boolean,
    onboardingStateLoaded: boolean,
    loading: boolean,
    serverError: string,
}

export class OnboardingModal extends React.Component<IOnboardingDialogProps, IOnboardingDialogState> {
    state = {
        onboardingState: {} as IOnboardingState,
        stage: OnboardingStageCode.Unknown,
        onboardingComplete: false,
        onboardingStateLoaded: false,
        loading: false,
        serverError: '',
    }

    async componentDidMount() {
        await this.LoadOnboardingState();
    }

    LoadOnboardingState = async () => {
        var onboardingStateResponse = await getOnboardingData();

        let onboardingComplete = false;
        var onboardingState: IOnboardingState = {} as IOnboardingState;
        let stage = OnboardingStageCode.PaidTicketing; // initial stage
        let serverError = '';

        if (onboardingStateResponse != undefined && onboardingStateResponse.status == 401) {
            this.props.changePage("/login?redirect=account-info");
        }
        else if (onboardingStateResponse == undefined ||
            onboardingStateResponse.status != 200 ||
            onboardingStateResponse.data?.success != true) {
            serverError = "An unknown error occured. Please try again later";
        }
        else {
            onboardingState = onboardingStateResponse.data;

            onboardingComplete = onboardingState.completed;

            if (!onboardingComplete) {
                if (onboardingState.completedStages.some((o: OnboardingStageCode) => o == OnboardingStageCode.PaidTicketing)) {
                    if (onboardingState.paidTicketing)
                        stage = OnboardingStageCode.FirstOrganizationBasicInfo;
                    else
                        stage = OnboardingStageCode.SetupSuccessful;
                }

                if (onboardingState.completedStages.some((o: OnboardingStageCode) => o == OnboardingStageCode.FirstOrganizationBasicInfo)) {
                    stage = OnboardingStageCode.FirstOrganizationBankInfo;
                }

                if (onboardingState.completedStages.some((o: OnboardingStageCode) => o == OnboardingStageCode.FirstOrganizationBankInfo)) {
                    stage = OnboardingStageCode.ActivateOrganization;
                }

                if (onboardingState.completedStages.some((o: OnboardingStageCode) => o == OnboardingStageCode.ActivateOrganization)) {
                    stage = OnboardingStageCode.SetupSuccessful;
                }
            }
        }

        this.setState({
            ...this.state,
            onboardingComplete: onboardingComplete,
            onboardingState: onboardingState,
            onboardingStateLoaded: serverError == '',
            serverError: serverError,
            stage: stage
        });
    }

    HandleNextStage = async (newState: IOnboardingState) => {
        this.state.onboardingState = newState;
        this.GoToNextStage();
    }


    GoToNextStage = () => {
        let newStage = this.state.stage;

        switch (this.state.stage) {
            case OnboardingStageCode.PaidTicketing: {
                if (this.state.onboardingState.paidTicketing) {
                    newStage = OnboardingStageCode.FirstOrganizationBasicInfo;
                }
                else {
                    newStage = OnboardingStageCode.SetupSuccessful;
                }
                break;
            }

            case OnboardingStageCode.FirstOrganizationBasicInfo: {
                newStage = OnboardingStageCode.FirstOrganizationBankInfo;
                break;
            }

            case OnboardingStageCode.FirstOrganizationBankInfo: {
                break;
            }

            case OnboardingStageCode.SetupFailed: {
                newStage = OnboardingStageCode.PaidTicketing;
                break;
            }

            default: {

                break;
            }
        }
        this.setState({ ...this.state, stage: newStage });
    }

    HandlePrevStage = async (newState: IOnboardingState) => {
        let newStage = this.state.stage;

        switch (this.state.stage) {
            case OnboardingStageCode.FirstOrganizationBankInfo: {
                newStage = OnboardingStageCode.FirstOrganizationBasicInfo;
                break;
            }

            case OnboardingStageCode.FirstOrganizationBasicInfo: {
                newStage = OnboardingStageCode.PaidTicketing;
                break;
            }

            case OnboardingStageCode.ActivateOrganization: {
                newStage = OnboardingStageCode.SetupFailed;
                break;
            }

        }
        this.setState({ ...this.state, stage: newStage });
    }


    CloseModal = async () => {
        if (this.state.stage == OnboardingStageCode.SetupSuccessful)
            await onboardingComplete();

        this.props.onCloseOnboarding(this.state.stage);
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: "" });
    };

    LoadingOn = () => {
        this.setState({ ...this.state, loading: true });
    }

    LoadingOff = () => {
        this.setState({ ...this.state, loading: false });
    }

    render() {
        var onboardingCompletedAtLeastOnce = this.state.onboardingState.timesCompleted > 0;
        return (
            <div>
                <Dialog
                    open={!this.state.onboardingComplete && this.props.visible && this.state.onboardingStateLoaded}
                    className="onboarding-dialog"
                    title={onboardingCompletedAtLeastOnce ? "Create Organization" : "Onboarding"} onClose={this.CloseModal}
                    disableBackdropClick={true}
                >
                    <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {this.state.stage == OnboardingStageCode.PaidTicketing &&
                        <PaidTicketingStage
                            onboardingState={this.state.onboardingState}
                            onNextStage={this.HandleNextStage}
                            onPrevStage={this.HandlePrevStage}
                            onCloseModal={this.CloseModal}
                            changePage={this.props.changePage}
                            loadingOff={this.LoadingOff}
                            loadingOn={this.LoadingOn}
                        />
                    }
                    {this.state.stage == OnboardingStageCode.FirstOrganizationBasicInfo &&
                        <OrganizationBasicInfoStage
                            onboardingState={this.state.onboardingState}
                            onNextStage={this.HandleNextStage}
                            onPrevStage={this.HandlePrevStage}
                            onCloseModal={this.CloseModal}
                            changePage={this.props.changePage}
                            loadingOff={this.LoadingOff}
                            loadingOn={this.LoadingOn}
                        />
                    }
                    {this.state.stage == OnboardingStageCode.FirstOrganizationBankInfo &&
                        <OrganizationBankInfoStage
                            onboardingState={this.state.onboardingState}
                            onNextStage={this.HandleNextStage}
                            onPrevStage={this.HandlePrevStage}
                            onCloseModal={this.CloseModal}
                            changePage={this.props.changePage}
                            loadingOff={this.LoadingOff}
                            loadingOn={this.LoadingOn}
                        />
                    }
                    {this.state.stage == OnboardingStageCode.SetupSuccessful &&
                        <SetupSuccessfulStage
                            onboardingState={this.state.onboardingState}
                            onNextStage={this.HandleNextStage}
                            onPrevStage={this.HandlePrevStage}
                            onCloseModal={this.CloseModal}
                            changePage={this.props.changePage}
                            loadingOff={this.LoadingOff}
                            loadingOn={this.LoadingOn}
                        />
                    }
                    {this.state.stage == OnboardingStageCode.SetupFailed &&
                        <SetupFailedStage
                            onboardingState={this.state.onboardingState}
                            onNextStage={this.HandleNextStage}
                            onPrevStage={this.HandlePrevStage}
                            onCloseModal={this.CloseModal}
                            changePage={this.props.changePage}
                            loadingOff={this.LoadingOff}
                            loadingOn={this.LoadingOn}
                        />
                    }
                    {this.state.stage == OnboardingStageCode.ActivateOrganization &&
                        <ActivateOrganizationStage
                            onboardingState={this.state.onboardingState}
                            onNextStage={this.HandleNextStage}
                            onPrevStage={this.HandlePrevStage}
                            onCloseModal={this.CloseModal}
                            changePage={this.props.changePage}
                            loadingOff={this.LoadingOff}
                            loadingOn={this.LoadingOn}
                        />
                    }
                </Dialog>
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
            </div>
        )
    }
}