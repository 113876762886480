/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import '../../Styles/EventTicketingFormSection.css';
import { GeneralAdmissionSubSection } from './GeneralAdmissionSubSection';
import { AssignedSeatingSubSection } from './AssignedSeatingSubSection';
import { TicketOptionType } from "../../../data/Models/Enums"
import { getTicketOptions, saveTicketOptions } from '../../../data/TicketOptionRepository';
import { uploadEventFiles, deleteEventFiles } from '../../../data/EventFileRepository';
import { EventFileTypeCode } from "../../../data/Models/EventFileTypeCode";
import WarningIcon from '@material-ui/icons/Warning';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import { debug } from 'console';
import Moment from 'moment';

var Enumerable = require('linq');

interface IEventTicketingFormSectionProps {
    userPermissions: any[],
    isClusterOwner: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    handleChangesPendingChange: (changesPending: boolean) => void,
    changePage: (page: string) => void
}

export class EventTicketingFormSection extends React.Component<IEventTicketingFormSectionProps> {

    state = {
        eventId: '',
        eventClusterId: '',
        startEventDateTime: new Date,
        finishEventDateTime: new Date,
        currentTab: 0,
        hasFreeAdmissions: false,
        freeTicketsOnly: false,
        ownerUserRole: 0,
        hasUserTicketLimit: false,
        userTicketLimit: 0,
        userTicketLimitError: "",
        generalTicketOptions: [],
        assignedSeatingTicketOptions: [],
        pricingAdjustments: [],
        focusedId: '',
        inputCursorStart: 0,
        venueSeatingImages: [],
        venueSeatingImagePreviews: [],
        savedVenueSeatingImages: [],
        deleteFileIds: [],
        saveValidationErrors: [],
        saveSuccess: false,
        pendingChanges: false,
        saveErrors: [],
        serverError: '',
        editable: false,
    }

    async componentDidMount() {
        this.props.loadingOn();
        const query = new URLSearchParams(window.location.search);

        let eventId = query.get('id');
        let eventClusterId: string = query.get('eventGroupId') ?? "";
        let serverError = '';

        let hasFreeAdmissions = false;
        let hasUserTicketLimit = false;
        let userTicketLimit = 0;
        let generalAdmissionTicketOptions: any = [];
        let assignedSeatingTicketOptions: any = [];
        let pricingAdjustments: any = [];
        let savedVenueSeatingImages: any[] = [];
        let editable = false;
        let freeTicketsOnly = false;
        let ownerUserRole = 0;
        let startEventDateTime = new Date();
        let finishEventDateTime = new Date();

        if (eventId == undefined || eventId == '') {
            serverError = "Event Id required."
        }
        else {
            var response = await getTicketOptions(eventId);

            if (response != null && response.status == 401) {
                this.RedirectToLogin(eventClusterId, eventId);
            }
            else if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = response.data.errorMsg;
                }
                else {
                    hasFreeAdmissions = response.data.hasFreeAdmissions;
                    hasUserTicketLimit = response.data.userTicketLimit != null;
                    userTicketLimit = response.data.userTicketLimit ?? 0;
                    freeTicketsOnly = response.data.freeTicketsOnly;
                    ownerUserRole = response.data.ownerUserRole;

                    var generalAdmissionTicketOptionList: any = response.data.generalAdmissionTicketOptions;
                    var assignedSeatingTicketOptionList: any = response.data.assignedSeatingTicketOptions;
                    var pricingAdjustmentList: any = response.data.pricingAdjustments;

                    savedVenueSeatingImages = response.data.eventVenueMapImages;
                    generalAdmissionTicketOptions = this.GetResponseGenAdmission(generalAdmissionTicketOptionList);

                    assignedSeatingTicketOptions = this.GetResponseAssignedSeating(assignedSeatingTicketOptionList);
                    pricingAdjustments = this.GetResponsePricingAdjustments(pricingAdjustmentList);

                    editable = response.data.editable;

                    startEventDateTime = new Date(response.data.startEventDateTime);
                    finishEventDateTime = new Date(response.data.finishEventDateTime);
                }
            }
        }

        this.setState({
            ...this.state,
            eventId: eventId,
            eventClusterId: eventClusterId,
            startEventDateTime: startEventDateTime,
            finishEventDateTime: finishEventDateTime,
            hasFreeAdmissions: hasFreeAdmissions,
            freeTicketsOnly: freeTicketsOnly,
            ownerUserRole: ownerUserRole,
            hasUserTicketLimit: hasUserTicketLimit,
            userTicketLimit: userTicketLimit,
            generalTicketOptions: generalAdmissionTicketOptions,
            assignedSeatingTicketOptions: assignedSeatingTicketOptions,
            pricingAdjustments: pricingAdjustments,
            savedVenueSeatingImages: savedVenueSeatingImages,
            venueSeatingImages: [],
            venueSeatingImagePreviews: [],
            serverError: serverError,
            editable: editable
        });

        this.props.loadingOff();
    }

    GetResponseGenAdmission = (generalAdmissionTicketOptions: any[]) => {
        return Enumerable.from(generalAdmissionTicketOptions).select((o: any) => ({
            id: o.id,
            name: o.name,
            nameError: '',
            price: o.price,
            priceError: '',
            qty: o.qty,
            qtyError: '',
            saleStartsDateTime: o.saleStartsDateTime != null ? new Date(o.saleStartsDateTime): null,
            saleStartsDateTimeError: "",
            saleEndsDateTime: o.saleEndsDateTime != null ? new Date(o.saleEndsDateTime): null,
            saleEndsDateTimeError: ""
        })).toArray();
    }

    GetResponsePricingAdjustments = (pricingAdjustments: any[]) => {
        return Enumerable.from(pricingAdjustments).select((a: any) => ({
            id: a.id,
            name: a.name,
            nameError: a.nameError,
            discountAmountCode: a.discountAmountCode,
            discountAmount: a.discountAmount,
            discountAmountError: '',
            pricingAdjustmentCode: a.pricingAdjustmentCode,
            startDateTime: a.startDateTime,
            startDateTimeError: '',
            endDateTime: a.endDateTime,
            endDateTimeError: '',
            coupon: a.coupon,
            couponError: '',
            generalAdmissionTicketOptionIds: a.generalAdmissionTicketOptionIds,
            assignedSeatingTicketOptionIds: a.assignedSeatingTicketOptionIds
        })).toArray()
    }

    GetResponseAssignedSeating = (assignedSeatingTicketOptions: any[]) => {
        return Enumerable.from(assignedSeatingTicketOptions).select((o: any) => ({
            id: o.id,
            section: o.section,
            sectionError: '',
            row: o.row,
            rowError: '',
            seats: o.seats,
            seatsError: '',
            price: o.price,
            priceError: ''
        })).toArray();
    }

    RedirectToLogin = (eventClusterId: any, eventId: any) => {
        let eventClusterParam = eventClusterId != null && eventClusterId != "" ? `?eventGroupId=${eventClusterId}` : "";

        if (eventId != '' && eventId != undefined) {
            eventClusterParam += `%26id=${eventId}`;
        }

        this.props.changePage("/login?redirect=event" + eventClusterParam);
    }

    A11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    HandleChange = (newValue: number) => {
        this.setState({ ...this.state, currentTab: newValue });
    }

    AddTicketOption = (ticketOptionType: TicketOptionType) => {
        let newTicketOption = ticketOptionType == TicketOptionType.GeneralAdmission ? this.InitNewGeneralAdmissionTicketOption() : this.InitNewAssignedSeatingTicketOption();

        let ticketOptions: any[] = [];

        if (ticketOptionType == TicketOptionType.GeneralAdmission) {
            ticketOptions = this.state.generalTicketOptions;
            ticketOptions.push(newTicketOption);
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, generalTicketOptions: ticketOptions, pendingChanges: true });
        }
        else {
            ticketOptions = this.state.assignedSeatingTicketOptions;
            ticketOptions.push(newTicketOption);
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, pendingChanges: true });
        }
    }

    InitNewGeneralAdmissionTicketOption = () => {
        return {
            name: '',
            nameError: '',
            price: 0,
            priceError: '',
            qty: 0, qtyError: '',
            selected: false,
            saleStartsDateTime: null,
            saleStartsDateTimeError: "",
            saleEndsDateTime: this.state.finishEventDateTime,
            saleEndsDateTimeError: ""
        };
    }

    InitNewAssignedSeatingTicketOption = () => {
        return {
            section: '',
            sectionError: '',
            row: '',
            rowError: '',
            seats: 0,
            seatsError: '',
            price: 0,
            priceError: '',
            selected: false
        };
    }

    UpdateTicketOptions = (ticketOptions: any[], ticketOptionType: TicketOptionType, updatePendingChanges: boolean = true) => {
        if (ticketOptionType == TicketOptionType.GeneralAdmission) {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, generalTicketOptions: ticketOptions, pendingChanges: updatePendingChanges ? true : this.state.pendingChanges });
        }
        else {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, pendingChanges: updatePendingChanges ? true : this.state.pendingChanges });
        }
    }

    UpdatePricingAdjustments = (pricingAdjustments: any[]) => 
    {
        this.setState({ ...this.state, pricingAdjustments: pricingAdjustments, pendingChanges: true});
    }

    HandleTicketOptionNameChange = (dataIndex: number, v: any, id: string, cursorStart: number | null) => {
        let generalTicketOptions: any[] = this.state.generalTicketOptions;

        let ticketOption: any = generalTicketOptions[dataIndex];

        ticketOption.name = v;

        generalTicketOptions[dataIndex] = ticketOption;

        this.props.handleChangesPendingChange(true);

        this.setState({ ...this.state, generalTicketOptions: generalTicketOptions, focusedId: id, inputCursorStart: cursorStart, pendingChanges: true });
    }

    HandleTicketOptionPriceChange = (dataIndex: number, v: any, ticketOptionType: TicketOptionType) => {
        let ticketOptions: any[] = ticketOptionType == TicketOptionType.GeneralAdmission ? this.state.generalTicketOptions : this.state.assignedSeatingTicketOptions;

        let ticketOption: any = ticketOptions[dataIndex];
        
        ticketOption.price = v;

        ticketOptions[dataIndex] = ticketOption;

        if (ticketOptionType == TicketOptionType.GeneralAdmission) {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, generalTicketOptions: ticketOptions, pendingChanges: true });
        }
        else {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, pendingChanges: true });
        }
    }

    HandleTicketOptionQtyChange = (dataIndex: number, v: any, id: string, cursorStart: number | null, ticketOptionType: TicketOptionType) => {
        let ticketOptions: any[] = ticketOptionType == TicketOptionType.GeneralAdmission ? this.state.generalTicketOptions : this.state.assignedSeatingTicketOptions;

        let ticketOption: any = ticketOptions[dataIndex];

        v = v.replace(".", "");

        if (v && !Number.isNaN(Number(v)) && Number(v) <= 1000000000) {
            ticketOption.qty = v.replace(/^0+/g, "");
        }
        else if (v == undefined || v == "") {
            ticketOption.qty = 0;
        }

        ticketOptions[dataIndex] = ticketOption;

        if (ticketOptionType == TicketOptionType.GeneralAdmission) {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, generalTicketOptions: ticketOptions, focusedId: id, inputCursorStart: cursorStart, pendingChanges: true });
        }
        else {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, focusedId: id, inputCursorStart: cursorStart, pendingChanges: true });
        }
    }

    HandleTicketOptionSaleEndsDateTimeChange = (dataIndex: number, v: Date | null, ticketOptionType: TicketOptionType) => {
        let ticketOptions: any[] = ticketOptionType == TicketOptionType.GeneralAdmission ? this.state.generalTicketOptions : this.state.assignedSeatingTicketOptions;

        let ticketOption: any = ticketOptions[dataIndex];

        ticketOption.saleEndsDateTime = v;

        if (ticketOptionType == TicketOptionType.GeneralAdmission) {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, generalTicketOptions: ticketOptions, pendingChanges: true });
        }
        else {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, pendingChanges: true });
        }
    }

    HandleTicketOptionSaleStartsDateTimeChange = (dataIndex: number, v: Date | null, ticketOptionType: TicketOptionType) => {
        let ticketOptions: any[] = ticketOptionType == TicketOptionType.GeneralAdmission ? this.state.generalTicketOptions : this.state.assignedSeatingTicketOptions;

        let ticketOption: any = ticketOptions[dataIndex];

        ticketOption.saleStartsDateTime = v;

        if (ticketOptionType == TicketOptionType.GeneralAdmission) {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, generalTicketOptions: ticketOptions, pendingChanges: true });
        }
        else {
            this.props.handleChangesPendingChange(true);
            this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, pendingChanges: true });
        }
    }

    AddPricingAdjustment = (pricingAdjustment: any, ticketOptionType: TicketOptionType) => {

        let pricingAdjustments: any [] = this.state.pricingAdjustments;

        pricingAdjustments.push(pricingAdjustment);

        this.setState({ ...this.state, pricingAdjustments: pricingAdjustments, pendingChanges: true });
    }

    UpdateFocusId = (id: string, cursorStart: number | null) => {
        this.setState({ ...this.state, focusedId: id, inputCursorStart: cursorStart });
    }

    RemoveFocusedId = () => {
        this.setState({ ...this.state, focusedId: '', inputCursorStart: 0 });
    }

    HandleTicketOptionSectionChange = (dataIndex: number, v: any, id: string, cursorStart: number | null) => {
        let ticketOptions: any[] = this.state.assignedSeatingTicketOptions;

        let ticketOption: any = ticketOptions[dataIndex];

        ticketOption.section = v;

        ticketOptions[dataIndex] = ticketOption;

        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, focusedId: id, inputCursorStart: cursorStart, pendingChanges: true });
    }

    HandleTicketOptionRowChange = (dataIndex: number, v: any, id: string, cursorStart: number | null) => {
        let ticketOptions: any[] = this.state.assignedSeatingTicketOptions;

        let ticketOption: any = ticketOptions[dataIndex];

        ticketOption.row = v;

        ticketOptions[dataIndex] = ticketOption;

        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, focusedId: id, inputCursorStart: cursorStart, pendingChanges: true });
    }

    HandleTicketOptionSeatsChange = (dataIndex: number, v: any, id: string, cursorStart: number | null) => {
        let ticketOptions: any[] = this.state.assignedSeatingTicketOptions;

        let ticketOption: any = ticketOptions[dataIndex];

        v = v.replace(".", "");

        if (v && !Number.isNaN(Number(v)) && Number(v) <= 1000000000) {
            ticketOption.seats = v.replace(/^0+/g, "");
        }
        else if (v == undefined || v == "") {
            ticketOption.seats = 0;
        }

        ticketOptions[dataIndex] = ticketOption;

        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, assignedSeatingTicketOptions: ticketOptions, focusedId: id, inputCursorStart: cursorStart, pendingChanges: true });
    }

    AddVenueSeatingImages = (files: any[], callback?: (() => void)) => {
        this.setState({ ...this.state, venueSeatingImages: files, pendingChanges: true }, callback);
    }

    AddVenueSeatingImagePreview = (file: any, contentDisposition: string) => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, venueSeatingImagePreviews: { ...this.state.venueSeatingImagePreviews, [file.uid]: contentDisposition, pendingChanges: true } });
    }

    RemoveVenueSeatingImagePreviews = (files: any[], filePreviews: any[]) => {
        const venueSeatingImagePreviews = {
            ...this.state.venueSeatingImagePreviews
        };

        filePreviews.forEach(file => {
            delete venueSeatingImagePreviews[file.uid];
        });

        this.setState({
            venueSeatingImages: files,
            venueSeatingImagePreviews: venueSeatingImagePreviews
        });
    }

    SaveAllTicketing = async () => {
        this.props.loadingOn();

        let errored = false;

        let generalTicketOptions = this.state.generalTicketOptions;

        let saveValidationErrors: any = [];

        generalTicketOptions.forEach((option: any, index: number) => {
            let currentErrored = false;
            if (option.name == undefined || option.name == '') {
                option.nameError = "Ticket Name required.";
                currentErrored = true;
            }
            else {
                option.nameError = "";
            }

            if (option.qty == 0) {
                option.qtyError = "Qty must be greater than 0.";
                currentErrored = true;
            }
            else {
                option.qtyError = "";
            }

            if (option.saleEndsDateTime != null && option.saleEndsDateTime > this.state.finishEventDateTime){
                option.saleEndsDateTimeError = "Sale End Date cannot be after the event finish date/time.";
                currentErrored = true;
            }
            else{
                option.saleEndsDateTimeError = "";
            }

            if(option.saleStartsDateTime != null && option.saleEndsDateTime != null && option.saleStartsDateTime > option.saleEndsDateTime){
                option.saleStartsDateTimeError = "Sale Start Date can not be greater than sale end date.";
                option.saleEndsDateTimeError = "Sale Ends Date can not be less than sale start date.";
                currentErrored = true;
            }

            if (currentErrored) {
                errored = true;
                saveValidationErrors.push(`General Admission - Ticket Option (Row ${(index + 1)}) has errors.`);
            }
        });

        let assignedSeatingTicketOptions = this.state.assignedSeatingTicketOptions;

        assignedSeatingTicketOptions.forEach((option: any, index: number) => {
            let currentErrored = false;

            if (option.section == undefined || option.section == '') {
                option.sectionError = "Section required.";
                currentErrored = true;
            }
            else {
                option.sectionError = "";
            }

            if (option.row == undefined || option.row == '') {
                option.rowError = "Row required.";
                currentErrored = true;
            }
            else {
                option.rowError = "";
            }

            if (option.seats == 0) {
                option.seatsError = "Seats must be greater than 0."
                currentErrored = true;
            }
            else {
                option.seatsError = "";
            }

            if (currentErrored) {
                errored = true;
                saveValidationErrors.push(`Assigned Seating - Ticket Option (Row ${(index + 1)}) has errors.`);
            }

        });

        if (errored) {
            this.setState({ ...this.state, generalTicketOptions: generalTicketOptions, assignedSeatingTicketOptions: assignedSeatingTicketOptions, saveValidationErrors: saveValidationErrors });
        }
        else {
            let generalAdmissionTicketOptions: GeneralAdmissionTicketOption[] = Enumerable.from(this.state.generalTicketOptions).select((o: any) => ({
                id: o.id,
                name: o.name,
                price: o.price,
                qty: o.qty,
                saleStartsDateTime: Moment(o.saleStartsDateTime).format('MM/DD/yyyy HH:mm:ss'),
                saleEndsDateTime: Moment(o.saleEndsDateTime).format('MM/DD/yyyy HH:mm:ss')
            })).toArray();

            let assignedSeatingTicketOption: AssignedSeatingTicketOption[] = Enumerable.from(this.state.assignedSeatingTicketOptions).select((o: any) => ({
                id: o.id,
                section: o.section,
                row: o.row,
                seats: o.seats,
                price: o.price
            })).toArray();

            let pricingAdjustments: PricingAdjustment[] = Enumerable.from(this.state.pricingAdjustments).select((a: any) => ({
                id: a.id,
                name: a.name,
                discountAmountCode: a.discountAmountCode,
                discountAmount: a.discountAmount,
                pricingAdjustmentCode: a.pricingAdjustmentCode,
                startDateTime:Moment(a.startDateTime).format('MM/DD/yyyy HH:mm:ss'),
                endDateTime: Moment(a.endDateTime).format('MM/DD/yyyy HH:mm:ss'),
                coupon: a.coupon,
                generalAdmissionTicketOptionIds: a.generalAdmissionTicketOptionIds,
                assignedSeatingTicketOptionIds: a.assignedSeatingTicketOptionIds
            })).toArray()

            let request: ISaveTicketOptionsRequest = {
                eventId: this.state.eventId,
                hasFreeAdmissions: this.state.hasFreeAdmissions,
                userTicketLimit: this.state.hasUserTicketLimit ? this.state.userTicketLimit : null,
                generalAdmissionTicketOptions: generalAdmissionTicketOptions,
                assignedSeatingTicketOptions: assignedSeatingTicketOption,
                pricingAdjustments: pricingAdjustments
            };

            let saveErrors: any = [];
            let freeAdmissions = false;
            let newGeneralAdmissionTicketOptions: any[] = this.state.generalTicketOptions;
            let newAssignedSeatingTicketOptions: any[] = this.state.assignedSeatingTicketOptions;
            let newPricingAdjustments: any[] = this.state.pricingAdjustments;

            let venueSeatingImages: any[] = this.state.venueSeatingImages;
            let venueSeatingImagePreviews: any[] = this.state.venueSeatingImagePreviews;

            let savedVenueSeatingImages: any[] = this.state.savedVenueSeatingImages;
            let saveSuccess = false;

            var response = await saveTicketOptions(request);

            if (response != null && response.status == 401) {
                this.RedirectToLogin(this.state.eventClusterId, this.state.eventId);
            }
            else if (response != null && response.status == 200) {

                if (!response.data.success) {
                    saveErrors.push(response.data.errorMsg);
                }
                else {
                    saveSuccess = true;
                    let error = await this.UploadFiles(this.state.eventId);

                    if (error != undefined && error != '') {
                        saveErrors.push(error);
                    }
                    else {
                        venueSeatingImages = [];
                        venueSeatingImagePreviews = [];
                    }

                    error = await this.DeleteFiles();

                    if (error != undefined && error != '') {
                        saveErrors.push(error);
                    }

                    var getTicketOptionsResponse = await getTicketOptions(this.state.eventId);

                    if (getTicketOptionsResponse != null && getTicketOptionsResponse.status == 401) {
                        this.RedirectToLogin(this.state.eventClusterId, this.state.eventId);
                    }
                    else if (getTicketOptionsResponse != null && getTicketOptionsResponse.status == 200) {
                        if (!getTicketOptionsResponse.data.success) {
                            let error = getTicketOptionsResponse.data.errorMsg;

                            if (error != undefined && error != '') {
                                saveErrors.push(error);
                            }
                        }
                        else {
                            freeAdmissions = getTicketOptionsResponse.data.hasFreeAdmissions;
                            var generalAdmissionTicketOptionList: any = getTicketOptionsResponse.data.generalAdmissionTicketOptions;
                            var assignedSeatingTicketOptionList: any = getTicketOptionsResponse.data.assignedSeatingTicketOptions;
                            var pricingAdjustmentList: any = getTicketOptionsResponse.data.pricingAdjustments;

                            savedVenueSeatingImages = getTicketOptionsResponse.data.eventVenueMapImages;

                            newGeneralAdmissionTicketOptions = this.GetResponseGenAdmission(generalAdmissionTicketOptionList);

                            newAssignedSeatingTicketOptions = this.GetResponseAssignedSeating(assignedSeatingTicketOptionList);

                            newPricingAdjustments = this.GetResponsePricingAdjustments(pricingAdjustmentList);
                        }
                    }
                }
            }
            else {
                saveErrors.push("An error occured when attempting to save. Please try again later.");
            }

            this.props.handleChangesPendingChange(saveErrors.length > 0);

            this.setState({
                ...this.state,
                hasFreeAdmissions: freeAdmissions,
                generalTicketOptions: newGeneralAdmissionTicketOptions,
                assignedSeatingTicketOptions: newAssignedSeatingTicketOptions,
                pricingAdjustments: newPricingAdjustments,
                venueSeatingImages: venueSeatingImages,
                venueSeatingImagePreviews: venueSeatingImagePreviews,
                savedVenueSeatingImages: savedVenueSeatingImages,
                saveValidationErrors: saveValidationErrors,
                saveSuccess: saveSuccess,
                saveErrors: saveErrors,
                pendingChanges: saveErrors.length > 0
            });
        }

        this.props.loadingOff();
    }

    UploadFiles = async (eventId: any) => {
        let serverError = '';

        if (this.state.venueSeatingImages.length > 0) {
            let files = Enumerable.from(this.state.venueSeatingImages).select((f: any) => f.getRawFile()).toArray();
            var response = await uploadEventFiles(files, [], eventId, EventFileTypeCode.EventVenueMapImage);

            if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = `File upload failed: ${response.data.errorMsg}`;
                }
            }
            else {
                serverError = "File upload failed. Please try again later.";
            }
        }

        return serverError;
    }

    DeleteFiles = async () => {
        let serverError = '';

        if (this.state.deleteFileIds.length > 0) {
            let fileIds = this.state.deleteFileIds;
            var response = await deleteEventFiles(fileIds);

            if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = `File deletion failed: ${response.data.errorMsg}`;
                }
            }
            else {
                serverError = "File deletion failed. Please try again later.";
            }
        }

        return serverError;
    }

    CloseSaveSuccessModal = () => {
        this.setState({ ...this.state, saveSuccess: false });
    }

    CloseSaveErrorModal = () => {
        this.setState({ ...this.state, saveErrors: [] });
    }

    RemoveSavedFile = (index: number) => {
        let savedVenueSeatingImages: any = this.state.savedVenueSeatingImages;

        let savedVenueSeatingImagesNew: any = [];
        let deleteFileIds: any = this.state.deleteFileIds;

        savedVenueSeatingImages.forEach((file: any, i: number) => {
            if (index != i) {
                savedVenueSeatingImagesNew.push(file);
            }
            else {
                deleteFileIds.push(file.id);
            }
        });

        this.props.handleChangesPendingChange(true);

        this.setState({ ...this.state, savedVenueSeatingImages: savedVenueSeatingImagesNew, deleteFileIds: deleteFileIds, pendingChanges: true });
    }

    OnhasFreeAdmissionsCheckboxClick = () => {
        this.props.handleChangesPendingChange(true);
        this.setState({ ...this.state, hasFreeAdmissions: !this.state.hasFreeAdmissions, pendingChanges: true });
    }

    OnHasUserTicketLimitCheckboxClick = () => {
        this.props.handleChangesPendingChange(true);

        var userTicketLimit = this.state.hasUserTicketLimit ? 0 : this.state.userTicketLimit;
        this.setState({ ...this.state, hasUserTicketLimit: !this.state.hasUserTicketLimit, userTicketLimit: userTicketLimit, pendingChanges: true });
    }

    UserTicketLimitChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.handleChangesPendingChange(true);

        var value = e.target.value.replace(/[$,]+/g, "");

        let userTicketLimit = this.state.userTicketLimit;

        if (value && !Number.isNaN(Number(value)) && Number(value) <= 1000000000) {
            userTicketLimit = Number(value);
        }

        this.setState({ ...this.state, userTicketLimit: userTicketLimit, pendingChanges: true });
    }

    CloseSuccessModal = () => {
        this.setState({ ...this.state, serverSuccessMsg: "" });
    };

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: '' });
    }

    render() {
        var canEdit = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditEvents);

        return (
            <div style={{ height: '90%', width: "100%" }}>
                <div style={{ width: "100%", height: '100%' }}>
                    <Card style={{ height: '100%', backgroundColor: "#ededed" }}>
                        <CardContent style={{ height: '75%' }}>
                            <div style={{ marginBottom: "10px" }}>
                                {
                                    this.state.saveValidationErrors.map((value: any) => (
                                        <div style={{ color: "red" }}>{value}</div>
                                    ))
                                }
                            </div>
                            <div style={{ marginBottom: "20px" }}>
                                <Button
                                    variant="contained"
                                    className='btn-primary'
                                    style={{ verticalAlign: "middle" }}
                                    disabled={!canEdit}
                                    onClick={this.SaveAllTicketing} >
                                    Save All Ticketing
                                </Button>
                                {this.state.pendingChanges && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "10px" }}>
                                        <WarningIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "5px" }}>
                                        Changes Pending Save
                                    </span>
                                </span>}
                                {this.state.pendingChanges == false && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "10px" }}>
                                        <DoneOutlineIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "5px" }}>
                                        Up to date
                                    </span>
                                </span>}
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <table>
                                    <tr>
                                        <td>
                                            <FormControlLabel control={<Checkbox color="default" checked={this.state.hasFreeAdmissions} onClick={this.OnhasFreeAdmissionsCheckboxClick} disabled={!this.state.editable || !canEdit} />} label="Event has Free Admission" />
                                        </td>
                                        <td>
                                            <FormControlLabel control={<Checkbox color="default" checked={this.state.hasUserTicketLimit} onClick={this.OnHasUserTicketLimitCheckboxClick} disabled={!canEdit} />} label="Event has user ticket purchase limit" />
                                        </td>
                                        <td>
                                            {(canEdit && this.state.hasUserTicketLimit) &&
                                                <TextField
                                                    error={this.state.userTicketLimitError !== ""}
                                                    // style={{width: '90%'}}
                                                    label="Ticket Purchase Limit"
                                                    placeholder="Quantity"
                                                    margin="normal"
                                                    helperText={this.state.userTicketLimitError}
                                                    FormHelperTextProps={{ color: "red" }}
                                                    value={this.state.userTicketLimit}
                                                    onChange={(e) => this.UserTicketLimitChange(e)}
                                                    variant="outlined"
                                                    className="textFieldInput"
                                                />}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <Paper square
                                style={{ width: "100%" }}
                            >
                                <Tabs
                                    value={this.state.currentTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={(e, v) => this.HandleChange(v)}
                                    style={{ width: "100%" }}
                                    centered={true}
                                    className="tabs-split"
                                >
                                    <Tab label="General Admission"  {...this.A11yProps(0)} />
                                    {/* <Tab className="MuiTab-root-override" label="Assigned Seating" {...this.A11yProps(1)} />  */}
                                </Tabs>
                            </Paper>
                            <div
                                role="tabpanel"
                                hidden={this.state.currentTab !== 0}
                                id={`wrapped-tabpanel-0`}
                                aria-labelledby={`wrapped-tab-0`}
                                style={{ height: '100%' }}
                                className="tabPanelOverride"
                            >
                                {this.state.currentTab === 0 && (
                                    <Box p={3} style={{ height: "100%" }}>
                                        <Typography style={{ height: "100%" }}>
                                            <GeneralAdmissionSubSection
                                                disabled={!this.state.editable || !canEdit}
                                                focusedId={this.state.focusedId}
                                                inputCursorStart={this.state.inputCursorStart}
                                                ticketOptions={this.state.generalTicketOptions}
                                                pricingAdjustments={this.state.pricingAdjustments}
                                                addTicketOption={this.AddTicketOption}
                                                updateTicketOptions={this.UpdateTicketOptions}
                                                updatePricingAdjustments={this.UpdatePricingAdjustments}
                                                handleTicketOptionNameChange={this.HandleTicketOptionNameChange}
                                                handleTicketOptionPriceChange={this.HandleTicketOptionPriceChange}
                                                handleTicketOptionQtyChange={this.HandleTicketOptionQtyChange}
                                                handleTicketOptionSaleStartsDateTimeChange={this.HandleTicketOptionSaleStartsDateTimeChange}
                                                handleTicketOptionSaleEndsDateTimeChange={this.HandleTicketOptionSaleEndsDateTimeChange}
                                                addPricingAdjustment={this.AddPricingAdjustment}
                                                updateFocusId={this.UpdateFocusId}
                                                removeFocusedId={this.RemoveFocusedId}
                                                freeTicketsOnly={this.state.freeTicketsOnly}
                                                ownerUserRole={this.state.ownerUserRole}
                                                isClusterOwner={this.props.isClusterOwner}
                                                eventClusterId={this.state.eventClusterId}
                                                finishEventDateTime={this.state.finishEventDateTime}
                                            />
                                        </Typography>
                                    </Box>
                                )}
                            </div>
                            {/* TODO: Add this back when we enable assigned seating */}
                            {/* <div
                            role="tabpanel"
                            hidden={this.state.currentTab !== 1}
                            id={`wrapped-tabpanel-1`}
                            aria-labelledby={`wrapped-tab-1`}
                            style={{height: '100%'}}
                            className="tabPanelOverride"
                            >
                             {this.state.currentTab === 1 && (
                                <Box p={3} style={{height: "100%"}}>
                                    <Typography style={{height: "100%"}}>
                                        <AssignedSeatingSubSection 
                                                focusedId={this.state.focusedId}
                                                inputCursorStart={this.state.inputCursorStart}
                                                ticketOptions={this.state.assignedSeatingTicketOptions} 
                                                venueSeatingImages={this.state.venueSeatingImages}
                                                venueSeatingImagePreviews={this.state.venueSeatingImagePreviews}
                                                savedVenueSeatingImages={this.state.savedVenueSeatingImages}
                                                addTicketOption={this.AddTicketOption}
                                                updateTicketOptions={this.UpdateTicketOptions}
                                                handleTicketOptionSectionChange={this.HandleTicketOptionSectionChange}
                                                handleTicketOptionRowChange={this.HandleTicketOptionRowChange}
                                                handleTicketOptionSeatsChange={this.HandleTicketOptionSeatsChange}
                                                handleTicketOptionPriceChange={this.HandleTicketOptionPriceChange}
                                                addPricingAdjustment={this.AddPricingAdjustment}
                                                updateFocusId={this.UpdateFocusId}
                                                removeFocusedId={this.RemoveFocusedId}
                                                addVenueSeatingImages={this.AddVenueSeatingImages}
                                                addVenueSeatingImagePreview={this.AddVenueSeatingImagePreview}
                                                removeSavedFile={this.RemoveSavedFile}
                                                removeVenueSeatingImagePreviews={this.RemoveVenueSeatingImagePreviews}
                                            />
                                    </Typography>
                                </Box>
                            )} 
                            </div> */}
                            <div style={{ marginTop: "20px" }}>
                                <Button variant="contained" className='btn-primary' style={{ verticalAlign: "middle" }} onClick={this.SaveAllTicketing} disabled={!canEdit} >
                                    Save All Ticketing
                                </Button>
                                {this.state.pendingChanges && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "10px" }}>
                                        <WarningIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "orange", marginLeft: "5px" }}>
                                        Changes Pending Save
                                    </span>
                                </span>}
                                {this.state.pendingChanges == false && <span style={{ height: "100%" }} className="lowerSaveStatus">
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "10px" }}>
                                        <DoneOutlineIcon />
                                    </span>
                                    <span style={{ height: "100%", verticalAlign: "middle", color: "limegreen", marginLeft: "5px" }}>
                                        Up to date
                                    </span>
                                </span>}
                            </div>
                        </CardContent>
                    </Card>
                    <Dialog
                        onClose={this.CloseSaveErrorModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        className="genericErrorModal"
                        open={this.state.saveErrors != undefined && this.state.saveErrors.length > 0}
                    >
                        <DialogTitle disableTypography >
                            <Typography variant="h6">Error</Typography>
                            <IconButton aria-label="close"
                                className='close-button'
                                onClick={this.CloseSaveErrorModal}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            {
                                this.state.saveErrors.map((error: any) => (<div>
                                    {error}
                                </div>
                                ))
                            }
                        </DialogContent>
                        <DialogActions disableSpacing={true}>
                            <Button onClick={this.CloseSaveErrorModal} className='action-button'>
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <GenericSuccessModal
                    hidden={this.state.saveSuccess == false}
                    message="Your ticket options saved successfully."
                    closeModal={this.CloseSaveSuccessModal}
                />
                <GenericErrorModal hidden={this.state.serverError == undefined || this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
            </div>
        );
    }
}