import * as React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

interface IEditDeleteActionCellProps{
    dataIndex: number,
    editMode: boolean,
    hideEditButton: boolean,
    hideDeleteButton: boolean,
    hideCancelButton: boolean,
    onEditButtonClick: (dataIndex: number) => void;
    onSaveButtonClick: (dataIndex: number) => void;
    onDeleteButtonClick: (dataIndex: number) => void;
    onCancelButtonClick: (dataIndex: number) => void;
}

export class EditDeleteActionCell extends React.Component<IEditDeleteActionCellProps> {
    public static defaultProps: Partial<IEditDeleteActionCellProps> = {
        hideCancelButton: true,
        hideDeleteButton: false,
        hideEditButton: false,
        onEditButtonClick: ()=>{},
        onSaveButtonClick: ()=>{},
        onDeleteButtonClick: ()=>{},
        onCancelButtonClick: ()=>{},
    };
   
    render() {
        return (
            <td style={{textAlign: "center"}}>
                {this.props.hideEditButton == false && this.props.editMode == false && <Button variant="contained" className="btn-secondary" style={{ marginRight: "5px", marginBottom: "5px" }} onClick={() => this.props.onEditButtonClick(this.props.dataIndex)}>
                    Edit
                </Button>}
                {this.props.hideEditButton == false && this.props.editMode == true && <Button variant="contained" className="btn-secondary" style={{ marginRight: "5px", marginBottom: "5px" }} onClick={() => this.props.onSaveButtonClick(this.props.dataIndex)} >
                    Save
                </Button>}
                {this.props.hideCancelButton == false && this.props.editMode == true && 
                    <Button variant="contained" className="btn-default" style={{ marginRight: "5px", marginBottom: "5px" }} onClick={() => this.props.onCancelButtonClick(this.props.dataIndex)} >
                        Cancel
                    </Button>}
                {this.props.hideDeleteButton == false && <Button variant="contained" style={{ marginRight: "5px", marginBottom: "5px" }} className="btn-delete" onClick={() => this.props.onDeleteButtonClick(this.props.dataIndex)}>
                    Delete
                </Button>}
            </td>
        );
    }
}