import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const getEventClusterPortalUsersResponse = async(eventClusterId: string, gridState: ITableDef): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalUserEventClusters/list`, { eventClusterId: eventClusterId, gridState: gridState}, tokenConfig);
}

export const getEventClusterPortalUsers = AwesomeDebouncePromise(getEventClusterPortalUsersResponse, 500);

export const updateEventClusterPortalUser = async(portalUserEventClusterId: string, eventClusterRoleId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/portalUserEventClusters`, { portalUserEventClusterId: portalUserEventClusterId, eventClusterRoleId: eventClusterRoleId }, tokenConfig);
}

export const deleteEventClusterPortalUser = async(portalUserEventClusterId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    
    return await axios.delete(`${baseAPIUrl}/api/portalUserEventClusters?portalUserEventClusterId=${portalUserEventClusterId}`, tokenConfig);
}