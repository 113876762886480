/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import '../../Styles/EventPrimaryFormSection.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { getEvent } from '../../../data/EventRepository';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { EventStatusCode } from '../../../data/Models/Enums';
import { Chip } from '@material-ui/core';
var Enumerable = require('linq');

interface IPublishSectionProps {
    userPermissions: any[],
    isClusterOwner: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    publishEvent: (eventId: string) => void,
    publishValidationErrors: any[],
    publishSuccess: boolean,
    changePage: (page: string) => void
}

export class PublishSection extends React.Component<IPublishSectionProps>{

    state = {
        eventId: '',
        eventWebsiteLink: '',
        eventDeepLink: '',
        eventClusterId: '',
        published: false,
        serverError: '',
        showPublishConfirmationModal: false,
        cancelled: false,
    }

    async componentDidMount() {
        this.props.loadingOn();

        const query = new URLSearchParams(window.location.search);

        let id = query.get('id');
        let eventWebsiteLink = process.env.REACT_APP_NRBY_EVENT_TICKETPURCHASE_LINK;
        let eventDeepLink = process.env.REACT_APP_NRBY_EVENT_DEEPLINK;
        let eventClusterId: string = query.get('eventGroupId') ?? "";
        let serverError = '';
        let published = false;
        let cancelled = false;

        if (id == undefined || id == '' || eventClusterId == undefined || eventClusterId == '') {
            serverError = "Event Id and Event Group Id are required.";
        }
        else {
            var eventResponse = await getEvent(id);

            if (eventResponse != null && eventResponse.status == 401) {
                this.RedirectToLogin(eventClusterId, id);
            }
            else if (eventResponse != null && eventResponse.status == 200) {
                if (!eventResponse.data.success) {
                    serverError = eventResponse.data.errorMsg;
                }
                else {
                    var event = eventResponse.data.event;

                    id = event.id;
                    eventWebsiteLink = eventWebsiteLink?.replace("{id}", event.id);
                    eventDeepLink = eventDeepLink?.replace("{id}", event.id);
                    published = event.published;
                    cancelled = event.cancelled;
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }
        }

        this.setState({ ...this.state, eventId: id, eventWebsiteLink: eventWebsiteLink, eventDeepLink: eventDeepLink, eventClusterId: eventClusterId, published: published, serverError: serverError, cancelled: cancelled });

        this.props.loadingOff();
    }

    RedirectToLogin = (eventClusterId: any, eventId: any) => {
        let eventClusterParam = eventClusterId != null && eventClusterId != "" ? `?eventGroupId=${eventClusterId}` : "";

        if (eventId != '' && eventId != undefined) {
            eventClusterParam += `%26id=${eventId}`;
        }

        this.props.changePage("/login?redirect=event" + eventClusterParam);
    }

    CloseSaveSuccessModal = () => {
        this.setState({ ...this.state, saveSuccess: false });
    }

    CloseSaveErrorModal = () => {
        this.setState({ ...this.state, saveError: '' });
    }

    HandlePublishEventClick = () => {
        this.setState({ ...this.state, showPublishConfirmationModal: true });
    }

    HandlePublishEvent = () => {
        this.setState({ ...this.state, showPublishConfirmationModal: false });
        this.props.publishEvent(this.state.eventId);
    }

    CancelPublishEvent = () => {
        this.setState({ ...this.state, showPublishConfirmationModal: false });
    }

    render() {
        var canEdit = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditEvents);

        return (
            <div style={{ height: '90%', width: "100%" }}>
                <div style={{ width: "100%", maxHeight: "100%", overflowY: "scroll" }}>
                    <Card style={{ height: '90%', backgroundColor: "#ededed" }}>
                        <CardContent >
                            <div style={{ marginBottom: "20px" }}>
                                {!this.state.published && !this.props.publishSuccess && <Button variant="contained" className='btn-primary' style={{ verticalAlign: "middle" }} onClick={this.HandlePublishEventClick} disabled={!canEdit} >
                                    Publish Event
                                </Button>}
                            </div>
                            <div>
                                {this.state.cancelled && <div><Chip label="Event Cancelled" color='secondary' /></div>}
                                {(this.props.publishSuccess || this.state.published) && <div>
                                    <div style={{ color: "limegreen", height: "100%" }}>
                                        <span style={{ height: "100%", verticalAlign: "middle" }}><DoneOutlineIcon /></span>
                                        <span style={{ height: "100%", verticalAlign: "middle", marginLeft: "5px" }}>Event Published</span>
                                    </div>
                                    <div style={{ marginTop: "40px", fontSize: "20px" }}>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Event Website Link:</td>
                                            <td><a href={this.state.eventWebsiteLink}>{this.state.eventWebsiteLink}</a></td>
                                        </tr>
                                    </div>
                                    <div style={{ marginTop: "40px", fontSize: "20px" }}>
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>Event App Link:</td>
                                            <td><a href={this.state.eventDeepLink}>{this.state.eventDeepLink}</a></td>
                                        </tr>
                                    </div>
                                </div>
                                }
                                {this.props.publishValidationErrors.length > 0 &&
                                    this.props.publishValidationErrors.map((error: any) => (
                                        <div style={{ color: "red" }}>
                                            {error}
                                        </div>
                                    ))
                                }
                            </div>
                        </CardContent>
                        <CardContent>
                            <div style={{ color: 'red' }} hidden={this.state.serverError == ''}>
                                {this.state.serverError}
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <GenericConfirmationModal hidden={!this.state.showPublishConfirmationModal}
                    cancelButtonText="No, Cancel" confirmButtonText="Yes, Publish"
                    onConfirm={this.HandlePublishEvent}
                    closeModal={this.CancelPublishEvent}
                    message="Are you sure you want to publish this event? Once published you will be unable to edit any fields other that Event Description, Keywords or Image."
                />
            </div>

        );
    }
}