import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

interface IGenericDropdownProps{
    label: string,
    value: any,
    error: string,
    hideErrorText?: boolean,
    data: any[],
    style?: any
    disabled: boolean,
    onChange: (value: any) => void;
}

export class GenericDropdown extends React.Component<IGenericDropdownProps> {

    public static defaultProps: Partial<IGenericDropdownProps> = {
        disabled: false
    };
    
    HandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.preventDefault();
        this.props.onChange(
            event.target.value
        );
    }

    render() {
        return (
                <div style={{width: "100%"}}>
                    <FormControl variant="outlined" style={{width: "100%"}} margin="normal">
                        <InputLabel>{this.props.label}</InputLabel>
                        <Select
                        title={this.props.label}
                        label={this.props.label}
                        fullWidth={true}
                        disabled={this.props.disabled}
                        value={this.props.value}
                        style={this.props.style}
                        margin="none"
                        onChange={(e) => this.HandleChange(e)}
                        error={this.props.error !== "" && this.props.error != null}
                        >
                        {
                            this.props.data.map((item: any) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))
                        }
                        </Select>
                    </FormControl>
                    <div style={{color: '#f44336', fontSize: '0.75rem'}} hidden={this.props.error == '' && this.props.error != null || this.props.hideErrorText == true}>
                        {this.props.error}
                    </div>
                </div>
        );
    }
}