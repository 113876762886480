/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import { EventClusterSection } from './EventClusterSection';
import { EventClusterInvitesSection } from './EventClusterInvitesSection';
import { EventClusterRolesSection } from './EventClusterRolesSection';
import { EventClusterPortalUsersSection } from './EventClusterPortalUsersSection';
import { EventClusterEventsSection } from './EventClusterEventsSection';
import { TabPanel } from '../SubComponents/TabPanel';
import { getPortalUserEventClusterRole } from '../../../data/EventClusterRoleRepository';
import {EventClusterPermissionCode} from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { MobileFriendlyTabs } from '../SubComponents/MobileFriendlyTabs';
import Box from '@material-ui/core/Box';
var Enumerable = require('linq');

interface IEventClusterPageProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class EventClusterPage extends React.Component<IEventClusterPageProps> {


    state = {
        eventClusterId: '',
        selected: 0,
        serverError: '',
        userPermissions: [],
        isClusterOwner: false,
        eventClusterTabAccess: false,
        eventsTabAccess: false,
        teamTabAccess: false,
        rolesTabAccess: false,
        invitesTabAccess: false
    }

    async componentDidMount(){
        this.props.loadingOn();
        const query = new URLSearchParams(window.location.search);

        var id = query.get('id');
        var selected = parseInt(query.get('tab') ?? "0");

        var userPermissions: EventClusterPermission[] = [];
        var serverError = "";
        let eventClusterTabAccess: boolean = false;
        let eventsTabAccess: boolean = false;
        let teamTabAccess: boolean = false;
        let rolesTabAccess: boolean = false;
        let invitesTabAccess: boolean = false;
        let isClusterOwner: boolean = false;

        if(id != '' && id != null){
            var response = await getPortalUserEventClusterRole(id);

            if(response != null && response.status == 401){
                this.props.changePage(`/login?redirect=event-group?id=${id}${encodeURIComponent("&")}tab=${selected}`);
            }
            else if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverError = response.data.errorMsg;
                }
                else{
                    userPermissions = response.data.eventClusterRole.eventClusterPermissions;

                    eventClusterTabAccess = Enumerable.from(userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.ViewEventClusterDetails);
                    eventsTabAccess = Enumerable.from(userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.ViewEvents);
                    teamTabAccess = Enumerable.from(userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.ViewTeam); 
                    rolesTabAccess = Enumerable.from(userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.ViewRoles);
                    invitesTabAccess = Enumerable.from(userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.ViewInvites);

                    isClusterOwner = response.data.eventClusterRole.isOwnerRole;
                }
            }
            else{
                serverError = "An unknown error occured. Please try again later";
            }
        }

        this.setState({...this.state, 
            selected: selected,
            eventClusterId: id, 
            userPermissions: userPermissions, 
            isClusterOwner: isClusterOwner,
            eventClusterTabAccess: eventClusterTabAccess,
            eventsTabAccess: eventsTabAccess,
            teamTabAccess: teamTabAccess,
            rolesTabAccess: rolesTabAccess,
            invitesTabAccess: invitesTabAccess,
            serverError: serverError
        });

        this.props.loadingOff();
    }

    HandleChange = (newValue: number) => {

        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?id=${this.state.eventClusterId}&tab=${newValue}`;
        window.history.pushState({path:newurl},'',newurl);

        this.setState({ selected:newValue });
    }

    ChangePage = (page: string) => {
        if(page.indexOf("event-group") > -1){
            page += `${encodeURIComponent("&")}tab=${this.state.selected}`;
        }
        
        this.props.changePage(page);
    }

    A11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
          };
    }

    render()
    {
        var eventClusterTabAccess = this.state.eventClusterTabAccess || this.state.isClusterOwner;
        var eventsTabAccess = this.state.eventsTabAccess || this.state.isClusterOwner;
        var teamTabAccess = this.state.teamTabAccess || this.state.isClusterOwner;
        var rolesTabAccess = this.state.rolesTabAccess || this.state.isClusterOwner;
        var invitesTabAccess = this.state.invitesTabAccess || this.state.isClusterOwner;

        var titles: string[] = ["Event Group", "Events", "Team", "Roles", "Invites"];
        return (
            <div style={{ height: '100%', width: '100%' }} >
                <Box style={{ width: '100%' }}>
                    <MobileFriendlyTabs 
                        titles={titles}
                        value={this.state.selected}
                        handleChange={this.HandleChange}
                    />
                    <TabPanel value={this.state.selected} index={0}>
                        {eventClusterTabAccess && <EventClusterSection  
                                                    changePage={this.ChangePage} 
                                                    userPermissions={this.state.userPermissions} 
                                                    isClusterOwner={this.state.isClusterOwner} 
                                                    loadingOn={this.props.loadingOn}
                                                    loadingOff={this.props.loadingOff}
                                                    />}
                        {eventClusterTabAccess == false && <div>You do not have access to the content of this tab.</div>}
                    </TabPanel>
                    <TabPanel value={this.state.selected} index={1}>
                        {eventsTabAccess && <EventClusterEventsSection  
                                                changePage={this.ChangePage} 
                                                userPermissions={this.state.userPermissions} 
                                                isClusterOwner={this.state.isClusterOwner} 
                                                loadingOn={this.props.loadingOn}
                                                loadingOff={this.props.loadingOff}
                                                />}
                        {eventsTabAccess == false && <div>You do not have access to the content of this tab.</div>}
                    </TabPanel>
                    <TabPanel value={this.state.selected} index={2}>
                        {teamTabAccess && <EventClusterPortalUsersSection 
                                                changePage={this.ChangePage} 
                                                userPermissions={this.state.userPermissions} 
                                                isClusterOwner={this.state.isClusterOwner}
                                                loadingOn={this.props.loadingOn}
                                                loadingOff={this.props.loadingOff}
                                                />}
                        {teamTabAccess == false && <div>You do not have access to the content of this tab.</div>}
                    </TabPanel>
                    <TabPanel value={this.state.selected} index={3}>
                        {rolesTabAccess && <EventClusterRolesSection  
                                            changePage={this.ChangePage} 
                                            userPermissions={this.state.userPermissions} 
                                            isClusterOwner={this.state.isClusterOwner}
                                            loadingOn={this.props.loadingOn}
                                            loadingOff={this.props.loadingOff}/>}
                        {rolesTabAccess == false && <div>You do not have access to the content of this tab.</div>}
                    </TabPanel>
                    <TabPanel value={this.state.selected} index={4}>
                        {invitesTabAccess && <EventClusterInvitesSection 
                                                changePage={this.ChangePage} 
                                                eventClusterId={this.state.eventClusterId}
                                                userPermissions={this.state.userPermissions} 
                                                isClusterOwner={this.state.isClusterOwner}
                                                loadingOn={this.props.loadingOn}
                                                loadingOff={this.props.loadingOff}
                                                />}
                        {invitesTabAccess == false && <div>You do not have access to the content of this tab.</div>}
                    </TabPanel>
                </Box>
            </div>
        )
    }
}