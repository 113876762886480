/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { getEventClusterPermissions, updateEventClusterPermission } from "../../../data/EventClusterPermissionRepository";
import { EditDeleteActionCell } from '../SubComponents/EditDeleteActionCell'
import { TextFieldCell } from '../SubComponents/TextFieldCell'
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import {PortalUserRoleCode} from "../../../data/Models/PortalUserRoleCode";
import { getAuthStatus } from '../../../data/PortalUserRepository';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

var Enumerable = require('linq');

interface IEventClusterPermissionProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IEventClusterPermissionState {
    permissions: any[],
    permissionsTotal: number,
    editPermissionIds: any[],
    pageable: any,
    tableDef: ITableDef,
    focusedId: string,
    inputCursorStart: number | null,
    mobileFiltersVisible: boolean,
    serverError: string,
    serverSuccessMsg: string,
}

const getInitTableDef = () =>{
    let sortDef: ISortDef = {
        field: "SortOrder",
        dir: "asc"
    };

    let filterArray = [
        {
            field: "id",
            operator: "eq",
            value: ""
        },
        {
            field: "codeName",
            operator: "eq",
            value: ""
        },
        {
            field: "name",
            operator: "eq",
            value: ""
        },
        {
            field: "sortOrder",
            operator: "eq",
            value: null
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 20,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class EventClusterPermissionDashboard extends React.Component<IEventClusterPermissionProps, IEventClusterPermissionState> {

    state = {
        permissions: [],
        permissionsTotal: 0,
        editPermissionIds: [],
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
              id: 'id',
              label: 'Id',
            },
            {
              id: 'codeName',
              label: 'Code Name',
            },
            {
              id: 'name',
              label: 'Name',
            },
            {
              id: 'sortOrder',
              label: 'sortOrder',
            }
          ],
        focusedId: '',
        inputCursorStart: 0,
        mobileFiltersVisible: false,
        serverError: '',
        serverSuccessMsg: '',
    }

    async componentDidMount(){
        this.props.loadingOn();

        let role: PortalUserRoleCode = PortalUserRoleCode.None;

        const response = await getAuthStatus();

        if(response == undefined || response.status != 200){
            this.props.changePage("/login?redirect=permissions-dashboard");
        }

        role = response.data.role;

        if(role == PortalUserRoleCode.Admin){
            await this.LoadEventClusterPermissions(this.state.tableDef);
        }
        else{
            this.props.changePage("/dashboard");
        }

        this.props.loadingOff();
    }

    LoadEventClusterPermissions = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let permissions: any[] = [];
        let permissionsTotal = 0;

        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "")).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getEventClusterPermissions(tempTableDef);

        if(response != null && response.status == 401){
            this.props.changePage("/login?redirect=permissions-dashboard");
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                permissions = response.data.eventClusterPermissions;
                permissionsTotal = response.data.eventClusterPermissionsTotal;
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, 
            permissions: permissions, 
            permissionsTotal: permissionsTotal, 
            tableDef: tableDef, 
            serverError: serverError});
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        if(tableDef != null && tableDef.sort!.length > 0){
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadEventClusterPermissions(tableDef);
        }
    
        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({f, index})).firstOrDefault((fi: any) => fi.f.field == filter);

        if(filter == "sortOrder"){
            if(value == null || value == ""){
                filterDef.f.value = "";
            }
            else{
                filterDef.f.value =  parseFloat(value);
            }
        }else{
            filterDef.f.value = value;
        }
        
        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadEventClusterPermissions(tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadEventClusterPermissions(tableDef);
        this.props.loadingOff();
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadEventClusterPermissions(tableDef);

        this.props.loadingOff();
    };

    OpenPermissionsEditMode = (dataIndex: number) => {
        let permission: any = this.state.permissions[dataIndex];

        permission.savedName = permission.name;
        permission.savedSortOrder = permission.sortOrder;

        let editPermissionIds: any[] = this.state.editPermissionIds;

        editPermissionIds.push(permission.id);

        this.setState({...this.state, editPermissionIds: editPermissionIds});
    }

    CancelPermissionsEditMode = (dataIndex: number) => {
        let permission: any = this.state.permissions[dataIndex];
        
        permission.name = permission.savedName;
        permission.sortOrder = permission.savedSortOrder;

        let editPermissionIds: any[] = this.state.editPermissionIds;

        editPermissionIds = editPermissionIds.filter(obj => obj !== permission.id);

        this.setState({...this.state, editPermissionIds: editPermissionIds});
    }

    SaveEditedPermission = async (dataIndex: number) => {
        this.props.loadingOn();

        let permissions: any[] = this.state.permissions;
        let permission: any = permissions[dataIndex];

        let errored = false;
        let serverError = "";
        let serverSuccessMsg = '';
    
        if(permission.name == "")
        {
            permission.nameError = "Permission Name required.";
            errored = true;
        }   

        if(permission.sortOrder < 1){
            permission.sortOrderError = "Permission Sort Order must be greater than 0.";
            errored = true;
        }

        let editPermissionIds = this.state.editPermissionIds;

        if(errored == false){
            var response: any = await updateEventClusterPermission(permission.id, permission.name, permission.sortOrder);

            if(response != null && response.status == 401){
                this.props.changePage("/login?redirect=permissions-dashboard");
            }
            else if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverError = response.data.errorMsg;
                }
                else{
                    editPermissionIds = Enumerable.from(editPermissionIds).where((id: any) => id != permission.id).toArray();

                    serverSuccessMsg = `Event group permission with name "${permission.name}" updated successfully.`;
                }
            }
            else{
                serverError = "An unknown error occured. Please try again later";
            }
        }

        this.setState({...this.state, permissions: permissions, editPermissionIds: editPermissionIds, serverSuccessMsg: serverSuccessMsg, serverError: serverError});
        this.props.loadingOff();
    }

    CheckIfInEditMode = (id: string) => {
        let editPermissionIds: string[] = this.state.editPermissionIds;
        return editPermissionIds.indexOf(id) > -1;
    }

    ChangePermissionName = (dataIndex: number, value: any, id: string, inputCursorStart: number | null) => {
        var permissions: any[] = this.state.permissions;
        var permission: any = permissions[dataIndex];

        permission.name = value;

        permissions[dataIndex] = permission;
        
        this.setState({...this.state, permissions: permissions, focusedId: id, inputCursorStart: inputCursorStart});
    }

    ChangePermissionSortOrder= (dataIndex: number, value: any, id: string, inputCursorStart: number | null) => {
        var permissions: any[] = this.state.permissions;
        var permission: any = permissions[dataIndex];

        if (value && !Number.isNaN(value) && Number(value) <= 1000000000) {
            permission.sortOrder = Number(value);
        }
        else if(value == undefined || value == ""){
            permission.sortOrder = 0;
        }

        permissions[dataIndex] = permission;
        
        this.setState({...this.state, permissions: permissions, focusedId: id, inputCursorStart: inputCursorStart});
    }

    RemoveFocusedId = () => {
        this.setState({...this.state, focusedId: ''})
    }

    ToggleFilterVisibility = () => {
        this.setState({...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible});
    }

    CloseSuccessModal = () => {
        this.setState({...this.state, serverSuccessMsg: ""});
    };

    CloseErrorModal = () => {
        this.setState({...this.state, serverError: ""});
    };

    render()
    {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "SortOrder";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{width: "100%"}}>
                <Card style={{backgroundColor: "#ededed"}}>
                    <CardHeader title="Event Group Permissions" />
                    <CardContent>
                    <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility}/>
                    <Box style={{ width: '100%' }}>
                        <Paper style={{ width: '100%'}}>
                            <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                style={{marginLeft: "0.5%", width: "99%"}}
                                className={this.state.mobileFiltersVisible ? "table-filters-visible admin-permissions-table" : "table-filters-hidden"}
                            >
                                <TableHead>
                                <TableRow>
                                    {this.state.headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                        className={headCell.id != "codeName" ? "table-header-border show-on-mobile" : "table-header-border no-show-on-mobile"}
                                    >
                                        <TableSortLabel
                                        active={currentSortName === headCell.id }
                                        direction={currentSortName === headCell.id  ? currentSortDir : null}
                                        hideSortIcon={currentSortName !== headCell.id}
                                        onClick={() => this.OnSortChange(headCell.id)}
                                        style={{width: "100%"}}
                                        >
                                        {headCell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                    ))}
                                    <TableCell
                                        className="table-header-border no-show-on-mobile"
                                    >
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                    <TableCell className={headCell.field != "codeName" ? "table-header-border show-on-mobile" : "table-header-border no-show-on-mobile"} >
                                        {headCell.field != "codeName" && <TextField
                                            fullWidth
                                            margin="dense"
                                            size="small"
                                            onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                            type={headCell.field == "sortOrder" ? "number" : "string"}
                                            value={headCell.value}
                                            variant="outlined"
                                            />}
                                    </TableCell>
                                    ))}
                                    <TableCell className="table-header-border no-show-on-mobile">

                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.permissions.map((row: any, index: any) => {
                                    return (
                                        <TableRow
                                        hover
                                        >
                                        <TableCell align="right"><GenericDisplayCell title="Id" value={row.id} /></TableCell>
                                        <TableCell align="right"><GenericDisplayCell title="Code Name" value={row.codeName} /></TableCell>
                                        <TableCell align="right">
                                            <TextFieldCell 
                                                title="Name"
                                                dataIndex={index} 
                                                focusedId={this.state.focusedId}
                                                inputCursorStart={this.state.inputCursorStart}
                                                idPrefix="permission-name-"
                                                value={row.name}
                                                placeHolder="Name"
                                                error={row.nameError}
                                                editMode={this.CheckIfInEditMode(row.id)}
                                                onChange={this.ChangePermissionName}
                                                removeFocusedId={this.RemoveFocusedId}
                                            />    
                                        </TableCell>
                                        <TableCell align="right"> <TextFieldCell 
                                                title="Sort Order"
                                                dataIndex={index} 
                                                focusedId={this.state.focusedId}
                                                inputCursorStart={this.state.inputCursorStart}
                                                idPrefix="permission-sortorder-"
                                                value={row.sortOrder}
                                                placeHolder="Sort Order"
                                                error={row.sortOrderError}
                                                editMode={this.CheckIfInEditMode(row.id)}
                                                onChange={this.ChangePermissionSortOrder}
                                                removeFocusedId={this.RemoveFocusedId}
                                            />
                                        </TableCell>
                                        <TableCell align="center">  <EditDeleteActionCell 
                                                    dataIndex={index} 
                                                    onEditButtonClick={this.OpenPermissionsEditMode} 
                                                    onCancelButtonClick={this.CancelPermissionsEditMode}
                                                    onSaveButtonClick={this.SaveEditedPermission}
                                                    onDeleteButtonClick={this.SaveEditedPermission}
                                                    hideEditButton={false}
                                                    hideDeleteButton={true}
                                                    editMode={this.CheckIfInEditMode(row.id)}
                                            />
                                        </TableCell>
                                        </TableRow>
                                    );
                                    })}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            count={this.state.permissionsTotal}
                            rowsPerPage={this.state.tableDef.take!}
                            page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                            onChangePage={(e, page) => this.PageChange(page)}
                            onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                            />
                        </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <GenericSuccessModal 
                    hidden={this.state.serverSuccessMsg == ""}
                    message={this.state.serverSuccessMsg}
                    closeModal={this.CloseSuccessModal}
                    />
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
            </div>
        )
    }
}