/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { scanUserEventTicket } from '../../../data/UserEventTicketScansRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { QRCodeScanModal } from '../SubComponents/QRCodeScanModal';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

interface IUserEventTicketScanPageProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IUserEventTicketScanPageState {
    eventId: string,
    eventClusterId: string,
    userEventTicketId: string,
    eventName: string,
    eventStartDateTimeLocal: string,
    orderNo: string,
    purchaseDateTimeLocal: string,
    ticketOption: string,
    ticketOptionCodeDescription: string,
    ticketPrice: number,
    firstName: string,
    lastName: string,
    paymentIntentStatus: string,
    userEventTicketScans: any[],
    ticketAlreadyScannedModalVisible: boolean,
    scanSuccessModalVisible: boolean,
    qrCodeScannerVisible: boolean,
    serverError: string
}

export class UserEventTicketScanPage extends React.Component<IUserEventTicketScanPageProps, IUserEventTicketScanPageState> {

    state = {
        eventId: "",
        eventClusterId: "",
        userEventTicketId: "",
        eventName: "",
        eventStartDateTimeLocal: "",
        orderNo: "",
        purchaseDateTimeLocal: "",
        ticketOption: "",
        ticketOptionCodeDescription: "",
        ticketPrice: 0,
        firstName: "",
        lastName: "",
        paymentIntentStatus: "",
        userEventTicketScans: [],
        headCells: [
            {
                id: 'scannerFirstName',
                label: 'Scanner First Name',
            },
            {
                id: 'scannerLastName',
                label: 'Scanner Last Name',
            },
            {
                id: 'scannedDateTimeUTC', 
                label: 'Scan Date/Time'
            }
        ],
        ticketAlreadyScannedModalVisible: false,
        scanSuccessModalVisible: false,
        qrCodeScannerVisible: false,
        serverError: ''
    }

    async componentDidMount() {
        this.props.loadingOn();

        const query = new URLSearchParams(window.location.search);

        var userEventTicketId: string = query.get('id') ?? "";

        await this.ScanUserEventTicket(userEventTicketId);

        this.setState({
            ...this.state,
            userEventTicketId: userEventTicketId
        });

        this.props.loadingOff();
    }

    ScanTicketButtonClick = async () => {
        this.props.loadingOn();

        await this.ScanUserEventTicket(this.state.userEventTicketId, true);

        this.CloseTicketAlreadyScannedModal();

        this.props.loadingOff();
    }

    ScanUserEventTicket = async (userEventTicketId: string, saveScan?: boolean) => {
        let eventId = "";
        let eventClusterId = "";
        let eventName = "";
        let eventStartDateTimeLocal = "";
        let orderNo = "";
        let purchaseDateTimeLocal = "";
        let ticketOption = "";
        let ticketOptionCodeDescription = "";
        let ticketPrice = 0;
        let firstName = "";
        let lastName = "";
        let paymentIntentStatus = "";
        let ticketAlreadyScannedModalVisible = false;
        let scanSuccessModalVisible = false;

        let userEventTicketScans: any = [];

        let serverError = '';

        var response = await scanUserEventTicket(userEventTicketId, saveScan);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=scan-user-event-ticket?id=" + userEventTicketId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else{
                ticketAlreadyScannedModalVisible = saveScan == null && response.data.alreadyScannedFlag;
                scanSuccessModalVisible = response.data.ticketScanSaved;
            }

            if(response.data.eventId && response.data.eventName) {
                eventId = response.data.eventId;
                eventClusterId = response.data.eventClusterId;
                eventName = response.data.eventName;
                eventStartDateTimeLocal = response.data.eventStartDateTimeLocal;
                orderNo = response.data.orderNo;
                purchaseDateTimeLocal = response.data.purchaseDateTimeLocal;
                ticketOption = response.data.ticketOption;
                ticketOptionCodeDescription = response.data.ticketOptionCodeDescription;
                ticketPrice = response.data.ticketPrice;
                firstName = response.data.firstName;
                lastName = response.data.lastName;
                paymentIntentStatus = response.data.paymentIntentStatus;
                userEventTicketScans = response.data.userEventTicketScans;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({
            ...this.state,
            eventId: eventId,
            eventClusterId: eventClusterId,
            eventName: eventName,
            eventStartDateTimeLocal: eventStartDateTimeLocal,
            orderNo: orderNo,
            purchaseDateTimeLocal: purchaseDateTimeLocal,
            ticketOption: ticketOption,
            ticketOptionCodeDescription: ticketOptionCodeDescription,
            ticketPrice: ticketPrice,
            firstName: firstName,
            lastName: lastName,
            paymentIntentStatus: paymentIntentStatus,
            userEventTicketScans: userEventTicketScans,
            scanSuccessModalVisible: scanSuccessModalVisible,
            ticketAlreadyScannedModalVisible: ticketAlreadyScannedModalVisible,
            serverError: serverError
        });
    }

    ConvertUTCToLocalDateTimeString = (date: Date) => {
        var utcDate = moment.utc(date).toDate();
        var local = moment(utcDate).local().format('MM/DD/YYYY hh:mm A');

        return local
    }

    CloseTicketAlreadyScannedModal = () => {
        this.setState({
            ...this.state,
            ticketAlreadyScannedModalVisible: false
        });
    }

    CloseScanSuccessModal = () => {
        this.setState({
            ...this.state,
            scanSuccessModalVisible: false
        });
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: "" });
    };

    ShowQRCodeScanner = () => {
        this.setState({ ...this.state, qrCodeScannerVisible: true });
    }

    CloseQRCodeScanner = () => {
        this.setState({ ...this.state, qrCodeScannerVisible: false });
    }

    ViewEventButtonClick = () => {
        window.open(`/event?id=${this.state.eventId}&eventGroupId=${this.state.eventClusterId}`, '_blank');
    }

    render() {
        return (
            <div style={{ width: "100%" }}>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <div style={{ width: "100%" }}>
                        <table style={{ width: "100%" }}>
                            <tr style={{ width: "100%" }}>
                                <td style={{ width: "50%" }}>
                                    <CardHeader title="Ticket Scans" />
                                </td>
                                <td style={{ width: "50%" }}>
                                    <div className="ticket-scan-page-view-event">
                                        <Button variant="contained" className='btn-primary'
                                            onClick={this.ViewEventButtonClick} >
                                            View Event
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <CardContent>
                        <Button variant="contained" className='btn-primary' style={{ marginBottom: "10px" }}
                            onClick={this.ScanTicketButtonClick} >
                            Scan Again
                        </Button>
                        <Button variant="contained" className='btn-secondary' style={{ marginBottom: "10px", marginLeft: "20px" }}
                            onClick={this.ShowQRCodeScanner} >
                            Scan New
                        </Button>
                    </CardContent>
                    <CardContent style={{ width: "100%" }}>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td><strong>Event:</strong></td>
                                <td>{this.state.eventName}</td>
                            </tr>
                            <tr>
                                <td><strong>Event Start Date/Time:</strong></td>
                                <td>{this.state.eventStartDateTimeLocal}</td>
                            </tr>
                            <tr style={{ width: "90%" }}>
                                <td><strong>Order No:</strong></td>
                                <td style={{ overflowWrap: "break-word" }}>{this.state.orderNo}</td>
                            </tr>
                            <tr>
                                <td><strong>Purchase Date/Time:</strong></td>
                                <td>{this.state.purchaseDateTimeLocal}</td>
                            </tr>
                            <tr>
                                <td><strong>Ticket Option Type:</strong></td>
                                <td>{this.state.ticketOptionCodeDescription}</td>
                            </tr>
                            <tr>
                                <td><strong>Ticket Option:</strong></td>
                                <td>{this.state.ticketOption}</td>
                            </tr>
                            <tr>
                                <td><strong>Ticket Price:</strong></td>
                                <td>
                                    <NumberFormat value={this.state.ticketPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Purchaser Name:</strong></td>
                                <td>{this.state.firstName + " " + this.state.lastName}</td>
                            </tr>
                            <tr>
                                <td><strong>Payment Status:</strong></td>
                                <td>{this.state.paymentIntentStatus}</td>
                            </tr>
                        </table>
                    </CardContent>
                    <CardContent>
                        <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className="event-cluster-invites-table table-filters-hidden"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.userEventTicketScans.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="right"><GenericDisplayCell title="Scanner First Name" value={row.scannerFirstName} /></TableCell>
                                                        <TableCell align="right">
                                                            <GenericDisplayCell title="Scanner Last Name" value={row.scannerLastName} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <GenericDisplayCell title="Scan Date/Time" value={this.ConvertUTCToLocalDateTimeString(row.scannedDateTimeUTC)} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <Dialog
                    onClose={this.CloseScanSuccessModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="genericSuccessModal"
                    open={this.state.scanSuccessModalVisible}
                >
                    <DialogTitle disableTypography className='dialog-title-success'>
                        <Typography variant="h6">Confirmation</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseScanSuccessModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Ticket Scan Successful!
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseScanSuccessModal} className='action-button'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.CloseTicketAlreadyScannedModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="genericErrorModal"
                    open={this.state.ticketAlreadyScannedModalVisible}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Confirmation</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseTicketAlreadyScannedModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        This ticket has already been scanned. Click the "Scan Again" button if you would like to save a new scan to the ticket.
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseTicketAlreadyScannedModal} className='two-action-buttons'>
                            Close
                        </Button>
                        <Button onClick={this.ScanTicketButtonClick} className='two-action-buttons'>
                            Scan Again
                        </Button>
                    </DialogActions>
                </Dialog>
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
                <QRCodeScanModal
                    hidden={!this.state.qrCodeScannerVisible}
                    closeModal={this.CloseQRCodeScanner}
                    changePage={this.props.changePage}
                />
            </div>
        )
    }
}