/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { getEventUserEventTickets } from '../../../data/UserEventTicketRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import { GenericDisplayTrueFalseIconCell } from '../SubComponents/GenericDisplayTrueFalseIconCell';
import { TicketDetailsDialog } from './TicketDetailsDialog';
import {TicketDetailsLinkCell} from './TicketDetailsLinkCell';
import { Button } from '@material-ui/core';
import { QRCodeScanModal } from '../SubComponents/QRCodeScanModal';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DateFilterCell } from '../SubComponents/DateFilterCell';
import { debug } from 'console';
var Enumerable = require('linq');

interface IEventTicketsSectionProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IEventTicketsSectionState {
    eventId: string,
    eventClusterId: string,
    tickets: any[],
    ticketsTotal: number,
    pageable: any,
    tableDef: ITableDef,
    mobileFiltersVisible: boolean,
    purchaserFirstName: string,
    purchaserLastName: string,
    scannerFirstName: string,
    scannerLastName: string,
    scanned: boolean,
    mostRecentScannedDateTimeLocal: string,
    orderNo: string,
    orderBasePrice: number,
    orderCouponCode: string,
    orderCouponCodeDiscountAmount: number,
    orderServiceFee: number,
    orderSalesTax: number,
    orderProcessingFee: number,
    orderTotalPrice: number,
    orderPayoutAmount: number,
    purchaseDateTimeLocal: string,
    ticketOptionCodeDescription: string,
    ticketOption: string,
    ticketPrice: number,
    ticketServiceFee: number,
    ticketModalVisible: boolean,
    qrCodeScannerVisible: boolean,
    ticketOptionFilters: any[],
    serverError: string
}

const getInitTableDef = () =>{
    let sortDef: ISortDef = {
        field: "",
        dir: "desc"
    };

    let filterArray = [
        {
            field: "ticketOption",
            operator: "eq",
            value: ""
        },
        {
            field: "purchaserFirstName",
            operator: "eq",
            value: ""
        },
        {
            field: "purchaserLastName",
            operator: "eq",
            value: ""
        },
        {
            field: "scannerFirstName",
            operator: "eq",
            value: ""
        },
        {
            field: "scannerLastName",
            operator: "eq",
            value: ""
        },
        {
            field: "scanned",
            operator: "eq",
            value: ""
        },
        {
            field: "mostRecentScannedDateTimeLocal",
            operator: "eq",
            value: null
        },
        {
            field: "couponCode",
            operator: "eq",
            value: ""
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class EventTicketScansSection extends React.Component<IEventTicketsSectionProps, IEventTicketsSectionState> {

    state = {
        headCells: [
        {
            id: "userTicketCount",
            label: "User Ticket Count"
        },
        {
            id: "ticketOption",
            label: "Ticket Option"
        },
        {
            id: "purchaserFirstName",
            label: "First Name"
        },
        {
            id: "purchaserLastName",
            label: "Last Name"
        },
        {
            id: "scannerFirstName",
            label: "Scanner First Name"
        },
        {
            id: "scannerLastName",
            label: "Last Name"
        },
        {
            id: "scanned",
            label: "Scanned"
        },
        {
            id: "mostRecentScannedDateTimeLocal",
            label: "Scan Date/Time"
        },
        {
            id: "couponCode",
            label: "Coupon Code"
        }
        ],
        eventId: '',
        eventClusterId: '',
        tickets: [],
        ticketsTotal: 0,
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        mobileFiltersVisible: false,
        purchaserFirstName: '',
        purchaserLastName: '',
        scannerFirstName: '',
        scannerLastName: '',
        scanned: false,
        mostRecentScannedDateTimeLocal: '',
        orderNo: '',
        orderBasePrice: 0,
        orderCouponCode: '',
        orderCouponCodeDiscountAmount: 0,
        orderServiceFee: 0,
        orderSalesTax: 0,
        orderProcessingFee: 0,
        orderTotalPrice: 0,
        orderPayoutAmount: 0,
        purchaseDateTimeLocal: '',
        ticketOptionCodeDescription: '',
        ticketOption: '',
        ticketPrice: 0,
        ticketServiceFee: 0,
        ticketModalVisible: false,
        qrCodeScannerVisible: false,
        ticketOptionFilters: [],
        serverError: ''
    }

    async componentDidMount(){
        this.props.loadingOn();
        const query = new URLSearchParams(window.location.search);

        var eventId: string = query.get('id') ?? "";
        let eventClusterId: string = query.get('eventGroupId') ?? "";

        await this.LoadEventTickets(eventId, eventClusterId, this.state.tableDef);
        this.props.loadingOff();
    }

    LoadEventTickets = async (eventId: any, eventClusterId: any, tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let tickets: any = [];
        let ticketsTotal = 0;
        let ticketOptionFilters: any = [];
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "") || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getEventUserEventTickets(eventId, tempTableDef);

        if(response != null && response.status == 401){
            this.RedirectToLogin(eventClusterId, eventId);
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                tickets = response.data.userEventTickets;
                ticketsTotal = response.data.total;
                ticketOptionFilters = response.data.ticketOptionFilters;
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, 
            eventId: eventId, 
            eventClusterId: eventClusterId, 
            tickets: tickets, 
            ticketsTotal: ticketsTotal, 
            tableDef: tableDef, 
            ticketOptionFilters: ticketOptionFilters, 
            serverError: serverError});
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadEventTickets(this.state.eventId, this.state.eventClusterId, tableDef);
        }

        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        if (filter == "scanned") {
            if (value == "false") {
                filterDef.f.value = false;
            }
            else if (value == "true") {
                filterDef.f.value = true;
            }
            else {
                filterDef.f.value = null;
            }
        }
        else if (filter == "mostRecentScannedDateTimeLocal" && value != null && value != "") {
            filterDef.f.value = value.toLocaleDateString();
        }
        else if (filter == "ticketOption"){
            filterDef.f.value = Enumerable.from(this.state.ticketOptionFilters).firstOrDefault((f: any) => f.id == value);
        }
        else {
            filterDef.f.value = value;
        }

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadEventTickets(this.state.eventId, this.state.eventClusterId, tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadEventTickets(this.state.eventId, this.state.eventClusterId, tableDef);
        this.props.loadingOff();
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadEventTickets(this.state.eventId, this.state.eventClusterId, tableDef);

        this.props.loadingOff();
    }

    RedirectToLogin = (eventClusterId: any, eventId: any) => {
        let eventClusterParam = eventClusterId != null && eventClusterId != "" ? `?eventGroupId=${eventClusterId}` : "";

        if(eventId != '' && eventId != undefined){
            eventClusterParam += `%26id=${eventId}`;
        }

        this.props.changePage("/login?redirect=event" + eventClusterParam);
    }

    ToggleFilterVisibility = () => {
        this.setState({...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible});
    }

    OnTicketDetailsClick = (id: string) => {
        var ticket = Enumerable.from(this.state.tickets).where((t: any) => t.id == id).firstOrDefault();
        
        if(ticket != null){
            this.setState({
                ...this.state,
                purchaserFirstName: ticket.purchaserFirstName,
                purchaserLastName: ticket.purchaserLastName,
                scannerFirstName: ticket.scannerFirstName,
                scannerLastName: ticket.scannerLastName,
                scanned: ticket.scanned,
                mostRecentScannedDateTimeLocal: ticket.mostRecentScannedDateTimeLocal,
                orderNo: ticket.orderNo,
                orderBasePrice: ticket.orderBasePrice,
                orderCouponCode: ticket.couponCode,
                orderCouponCodeDiscountAmount: ticket.orderCouponCodeDiscountAmount,
                orderServiceFee: ticket.orderServiceFee,
                orderSalesTax: ticket.orderSalesTax,
                orderProcessingFee: ticket.orderProcessingFee,
                orderTotalPrice: ticket.orderTotalPrice,
                orderPayoutAmount: ticket.orderPayoutAmount,
                purchaseDateTimeLocal: ticket.purchaseDateTimeLocal,
                ticketOptionCodeDescription: ticket.ticketOptionCodeDescription,
                ticketOption: ticket.ticketOption,
                ticketPrice: ticket.ticketPrice,
                ticketServiceFee: ticket.ticketServiceFee,
                ticketModalVisible: true
            });
        }
    }

    CloseTicketModal =  () => {
        this.setState({
            ...this.state,
            purchaserFirstName: '',
            purchaserLastName: '',
            scannerFirstName: '',
            scannerLastName: '',
            scanned: false,
            mostRecentScannedDateTimeLocal: '',
            orderNo: '',
            orderBasePrice: 0,
            orderCouponCode: '',
            orderCouponCodeDiscountAmount: 0,
            orderServiceFee: 0,
            orderSalesTax: 0,
            orderProcessingFee: 0,
            orderTotalPrice: 0,
            orderPayoutAmount: 0,
            purchaseDateTimeLocal: '',
            ticketOptionCodeDescription: '',
            ticketOption: '',
            ticketPrice: 0,
            ticketServiceFee: 0,
            ticketModalVisible: false
        });
    }

    ShowQRCodeScanner = () => {
        this.setState({...this.state, qrCodeScannerVisible: true});
    }

    CloseQRCodeScanner = () => {
        this.setState({...this.state, qrCodeScannerVisible: false});
    }

    CloseErrorModal = () => {
        this.setState({...this.state, serverError: ''});
    }

    render()
    {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{width: "100%"}}>
                <Card style={{backgroundColor: "#ededed"}}>
                    <CardHeader title="Tickets Sold" />
                    <CardContent>
                        <Button variant="contained" className='btn-primary' style={{marginBottom: "10px", marginLeft: "20px"}}
                            onClick={this.ShowQRCodeScanner} >
                            Scan
                        </Button>  
                    </CardContent>
                    <CardContent>
                    <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility}/>
                    <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className={this.state.mobileFiltersVisible ? "table-filters-visible event-ticket-scans-table" : "table-filters-hidden"}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                        className={headCell.id != "userTicketCount" ? "table-header-border show-on-mobile" : "table-header-border no-show-on-mobile"}
                                                    >
                                                        {(headCell.id != "userTicketCount" && headCell.id != "ticketOption") ? <TableSortLabel
                                                            active={currentSortName === headCell.id}
                                                            direction={currentSortName === headCell.id ? currentSortDir : null}
                                                            hideSortIcon={currentSortName !== headCell.id}
                                                            onClick={() => this.OnSortChange(headCell.id)}
                                                            style={{ width: "100%" }}
                                                        >
                                                            {headCell.label}
                                                        </TableSortLabel>
                                                        : <div>{headCell.label} </div>   
                                                    }

                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="table-header-border no-show-on-mobile" >

                                                </TableCell>
                                                {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                                    <TableCell className="table-header-border show-on-mobile" >
                                                        {headCell.field != "ticketOption" 
                                                            && headCell.field != "scanned"
                                                            && headCell.field != "mostRecentScannedDateTimeLocal" &&
                                                            <TextField
                                                                fullWidth
                                                                margin="dense"
                                                                size="small"
                                                                onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                                value={headCell.value}
                                                                variant="outlined"
                                                            />
                                                        }
                                                        {headCell.field == "ticketOption" &&
                                                            <FormControl variant="filled" size="small" fullWidth>
                                                                <Select
                                                                    value={headCell.id}
                                                                    label="Ticket Option"
                                                                    onChange={(e) => this.OnFilterChange(headCell.field, e.target.value)}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    style={{ marginTop: "5px" }}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">(All)</MenuItem>
                                                                    {this.state.ticketOptionFilters.map((f: any) => (
                                                                    <MenuItem value={f.id}>{f.name}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                        {headCell.field == "scanned" &&
                                                            <FormControl variant="filled" size="small" fullWidth>
                                                                <Select
                                                                    value={headCell.value}
                                                                    onChange={(e) => this.OnFilterChange(headCell.field, e.target.value)}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    style={{ marginTop: "5px" }}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">(All)</MenuItem>
                                                                    <MenuItem value="false">False</MenuItem>
                                                                    <MenuItem value="true">True</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                        {headCell.field == "mostRecentScannedDateTimeLocal" &&
                                                            <DateFilterCell value={headCell.value} field={headCell.field} onChange={this.OnFilterChange} />
                                                        }
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.tickets.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                    >
                                                        <TableCell align="right"><TicketDetailsLinkCell id={row.id} title="User Ticket Count" value={row.indexOfTotal + " of " + row.total} onClick={this.OnTicketDetailsClick} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Ticket Option" value={row.ticketOption} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="First Name" value={row.purchaserFirstName} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Last Name" value={row.purchaserLastName} /></TableCell>
                                                        <TableCell align="right"> <GenericDisplayCell title="Scanner First Name" value={row.scannerFirstName} /></TableCell>
                                                        <TableCell align="right"> <GenericDisplayCell title="Scanner Last Name" value={String(row.scannerLastName)} /></TableCell>
                                                        <TableCell align="right"> <GenericDisplayTrueFalseIconCell title="Scanned" isTrue={row.scanned} /></TableCell>
                                                        <TableCell align="right"> <GenericDisplayCell title="Scan Date/Time" value={row.mostRecentScannedDateTimeLocal} /></TableCell>
                                                        <TableCell align="right"> <GenericDisplayCell title="Coupon" value={row.couponCode} /></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={this.state.ticketsTotal}
                                    rowsPerPage={this.state.tableDef.take!}
                                    page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                    onChangePage={(e, page) => this.PageChange(page)}
                                    onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                                />
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <TicketDetailsDialog 
                    purchaserFirstName={this.state.purchaserFirstName}
                    purchaserLastName={this.state.purchaserLastName}
                    scannerFirstName={this.state.scannerFirstName}
                    scannerLastName={this.state.scannerLastName}
                    scanned={this.state.scanned}
                    mostRecentScannedDateTimeLocal={this.state.mostRecentScannedDateTimeLocal}
                    orderNo={this.state.orderNo}
                    orderBasePrice={this.state.orderBasePrice}
                    orderCouponCode={this.state.orderCouponCode}
                    orderCouponCodeDiscountAmount={this.state.orderCouponCodeDiscountAmount}
                    orderServiceFee={this.state.orderServiceFee}
                    orderSalesTax={this.state.orderSalesTax}
                    orderProcessingFee={this.state.orderProcessingFee}
                    orderTotalPrice={this.state.orderTotalPrice}
                    orderPayoutAmount={this.state.orderPayoutAmount}
                    purchaseDateTimeLocal={this.state.purchaseDateTimeLocal}
                    ticketOptionCodeDescription={this.state.ticketOptionCodeDescription}
                    ticketOption={this.state.ticketOption}
                    ticketPrice={this.state.ticketPrice}
                    ticketServiceFee={this.state.ticketServiceFee}
                    visible={this.state.ticketModalVisible}
                    onCloseDialog={this.CloseTicketModal}
                />
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />   
                <QRCodeScanModal 
                    hidden={!this.state.qrCodeScannerVisible} 
                    closeModal={this.CloseQRCodeScanner}
                    changePage={this.props.changePage}
                />
                
            </div>
        )
    }
}