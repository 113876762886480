/// <reference path="../../../data/DataModels/types.d.ts" />
import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { TicketOptionSelectionSection } from './TicketOptionSelectionSection';
import { TicketPurchaseCheckoutSection } from './TicketPurchaseCheckoutSection';
import { getPurchaseTicketOptions } from '../../../data/TicketOptionRepository';
import { getEventPublicDetails } from '../../../data/EventRepository';
import { previewUserEventTicketsOrder } from '../../../data/UserEventTicketRepository';
import { EventFileTypeCode } from "../../../data/Models/EventFileTypeCode";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import logo from '../../../Images/logo.png';
import { OrderCountDownTimerV2 } from './OrderCountDownTimerV2';
import validator from 'email-validator';
import { validPhoneNumber } from '../../Shared/ValidationFunctions';
import ReactMarkdown from 'react-markdown';
import { Backdrop, CircularProgress, Link } from '@material-ui/core';

var Enumerable = require('linq');

interface ITicketPurchasePageProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}


export class TicketPurchasePage extends React.Component<ITicketPurchasePageProps> {

    state = {
        eventId: '',
        eventName: '',
        venueName: '',
        address: '',
        description: '',
        eventStartDateTime: new Date(),
        eventAgeRestriction: '',
        eventImageUrl: '',
        gaTicketOptions: [],
        orderId: "",
        orderNo: "",
        basePriceTotal: 0,
        serviceFeeTotal: 0,
        salesTaxTotal: 0,
        processingFeeTotal: 0,
        couponCodeDiscountAmount: 0,
        totalPrice: 0,
        paymentIntentClientSecret: "",
        orderExpirationDateTime: new Date(),
        showCountdownTimer: false,
        pageSectionIndex: 0,
        eventDetailsError: '',
        ticketOptionsError: '',
        email: "",
        emailError: "",
        emailVerify: "",
        emailVerifyError: "",
        firstName: "",
        firstNameError: "",
        lastName: "",
        lastNameError: "",
        phoneNumber: "",
        phoneNumberError: "",
        couponCode: "",
        loading: true,
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search.toLowerCase());

        var eventId: string = query.get('eventid')?? query.get('id') ?? "";

        this.setState({
            ...this.state,
            eventId: eventId
        });

        await this.LoadEventDetails(eventId);
        await this.LoadTicketOptions(eventId);

        this.setState({
            ...this.state,
            loading: false
        });
    }

    LoadEventDetails = async (eventId: string) => {

        var eventName = '';
        var eventStartDateTime = new Date();
        var eventAgeRestriction = '';
        var venueName = '';
        var address = '';
        var description = '';
        var eventImageUrl = '';
        var serverError = '';

        var response = await getEventPublicDetails(eventId);

        if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                eventName = response.data.event.name;
                eventAgeRestriction = response.data.event.ageRestriction;
                venueName = response.data.event.venueName;
                address = response.data.event.address;
                description = response.data.event.description;
                eventStartDateTime = new Date(response.data.event.startDateTimeLocal);

                eventImageUrl = Enumerable.from(response.data.event.eventImageFiles).where((f: any) => f.eventFileTypeCode == EventFileTypeCode.EventImage).firstOrDefault()?.fileUrl;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            eventName: eventName,
            eventAgeRestriction: eventAgeRestriction,
            venueName: venueName,
            address: address,
            description: description,
            eventStartDateTime: eventStartDateTime,
            eventImageUrl: eventImageUrl,
            eventDetailsError: serverError
        });
    }

    LoadTicketOptions = async (eventId: string) => {

        let gaTicketOptions: any[] = this.state.gaTicketOptions;
        let serverError = '';

        let currentOrderEventId = localStorage.getItem("orderEventId");

        let orderId: string | null = "";

        if (currentOrderEventId == this.state.eventId) {
            orderId = localStorage.getItem("orderId");
        }

        var response = await getPurchaseTicketOptions(eventId, orderId);

        if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {

                gaTicketOptions = Enumerable.from(response.data.generalAdmissionTicketOptions).select((o: any) =>
                ({
                    id: o.id,
                    qtySelected: 0,
                    name: o.name,
                    price: o.price,
                    qty: o.qty,
                    saleStartsDateTime: o.saleStartsDateTime != null ? new Date(o.saleStartsDateTime): null,
                    saleStartsDateTimeUTC: o.saleStartsDateTimeUTC != null ? Moment.utc(o.saleStartsDateTimeUTC): null,
                    saleEndsDateTime: o.saleEndsDateTime != null ? new Date(o.saleEndsDateTime): null,
                    saleEndsDateTimeUTC: o.saleEndsDateTimeUTC != null ? Moment.utc(o.saleEndsDateTimeUTC): null,
                }))
                    .toArray();
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({
            ...this.state,
            gaTicketOptions: gaTicketOptions,
            ticketOptionsError: serverError
        });
    }

    UpdateGATicketOptions = (qtySelected: number, ticketOptionId: any) => {

        let gaTicketOptions = Enumerable.from(this.state.gaTicketOptions).select((o: any) =>
        ({
            id: o.id,
            qtySelected: ticketOptionId == o.id ? qtySelected : o.qtySelected,
            name: o.name,
            price: o.price,
            qty: o.qty,
            saleStartsDateTime: o.saleStartsDateTime,
            saleStartsDateTimeUTC: o.saleStartsDateTimeUTC,
            saleEndsDateTime: o.saleEndsDateTime,
            saleEndsDateTimeUTC: o.saleEndsDateTimeUTC,
        }))
            .toArray();

        this.setState({
            ...this.state,
            gaTicketOptions: gaTicketOptions
        });
    }

    CheckoutButtonClick = async () => {
        this.props.loadingOn();
        var generalAdmissionTickets: ITickets[] = Enumerable.from(this.state.gaTicketOptions).where((o: any) => o.qtySelected > 0)
            .select((o: any) => ({
                id: o.id,
                qty: o.qtySelected
            }))
            .toArray();

        let emailError = "";
        let emailVerifyError = "";
        let firstNameError = "";
        let lastNameError = "";
        let ticketOptionsError = "";
        let phoneNumberError = "";

        let errored = false;

        let email = this.state.email?.trim() ?? '';
        let emailVerify = this.state.emailVerify?.trim() ?? '';

        if (email == undefined || email.length == 0) {
            emailError = "Email required.";
            errored = true;
        }
        else if (validator.validate(email) == false) {
            emailError = `"${email}" is not a valid email address.`;
            errored = true;
        }

        if (emailVerify == undefined || emailVerify.length == 0) {
            emailVerifyError = "Email Verification required.";
            errored = true;
        }
        else if (emailVerify != email) {
            emailVerifyError = `Email verification must match email.`;
            errored = true;
        }
        else if (validator.validate(emailVerify) == false) {
            emailVerifyError = `"$emailVerify}" is not a valid email address.`;
            errored = true;
        }

        if (this.state.firstName == undefined || this.state.firstName.length == 0) {
            firstNameError = "First Name required.";
            errored = true;
        }

        if (this.state.lastName == undefined || this.state.lastName.length == 0) {
            lastNameError = "Last Name required.";
            errored = true;
        }

        if (this.state.phoneNumber == undefined || this.state.phoneNumber.length == 0) {
            phoneNumberError = "Phone Number required.";
            errored = true;
        }

        if (this.state.phoneNumber.length > 0 && validPhoneNumber(this.state.phoneNumber) == false) {
            phoneNumberError = `"${this.state.phoneNumber}" is not a valid 10 digit phone number.`;
            errored = true;
        }

        if (generalAdmissionTickets.length == 0) {
            ticketOptionsError = "You must selected at least one ticket in order to checkout";
            errored = true;
        }

        if (errored) {
            this.setState({
                ...this.state,
                emailError: emailError,
                emailVerifyError: emailVerifyError,
                firstNameError: firstNameError,
                lastNameError: lastNameError,
                ticketOptionsError: ticketOptionsError,
                phoneNumberError: phoneNumberError,
            });

            this.props.loadingOff();
            return;
        }

        if (generalAdmissionTickets.length > 0) {

            var storedEventId = localStorage.getItem("orderEventId");
            let orderId: string | null = null;

            if (this.state.eventId == storedEventId) {
                orderId = localStorage.getItem("orderId");
            }

            let request: IPreviewUserEventTicketOrderRequest = {
                orderId: orderId,
                email: email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                couponCode: this.state.couponCode,
                generalAdmissionTickets: generalAdmissionTickets
            };

            let orderNo = "";
            let basePriceTotal = 0;
            let couponCodeDiscountAmount = 0;
            let serviceFeeTotal = 0;
            let salesTaxTotal = 0;
            let processingFeeTotal = 0;
            let totalPrice = 0;
            let paymentIntentClientSecret = "";
            let orderExpirationDateTime = new Date();
            let showCountdownTimer = false;
            let serverError = "";
            let pageSectionIndex = 0;

            this.HideCountDownTimer();

            var response = await previewUserEventTicketsOrder(request);

            if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = response.data.userVisibleError;
                }
                else {
                    orderId = response.data.orderId;
                    orderNo = response.data.orderNo;
                    basePriceTotal = response.data.basePriceTotal;
                    couponCodeDiscountAmount = response.data.couponCodeDiscountAmount;
                    serviceFeeTotal = response.data.serviceFeeTotal;
                    salesTaxTotal = response.data.salesTaxTotal;
                    processingFeeTotal = response.data.processingFeeTotal;
                    totalPrice = response.data.totalPrice;
                    paymentIntentClientSecret = response.data.paymentIntentClientSecret;
                    orderExpirationDateTime = response.data.deletePendingOrderDateTimeUTC;
                    showCountdownTimer = true;
                    pageSectionIndex = 1;

                    localStorage.setItem("orderId", response.data.orderId);
                    localStorage.setItem("orderEventId", this.state.eventId);
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }

            this.setState({
                ...this.state,
                orderId: orderId,
                orderNo: orderNo,
                basePriceTotal: basePriceTotal,
                couponCodeDiscountAmount: couponCodeDiscountAmount,
                serviceFeeTotal: serviceFeeTotal,
                salesTaxTotal: salesTaxTotal,
                processingFeeTotal: processingFeeTotal,
                totalPrice: totalPrice,
                paymentIntentClientSecret: paymentIntentClientSecret,
                orderExpirationDateTime: orderExpirationDateTime,
                showCountdownTimer: showCountdownTimer,
                pageSectionIndex: pageSectionIndex,
                emailError: emailError,
                emailVerifyError: emailVerifyError,
                firstNameError: firstNameError,
                lastNameError: lastNameError,
                phoneNumberError: phoneNumberError,
                ticketOptionsError: serverError
            });

            this.props.loadingOff();
        }
    }

    HideCountDownTimer = () => {
        this.setState({
            ...this.state,
            showCountdownTimer: false
        });
    }

    CheckoutBackButtonClick = () => {
        this.props.loadingOn();

        this.LoadTicketOptions(this.state.eventId);

        this.setState({
            showCountdownTimer: false,
            pageSectionIndex: 0
        });

        this.props.loadingOff();
    }

    HandleUserInfoInputChange = (value: string, fieldName: string) => {
        switch (fieldName) {
            case "email":
                this.setState({ ...this.state, email: value });
                break;
            case "emailVerify":
                this.setState({ ...this.state, emailVerify: value });
                break;
            case "firstName":
                this.setState({ ...this.state, firstName: value });
                break;
            case "lastName":
                this.setState({ ...this.state, lastName: value });
                break;
            case "phoneNumber":
                this.setState({ ...this.state, phoneNumber: value });
                break;
            case "couponCode":
                this.setState({ ...this.state, couponCode: value });
                break;
        }
    }

    render() {
        return (
            <div style={{ width: "100%", fontFamily: "sans-serif" }}>
                <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="ticketPurchasePage">
                    <Card style={{ width: "100%", height: "70px", backgroundColor: "#ededed", color: "white", marginBottom: "10px" }}>
                        <CardContent style={{ height: "70px", padding: "0px", textAlign: "center" }}>
                            <span style={{ height: "70px", padding: "0px 0px 0px 0px" }}>
                                <Button style={{ backgroundColor: 'transparent', padding: "0px" }} >
                                    <img src={logo} style={{ maxHeight: '55px', width: 'auto', objectFit: 'contain', padding: "10px 10px 5px 10px" }} />
                                </Button>
                            </span>
                        </CardContent>
                    </Card>
                    {!this.state.loading &&
                        <Card style={{ width: "100%", backgroundColor: "#ededed", marginBottom: "10px" }}>
                            <CardContent>
                                <table style={{ width: "100%" }}>
                                    <tr style={{ width: "100%" }}>
                                        {this.state.eventImageUrl != undefined && this.state.eventImageUrl != "" && <td style={{ width: "15%", paddingRight: "20px" }}><img className="ticketPurchasePageImage" src={this.state.eventImageUrl} /></td>}
                                        <td style={{ width: "85%", verticalAlign: "top" }}>
                                            <div style={{ fontSize: "25px", paddingBottom: "10px" }}>
                                                {this.state.eventName}
                                            </div>
                                            <div style={{ paddingBottom: "5px" }}>
                                                {Moment(this.state.eventStartDateTime).format("MM/DD/yyyy") + " @ " + Moment(this.state.eventStartDateTime).format("h:mm A")}
                                            </div>
                                            <div style={{ paddingBottom: "5px" }} >
                                                {this.state.venueName}
                                            </div>
                                            {this.state.address && <div style={{ paddingBottom: "5px" }} >
                                                {this.state.address}
                                            </div>
                                            }
                                            <div style={{ paddingBottom: "5px" }} >
                                                Age restriction: {this.state.eventAgeRestriction}
                                            </div>
                                            {this.state.description && <div style={{ paddingBottom: "5px" }} >
                                                <hr />
                                                <div dangerouslySetInnerHTML={{ __html: this.state.description }} >
                                                </div>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </CardContent>
                        </Card>
                    }
                    <div style={{ width: "100%" }}>
                        {this.state.showCountdownTimer &&
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <OrderCountDownTimerV2 expiratationDateTime={this.state.orderExpirationDateTime} />
                            </div>
                        }
                    </div>
                    {this.state.pageSectionIndex == 0 && <TicketOptionSelectionSection
                        gaTicketOptions={this.state.gaTicketOptions}
                        email={this.state.email}
                        emailError={this.state.emailError}
                        emailVerify={this.state.emailVerify}
                        emailVerifyError={this.state.emailVerifyError}
                        firstName={this.state.firstName}
                        firstNameError={this.state.firstNameError}
                        lastName={this.state.lastName}
                        lastNameError={this.state.lastNameError}
                        phoneNumber={this.state.phoneNumber}
                        phoneNumberError={this.state.phoneNumberError}
                        couponCode={this.state.couponCode}
                        updateGATicketOptions={this.UpdateGATicketOptions}
                        checkoutButtonClick={this.CheckoutButtonClick}
                        handleUserInfoInputChange={this.HandleUserInfoInputChange}
                    />}
                    {this.state.pageSectionIndex == 1 && <TicketPurchaseCheckoutSection
                        gaTicketOptions={this.state.gaTicketOptions}
                        orderId={this.state.orderId}
                        orderNo={this.state.orderNo}
                        basePriceTotal={this.state.basePriceTotal}
                        serviceFeeTotal={this.state.serviceFeeTotal}
                        salesTaxTotal={this.state.salesTaxTotal}
                        processingFeeTotal={this.state.processingFeeTotal}
                        couponCodeDiscountAmount={this.state.couponCodeDiscountAmount}
                        totalPrice={this.state.totalPrice}
                        paymentIntentClientSecret={this.state.paymentIntentClientSecret}
                        orderExpirationDateTime={this.state.orderExpirationDateTime}
                        email={this.state.email}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        phoneNumber={this.state.phoneNumber}
                        couponCode={this.state.couponCode}
                        backButtonClick={this.CheckoutBackButtonClick}
                    />}
                    <div style={{ paddingTop: "10px", color: "red" }}>
                        {(this.state.eventDetailsError != undefined || this.state.eventDetailsError != "") &&
                            <div>
                                {this.state.eventDetailsError}
                            </div>
                        }
                        {(this.state.ticketOptionsError != undefined || this.state.ticketOptionsError != "") &&
                            <div>
                                {this.state.ticketOptionsError}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
