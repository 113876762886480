import * as React from 'react';
import TextField from '@material-ui/core/TextField';

interface IKeywordDisplayCellProps{
    keywords: [],
    title: string
}

export class KeywordDisplayCell extends React.Component<IKeywordDisplayCellProps> {

    render() {
        return (
            <td>
                <div className="genericDisplayCellTitle">
                    {this.props.title}:
                </div>
                <div className="genericDisplayCellContent">
                    {
                        this.props.keywords.map((k: any, index: number) => {
                            var textColor = k.isMatch ? "limegreen" : "black";

                            var includeComma = index != 0;
                            return(<span>
                                        {includeComma ? ", " : ""}
                                        <span style={{color: textColor}}>{k.keyword}</span>
                                    </span>);
                        }) 
                    }
                </div>
            </td>
        );
    }
}