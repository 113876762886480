import * as React from 'react';
import TextField from '@material-ui/core/TextField';

interface IEmailAddressCellProps{
    dataItem: any,
    dataIndex: number,
    focusedId: string,
    onChange: (dataIndex: number, value: any, id: string) => void;
    removeFocusedId: () => void
}

export class EmailAddressCell extends React.Component<IEmailAddressCellProps> {

    HandleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        this.props.onChange(
            this.props.dataIndex,
            event.target.value,
            event.target.id
        );
    }


    render() {
        return (
            <td>
                <TextField 
                    error={this.props.dataItem.emailError !== ""}
                    id={'emailInput-' + this.props.dataIndex}
                    placeholder="Email Address"
                    fullWidth
                    margin="normal"
                    helperText={this.props.dataItem.emailError}
                    FormHelperTextProps={{ color: "red" }}
                    value={this.props.dataItem.email}
                    onChange={(e) => this.HandleEmailChange(e)}
                    autoFocus={this.props.focusedId == ("emailInput-" + this.props.dataIndex)}
                    onBlur={this.props.removeFocusedId}
                    />
            </td>
        );
    }
}