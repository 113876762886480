/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export function OrderCountDownTimerV2(props: any) {
    
    const minuteSeconds = 60;
    const hourSeconds = 3600;

    const timerProps = {
        isPlaying: true,
        size: 80,
        strokeWidth: 4
    };

    const [, setOneLastRerender] = React.useState(0);

    const renderTime = (dimension: any, time: any) => {

        var newDateTime = new Date(props.expiratationDateTime);

        var expiratationTime = Date.parse(newDateTime.toString());

        var timeDiff = (expiratationTime - Date.now());

        // force one last re-render when the time is over to tirgger the last animation
        if (timeDiff <= 0) {
        setTimeout(() => {
            setOneLastRerender(val => val + 1);
        }, 20);
        }

        return (
            <div className="time-wrapper">
                <div className="time">{time}</div>
                <div>{dimension}</div>
            </div>);
    };
    
    const getTimeSeconds = (time: any) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time: any) => ((time % hourSeconds) / minuteSeconds) | 0;
    
    var newDateTime = new Date(props.expiratationDateTime);

    var expiratationTime = Date.parse(newDateTime.toString());

    var timeDiff = (expiratationTime - Date.now());

    const remainingTime = timeDiff / 1000;
    return (
        <div>
            {remainingTime > 0 ? <div>
                <div>
                    Countdown to order expiration:
                </div>
                <div className="countdownTimerContainer">
                    <div style={{float: "right", marginRight: "5px"}}>
                        <CountdownCircleTimer
                            {...timerProps}
                            colors="#e02d75"
                            duration={hourSeconds}
                            initialRemainingTime={remainingTime % hourSeconds}
                            onComplete={() => {
                                // do your stuff here
                                return [true, 500] // repeat animation in 1.5 seconds
                            }}
                            >
                            {({ elapsedTime }) =>
                                renderTime("minutes", getTimeMinutes(hourSeconds - (elapsedTime ?? 0)))
                            }
                            </CountdownCircleTimer>
                        </div>
                        <div style={{float: "left", marginLeft: "5px"}}>
                            <CountdownCircleTimer
                            {...timerProps}
                            colors="#ec563b"
                            duration={minuteSeconds}
                            initialRemainingTime={remainingTime % minuteSeconds}
                            onComplete={() => {
                                // do your stuff here
                                return [true, 500] // repeat animation in 1.5 seconds
                            }}
                            >
                            {({ elapsedTime }) =>
                                renderTime("seconds", getTimeSeconds(elapsedTime))
                            }
                            </CountdownCircleTimer>
                        </div>
                    </div>
                </div> : 
                <div >
                    Your order has expired. Please try again.
                </div>
                }
            </div>
    )
  }