import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const getSimilarEvents = async(eventId: string, startDateRange: Date | undefined, finishDateRange: Date | undefined, filterByKeyword: string, latitude: any = null, longitude: any = null, startDateTime: any = null, keywords: any[] = [], dateRange: any = 2): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    var query = '';
    if (startDateRange != undefined)
        query += `&startDateRange=${startDateRange.toLocaleDateString()}`;
    if (finishDateRange != undefined)
        query += `&finishDateRange=${finishDateRange.toLocaleDateString()}`;

    if(latitude != undefined && latitude != ""){
        query += `&latitude=${latitude}`;
    }

    if(longitude != undefined && longitude != ""){
        query += `&longitude=${longitude}`;
    }

    if(startDateTime != undefined && startDateTime != ""){
        query += `&startDateTime=${startDateTime}`;
    }

    if(keywords != undefined && keywords.length > 0){
        var keywordsString = keywords.join(",");
        query += `&keywords=${encodeURIComponent(keywordsString)}`;
    }

    if(dateRange != undefined){
        query += `&dateRange=${dateRange}`;
    }

    return await axios.get(`${baseAPIUrl}/api/analytics/similar-events?eventId=${eventId}${query}&keyword=${filterByKeyword}`, tokenConfig);
}

