/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import '../../Styles/EventTicketingFormSection.css';
import { EditDeleteActionCell } from '../SubComponents/EditDeleteActionCell'
import { PricingAdjustmentFieldsContainer } from './PricingAdjustmentFieldsContainer';
import Moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CheckboxGridCell } from '../SubComponents/CheckboxGridCell';

var Enumerable = require('linq');

interface IAddEditPricingAdjustmentDialogProps {
    open: boolean,
    currentIndex: any,
    pricingAdjustments: any[],
    pricingAdjustmentAmountCodes: any[],
    pricingAdjustmentCodes: any[],
    ticketOptions: any[],
    finishEventDateTime: Date, 
    closePricingAdjustmentModal: (updatePendingChanges: boolean) => void,
    updateTicketOptions: (ticketOptions: any[]) => void,
    updatePricingAdjustments: (pricingAdjustments: any[]) => void
}

export class AddEditPricingAdjustmentDialog extends React.Component<IAddEditPricingAdjustmentDialogProps> {

    state = {
        deleteAdjustmentModalOpen: false,
        pricingAdjustmentName: '',
        pricingAdjustmentNameError: '',
        pricingAdjustmentAmount: 0,
        pricingAdjustmentAmountError: '',
        pricingAdjustmentAmountCode: 0,
        pricingAdjustmentAmountCodeError: '',
        pricingAdjustmentCode: 1,
        pricingAdjustmentCodeError: '',
        pricingAdjustmentStartDate: new Date(),
        pricingAdjustmentStartDateError: '',
        pricingAdjustmentEndDate: new Date(),
        pricingAdjustmentEndDateError: '',
        pricingAdjustmentCoupon: '',
        pricingAdjustmentCouponError: ''
    }

    componentDidUpdate(prevProps: any) {
        if(this.props.open == false) return;
        if(this.props.currentIndex === -1 && this.state.pricingAdjustmentEndDate !== this.props.finishEventDateTime){
            this.setState({...this.state,
                pricingAdjustmentEndDate: this.props.finishEventDateTime
            });
        }
        else if (this.props.currentIndex !== prevProps.currentIndex && this.props.currentIndex >= 0) {
            let pricingAdjustment = this.props.pricingAdjustments[this.props.currentIndex];

            this.setState({...this.state,
                pricingAdjustmentName: pricingAdjustment.name,
                pricingAdjustmentAmount: pricingAdjustment.discountAmount,
                pricingAdjustmentAmountCode: pricingAdjustment.discountAmountCode,
                pricingAdjustmentCode: pricingAdjustment.pricingAdjustmentCode,
                pricingAdjustmentStartDate: pricingAdjustment.startDateTime,
                pricingAdjustmentEndDate: pricingAdjustment.endDateTime,
                pricingAdjustmentCoupon: pricingAdjustment.coupon
            });
        }
      }

    CloseModal = (updatePendingChanges: boolean) => {
        this.props.closePricingAdjustmentModal(updatePendingChanges);

        this.setState({
            ...this.state,
            pricingAdjustmentName: '',
            pricingAdjustmentNameError: '',
            pricingAdjustmentAmount: 0,
            pricingAdjustmentAmountError: '',
            pricingAdjustmentAmountCode: 0,
            pricingAdjustmentAmountCodeError: '',
            pricingAdjustmentCode: 1,
            pricingAdjustmentCodeError: '',
            pricingAdjustmentStartDate: new Date(),
            pricingAdjustmentStartDateError: '',
            pricingAdjustmentEndDate: new Date(),
            pricingAdjustmentEndDateError: '',
            pricingAdjustmentCoupon: '',
            pricingAdjustmentCouponError: ''
        });
    }

    HandlePricingAdjustmentNameChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentName: value });
    }

    HandlePricingAdjustmentAmountChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentAmount: value });
    }

    HandlePricingAdjustmentCodeChange = (value: any, pricingAdjustmentCoupon: any) => {
        this.setState({ ...this.state, pricingAdjustmentCode: value, pricingAdjustmentCoupon: pricingAdjustmentCoupon });
    }

    HandleAdjustmentStartDateChange = (date: Date | null) => {
        this.setState({ ...this.state, pricingAdjustmentStartDate: date });
    }

    HandleAdjustmentEndDateChange = (date: Date | null) => {
        this.setState({ ...this.state, pricingAdjustmentEndDate: date });
    }

    HandlePricingAdjustmentCouponChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentCoupon: value });
    }

    HandlePricingAdjustmentAmountCodeChange = (value: any) => {
        this.setState({ ...this.state, pricingAdjustmentAmountCode: value, pricingAdjustmentAmount: 0 });
    }

    OnAddEditAdjustmentClick = () => {

        let pricingAdjustments: any[] = this.props.pricingAdjustments;

        let errored = false;
        let pricingAdjustmentNameError = '';
        let pricingAdjustmentAmountError = '';
        let pricingAdjustmentStartDateError = '';
        let pricingAdjustmentEndDateError = '';
        let pricingAdjustmentCouponError = '';
        let selectedTicketOptionsError = '';

        if (this.state.pricingAdjustmentName == undefined || this.state.pricingAdjustmentName == '') {
            pricingAdjustmentNameError = "Pricing adjustment name required.";
            errored = true;
        }

        if (this.state.pricingAdjustmentAmount == undefined) {
            pricingAdjustmentAmountError = "Pricing adjustment amount required.";
            errored = true;
        }

        if (this.state.pricingAdjustmentAmount == 0) {
            pricingAdjustmentAmountError = "Pricing adjustment amount must be greater than 0.";
            errored = true;
        }

        if (this.state.pricingAdjustmentCode == 1 && (this.state.pricingAdjustmentCoupon == undefined || this.state.pricingAdjustmentCoupon == '')) {
            pricingAdjustmentCouponError = "Pricing adjustment code required.";
            errored = true;
        }

        if (this.state.pricingAdjustmentStartDate == undefined) {
            pricingAdjustmentStartDateError = "Pricing adjustment start date required.";
            errored = true;
        }

        if (this.state.pricingAdjustmentStartDate == undefined) {
            pricingAdjustmentEndDateError = "Pricing adjustment end date required.";
            errored = true;
        }

        let ticketOptions: any[] = this.props.ticketOptions;
        
        var selectedTicketOptions = Enumerable.from(ticketOptions).where((o: any) => o.selected == true).toArray();

        if (selectedTicketOptions.length == 0) {
            selectedTicketOptionsError = "Please select at least one ticket option.";
            errored = true;
        }

        var ticketPricesExceeded = Enumerable.from(selectedTicketOptions).where((o: any) => this.state.pricingAdjustmentAmountCode == 0 && this.state.pricingAdjustmentAmount > o.price).any();

        if(ticketPricesExceeded){
            pricingAdjustmentAmountError = "Pricing adjustment amount exceeds at least one of the selected ticket prices.";
            errored = true;
        }

        var percentExceeded = Enumerable.from(selectedTicketOptions).where((o: any) => this.state.pricingAdjustmentAmountCode == 1 && this.state.pricingAdjustmentAmount > 100).any();

        if(percentExceeded){
            pricingAdjustmentAmountError = "Pricing adjustment discount amount cannot exceed 100%.";
            errored = true;
        }

        if (errored) {
            this.setState({
                ...this.state,
                pricingAdjustmentNameError: pricingAdjustmentNameError,
                pricingAdjustmentAmountError: pricingAdjustmentAmountError,
                pricingAdjustmentStartDateError: pricingAdjustmentStartDateError,
                pricingAdjustmentEndDateError: pricingAdjustmentEndDateError,
                pricingAdjustmentCouponError: pricingAdjustmentCouponError,
                selectedTicketOptionsError: selectedTicketOptionsError
            });
        }
        else {

            var startDateTimeString = this.state.pricingAdjustmentStartDate;
            var endDateTimeString = this.state.pricingAdjustmentEndDate;

            var selectTicketOptionIds = Enumerable.from(selectedTicketOptions).select((o: any) => o.id).toArray();

            let newPricingAdjustment: any = {
                name: this.state.pricingAdjustmentName,
                discountAmount: this.state.pricingAdjustmentAmount,
                discountAmountCode: this.state.pricingAdjustmentAmountCode,
                pricingAdjustmentCode: this.state.pricingAdjustmentCode,
                startDateTime: startDateTimeString,
                endDateTime: endDateTimeString,
                coupon: this.state.pricingAdjustmentCoupon,
                generalAdmissionTicketOptionIds: selectTicketOptionIds
            };

            if(this.props.currentIndex >= 0){
                let pricingAdjustment = pricingAdjustments[this.props.currentIndex];
                newPricingAdjustment.id = pricingAdjustment.id;
                newPricingAdjustment.assignedSeatingTicketOptionIds = pricingAdjustment.assignedSeatingTicketOptionIds;
                pricingAdjustments[this.props.currentIndex] = newPricingAdjustment;
            }
            else{
                pricingAdjustments.push(newPricingAdjustment);
            }

            this.props.updatePricingAdjustments(pricingAdjustments);

            this.CloseModal(true);
        }
    }

    ClickTicketOption = (currentIndex: any) => {
        let ticketOptions = this.props.ticketOptions;
        let ticketOption = ticketOptions[currentIndex];
        ticketOption.selected = !ticketOption.selected;
        ticketOptions[currentIndex] = ticketOption;
        this.props.updateTicketOptions(ticketOptions);
    }   

    render() {
        return (
            <Dialog
                    onClose={() => this.CloseModal(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.open}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Pricing Adjustment</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={() => this.CloseModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <CardContent>
                            <PricingAdjustmentFieldsContainer
                                pricingAdjustmentName={this.state.pricingAdjustmentName}
                                pricingAdjustmentNameError={this.state.pricingAdjustmentNameError}
                                pricingAdjustmentAmount={this.state.pricingAdjustmentAmount}
                                pricingAdjustmentAmountError={this.state.pricingAdjustmentAmountError}
                                pricingAdjustmentAmountCode={this.state.pricingAdjustmentAmountCode}
                                pricingAdjustmentAmountCodeError={this.state.pricingAdjustmentAmountCodeError}
                                pricingAdjustmentCode={this.state.pricingAdjustmentCode}
                                pricingAdjustmentCodeError={this.state.pricingAdjustmentCodeError}
                                pricingAdjustmentStartDate={this.state.pricingAdjustmentStartDate}
                                pricingAdjustmentStartDateError={this.state.pricingAdjustmentStartDateError}
                                pricingAdjustmentEndDate={this.state.pricingAdjustmentEndDate}
                                pricingAdjustmentEndDateError={this.state.pricingAdjustmentEndDateError}
                                pricingAdjustmentCoupon={this.state.pricingAdjustmentCoupon}
                                pricingAdjustmentCouponError={this.state.pricingAdjustmentCouponError}
                                pricingAdjustmentAmountCodes={this.props.pricingAdjustmentAmountCodes}
                                pricingAdjustmentCodes={this.props.pricingAdjustmentCodes}
                                handlePricingAdjustmentNameChange={this.HandlePricingAdjustmentNameChange}
                                handlePricingAdjustmentAmountChange={this.HandlePricingAdjustmentAmountChange}
                                handlePricingAdjustmentAmountCodeChange={this.HandlePricingAdjustmentAmountCodeChange}
                                handlePricingAdjustmentCodeChange={this.HandlePricingAdjustmentCodeChange}
                                handlePricingAdjustmentCouponChange={this.HandlePricingAdjustmentCouponChange}
                                handleAdjustmentStartDateChange={this.HandleAdjustmentStartDateChange}
                                handleAdjustmentEndDateChange={this.HandleAdjustmentEndDateChange}
                            />
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    style={{ marginLeft: "0.5%", width: "99%" }}
                                    className="TicketOptionsGrid general-admissions-table table-filters-hidden"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                    className="table-header-border show-on-mobile"
                                                >
                                                    Selected
                                                </TableCell>
                                            <TableCell
                                                className="table-header-border no-show-on-mobile"
                                            >
                                                Ticket Option
                                            </TableCell>
                                            <TableCell
                                                className="table-header-border no-show-on-mobile"
                                            >
                                                Ticket Price
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.ticketOptions.map((row: any, index: any) => {
                                                 return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        {/* This code is for pricing adjustments */}
                                                        <TableCell>
                                                            <CheckboxGridCell dataIndex={index} checked={row.selected} onClick={() => this.ClickTicketOption(index) } />
                                                        </TableCell>
                                                        <TableCell>
                                                            <div>{row.name}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div>${row.price}</div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                </TableContainer>
                        </CardContent>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={() => this.CloseModal(false)} className='two-action-buttons'>
                            Close
                        </Button>
                        <Button onClick={this.OnAddEditAdjustmentClick} className='two-action-buttons'>
                            {this.props.currentIndex >= 0 ? "Update Pricing Adjustment" : "Add Pricing Adjustment"}
                        </Button>
                    </DialogActions>
                </Dialog>
    );
}
}