/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { EmailAddressCell } from '../SubComponents/EmailAddressCell';
import { EditDeleteActionCell } from '../SubComponents/EditDeleteActionCell'
import { EventClusterRoleDropdownCell } from '../SubComponents/EventClusterRoleDropdownCell';
import { InviteUserActionCell } from '..//SubComponents/InviteUserActionCell';
import { getClusterPendingEventClusterInvites, createEventClusterInvites, updateEventClusterInvite, deleteEventClusterInvite } from '../../../data/EventClusterInviteRepository';
import { getEventClusterRoles } from '../../../data/EventClusterRoleRepository';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import validator from 'email-validator';
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { AddRoleDialog } from './AddRoleDialog';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
var Enumerable = require('linq');

interface IEventClusterInvitesSectionProps {
    eventClusterId: string,
    userPermissions: EventClusterPermission[],
    isClusterOwner: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class EventClusterInvitesSection extends React.Component<IEventClusterInvitesSectionProps> {

    state = {
        roles: [],
        pendingEventClusterInvites: [],
        insertEventClusterInvites: [],
        headCells: [
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'role',
                label: 'Role',
            },
        ],
        eventClusterInviteServerError: '',
        createEventClusterInvitesError: '',
        addEventClusterInviteModalOpen: false,
        focusedId: '',
        serverSuccessMsg: '',
        editModeInviteIds: [],
        deleteInviteDataIndex: -1,
        deleteInviteModalOpen: false,
        newRoleModalOpen: false,
    }

    async componentDidMount() {
        this.props.loadingOn();

        let pendingEventClusterInvites = [];

        let eventClusterInviteServerError = '';

        const query = new URLSearchParams(window.location.search);

        var response = await getClusterPendingEventClusterInvites(this.props.eventClusterId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.props.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterInviteServerError = response.data.errorMsg;
            }
            else {
                pendingEventClusterInvites = response.data.eventClusterInvites;
            }
        }
        else {
            eventClusterInviteServerError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            pendingEventClusterInvites: pendingEventClusterInvites,
        });
        await this.LoadRoles();

        this.props.loadingOff();
    }

    LoadRoles = async () => {
        var roles = [];
        let eventClusterInviteServerError = '';

        var getEventClusterRolesResponse = await getEventClusterRoles(this.props.eventClusterId);

        if (getEventClusterRolesResponse != null && getEventClusterRolesResponse.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.props.eventClusterId);
        }
        else if (getEventClusterRolesResponse != null && getEventClusterRolesResponse.status == 200) {
            if (!getEventClusterRolesResponse.data.success) {
                eventClusterInviteServerError = getEventClusterRolesResponse.data.errorMsg;
            }
            else {
                roles = getEventClusterRolesResponse.data.eventClusterRoles.filter((role: any) => !role.isOwnerRole);
            }
        }
        else {
            eventClusterInviteServerError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            roles: roles,
            eventClusterInviteServerError: eventClusterInviteServerError
        });
    }

    AddInviteUserClick = () => {
        let eventClusterInvite: any = { email: '', eventClusterRoleId: '', emailError: '', roleError: '' };

        let insertEventClusterInvites: any[] = this.state.insertEventClusterInvites;

        insertEventClusterInvites.push(eventClusterInvite);

        this.setState({ ...this.state, insertEventClusterInvites: insertEventClusterInvites })
    }

    OnEmailChange = (
        dataIndex: number,
        value: any,
        id: string) => {

        var eventClusterInvites: any[] = this.state.insertEventClusterInvites;
        var eventClusterInvite: any = eventClusterInvites[dataIndex];

        eventClusterInvite.email = value;

        eventClusterInvites[dataIndex] = eventClusterInvite;

        this.setState({ ...this.state, insertEventClusterInvites: eventClusterInvites, focusedId: id });
    }

    OnRoleDropdownChange = (dataIndex: number, value: any) => {
        var eventClusterInvites: any[] = this.state.insertEventClusterInvites;
        var eventClusterInvite: any = eventClusterInvites[dataIndex];

        eventClusterInvite.eventClusterRoleId = value;

        eventClusterInvites[dataIndex] = eventClusterInvite;

        this.setState({ ...this.state, insertEventClusterInvites: eventClusterInvites });
    }

    RemoveFocuseId = () => {
        this.setState({ ...this.state, focusedId: '' })
    }

    DeleteInviteUser = (dataIndex: number) => {

        let newEventClusterInvites: any[] = [];
        let currentEventClusterInvites: any[] = this.state.insertEventClusterInvites;

        currentEventClusterInvites.forEach((newEventClusterInvite, i) => {
            if (i != dataIndex) {
                newEventClusterInvites.push(newEventClusterInvite);
            }
        });

        this.setState({ ...this.state, insertEventClusterInvites: newEventClusterInvites });
    }

    OpenEventClusterInvitesModal = () => {
        this.setState({ ...this.state, addEventClusterInviteModalOpen: true, insertEventClusterInvites: [] });
    }

    CloseEventClusterInvitesModal = () => {
        this.setState({ ...this.state, addEventClusterInviteModalOpen: false, insertEventClusterInvites: [] });
    }

    CreateEventClusterInviteClick = async () => {
        this.props.loadingOn();

        let newInsertEventClusterInvites: any[] = [];
        let insertEventClusterInvites: any[] = this.state.insertEventClusterInvites;
        let errored = false;

        let pendingEventClusterInvites: any = this.state.pendingEventClusterInvites;
        let requestInsertEventClusterInvites: any[] = [];

        let serverErrorText = '';
        let serverSuccessMsg = '';

        for (var i = 0; i < insertEventClusterInvites.length; i++) {
            var eventClusterInvite: any = insertEventClusterInvites[i];

            eventClusterInvite.emailError = '';
            eventClusterInvite.roleError = '';

            if (eventClusterInvite.email.length == 0) {
                eventClusterInvite.emailError = "Email required.";
                errored = true;
            }
            else if (validator.validate(eventClusterInvite.email) == false) {
                eventClusterInvite.emailError = `"${eventClusterInvite.email}" is not a valid email address.`;
                errored = true;
            }
            else {
                var emailCount = Enumerable.from(insertEventClusterInvites).where((invite: any) => invite.email == eventClusterInvite.email).count();

                if (emailCount > 1) {
                    eventClusterInvite.emailError = `"${eventClusterInvite.email}" can only be used once.`;
                    errored = true;
                }
            }

            if (eventClusterInvite.eventClusterRoleId == "") {
                eventClusterInvite.roleError = "You must make a role selection";
                errored = true;
            }

            newInsertEventClusterInvites.push(eventClusterInvite);

            let requestEventClusterInvite: any = { email: eventClusterInvite.email, eventClusterRoleId: eventClusterInvite.eventClusterRoleId };

            requestInsertEventClusterInvites.push(requestEventClusterInvite);
        }

        if (errored == false) {

            let request: ICreateEventClusterInvitesReqeust = {
                eventClusterId: this.props.eventClusterId,
                eventClusterInvites: requestInsertEventClusterInvites
            };

            var response = await createEventClusterInvites(request);

            if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverErrorText = response.data.errorMsg;
                    errored = true;
                }
                else {
                    serverSuccessMsg = "Invites sent successfully";

                    var getPendingInvitesResponse = await getClusterPendingEventClusterInvites(this.props.eventClusterId);

                    if (getPendingInvitesResponse != null && getPendingInvitesResponse.status == 401) {
                        this.props.changePage("/login?redirect=event-group?id=" + this.props.eventClusterId);
                    }
                    else if (getPendingInvitesResponse != null && getPendingInvitesResponse.status == 200) {
                        if (!getPendingInvitesResponse.data.success) {
                            serverErrorText = getPendingInvitesResponse.data.errorMsg;
                        }
                        else {
                            pendingEventClusterInvites = getPendingInvitesResponse.data.eventClusterInvites;
                        }
                    }
                    else {
                        serverErrorText = "An unknown error occured. Please try again later";
                    }

                    newInsertEventClusterInvites = [];
                }
            }
            else {
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            pendingEventClusterInvites: pendingEventClusterInvites,
            insertEventClusterInvites: newInsertEventClusterInvites,
            addEventClusterInviteModalOpen: errored,
            createEventClusterInvitesError: serverErrorText,
            serverSuccessMsg: serverSuccessMsg
        });

        this.props.loadingOff();
    }

    OpenInviteEditMode = (dataIndex: number) => {
        let eventClusterInvite: any = this.state.pendingEventClusterInvites[dataIndex];

        let editModeInviteIds: any[] = this.state.editModeInviteIds;

        editModeInviteIds.push(eventClusterInvite.id);

        this.setState({ ...this.state, editModeInviteIds: editModeInviteIds })
    }

    CancelInviteEditMode = (dataIndex: number) => {
        let eventClusterInvite: any = this.state.pendingEventClusterInvites[dataIndex];

        let editModeInviteIds: any[] = this.state.editModeInviteIds;

        editModeInviteIds = editModeInviteIds.filter(obj => obj !== eventClusterInvite.id);

        this.setState({ ...this.state, editModeInviteIds: editModeInviteIds })
    }

    SaveEditedInvite = async (dataIndex: number) => {
        this.props.loadingOn();

        let pendingEventClusterInvites: any[] = this.state.pendingEventClusterInvites;
        let eventClusterInvite: any = pendingEventClusterInvites[dataIndex];

        let eventClusterInviteServerError = '';
        let serverSuccessMsg = '';

        let editModeInviteIds = this.state.editModeInviteIds;

        var response = await updateEventClusterInvite(eventClusterInvite.id, eventClusterInvite.eventClusterRoleId);

        let roles = this.state.roles;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.props.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterInviteServerError = response.data.errorMsg;
            }
            else {
                editModeInviteIds = Enumerable.from(editModeInviteIds).where((id: any) => id != eventClusterInvite.id).toArray();

                let role = Enumerable.from(roles).where((r: any) => r.id == eventClusterInvite.eventClusterRoleId).firstOrDefault();

                eventClusterInvite.eventClusterRoleName = role.name;

                pendingEventClusterInvites[dataIndex] = eventClusterInvite;

                serverSuccessMsg = `Event group invite for email "${eventClusterInvite.email}" updated successfully.`;
            }
        }
        else {
            eventClusterInviteServerError = "An unknown error occured. Please try again later";
        }

        this.setState({ ...this.state, pendingEventClusterInvites: pendingEventClusterInvites, editModeInviteIds: editModeInviteIds, serverSuccessMsg: serverSuccessMsg, eventClusterInviteServerError: eventClusterInviteServerError });

        this.props.loadingOff();
    }

    OpenDeleteInviteModal = (dataIndex: number) => {

        this.setState({ ...this.state, deleteInviteDataIndex: dataIndex, deleteInviteModalOpen: true });
    }

    CloseDeleteInviteModal = () => {
        this.setState({ ...this.state, deleteInviteDataIndex: -1, deleteInviteModalOpen: false });
    }

    DeleteInvite = async () => {
        this.props.loadingOn();

        let pendingEventClusterInvites: any[] = this.state.pendingEventClusterInvites;
        let eventClusterInvite: any = pendingEventClusterInvites[this.state.deleteInviteDataIndex];

        let newPendingEventClusterInvites: any[] = pendingEventClusterInvites;

        let eventClusterInviteServerError = '';
        let serverSuccessMsg = '';

        let editModeInviteIds = this.state.editModeInviteIds;

        var response = await deleteEventClusterInvite(eventClusterInvite.id);

        let roles = this.state.roles;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.props.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterInviteServerError = response.data.errorMsg;
            }
            else {
                editModeInviteIds = Enumerable.from(editModeInviteIds).where((id: any) => id != eventClusterInvite.id).toArray();

                newPendingEventClusterInvites = Enumerable.from(pendingEventClusterInvites).where((i: any) => i.id != eventClusterInvite.id).toArray();

                serverSuccessMsg = `Event group invite for email "${eventClusterInvite.email}" deleted successfully.`;
            }
        }
        else {
            eventClusterInviteServerError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            pendingEventClusterInvites: newPendingEventClusterInvites,
            editModeInviteIds: editModeInviteIds,
            serverSuccessMsg: serverSuccessMsg,
            eventClusterInviteServerError: eventClusterInviteServerError,
            deleteInviteDataIndex: -1,
            deleteInviteModalOpen: false
        });
        this.props.loadingOff();
    }

    CheckIfInEditMode = (id: string) => {
        let editModeInviteIds: string[] = this.state.editModeInviteIds;
        return editModeInviteIds.indexOf(id) > -1;
    }

    OnPendingRoleDropdownChange = (dataIndex: number, value: any) => {
        var eventClusterInvites: any[] = this.state.pendingEventClusterInvites;
        var eventClusterInvite: any = eventClusterInvites[dataIndex];

        eventClusterInvite.eventClusterRoleId = value;

        eventClusterInvites[dataIndex] = eventClusterInvite;

        this.setState({ ...this.state, pendingEventClusterInvites: eventClusterInvites });
    }

    CloseSuccessModal = () => {
        this.setState({ ...this.state, serverSuccessMsg: "" });
    };

    CloseErrorModal = () => {
        this.setState({ ...this.state, eventClusterInviteServerError: "", createEventClusterInvitesError: "" });
    };

    OpenNewRoleModal = () => {
        this.setState({ ...this.state, newRoleModalOpen: true });
    }

    CloseNewRoleModal = () => {
        this.setState({
            ...this.state,
            newRoleModalOpen: false,
            currentRoleNameError: "",
            currentRoleServerError: ""
        });
    }

    OnRoleAdded = async () => {
        this.setState({
            ...this.state,
            newRoleModalOpen: false,
            currentRoleNameError: "",
            currentRoleServerError: ""
        });
        await this.LoadRoles();
    }

    render() {
        let editInvitesAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditInvites);
        let deleteInvitesAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.DeleteInvites);
        let inviteUsersAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.InviteUsers);
        let addRolesAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.AddRoles);

        let actionColumnVisible = editInvitesAccess || deleteInvitesAccess;
        return (
            <div>
                <Card style={{ height: '30%', backgroundColor: "#ededed" }}>
                    <CardHeader title="Pending Event Group Invites" />
                    {inviteUsersAccess && <CardContent>
                        <div style={{ paddingBottom: '10px' }}>
                            <Button variant="contained" className='btn-primary' onClick={this.OpenEventClusterInvitesModal}  >
                                Invite Users
                            </Button>
                        </div>
                    </CardContent>}
                    <CardContent>
                         <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className="event-cluster-invites-table table-filters-hidden"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                                {actionColumnVisible && <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.pendingEventClusterInvites.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="right"><GenericDisplayCell title="Email" value={row.email} /></TableCell>
                                                        <TableCell align="right">
                                                            <EventClusterRoleDropdownCell
                                                                title="Role"
                                                                dataItem={row}
                                                                dataIndex={index}
                                                                onChange={this.OnPendingRoleDropdownChange}
                                                                roles={this.state.roles}
                                                                editMode={this.CheckIfInEditMode(row.id)} />
                                                        </TableCell>
                                                        {actionColumnVisible && <TableCell align="right">
                                                            <EditDeleteActionCell
                                                                    dataIndex={index}
                                                                    onEditButtonClick={this.OpenInviteEditMode}
                                                                    onSaveButtonClick={this.SaveEditedInvite}
                                                                    onDeleteButtonClick={this.OpenDeleteInviteModal}
                                                                    onCancelButtonClick={this.CancelInviteEditMode}
                                                                    hideEditButton={editInvitesAccess == false}
                                                                    hideDeleteButton={deleteInvitesAccess == false}
                                                                    hideCancelButton={editInvitesAccess == false}
                                                                    editMode={this.CheckIfInEditMode(row.id)} />
                                                        </TableCell>}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <Dialog
                    onClose={this.CloseEventClusterInvitesModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    fullWidth
                    open={this.state.addEventClusterInviteModalOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Invite Users</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseEventClusterInvitesModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <CardContent>
                            {addRolesAccess &&
                                    <Button variant="contained" className='btn-secondary' onClick={this.OpenNewRoleModal} style={{ marginBottom: "40px" }} >
                                        Add New Role
                                    </Button>}
                            <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className="new-event-cluster-invites-table table-filters-hidden"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                                <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.insertEventClusterInvites.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="right">
                                                            <EmailAddressCell dataItem={row} dataIndex={index}
                                                            focusedId={this.state.focusedId} onChange={this.OnEmailChange} removeFocusedId={this.RemoveFocuseId} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <EventClusterRoleDropdownCell
                                                                    title="Role"
                                                                    dataItem={row}
                                                                    dataIndex={index}
                                                                    onChange={this.OnRoleDropdownChange}
                                                                    roles={this.state.roles}
                                                                    editMode={true} />
                                                        </TableCell>
                                                        <TableCell>
                                                            <InviteUserActionCell dataIndex={index} deleteInviteUser={this.DeleteInviteUser} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                        </CardContent>
                        <CardContent>
                            <div style={{ paddingTop: '10px' }}>
                                <Button variant="contained" className='btn-secondary' onClick={this.AddInviteUserClick}  >
                                    Add User Invite
                                </Button>
                            </div>
                        </CardContent>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseEventClusterInvitesModal} className='two-action-buttons'>
                            Close
                        </Button>
                        <Button onClick={this.CreateEventClusterInviteClick} className='two-action-buttons' >
                            Send Invites
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.CloseDeleteInviteModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.deleteInviteModalOpen}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Delete Invite</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseDeleteInviteModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this invite?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseDeleteInviteModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.DeleteInvite} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <GenericSuccessModal
                    hidden={this.state.serverSuccessMsg == ""}
                    message={this.state.serverSuccessMsg}
                    closeModal={this.CloseSuccessModal}
                />
                <GenericErrorModal hidden={this.state.eventClusterInviteServerError == ''}
                    message={this.state.eventClusterInviteServerError}
                    closeModal={this.CloseErrorModal} />
                <GenericErrorModal hidden={this.state.createEventClusterInvitesError == ''}
                    message={this.state.createEventClusterInvitesError}
                    closeModal={this.CloseErrorModal} />

                <AddRoleDialog
                    visible={this.state.newRoleModalOpen}
                    eventClusterId={this.props.eventClusterId}
                    loadingOff={this.props.loadingOff}
                    loadingOn={this.props.loadingOn}
                    changePage={this.props.changePage}
                    onCloseDialog={this.CloseNewRoleModal}
                    onRoleAdded={this.OnRoleAdded}
                />
            </div>
        )
    }
}