/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import ReactMarkdown from 'react-markdown';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { getAllEvents } from '../../../data/EventRepository';
import Moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { Chip } from '@material-ui/core';
import { EventStatusCode } from '../../../data/Models/Enums';

var Enumerable = require('linq');


interface IUpcomingEventsSectionProps {
    changePage: (page: string) => void
}

interface IUpcomingEventsSectionState {
    events: any[],
    pageable: any,
    tableDef: ITableDef,
    serverError: string,
    loading: boolean,
    currentPortalUserId: string
}

const getInitTableDef = () => {
    let sortDef: ISortDef = {
        field: "StartDateTime",
        dir: "asc"
    };

    let filterArray: any[] = [];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let gridState: ITableDef = {
        skip: 0,
        take: 5,
        sort: [sortDef],
        filter: tableFilters
    };

    return gridState;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class UpcomingEventsSection extends React.Component<IUpcomingEventsSectionProps, IUpcomingEventsSectionState> {

    state = {
        events: [],
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        serverError: '',
        loading: false,
        currentPortalUserId: ''
    }

    async componentDidMount() {
        this.LoadingOn();
        const query = new URLSearchParams(window.location.search);

        let searchTerm = query.get('name');
        if (searchTerm != null) {
            let filter2: IGridFilter = {
                field: "name",
                operator: "contains",
                value: searchTerm,
            };
            let filterArray: any[] = [filter2];
            let tableFilters: ITableFilters = {
                logic: "and",
                filters: filterArray
            };

            this.state.tableDef.filter = tableFilters;
        }

        await this.LoadEvents(this.state.tableDef);
        this.LoadingOff();
    }

    LoadEvents = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let events: any = [];
        let eventsTotal = 0;
        let serverError = '';
        let currentPortalUserId = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "") || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getAllEvents(tempTableDef, true);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=dashboard");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                currentPortalUserId = response.data.portalUserId;
                events = response.data.events;
                eventsTotal = response.data.total;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({ ...this.state, events: events, tableDef: tableDef, serverError: serverError, currentPortalUserId: currentPortalUserId });
    }

    LoadingOn = () => {
        this.setState({ ...this.state, loading: true });
    }

    LoadingOff = () => {
        this.setState({ ...this.state, loading: false });
    }

    EditEventClick = (eventId: any, eventClusterId: any) => {
        this.props.changePage(`/event?id=${eventId}&eventGroupId=${eventClusterId}`);
    }

    ShowReadMore = (eventId: any) => {
        var events: any[] = this.state.events;

        var eventIndex = events.findIndex(obj => obj.id === eventId);

        var event = events[eventIndex];

        event.showReadMore = true;

        events[eventIndex] = event;

        this.setState({
            ...this.state,
            events: events
        });
    }

    render() {
        return (
            <div style={{ width: "100%" }}>
                <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <CardHeader title="Upcoming Events" />
                    <CardContent>
                        <table style={{ width: "100%", borderSpacing: "0px", borderCollapse: "collapse" }}>
                            {this.state.events.map((data: any, index: number) => (
                                <tr style={index % 2 == 0 ? { width: "100%", border: "solid grey 1px", backgroundColor: "#fff" } : { width: "100%", border: "solid grey 1px", backgroundColor: "#f8f8ff" }}>
                                    <td className="upcoming-events-td1" style={{ width: "5%", minWidth: "50px", verticalAlign: "top" }} >
                                        <div style={{ margin: "5px 5px 5px 5px", width: "60px", height: "60px", color: "#fff", borderRadius: "1px", textAlign: "center", backgroundColor: "#e02d75", display: "blockS" }}>
                                            <div style={{ fontSize: "22px" }}>{Moment(data.startEventDateTimeLocal).format("DD")}</div>
                                            <div style={{ fontSize: "18px" }}>{Moment(data.startEventDateTimeLocal).format("MMM")}</div>
                                        </div>
                                    </td>
                                    <td style={{ width: "85%" }}>
                                        <div style={{ fontWeight: "bold" }}>{data.name}</div>
                                        <div style={{ fontWeight: "bold" }}>{"Starts @ " + Moment(data.startEventDateTimeLocal).format("h:mm A")}</div>
                                        <div><ReactMarkdown>{data.showReadMore != true ? data.description.split('\n')[0] : data.description}</ReactMarkdown>
                                            <Link hidden={data.showReadMore == true} href="#" onClick={() => this.ShowReadMore(data.id)}>Read More</Link></div>
                                    </td>
                                    <td className="upcoming-events-td3" style={{ width: "10%", minWidth: "50px" }}>
                                        {data.statusCode == EventStatusCode.Cancelled && <div><Chip variant='default' label="Event Cancelled" style={{ marginBottom: "4px" }} color="secondary" /></div>}
                                        {!data.published && <div><Chip variant='default' label="DRAFT" style={{ marginBottom: "4px" }} color="secondary" /></div>}
                                        <Button hidden={data.editable == false} variant="contained" className="btn-secondary" size="small" style={{ marginRight: "5px" }} onClick={() => this.EditEventClick(data.id, data.clusterId)}>
                                            Edit
                                        </Button>
                                        {this.state.currentPortalUserId != data.ownerId && <div>By: {data.clusterName}</div>}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </CardContent>
                </Card>
            </div>
        )
    }
}