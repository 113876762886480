import * as React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';

interface ITableFilterToggleButtonProps{
    expanded: boolean,
    onClick: () => void;
}

export class TableFilterToggleButton extends React.Component<ITableFilterToggleButtonProps> {

    render() {
        return (
            <div>
                <Button className="btn-filter-toggle" onClick={this.props.onClick} style={{width: "100%", marginBottom: "10px"}}>
                    {this.props.expanded ? 
                      <div style={{height: "54px", display: "table-cell", verticalAlign: "middle"}}><span style={{height: "54px", display: "table-cell", verticalAlign: "middle"}}>Hide Filters</span><span style={{height: "54px", display: "table-cell", verticalAlign: "middle"}}><ExpandLessIcon fontSize="large"/></span></div>
                    : <div style={{height: "54px", display: "table-cell", verticalAlign: "middle"}}><span style={{height: "54px", display: "table-cell", verticalAlign: "middle"}}>Show Filters</span><span style={{height: "54px", display: "table-cell", verticalAlign: "middle"}}><ExpandMoreIcon fontSize="large"/></span></div>}
                </Button>
            </div>
        );
    }
}