import React from 'react';
import { generateStripeSetupUrl } from '../../data/StripeRepository';
import { GenericErrorModal } from '../Components/GenericErrorModal';

interface IGenerateStripeSetupUrlPageProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class GenerateStripeSetupUrlPage extends React.Component<IGenerateStripeSetupUrlPageProps> {

    state = {
        serverError: ""
    }

    async componentDidMount()
    {
        this.props.loadingOn();
        let serverError = "";

        const query = new URLSearchParams(window.location.search);

        var organizationId: string = query.get('organizationId') ?? "";

        var response = await generateStripeSetupUrl(organizationId);

        if(response != null && response.status == 401){
            this.props.changePage(`/login?redirect=payment-account-signup-generate`);
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                window.location.replace(response.data.url);
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, serverError: serverError})
        this.props.loadingOff();
    }

    CloseErrorModal = () => {
        this.setState({...this.state, serverError: ""});
    };

    render()
    {
        return (
            <GenericErrorModal hidden={this.state.serverError == ''}
                message={this.state.serverError}
                closeModal={this.CloseErrorModal} />   
        )
    }
}