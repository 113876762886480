/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { getEventFinancials } from '../../../data/UserEventTicketRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { EventFinancialsTable } from '../SubComponents/EventFinancialsTable';
import { EventTicketingChart } from './EventTicketingChart';
import { getEventSuccessPrediction } from '../../../data/EventSuccessPredictionRepository';
import { PredictiveAnalyticsSection } from './PredictiveAnalyticsSection';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { EventCustomersTable } from '../SubComponents/EventCustomersTable';

var Enumerable = require('linq');

interface IEventAnalyticsSectionProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class EventAnalyticsSection extends React.Component<IEventAnalyticsSectionProps> {

    state = {
        eventId: '',
        eventClusterId: '',
        totalIncome: 0,
        totalFees: 0,
        totalTicketsPurchased: 0,
        payoutDateTime: undefined,
        payoutAttempts: 0,
        payoutSuccessful: false,
        currentTab: 0,
        serverError: ''
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);

        var eventId: string = query.get('id') ?? "";
        this.setState({ ...this.state, eventId: eventId });

        let eventClusterId: string = query.get('eventGroupId') ?? "";

        let currentTab = 0;

        if (query.get('showfinancials') == '1')
            currentTab = 1;

        this.setState({
            ...this.state,
            eventId: eventId,
            eventClusterId: eventClusterId,
            currentTab: currentTab
        });
    }

    LoadEventAnalytics = async (eventId: any, eventClusterId: any) => {
        let totalIncome = 0;
        let totalFees = 0;
        let totalTicketsPurchased = 0;
        let payoutDateTime: Date | undefined = undefined;
        let payoutAttempts = 0;
        let payoutSuccessful = false;
        let serverError = '';

        var response = await getEventFinancials(eventId);

        if (response != null && response.status == 401) {
            this.RedirectToLogin(eventClusterId, eventId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                totalIncome = response.data.totalIncome;
                totalFees = response.data.totalFees;
                totalTicketsPurchased = response.data.totalTicketsPurchased;
                payoutDateTime = response.data.payoutDateTime;
                payoutAttempts = response.data.payoutAttempts;
                payoutSuccessful = response.data.payoutSuccessful;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            eventId: eventId,
            totalIncome: totalIncome,
            totalFees: totalFees,
            totalTicketsPurchased: totalTicketsPurchased,
            payoutDateTime: payoutDateTime,
            payoutAttempts: payoutAttempts,
            payoutSuccessful: payoutSuccessful,
            serverError: serverError
        });
    }

    RedirectToLogin = (eventClusterId: any, eventId: any) => {
        let eventClusterParam = eventClusterId != null && eventClusterId != "" ? `?eventGroupId=${eventClusterId}` : "";

        if (eventId != '' && eventId != undefined) {
            eventClusterParam += `%26id=${eventId}`;
        }

        this.props.changePage("/login?redirect=event" + eventClusterParam);
    }

    HandleChange = async (newValue: number) => {
        if (newValue == 1) {
            await this.LoadEventAnalytics(this.state.eventId, this.state.eventClusterId);
        }

        this.setState({ ...this.state, currentTab: newValue });
    }

    A11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: '' });
    }

    render() {
        return (
            <div style={{ width: "100%" }}>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <CardHeader title="Analytics" />
                    <CardContent>
                        <Paper square
                            style={{ width: "100%" }}
                        >
                            <Tabs
                                value={this.state.currentTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(e, v) => this.HandleChange(v)}
                                style={{ width: "100%" }}
                                className="tabs-split-3"
                                centered={true}
                            >
                                <Tab label="Predictive"  {...this.A11yProps(0)} />
                                <Tab className="MuiTab-root-override" label="Financial" {...this.A11yProps(1)} />
                                <Tab className="MuiTab-root-override" label="Customers" {...this.A11yProps(2)} />
                            </Tabs>
                        </Paper>
                    </CardContent>
                    <CardContent className='event-analytics-card-content'>
                        {this.state.currentTab === 0 && <Box p={3} style={{ height: "100%" }} className="analytics-tab-content">
                            <Typography style={{ height: "100%" }}>
                                <div style={{ width: "100%" }} className="eventsPageFinancialsTable">
                                    <PredictiveAnalyticsSection
                                        loadingOn={this.props.loadingOn}
                                        loadingOff={this.props.loadingOff}
                                        changePage={this.props.changePage}
                                    />
                                </div>
                            </Typography>
                        </Box>}
                        {this.state.currentTab === 1 && <Box p={3} style={{ height: "100%" }} className="analytics-tab-content">
                            <Typography style={{ height: "100%" }}>
                                <div style={{ width: "100%" }} className="eventsPageFinancialsTable">
                                    <EventFinancialsTable
                                        totalIncome={this.state.totalIncome}
                                        totalFees={this.state.totalFees}
                                        totalTicketsPurchased={this.state.totalTicketsPurchased}
                                        payoutDateTime={this.state.payoutDateTime}
                                        payoutAttempts={this.state.payoutAttempts}
                                        payoutSuccessful={this.state.payoutSuccessful}
                                    />
                                    <EventTicketingChart changePage={this.props.changePage} />
                                </div>
                            </Typography>
                        </Box>}
                        {this.state.currentTab === 2 && <Box p={3} style={{ height: "100%" }} className="analytics-tab-content">
                            <EventCustomersTable
                                loadingOn={this.props.loadingOn}
                                loadingOff={this.props.loadingOff}
                                changePage={this.props.changePage}
                                eventId={this.state.eventId}
                            >
                            </EventCustomersTable>
                        </Box>}
                    </CardContent>
                </Card>
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />

            </div>
        )
    }
}