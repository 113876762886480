/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { EditDeleteActionCell } from '../SubComponents/EditDeleteActionCell'
import { EventClusterRoleDropdownCell } from '../SubComponents/EventClusterRoleDropdownCell';
import { getEventClusterPortalUsers, updateEventClusterPortalUser, deleteEventClusterPortalUser } from '../../../data/PortalUserEventClusterRepository';
import { getEventClusterRoles } from '../../../data/EventClusterRoleRepository';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

var Enumerable = require('linq');

interface IEventClusterPortalUsersSectionProps {
    userPermissions: EventClusterPermission[],
    isClusterOwner: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IEventClusterPortalUsersSectionState {
    eventClusterId: string,
    roles: any[],
    portalUsers: any[],
    usersTotal: number,
    pageable: any,
    tableDef: ITableDef,
    eventClusterPortalUsersServerError: string,
    serverSuccessMsg: string,
    editModePortalUserIds: any[],
    deletePortalUserDataIndex: number,
    deletePortalUserModalOpen: boolean,
    mobileFiltersVisible: boolean
}

const getInitTableDef = () => {
    let sortDef: ISortDef = {
        field: "firstName",
        dir: "desc"
    };

    let filterArray = [
        {
            field: "firstName",
            operator: "eq",
            value: ""
        },
        {
            field: "lastName",
            operator: "eq",
            value: ""
        },
        {
            field: "email",
            operator: "eq",
            value: ""
        },
        {
            field: "role",
            operator: "eq",
            value: ""
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let gridState: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return gridState;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class EventClusterPortalUsersSection extends React.Component<IEventClusterPortalUsersSectionProps, IEventClusterPortalUsersSectionState> {

    state = {
        eventClusterId: '',
        roles: [],
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
                id: 'firstName',
                label: 'First Name',
            },
            {
                id: 'lastName',
                label: 'Last Name',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'role',
                label: 'Email',
            }
        ],
        portalUsers: [],
        usersTotal: 0,
        eventClusterPortalUsersServerError: '',
        serverSuccessMsg: '',
        editModePortalUserIds: [],
        deletePortalUserDataIndex: -1,
        deletePortalUserModalOpen: false,
        mobileFiltersVisible: false
    }

    async componentDidMount() {
        this.props.loadingOn();

        await this.LoadPortalUsers(this.state.tableDef);

        this.props.loadingOff();
    }

    LoadPortalUsers = async (tableDef: ITableDef) => {
        let roles: any = [];
        let portalUsers = [];
        let usersTotal: number = 0;

        let eventClusterPortalUsersServerError = '';

        const query = new URLSearchParams(window.location.search);

        var eventClusterId: string = query.get('id') ?? "";

        this.setState({ ...this.state, tableDef: tableDef });

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "" && f.value != '')).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getEventClusterPortalUsers(eventClusterId, tempTableDef);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterPortalUsersServerError = response.data.errorMsg;
            }
            else {
                portalUsers = response.data.portalUserEventClusters;
                usersTotal = response.data.total;
            }
        }
        else {
            eventClusterPortalUsersServerError = "An unknown error occured. Please try again later";
        }

        var getEventClusterRolesResponse = await getEventClusterRoles(eventClusterId);

        if (getEventClusterRolesResponse != null && getEventClusterRolesResponse.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + eventClusterId);
        }
        else if (getEventClusterRolesResponse != null && getEventClusterRolesResponse.status == 200) {
            if (!getEventClusterRolesResponse.data.success) {
                eventClusterPortalUsersServerError = getEventClusterRolesResponse.data.errorMsg;
            }
            else {
                roles = getEventClusterRolesResponse.data.eventClusterRoles.filter((role: any) => !role.isOwnerRole);
            }
        }
        else {
            eventClusterPortalUsersServerError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            eventClusterId: eventClusterId,
            tableDef: tableDef,
            roles: roles,
            portalUsers: portalUsers,
            usersTotal: usersTotal,
            eventClusterPortalUsersServerError: eventClusterPortalUsersServerError
        });
    }

    OpenPortalUserEditMode = (dataIndex: number) => {
        let portalUser: any = this.state.portalUsers[dataIndex];

        let editModePortalUserIds: any[] = this.state.editModePortalUserIds;

        editModePortalUserIds.push(portalUser.portalUserEventClusterId);

        this.setState({ ...this.state, editModePortalUserIds: editModePortalUserIds });
    }

    CancelPortalUserEditMode = (dataIndex: number) => {
        let portalUser: any = this.state.portalUsers[dataIndex];

        let editModePortalUserIds: any[] = this.state.editModePortalUserIds;

        editModePortalUserIds = editModePortalUserIds.filter(obj => obj !== portalUser.portalUserEventClusterId);

        this.setState({ ...this.state, editModePortalUserIds: editModePortalUserIds });
    }

    SaveEditedPortalUser = async (dataIndex: number) => {
        this.props.loadingOn();

        let portalUsers: any[] = this.state.portalUsers;
        let portalUser: any = portalUsers[dataIndex];

        let eventClusterPortalUsersServerError = '';
        let serverSuccessMsg = '';

        let editModePortalUserIds = this.state.editModePortalUserIds;

        var response = await updateEventClusterPortalUser(portalUser.portalUserEventClusterId, portalUser.eventClusterRoleId);

        let roles = this.state.roles;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.state.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterPortalUsersServerError = response.data.errorMsg;
            }
            else {
                editModePortalUserIds = Enumerable.from(editModePortalUserIds).where((id: any) => id != portalUser.portalUserEventClusterId).toArray();

                let role = Enumerable.from(roles).where((r: any) => r.id == portalUser.eventClusterRoleId).firstOrDefault();

                portalUser.eventClusterRoleName = role.name;

                portalUsers[dataIndex] = portalUser;

                serverSuccessMsg = `Event group member with email "${portalUser.email}" updated successfully.`;
            }
        }
        else {
            eventClusterPortalUsersServerError = "An unknown error occured. Please try again later";
        }

        this.setState({ ...this.state, portalUsers: portalUsers, editModePortalUserIds: editModePortalUserIds, serverSuccessMsg: serverSuccessMsg, eventClusterPortalUsersServerError: eventClusterPortalUsersServerError });
        this.props.loadingOff();
    }

    OpenDeletePortalUserModal = (dataIndex: number) => {

        this.setState({ ...this.state, deletePortalUserDataIndex: dataIndex, deletePortalUserModalOpen: true });
    }

    CloseDeletelUserModal = () => {
        this.setState({ ...this.state, deletePortalUserDataIndex: -1, deletePortalUserModalOpen: false });
    }

    DeletePortalUser = async () => {
        this.props.loadingOn();

        let portalUsers: any[] = this.state.portalUsers;
        let portalUser: any = portalUsers[this.state.deletePortalUserDataIndex];

        let newPortalUsers: any[] = portalUsers;

        let eventClusterPortalUsersServerError = '';
        let serverSuccessMsg = '';

        let editModePortalUserIds = this.state.editModePortalUserIds;

        var response = await deleteEventClusterPortalUser(portalUser.portalUserEventClusterId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.state.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterPortalUsersServerError = response.data.errorMsg;
            }
            else {
                editModePortalUserIds = Enumerable.from(editModePortalUserIds).where((id: any) => id != portalUser.portalUserEventClusterId).toArray();

                newPortalUsers = Enumerable.from(portalUsers).where((i: any) => i.portalUserEventClusterId != portalUser.portalUserEventClusterId).toArray();

                serverSuccessMsg = `Event group member with email "${portalUser.email}" deleted successfully.`;
            }
        }
        else {
            eventClusterPortalUsersServerError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            portalUsers: newPortalUsers,
            editModePortalUserIds: editModePortalUserIds,
            serverSuccessMsg: serverSuccessMsg,
            eventClusterPortalUsersServerError: eventClusterPortalUsersServerError,
            deletePortalUserDataIndex: -1,
            deletePortalUserModalOpen: false
        });

        this.props.loadingOff();
    }

    CheckIfInEditMode = (id: string) => {
        let editModePortalUserIds: string[] = this.state.editModePortalUserIds;
        return editModePortalUserIds.indexOf(id) > -1;
    }

    OnUserRoleDropdownChange = (dataIndex: number, value: any) => {
        var portalUsers: any[] = this.state.portalUsers;
        var eventClusterInvite: any = portalUsers[dataIndex];

        eventClusterInvite.eventClusterRoleId = value;

        portalUsers[dataIndex] = eventClusterInvite;

        this.setState({ ...this.state, portalUsers: portalUsers });
    }

    CloseSuccessModal = () => {
        this.setState({ ...this.state, serverSuccessMsg: "" });
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, eventClusterPortalUsersServerError: "" });
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadPortalUsers(tableDef);
        }

        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        filterDef.f.value = value;

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadPortalUsers(tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadPortalUsers(tableDef);
        this.props.loadingOff();
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadPortalUsers(tableDef);

        this.props.loadingOff();
    };

    ToggleFilterVisibility = () => {
        this.setState({ ...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible });
    }

    render() {
        let editTeamMemberAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditTeamMember);
        let deleteTeamMemberAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.DeleteTeamMember);

        let actionColumnVisible: boolean = editTeamMemberAccess || deleteTeamMemberAccess;

        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "firstName";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div>
                <Card style={{ height: '30%', backgroundColor: "#ededed" }}>
                    <CardHeader title="Team Management" />
                    <CardContent>
                        <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility} />
                        
                        <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className={this.state.mobileFiltersVisible ? "table-filters-visible event-cluster-users-table" : "table-filters-hidden"}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        <TableSortLabel
                                                            active={currentSortName === headCell.id}
                                                            direction={currentSortName === headCell.id ? currentSortDir : null}
                                                            hideSortIcon={currentSortName !== headCell.id}
                                                            onClick={() => this.OnSortChange(headCell.id)}
                                                            style={{ width: "100%" }}
                                                        >
                                                            {headCell.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                                {actionColumnVisible && <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>}
                                            </TableRow>
                                            <TableRow>
                                                {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                                    <TableCell className="table-header-border show-on-mobile" >
                                                        <TextField
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                            value={headCell.value}
                                                            type="string"
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                ))}
                                                {actionColumnVisible && <TableCell className="table-header-border no-show-on-mobile">

                                                </TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.portalUsers.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="right"><GenericDisplayCell title="First Name" value={row.firstName} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Last Name" value={row.lastName} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Email" value={row.email} /></TableCell>
                                                        <TableCell align="right"><EventClusterRoleDropdownCell
                                                            title="Role"
                                                            dataItem={row}
                                                            dataIndex={index}
                                                            onChange={this.OnUserRoleDropdownChange}
                                                            roles={this.state.roles}
                                                            editMode={this.CheckIfInEditMode(row.portalUserEventClusterId)} />
                                                        </TableCell>
                                                        {actionColumnVisible && <TableCell align="center"> <EditDeleteActionCell
                                                            dataIndex={index}
                                                            onEditButtonClick={this.OpenPortalUserEditMode}
                                                            onCancelButtonClick={this.CancelPortalUserEditMode}
                                                            onSaveButtonClick={this.SaveEditedPortalUser}
                                                            onDeleteButtonClick={this.OpenDeletePortalUserModal}
                                                            hideEditButton={editTeamMemberAccess == false || row.isOwnerRole}
                                                            hideCancelButton={editTeamMemberAccess == false || row.isOwnerRole}
                                                            hideDeleteButton={deleteTeamMemberAccess == false || row.isOwnerRole}
                                                            editMode={this.CheckIfInEditMode(row.portalUserEventClusterId)} />
                                                        </TableCell>}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={this.state.usersTotal}
                                    rowsPerPage={this.state.tableDef.take!}
                                    page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                    onChangePage={(e, page) => this.PageChange(page)}
                                    onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                                />
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <Dialog
                    onClose={this.CloseDeletelUserModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.deletePortalUserModalOpen}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Remove user</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseDeletelUserModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to remove this user from the event group?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseDeletelUserModal}  className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.DeletePortalUser}  className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <GenericSuccessModal
                    hidden={this.state.serverSuccessMsg == ""}
                    message={this.state.serverSuccessMsg}
                    closeModal={this.CloseSuccessModal}
                />
                <GenericErrorModal hidden={this.state.eventClusterPortalUsersServerError == ''}
                    message={this.state.eventClusterPortalUsersServerError}
                    closeModal={this.CloseErrorModal} />
            </div>
        )
    }
}