import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { getTicketingChartForEvent } from '../../../data/UserEventTicketRepository';
import Moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { TicketingChartDataTypeCode } from "../../../data/Models/TicketingChartDataTypeCode";
import { ChartDateRangeTypeCode } from "../../../data/Models/ChartDateRangeTypeCode";
import { ChartTypeCode } from "../../../data/Models/ChartTypeCode";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

var Enumerable = require('linq');

interface IEventTicketingChartProps {
    changePage: (page: string) => void
}

export class EventTicketingChart extends React.Component<IEventTicketingChartProps> {

    state = {
        eventId: "",
        currentChartType: ChartTypeCode.Column,
        currentDateRangeType: ChartDateRangeTypeCode.Month,
        currentChartDataType: TicketingChartDataTypeCode.TicketsSold,
        currentDateRange: "",
        data: [],
        chartData: [],
        chartTypes: [],
        chartDateRangeTypes: [],
        dateRanges: [],
        loading: false
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        var eventId: string = query.get('id') ?? "";
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, eventId);
        this.setState({ ...this.state, eventId: eventId });
    }

    LoadData = async (currentDateRangeType: any, currentChartDataType: any, currentDateRange: any, eventId: string) => {
        this.LoadingOn();

        let data: any[] = [];
        let dateRanges: any[] = [];
        let chartData: any[] = [{ name: "Tickets Sold", value: TicketingChartDataTypeCode.TicketsSold }, { name: "Revenue", value: TicketingChartDataTypeCode.Revenue }];
        let chartTypes: any[] = [{ name: "Column", value: ChartTypeCode.Column }, { name: "Line", value: ChartTypeCode.Line }];
        let chartDateRangeTypes: any[] = [{ name: "Month", value: ChartDateRangeTypeCode.Month }, { name: "Year", value: ChartDateRangeTypeCode.Year }];
        let serverError = '';

        var response = await getTicketingChartForEvent(currentDateRange, currentDateRangeType, currentChartDataType, eventId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=analytics");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                dateRanges = response.data.searchableDateRanges;
                data = Enumerable.from(response.data.chartDataList)
                    .select((d: any) => ({
                        x: Moment(d.xAxisDate).local().format(currentDateRangeType == ChartDateRangeTypeCode.Month ? "MM/DD" : "MM/YYYY"),
                        y: parseFloat(d.yValue)
                    }))
                    .toArray();
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        currentDateRange = (currentDateRangeType != this.state.currentDateRangeType ||
            currentDateRange == "" || currentDateRange == undefined) && dateRanges.length > 0 ? dateRanges[0].startDate : currentDateRange;

        this.setState({
            ...this.state,
            currentDateRangeType: currentDateRangeType,
            currentChartDataType: currentChartDataType,
            currentDateRange: currentDateRange,
            data: data,
            chartTypes: chartTypes,
            chartData: chartData,
            dateRanges: dateRanges,
            chartDateRangeTypes: chartDateRangeTypes,
            serverError: serverError
        });

        this.LoadingOff();
    }

    ChangeChartType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.setState({
            ...this.state,
            currentChartType: event.target.value
        });
    }

    ChangeChartDataType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.LoadData(this.state.currentDateRangeType, event.target.value, this.state.currentDateRange, this.state.eventId);
    }

    ChangeDateRangeType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.LoadData(event.target.value, this.state.currentChartDataType, "", this.state.eventId);
    }

    ChangeDateRange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, event.target.value, this.state.eventId);
    }

    GetToolTip = (point: any) => {
        let value = this.state.currentChartDataType == TicketingChartDataTypeCode.Revenue ? `$${Number(point).toFixed(2)}` : point;

        return value;
    }

    LoadingOn = () => {
        this.setState({ ...this.state, loading: true });
    }

    LoadingOff = () => {
        this.setState({ ...this.state, loading: false });
    }

    render() {
        let chartTitle = Enumerable.from(this.state.chartData).where((d: any) => d.value == this.state.currentChartDataType).firstOrDefault()?.name;

        return (
            <Card style={{ backgroundColor: "#ededed" }} className="ticketingChartCard">
                <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <CardContent>
                    <FormControl variant="outlined" className="chartTypeContainer">
                        <InputLabel>Chart Type</InputLabel>
                        <Select
                            value={this.state.currentChartType}
                            label="Chart Type"
                            onChange={(e) => this.ChangeChartType(e)}
                        >
                            {
                                this.state.chartTypes.map((data: any) => (
                                    <MenuItem value={data.value}>{data.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                        <InputLabel>Chart Data</InputLabel>
                        <Select
                            value={this.state.currentChartDataType}
                            label="Chart Data"
                            onChange={(e) => this.ChangeChartDataType(e)}
                        >
                            {
                                this.state.chartData.map((data: any) => (
                                    <MenuItem value={data.value}>{data.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginLeft: "10px", width: "140px" }}>
                        <InputLabel>Date Range Type</InputLabel>
                        <Select
                            value={this.state.currentDateRangeType}
                            label="Month"
                            onChange={(e) => this.ChangeDateRangeType(e)}

                        >
                            {
                                this.state.chartDateRangeTypes.map((data: any) => (
                                    <MenuItem value={data.value}>{data.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                        <InputLabel>Date Ranges</InputLabel>
                        <Select
                            value={this.state.currentDateRange}
                            label="Date Ranges"
                            onChange={(e) => this.ChangeDateRange(e)}
                        >
                            {
                                this.state.dateRanges.map((dateRange: any) => (
                                    <MenuItem value={dateRange.startDate}>{Moment(dateRange.startDate).local().format('MM/DD/YYYY') + " - " + Moment(dateRange.endDate).local().format('MM/DD/YYYY')}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <div style={{ color: "orange", paddingTop: "10px" }}>
                        ***Chart dates are based on your browser timezone
                    </div>
                </CardContent>
                <CardContent>
                    {this.state.dateRanges.length == 0 && <div style={{ color: "red" }}>
                        No Ticketing Records Available.
                    </div>}
                </CardContent>
                <CardContent className="ticketChartDesktopTable">
                    {this.state.currentChartType == ChartTypeCode.Column &&
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart style={{ backgroundColor: 'white' }}
                                height={300}
                                data={this.state.data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="x" />
                                <YAxis />
                                <Tooltip formatter={(value: any) =>
                                    this.state.currentChartDataType == TicketingChartDataTypeCode.TicketsSold
                                        ? value
                                        : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
                                } />
                                <Legend verticalAlign="top" height={40} />
                                <Bar dataKey="y" fill="#FF6358" name={chartTitle} />
                            </BarChart>
                        </ResponsiveContainer>
                    }
                    {this.state.currentChartType == ChartTypeCode.Line &&
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart style={{ backgroundColor: 'white' }}
                                height={300}
                                data={this.state.data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="x" />
                                <YAxis />
                                <Tooltip formatter={(value: any) =>
                                    this.state.currentChartDataType == TicketingChartDataTypeCode.TicketsSold
                                        ? value
                                        : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
                                } />
                                <Legend verticalAlign="top" height={40} />
                                <Line dataKey="y" fill="#FF6358" stroke="#FF6358" name={chartTitle} />
                            </LineChart >
                        </ResponsiveContainer>
                    }
                </CardContent>
                <CardContent className="ticketChartMobileTable">
                    <table>
                        <tr>
                            <th>{this.state.currentDateRangeType == ChartDateRangeTypeCode.Month ? "Days" : "Months"}</th>
                            <th>{this.state.currentChartDataType == TicketingChartDataTypeCode.Revenue ? "Revenue" : "Tickets Sold"}</th>
                        </tr>
                        {this.state.data.map((data: any) => (
                            <tr>
                                <td>{data.x}</td>
                                <td>{this.GetToolTip(data.y)}
                                </td>
                            </tr>
                        ))}
                    </table>
                </CardContent>
            </Card>
        );
    }
}