import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

interface ICheckboxGridCellProps{
    checked: boolean,
    id: any,
    onClick: (id: any) => void;
}

export class CheckboxGridCellV2 extends React.Component<ICheckboxGridCellProps> {

    render() {
        return (
            <Checkbox color="default" checked={this.props.checked} onClick={() => this.props.onClick(this.props.id)} />
        );
    }
}