import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const createEventClusterInvites = async(request: ICreateEventClusterInvitesReqeust): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/eventClusterInvites/batchInvites`, { eventClusterId: request.eventClusterId, eventClusterInvites: request.eventClusterInvites }, tokenConfig);
}

export const updateEventClusterInvite = async(eventClusterInviteId: string, eventClusterRoleId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/eventClusterInvites`, { eventClusterInviteId: eventClusterInviteId, eventClusterRoleId: eventClusterRoleId }, tokenConfig);
}

export const acceptEventClusterInvite = async(eventClusterInviteId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/eventClusterInvites/accept`, { eventClusterInviteId: eventClusterInviteId }, tokenConfig);
}

export const deleteEventClusterInvite = async(eventClusterInviteId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.delete(`${baseAPIUrl}/api/eventClusterInvites?eventClusterInviteId=${eventClusterInviteId}`, tokenConfig);
}

export const declineEventClusterInvite = async(eventClusterInviteId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/eventClusterInvites/decline?eventClusterInviteId=${eventClusterInviteId}`, null, tokenConfig);
}

export const getClusterPendingEventClusterInvites = async(eventClusterId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/eventClusterInvites/eventClusterPendingInvites?eventClusterId=${eventClusterId}`, tokenConfig);
}

const getUserPendingEventClusterInvitesResponse = async(gridState: ITableDef): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/eventClusterInvites/userPendingInvites`, gridState, tokenConfig);
}

export const getUserPendingEventClusterInvites = AwesomeDebouncePromise(getUserPendingEventClusterInvitesResponse, 500);
