/// <reference path="../../../data/DataModels/types.d.ts" />
import { Grid } from '@material-ui/core';
import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { StripeAccountStatusCode } from '../../../data/Models/StripeAccountStatusCode';
import { getAccountInfo } from '../../../data/PortalUserRepository';
import { StripeAccountTypeCode } from '../../../data/Models/StripeAccountTypeCode';
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import { getStates } from '../../../data/StatesRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { saveOrganizationBasicInfo } from '../../../data/OnboardingRepository';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

var Enumerable = require('linq');

export class OrganizationBasicInfoStage extends React.Component<IOnboardingStageProps> {
    public static defaultProps: Partial<IOnboardingStageProps> = {
    }

    state = {
        states: [],
        stripeAccountTypes: [],
        stripeAccountTypeCode: StripeAccountTypeCode.Individual,
        stripeAccountTypeCodeError: '',
        organizationName: '',
        organizationNameError: '',
        bankAccountNumber: '',
        stripeAccountStatus: '',
        stripeAccountStatusCode: StripeAccountStatusCode.Unknown,
        firstName: '',
        firstNameError: '',
        lastName: '',
        lastNameError: '',
        email: '',
        emailError: '',
        phone: '',
        phoneError: '',
        birthday: new Date,
        birthdayError: '',
        addressStreet: '',
        addressStreetError: '',
        addressCity: '',
        addressCityError: '',
        addressState: '',
        addressStateError: '',
        addressPostalCode: '',
        addressPostalCodeError: '',
        organizationPublicUrl: '',
        serverError: '',
    }

    async componentDidMount() {
        this.props.loadingOn();
        let stripeAccountTypes = [
            { id: StripeAccountTypeCode.Individual, name: "Individual" },
            { id: StripeAccountTypeCode.Company, name: "Company" }
        ];

        const query = new URLSearchParams(window.location.search);

        let states = [];
        let serverError = "";

        let response = await getStates();

        let urlParams = "";

        if (response != null && response.status == 401) {
            this.props.changePage(`/login?redirect=save-organization${urlParams}`);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                states = Enumerable.from(response.data.states).select((o: any) => ({ id: o.abbreviation, name: o.abbreviation })).toArray();
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            stripeAccountTypes: stripeAccountTypes,
            states: states,
            serverError: serverError,

            stripeAccountTypeCode: this.props.onboardingState.organization.stripeAccountTypeCode,
            organizationName: this.props.onboardingState.organization.name,
            firstName: this.props.onboardingState.organization.firstName,
            lastName: this.props.onboardingState.organization.lastName,
            email: this.props.onboardingState.organization.email,
            phone: this.props.onboardingState.organization.phone,
            birthday: this.props.onboardingState.organization.birthday,
            addressStreet: this.props.onboardingState.organization.streetAddress,
            addressCity: this.props.onboardingState.organization.city,
            addressState: this.props.onboardingState.organization.state,
            addressPostalCode: this.props.onboardingState.organization.postalCode
        });
        this.props.loadingOff();
    }

    HandleOrganizationNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, organizationName: event.currentTarget.value })
    }

    HandleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, firstName: event.currentTarget.value })
    }

    HandleLastNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, lastName: event.currentTarget.value })
    }

    HandleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, email: event.currentTarget.value })
    }

    HandlePhoneChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, phone: event.currentTarget.value })
    }

    HandleBirthdayChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, birthday: event.currentTarget.value });
    }

    // HandleBusinessTaxIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     this.setState({ ...this.state, businessTaxId: event.currentTarget.value })
    // }

    HandleAddressStreetChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, addressStreet: event.currentTarget.value })
    }

    HandleAddressCityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, addressCity: event.currentTarget.value })
    }

    HandleAddressStateChange = (value: any) => {
        this.setState({ ...this.state, addressState: value })
    }

    HandleAddressPostalCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, addressPostalCode: event.currentTarget.value })
    }

    // HandleRoutingNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     this.setState({ ...this.state, routingNumber: event.currentTarget.value })
    // }

    IsNullOrWhiteSpace = (str: string) => {
        return !(str && str.trim());
    }

    HandleSaveOrganizationBasicInfo = async () => {
        this.props.loadingOn();
        let serverError = "";

        let organizationNameError = "";
        let firstNameError = "";
        let lastNameError = "";
        let addressStreetError = "";
        let addressCityError = "";
        let addressStateError = "";
        let addressPostalCodeError = "";

        let emailError = "";
        let phoneError = "";
        let birthDayError = "";
        let businessTaxIdError = "";

        let errored = false;

        var organization = this.props.onboardingState.organization;

        if (this.state.organizationName == undefined || this.state.organizationName.length == 0) {
            organizationNameError = "Organization Name required.";
            errored = true;
        }

        if (this.state.email == undefined || this.state.email.length == 0) {
            emailError = "Email required.";
            errored = true;
        }

        if (this.state.phone == undefined || this.state.phone.length == 0) {
            phoneError = "Phone number required.";
            errored = true;
        }

        if (this.state.addressStreet == undefined || this.state.addressStreet.length == 0) {
            addressStreetError = "Street Address required.";
            errored = true;
        }

        if (this.state.addressCity == undefined || this.state.addressCity.length == 0) {
            addressCityError = "City required.";
            errored = true;
        }

        if (this.state.addressState == undefined || this.state.addressState.length == 0) {
            addressStateError = "State required.";
            errored = true;
        }

        if (this.state.addressPostalCode == undefined || this.state.addressPostalCode.length == 0) {
            addressPostalCodeError = "Zip code required.";
            errored = true;
        }

        if (this.state.stripeAccountTypeCode == StripeAccountTypeCode.Individual) {
            if (this.state.firstName == undefined || this.state.lastName.length == 0) {
                firstNameError = "First Name required.";
                errored = true;
            }

            if (this.state.lastName == undefined || this.state.lastName.length == 0) {
                lastNameError = "Last Name required.";
                errored = true;
            }

            var todaysDate = new Date();
            var todaysDay = todaysDate.getDate();
            var todaysMonth = todaysDate.getMonth();
            var todaysYear = todaysDate.getFullYear();

            var birthday = new Date(this.state.birthday);

            if (this.state.birthday == undefined || (birthday.getDate() == todaysDay &&
                birthday.getMonth() == todaysMonth && birthday.getFullYear() == todaysYear
            )) {
                birthDayError = "Invalid Date of Birth.";
                errored = true;
            }
        }



        if (errored == false) {

            var response = await this.SaveOrganization();

            if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = response.data.errorMsg;
                }
                else {
                    this.props.onNextStage(response.data);
                    this.props.loadingOff();
                    return;
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            organizationNameError: organizationNameError,
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            emailError: emailError,
            phoneError: phoneError,
            birthdayError: birthDayError,
            businessTaxIdError: businessTaxIdError,
            addressStreetError: addressStreetError,
            addressCityError: addressCityError,
            addressStateError: addressStateError,
            addressPostalCodeError: addressPostalCodeError,
            serverError: serverError
        });

        this.props.loadingOff();
    }

    SaveOrganization = async () => {
        let serverError = "";
        var organization = this.props.onboardingState.organization;

        let address: IStripeAccountAddress = {
            streetAddress: this.state.addressStreet,
            city: this.state.addressCity,
            state: this.state.addressState,
            postalCode: this.state.addressPostalCode
        }


        let request: IOnboardingSaveOrganizationBasicInfo = {
            stripeAccountTypeCode: this.state.stripeAccountTypeCode,
            organizationName: this.state.organizationName,
            firstName: this.state.stripeAccountTypeCode == StripeAccountTypeCode.Individual ? this.state.firstName : undefined,
            lastName: this.state.stripeAccountTypeCode == StripeAccountTypeCode.Individual ? this.state.lastName : undefined,
            email: this.state.email,
            phone: this.state.phone,
            birthday: this.state.stripeAccountTypeCode == StripeAccountTypeCode.Individual ? this.state.birthday : undefined,
            address: address,
        };

        var response = await saveOrganizationBasicInfo(request);

        if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {

            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        return response;
    }

    HandlePrevStage = async () => {
        var response = await this.SaveOrganization();
        if (response != null && response.status == 200 && response.data.success)
            this.props.onPrevStage(response.data.onboardingState);
        else {
            this.setState({ ...this.state, serverError: response.data.errorMsg });
        }
    }

    OnAccountTypeChange = async (value: any) => {
        var firstName = "";
        var lastName = "";
        var serverError = "";

        if (value == StripeAccountTypeCode.Company) {
            firstName = "";
            lastName = "";
        }
        else {
            var result = await this.GetAccountInfoFullName();

            serverError = result.serverError;

            if (serverError == null || serverError == "") {
                firstName = result.firstName;
                lastName = result.lastName;
            }
        }

        this.setState({
            ...this.state,
            stripeAccountTypeCode: value,
            firstName: firstName,
            lastName: lastName,
            serverError: serverError
        });
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: "" });
    };

    OpenCreateConfirmModal = async () => {

        var serverError = "";
        var taxFormSignatureExpected = "";

        if (this.state.stripeAccountTypeCode == StripeAccountTypeCode.Company) {
            var result = await this.GetAccountInfoFullName();
            serverError = result.serverError;

            if (serverError == null || serverError == "") {
                taxFormSignatureExpected = result.firstName + " " + result.lastName;
            }
        }
        else {
            taxFormSignatureExpected = this.state.firstName + " " + this.state.lastName;
        }

        this.setState({
            ...this.state,
            createConfirmModalOpen: true,
            taxFormSignatureExpected: taxFormSignatureExpected,
            serverError: serverError
        });
    }

    GetAccountInfoFullName = async () => {
        var accountInfoResponse = await getAccountInfo();

        var firstName = "";
        var lastName = "";
        var serverError = "";

        let urlParams = "";

        if (accountInfoResponse != null && accountInfoResponse.status == 401) {
            this.props.changePage(`/login`);
        }
        else if (accountInfoResponse != null && accountInfoResponse.status == 200) {
            if (!accountInfoResponse.data.success) {
                serverError = accountInfoResponse.data.errorMsg;
            }
            else {
                firstName = accountInfoResponse.data.firstName;
                lastName = accountInfoResponse.data.lastName;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        return {
            firstName: firstName,
            lastName: lastName,
            serverError: serverError
        };
    }

    CloseCreateModal = () => {
        this.setState({ ...this.state, createConfirmModalOpen: false, taxFormSignatureExpected: "" });
    }


    render() {
        return (
            <React.Fragment>
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">Onboarding - {this.props.onboardingState.timesCompleted > 0 ? "Organization" : "Create Organization"}</Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Card style={{ backgroundColor: "#ededed", marginTop: "10px" }}>
                        <CardContent>
                            <p style={{ marginTop: "0" }}>
                                <strong>
                                    Many of the fields below are used on for the generation of a 1099K form. 1099K details include First Name, Last Name, Tax Id (SSN or ITIN), Street Address, City, State, and Zip Code.
                                </strong>
                            </p>
                            {this.state.stripeAccountTypeCode != StripeAccountTypeCode.None &&
                                <GenericDropdown
                                    label="Account Type (Can not be updated once saved)"
                                    value={this.state.stripeAccountTypeCode}
                                    error={this.state.stripeAccountTypeCodeError}
                                    data={this.state.stripeAccountTypes}
                                    onChange={this.OnAccountTypeChange}
                                    disabled={false}
                                />
                            }
                            {this.state.stripeAccountTypeCode == StripeAccountTypeCode.None &&
                                <TextField
                                    error={this.state.emailError !== ""}
                                    fullWidth
                                    type="text"
                                    label="Account Type (Can not be updated once saved)"
                                    placeholder="Account type"
                                    margin="normal"
                                    FormHelperTextProps={{ color: "red" }}
                                    value="Default "
                                    variant="outlined"
                                    disabled={true}
                                />
                            }
                            <TextField
                                error={this.state.organizationNameError !== ""}
                                fullWidth
                                id="organizationName"
                                type="text"
                                label="Organization Name (Stripe Required)"
                                placeholder="Organization Name (Stripe Required)"
                                margin="normal"
                                helperText={this.state.organizationNameError}
                                FormHelperTextProps={{ color: "red" }}
                                value={this.state.organizationName}
                                onChange={(e) => this.HandleOrganizationNameChange(e)}
                                variant="outlined"
                            />
                            <Grid container spacing={1}>
                                <Grid item sm={6}>
                                    <TextField
                                        error={this.state.emailError !== ""}
                                        fullWidth
                                        id="email"
                                        type="text"
                                        label="Email"
                                        placeholder="Email"
                                        margin="normal"
                                        helperText={this.state.emailError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.state.email}
                                        onChange={(e) => this.HandleEmailChange(e)}
                                        variant="outlined"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <TextField
                                        error={this.state.phoneError !== ""}
                                        fullWidth
                                        id="phone"
                                        type="text"
                                        label="Phone"
                                        placeholder="Phone"
                                        margin="normal"
                                        helperText={this.state.phoneError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.state.phone}
                                        onChange={(e) => this.HandlePhoneChange(e)}
                                        variant="outlined"
                                        disabled={false}
                                    />
                                </Grid>
                            </Grid>

                            {this.state.stripeAccountTypeCode == StripeAccountTypeCode.Individual && <div>

                                <Grid container spacing={1}>
                                    <Grid item sm={4}>
                                        <TextField
                                            error={this.state.firstNameError !== ""}
                                            fullWidth
                                            id="firstName"
                                            type="text"
                                            label="First Name"
                                            placeholder="First Name"
                                            margin="normal"
                                            helperText={this.state.firstNameError}
                                            FormHelperTextProps={{ color: "red" }}
                                            value={this.state.firstName}
                                            onChange={(e) => this.HandleFirstNameChange(e)}
                                            variant="outlined"
                                            disabled={false}
                                        />
                                    </Grid>
                                    <Grid item sm={4}>
                                        <TextField
                                            error={this.state.lastNameError !== ""}
                                            fullWidth
                                            id="lastName"
                                            type="text"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            margin="normal"
                                            helperText={this.state.lastNameError}
                                            FormHelperTextProps={{ color: "red" }}
                                            value={this.state.lastName}
                                            onChange={(e) => this.HandleLastNameChange(e)}
                                            variant="outlined"
                                            disabled={false}
                                        />
                                    </Grid>

                                    <Grid item sm={4}>
                                        <TextField
                                            error={this.state.birthdayError !== ""}
                                            fullWidth
                                            id="birthday"
                                            type="date"
                                            label="Birthday"
                                            placeholder="Birthday"
                                            margin="normal"
                                            helperText={this.state.birthdayError}
                                            FormHelperTextProps={{ color: "red" }}
                                            value={this.state.birthday}
                                            onChange={(e) => this.HandleBirthdayChange(e)}
                                            variant="outlined"
                                            disabled={false}
                                        />
                                    </Grid>
                                </Grid>
                            </div>}

                            <TextField
                                error={this.state.addressStreetError !== ""}
                                fullWidth
                                id="addressStreet"
                                type="text"
                                label="Street Address"
                                placeholder="Street Address"
                                margin="normal"
                                helperText={this.state.addressStreetError}
                                FormHelperTextProps={{ color: "red" }}
                                value={this.state.addressStreet}
                                onChange={(e) => this.HandleAddressStreetChange(e)}
                                variant="outlined"
                                disabled={false}
                            />
                            <Grid container spacing={1}>
                                <Grid item sm={4}>
                                    <TextField
                                        error={this.state.addressCityError !== ""}
                                        fullWidth
                                        id="addressCity"
                                        type="text"
                                        label="City"
                                        placeholder="City"
                                        margin="normal"
                                        helperText={this.state.addressCityError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.state.addressCity}
                                        onChange={(e) => this.HandleAddressCityChange(e)}
                                        variant="outlined"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <GenericDropdown
                                        label="State"
                                        value={this.state.addressState}
                                        error={this.state.addressStateError}
                                        data={this.state.states}
                                        onChange={this.HandleAddressStateChange}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <TextField
                                        error={this.state.addressPostalCodeError !== ""}
                                        fullWidth
                                        id="addressPostalCode"
                                        type="text"
                                        label="Zip code"
                                        placeholder="Zip code"
                                        margin="normal"
                                        helperText={this.state.addressPostalCodeError}
                                        FormHelperTextProps={{ color: "red" }}
                                        value={this.state.addressPostalCode}
                                        onChange={(e) => this.HandleAddressPostalCodeChange(e)}
                                        variant="outlined"
                                        disabled={false}
                                    />

                                </Grid>
                            </Grid>

                        </CardContent>

                    </Card>
                </DialogContent>
                <DialogActions style={{ textAlign: "center", display: "block"}}>
                    {this.props.onboardingState.timesCompleted < 1 && <Button variant="contained"
                        size="large"
                        className='btn-primary'
                        style={{marginTop: "10px", marginBottom: "10px"}}
                        onClick={this.HandlePrevStage}>Prev</Button>}
                    <Button variant="contained"
                        size="large"
                        className='btn-primary'
                        style={{marginTop: "10px", marginBottom: "10px"}}
                        onClick={this.HandleSaveOrganizationBasicInfo}>Next</Button>
                </DialogActions>
                <div>
                    <GenericErrorModal hidden={this.state.serverError == ''}
                        message={this.state.serverError}
                        closeModal={this.CloseErrorModal} />
                </div>
            </React.Fragment>

        )
    }
}