import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckoutForm  from './StripeCheckoutForm';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY + "");

export default function StripeCheckoutLoader(props: any) {

  const appearance = {
    theme: 'stripe',
  };
  const options: any = {
    clientSecret: props.clientSecret,
    appearance,
  };

  return (
    <div className="App" style={{textAlign: "center", width: "100%"}}>
        <div style={{display: "inline-block", width: "100%"}}>
        {props.clientSecret && (
            <Elements options={options} stripe={stripePromise}>
            <StripeCheckoutForm 
              orderId={props.orderId} 
              clientSecret={props.clientSecret} 
              orderExpirationDateTime={props.orderExpirationDateTime}
              email={props.email}
              firstName={props.firstName}
              lastName={props.lastName}
              phoneNumber={props.phoneNumber}
              />
            </Elements>
        )}
      </div>
    </div>
  );
}