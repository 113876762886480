import React from 'react';
import ReactDOM from 'react-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { EventClusterActionCell} from './SubComponents/EventClusterActionCell';
import { PortalUserRoleCode } from '../../data/Models/PortalUserRoleCode';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GenericDisplayCell } from './SubComponents/GenericDisplayCell';
import { TableFilterToggleButton } from './SubComponents/TableFilterToggleButton';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

var Enumerable = require('linq');

interface IEventClustersTableProps{
    eventClusters: any[],
    eventClustersTotal: number,
    tableDef: ITableDef,
    eventClusterServerError: string,
    portalUserRole: PortalUserRoleCode,
    hostAccessRequestable: boolean,
    onFilterChange: (filter: any, value: any) => void,
    onPageChange: (newPage: any) => void, 
    onSortChange: (sort: any) => void,
    changePage: (page: string) => void,
    handleChangeRowsPerPage: (event: any) => void,
    onRequestHostElevation: () => void,
    loading: boolean
}

export class EventClusterTable extends React.Component<IEventClustersTableProps> {

    state = {
        mobileFiltersVisible: false,
        headCells: [
            {
              id: 'eventClusterName',
              label: 'Event Group Name',
            },
            {
              id: 'ownerName',
              label: 'Owner Name',
            },
            {
              id: 'role',
              label: 'Role',
            }
          ]
    }

    async componentDidMount(){
    }
    
    HasCreateEventClusterPermission = () => {
        return this.props.portalUserRole == PortalUserRoleCode.User || 
            this.props.portalUserRole == PortalUserRoleCode.Host || 
            this.props.portalUserRole == PortalUserRoleCode.Admin;
    }

    ToggleFilterVisibility = () => {
        this.setState({...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible});
    }
    
    render()
    {
        var hasCreateEventClusterPermission = this.HasCreateEventClusterPermission();

        var currentSort = Enumerable.from(this.props.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "eventClusterName";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{height: '100%'}}>
                <Backdrop style={{color: '#fff', zIndex: 10000}} open={this.props.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Card style={{height: '100%', backgroundColor: "#ededed"}}>
                        <CardHeader  title="Event Groups"/>
                        <CardContent style={{height: '100%', boxSizing: 'border-box', marginTop: '-64px', paddingTop: '64px', paddingBottom: '16px'}}>
                            {hasCreateEventClusterPermission && <Button variant="contained" className='btn-primary' style={{marginBottom: "20px"}} onClick={() => this.props.changePage("/create-event-group")}>
                                Create Event Group
                            </Button>   }                            
                            <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility}/>
                            <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%'}}>
                                <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    style={{marginLeft: "0.5%", width: "99%"}}
                                    className={this.state.mobileFiltersVisible ? "table-filters-visible event-cluster-table" : "table-filters-hidden"}
                                >
                                    <TableHead>
                                    <TableRow>
                                        {this.state.headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                            className={headCell.id == "eventClusterName" ? "table-header-border show-on-mobile" : "table-header-border no-show-on-mobile"}
                                        >
                                            <TableSortLabel
                                            active={currentSortName === headCell.id }
                                            direction={currentSortName === headCell.id  ? currentSortDir : null}
                                            hideSortIcon={currentSortName !== headCell.id}
                                            onClick={() => this.props.onSortChange(headCell.id)}
                                            style={{width: "100%"}}
                                            >
                                            {headCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                        ))}
                                        <TableCell
                                            className="table-header-border no-show-on-mobile"
                                        >
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {this.props.tableDef.filter!.filters.map((headCell: any) => (
                                        <TableCell className="table-header-border show-on-mobile" >
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                size="small"
                                                onChange={(e) => this.props.onFilterChange(headCell.field, e.currentTarget.value)}
                                                type={headCell.field == "sortOrder" ? "number" : "string"}
                                                value={headCell.value}
                                                variant="outlined"
                                                />
                                        </TableCell>
                                        ))}
                                        <TableCell className="table-header-border no-show-on-mobile">

                                        </TableCell>
                                        <TableCell className="table-header-border no-show-on-mobile">

                                        </TableCell>
                                        <TableCell className="table-header-border no-show-on-mobile">

                                        </TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.props.eventClusters.map((row: any, index: any) => {
                                        return (
                                            <TableRow
                                            hover
                                            >
                                            <TableCell align="right"><GenericDisplayCell title="Event Group Name" value={row.eventClusterName} /></TableCell>
                                            <TableCell align="right">
                                                <GenericDisplayCell title="Owner Name" value={row.ownerName} />
                                            </TableCell>
                                            <TableCell align="right"> 
                                                <GenericDisplayCell title="Role" value={row.role.name} />
                                            </TableCell>
                                            <TableCell align="center"> 
                                                 <EventClusterActionCell changePage={this.props.changePage} 
                                                        eventClusterId={row.eventClusterId}  />
                                             </TableCell>
                                            </TableRow>
                                        );
                                        })}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={this.props.eventClustersTotal}
                                rowsPerPage={this.props.tableDef.take!}
                                page={this.props.tableDef.skip! > 0 ? this.props.tableDef.skip! / this.props.tableDef.take! : 0}
                                onChangePage={(e, page) => this.props.onPageChange(page)}
                                onChangeRowsPerPage={(e) => this.props.handleChangeRowsPerPage(e)}
                                />
                            </Paper>
                            </Box>
                        </CardContent>
                        <CardContent>
                        <div style={{color: 'red'}} hidden={this.props.eventClusterServerError == ''}>
                            {this.props.eventClusterServerError}
                        </div>
                    </CardContent>
                </Card>
            </div>
        )
    }
}