/// <reference path="../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { createEventCluster } from '../../data/EventClusterRepository';
import { getAllCreatedOrganizations } from '../../data/OrganizationRepository';
import { GenericDropdown } from '../Components/SubComponents/GenericDropdown';
import { StripeAccountTypeCode } from '../../data/Models/StripeAccountTypeCode';

var Enumerable = require('linq');


interface ICreateEventClusterPageProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class CreateEventClusterPage extends React.Component<ICreateEventClusterPageProps> {


    state = {
        clusterName: '',
        description: '',
        clusterNameError: '',
        descriptionError: '',
        organizations: [],
        organizationId: '',
        organizationIdError: '',
        serverError: ''
    }

    async componentDidMount(){
        this.props.loadingOn();

        let serverError = '';
        let organizations = [];

        var response = await getAllCreatedOrganizations(true, "");

        if(response != null && response.status == 401){
            this.props.changePage("/login?redirect=create-event-group");
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                organizations = Enumerable.from(response.data.organizations)
                    .select((o: any) => 
                        ({
                            id: o.id, 
                            name: o.stripeAccountTypeCode == StripeAccountTypeCode.None
                                ? o.name + " (free tickets only)"
                                : o.name + " - " + o.bankRoutingNumber ?? "N/A" + " - ***" + o.bankAccountNumber}))
                    .toArray();
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, organizations: organizations});

        this.props.loadingOff();
    }

    HandleClusterNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, clusterName: event.currentTarget.value})
    }

    HandleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, description: event.currentTarget.value})
    }

    CreateNewClusterClick = async () => {

        let clusterNameError = '';
        let organizationIdError = '';
        let errored = false;

        let serverErrorText = '';

        if(this.state.clusterName.length == 0){
            clusterNameError = 'Group name is required.'
            errored = true;
        }

        if(this.state.organizationId == undefined || this.state.organizationId.length == 0){
            organizationIdError = 'Organization is required.'
            errored = true;
        }

        if(errored == false)
        {

            let request: ICreateEventClusterReqeust = {
                name: this.state.clusterName,
                organizationId: this.state.organizationId,
                description: this.state.description
            };

            var response = await createEventCluster(request);

            if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverErrorText = response.data.errorMsg;
                }
                else{
                    this.props.changePage("/event-group?id=" + response.data.eventClusterId)
                }
            }
            else{
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }
        
        this.setState({...this.state, clusterNameError: clusterNameError, organizationIdError: organizationIdError, serverError: serverErrorText});
    }

    OnOrganizationChange = (value: any) => {
        this.setState({...this.state, organizationId: value})
    }

    render()
    {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <Card style={{height: '100%', backgroundColor: "#ededed"}}>
                    <CardHeader title="Create New Group" />
                    <CardContent >
                        <TextField 
                            error={this.state.clusterNameError !== ""}
                            fullWidth
                            id="clusterName"
                            label="Group Name"
                            placeholder="Group Name"
                            margin="normal"
                            helperText={this.state.clusterNameError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.clusterName}
                            onChange={(e) => this.HandleClusterNameChange(e)}
                            variant="outlined"
                            />
                         <GenericDropdown 
                                    label="Payout Organization (Bank Account)"
                                    value={this.state.organizationId}
                                    error={this.state.organizationIdError}
                                    data={this.state.organizations}
                                    onChange={this.OnOrganizationChange}
                                    style={{marginTop: "10px"}}
                                />
                        <TextField 
                                error={this.state.descriptionError !== ""}
                                fullWidth
                                style={{backgroundColor: "white"}}
                                multiline={true}
                                rowsMax={5}
                                id="description"
                                label="Description (optional)"
                                placeholder="Description (optional)"
                                margin="normal"
                                helperText={this.state.descriptionError}
                                FormHelperTextProps={{ color: "red" }}
                                value={this.state.description}
                                onChange={(e) => this.HandleDescriptionChange(e)}
                                variant="outlined"
                                />
                    </CardContent>
                    <CardContent>
                        <div style={{paddingTop: '20px'}}>
                            <Button variant="contained" className='btn-primary' onClick={this.CreateNewClusterClick}  >
                                Create New Group
                            </Button>
                        </div>
                    </CardContent>
                    <CardContent>
                    <div style={{color: 'red'}} hidden={this.state.serverError == ''}>
                        {this.state.serverError}
                    </div>
                    </CardContent>
                </Card>
            </div>
        )
    }
}