import React from 'react';
import '../App.css';
import {PortalUserRoleCode} from "../../data/Models/PortalUserRoleCode";
import { LoginPage } from '../Pages/LoginPage';
import { HomePage } from '../Pages/HomePage';
import { MainDashboard } from './MainDashboard/MainDashboard';
import { AnalyticsDashboard } from './Analytics/AnalyticsDashboard';
import { ForgotPasswordPage } from '../Pages/ForgotPasswordPage';
import { SignUpPage } from '../Pages/SignUpPage';
import { SignUpSuccessPage } from '../Pages/SignUpSuccessPage';
import { ContactPage } from '../Pages/ContactPage';
import { ConfirmRegistrationPage } from '../Pages/ConfirmRegistrationPage';
import { AccountInfoPage } from '../Pages/AccountInfoPage';
import { CreateEventClusterPage } from '../Pages/CreateEventClusterPage';
import { EventClusterPage } from './EventCluster/EventClusterPage';
import { ResentRegistrationConfirmationPage } from '../Pages/ResendRegistrationConfirmationPage';
import { CreateEventPage } from './Events/CreateEventPage';
import { EventPage } from './Events/EventPage';
import { EventClustersDashboard } from './EventCluster/EventClustersDashboard';
import { AllEventsPage } from './Events/AllEventsPage';
import { AdminAllEventsPage } from './Events/AdminAllEventsPage';
import { AdminSalesTaxPage } from './AdminSalesTaxPage';
import { AdminPortalUserDashboard } from './PortalUsers/AdminPortalUserDashboard';
import { EventClusterPermissionDashboard } from './EventClusterPermissions/EventClusterPermissionDashboard';
import { GenerateStripeSetupUrlPage } from '../Pages/GenerateStripeSetupUrlPage';
import { PortalUserOrganizationsPage } from './Organizations/PortalUserOrganizationsPage';
import { UserEventTicketScanPage } from './UserEventTickets/UserEventTicketScanPage';
import { PublicOrganizationProfile } from './Organizations/PublicOrganizationProfile';
import { TicketPurchasePage } from './UserEventTickets/TicketPurchasePage';
import { TicketPurchaseSuccessPage } from './UserEventTickets/TicketPurchaseSuccessPage';
import { DocumentationPage } from './Documention/DocumentationPage';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import { AdminFinancialsDashboard } from './Financials/AdminFinancialsDashboard';
import { OptoutPage } from '../Pages/OptoutPage';

interface IRouterSectionProps{
    showOnboarding: boolean,
    authenticated: boolean,
    portalUserRole: PortalUserRoleCode,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void,
    onShowOnboarding: () => void
}

export class RouterSection extends React.Component<IRouterSectionProps> {
    
    render(){

        var scrollable = window.location.pathname !== '/event-public-details';

        return (
            <div style={scrollable ? {overflowY: "scroll", height: "100%"} : {height: "100%"}}>
                <div style={{padding: "16px"}}>
                    <Router>
                        <Switch>
                            {/* <Route path="/instructions">
                                <DocumentationPage />
                            </Route> */}
                            <Route path="/login">
                                <LoginPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}/>
                            </Route>
                            <Route path="/forgot-password">
                                <ForgotPasswordPage loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}/>
                            </Route>
                            <Route path="/sign-up">
                                <SignUpPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/sign-up-success">
                                <SignUpSuccessPage changePage={this.props.changePage}/>
                            </Route>
                            <Route path="/contact">
                                <ContactPage />
                            </Route>
                            <Route path="/dashboard">
                                <MainDashboard changePage={this.props.changePage}  />
                            </Route>
                            <Route path="/account-info">
                                <AccountInfoPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                                <Route path="/payment-account-signup-generate">
                                <GenerateStripeSetupUrlPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/organizations/public">
                                <PublicOrganizationProfile changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/organizations">
                                <PortalUserOrganizationsPage 
                                    showOnboarding={this.props.showOnboarding}
                                    onShowOnboarding={this.props.onShowOnboarding}
                                    changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/confirm-registration">
                                <ConfirmRegistrationPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/resend-confirmation">
                                <ResentRegistrationConfirmationPage loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/event-group-dashboard">
                                <EventClustersDashboard changePage={this.props.changePage} portalUserRole={this.props.portalUserRole} />
                            </Route>
                            <Route path="/all-events">
                                <AllEventsPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/event-group">
                                <EventClusterPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/create-event-group" render= { () => <CreateEventClusterPage loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}  changePage={this.props.changePage}/>} />
                            <Route path="/create-event">
                                <CreateEventPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/event/tickets">
                               <TicketPurchasePage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/order/confirmation">
                               <TicketPurchaseSuccessPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/event">
                                <EventPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/portal-users-dashboard">
                                <AdminPortalUserDashboard changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/permissions-dashboard">
                                <EventClusterPermissionDashboard changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}  />
                            </Route>
                            <Route path="/admin-all-events">
                                <AdminAllEventsPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/scan-user-event-ticket">
                                <UserEventTicketScanPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/analytics">
                                <AnalyticsDashboard changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}/>
                            </Route>
                            <Route path="/admin-financials">
                                <AdminFinancialsDashboard changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}/>
                            </Route>
                            <Route path="/admin-sales-tax">
                                <AdminSalesTaxPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/emailActions/OptOutPaymentConfirmationEmailToCreator">
                                <OptoutPage loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff} />
                            </Route>
                            <Route path="/">
                                {this.props.authenticated == false && <LoginPage changePage={this.props.changePage} loadingOn={this.props.loadingOn} loadingOff={this.props.loadingOff}/>}
                                {this.props.authenticated == true && <MainDashboard changePage={this.props.changePage}  />}
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </div>
            );
    }
}