export enum EventClusterPermissionCode{
    None = 0,
    ViewEventClusterDetails = 1,
    EditEventClusterDetails = 2,
    ViewEvents = 3,
    CreateEvents = 4,
    EditEvents = 5,
    DeleteOrCancelEvents = 6,
    ViewTeam = 7,
    EditTeamMember = 8,
    DeleteTeamMember = 9,
    ViewRoles = 10,
    AddRoles = 11,
    EditRoles = 12,
    DeleteRoles = 13,
    ViewInvites = 14,
    InviteUsers = 15,
    EditInvites = 16,
    DeleteInvites = 17,
    ViewEventAnalytics = 18
}