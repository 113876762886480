import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { getTicketingChart, getTicketingReportYears, getUserFinancialsFile } from '../../../data/UserEventTicketRepository';
import { getAllEventClustersAutocomplete } from '../../../data/EventClusterRepository';
import Moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { TicketingChartDataTypeCode } from "../../../data/Models/TicketingChartDataTypeCode";
import { ChartDateRangeTypeCode } from "../../../data/Models/ChartDateRangeTypeCode";
import { ChartTypeCode } from "../../../data/Models/ChartTypeCode";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { AutoComplete } from 'material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button, Grid } from '@material-ui/core';
import saveAs from 'file-saver';

var Enumerable = require('linq');

interface ITicketingChartProps {
    changePage: (page: string) => void,
    loadingOn: () => void,
    loadingOff: () => void
}

export class TicketingChart extends React.Component<ITicketingChartProps> {

    state = {
        currentChartType: ChartTypeCode.Column,
        currentDateRangeType: ChartDateRangeTypeCode.Month,
        currentChartDataType: TicketingChartDataTypeCode.TicketsSold,
        currentDateRange: "",
        data: [],
        chartData: [],
        chartTypes: [],
        eventClusters: [],
        eventClusterId: "",
        eventClusterName: "",
        chartDateRangeTypes: [],
        dateRanges: [],

        totalTicketsSold: 0,
        ticketsPerMonth: 0,
        serviceFeeTotal: 0,
        salesTaxTotal: 0,
        processingFeeTotal: 0,
        estimatedPayoutTotal: 0,

        reportYears: [],
        reportYear: 0,

        serverError: '',

        loading: false
    }

    async componentDidMount() {
        await this.LoadEventClusters(this.state.eventClusterName);
        await this.LoadReportYears();
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, this.state.eventClusterId);
    }

    LoadData = async (currentDateRangeType: any, currentChartDataType: any, currentDateRange: any, eventClusterId: string) => {
        this.LoadingOn();

        let data: any[] = [];
        let dateRanges: any[] = [];
        let chartData: any[] = [{ name: "Tickets Sold", value: TicketingChartDataTypeCode.TicketsSold }, { name: "Revenue", value: TicketingChartDataTypeCode.Revenue }];
        let chartTypes: any[] = [{ name: "Column", value: ChartTypeCode.Column }, { name: "Line", value: ChartTypeCode.Line }];
        let chartDateRangeTypes: any[] = [{ name: "Month", value: ChartDateRangeTypeCode.Month }, { name: "Year", value: ChartDateRangeTypeCode.Year }];
        let serverError = '';

        let totalTicketsSold = 0;
        let ticketsPerMonth = 0;
        let serviceFeeTotal = 0;
        let salesTaxTotal = 0;
        let processingFeeTotal = 0;
        let estimatedPayoutTotal = 0;

        var response = await getTicketingChart(currentDateRange, currentDateRangeType, currentChartDataType, eventClusterId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=analytics");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                dateRanges = response.data.searchableDateRanges;
                data = Enumerable.from(response.data.chartDataList)
                    .select((d: any) => ({
                        x: Moment(d.xAxisDate).local().format(currentDateRangeType == ChartDateRangeTypeCode.Month ? "MM/DD" : "MM/YYYY"),
                        y: parseFloat(d.yValue)
                    }))
                    .toArray();
                totalTicketsSold = response.data.totalTicketsSold;
                ticketsPerMonth = response.data.ticketsPerMonth;
                serviceFeeTotal = response.data.serviceFeeTotal;
                salesTaxTotal = response.data.salesTaxTotal;
                processingFeeTotal = response.data.processingFeeTotal;
                estimatedPayoutTotal = response.data.estimatedPayoutTotal;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        currentDateRange = (currentDateRangeType != this.state.currentDateRangeType ||
            currentDateRange == "" || currentDateRange == undefined) && dateRanges.length > 0 ? dateRanges[0].startDate : currentDateRange;

        this.setState({
            ...this.state,
            currentDateRangeType: currentDateRangeType,
            currentChartDataType: currentChartDataType,
            currentDateRange: currentDateRange,
            data: data,
            chartTypes: chartTypes,
            chartData: chartData,
            dateRanges: dateRanges,
            chartDateRangeTypes: chartDateRangeTypes,
            serverError: serverError,
            eventClusterId: eventClusterId,

            totalTicketsSold: totalTicketsSold,
            ticketsPerMonth: ticketsPerMonth,
            serviceFeeTotal: serviceFeeTotal,
            salesTaxTotal: salesTaxTotal,
            processingFeeTotal: processingFeeTotal,
            estimatedPayoutTotal: estimatedPayoutTotal
        });

        this.LoadingOff();
    }

    ChangeChartType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.setState({
            ...this.state,
            currentChartType: event.target.value
        });
    }

    ChangeReportYear = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.setState({
            ...this.state,
            reportYear: event.target.value
        });
    }

    ChangeChartDataType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.LoadData(this.state.currentDateRangeType, event.target.value, this.state.currentDateRange, this.state.eventClusterId);
    }

    ChangeDateRangeType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.LoadData(event.target.value, this.state.currentChartDataType, "", this.state.eventClusterId);
    }

    ChangeDateRange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, event.target.value, this.state.eventClusterId);
    }

    GetToolTip = (point: any) => {
        let value = this.state.currentChartDataType == TicketingChartDataTypeCode.Revenue ? `$${Number(point).toFixed(2)}` : point;

        return value;
    }

    LoadingOn = () => {
        this.setState({ ...this.state, loading: true });
    }

    LoadingOff = () => {
        this.setState({ ...this.state, loading: false });
    }

    LoadEventClusters = async (name: string) => {
        let eventClusters: any[] = [];
        let serverError = '';

        var response = await getAllEventClustersAutocomplete(name);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=analytics");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                response.data.eventClusters.forEach((c: any) => {
                    let codeValuePermissions = EventClusterPermissionCode.ViewEventAnalytics;
                    var hasPermission = c.role.isOwnerRole || Enumerable.from(c.role.eventClusterPermissions).any((p: EventClusterPermission) => p.id == codeValuePermissions);

                    if (hasPermission == true) {
                        eventClusters.push(c);
                    }
                });
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            eventClusters: eventClusters,
            serverError: serverError
        });
    }

    LoadReportYears = async () => {
        let reportYears: any[] = [];
        let reportYear = (new Date()).getFullYear();
        let serverError = '';

        var response = await getTicketingReportYears(this.state.eventClusterId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=analytics");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                reportYears = response.data.years;
                if (reportYears.length > 0)
                    reportYear = reportYears[0];
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            reportYears: reportYears,
            reportYear: reportYear,
            serverError: serverError
        });
    }

    HandleEventClusterNameChange = async (event: any) => {
        let eventClusterName = event.currentTarget.value;
        await this.LoadEventClusters(eventClusterName);
        await this.LoadReportYears();
    }

    SelectEventCluster = async (cluster: any) => {
        this.state.eventClusterName = cluster?.eventClusterName ?? '';
        this.setState({ ...this.state, eventClusterId: cluster?.eventClusterId ?? '' });
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, cluster?.eventClusterId ?? '');
    }

    GenerateReport = async () => {
        this.props.loadingOn();
        let serverError = '';

        var response = await getUserFinancialsFile(this.state.reportYear.toString(), this.state.eventClusterId);

        if (response.status == 401) {
            this.props.changePage("/login?redirect=admin-financials");
        }
        else if (response.status == 200) {
            const blob = new Blob([response.data], {
                type: 'text.csv',
            });
            let fileName = `Nrby-Financials-${this.state.reportYear}`
            if (this.state.eventClusterId && this.state.eventClusterId.trim())
                fileName = fileName + " - " + this.state.eventClusterName
            saveAs(blob, fileName + '.csv')
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            serverError: serverError
        });
        this.props.loadingOff();
    }

    formatYAxisTick = (value: number): string => {
        if (this.state.currentChartDataType == TicketingChartDataTypeCode.TicketsSold)
            return value.toString();
        return "$" + value.toString();
    }

    render() {
        let chartTitle = Enumerable.from(this.state.chartData).where((d: any) => d.value == this.state.currentChartDataType).firstOrDefault()?.name;

        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Card style={{ backgroundColor: "white" }} >
                            <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <CardContent>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.eventClusters}
                                        style={{ width: "100%" }}
                                        getOptionLabel={(option: any) => option.eventClusterName}
                                        onChange={(e, cluster) => this.SelectEventCluster(cluster)}
                                        renderInput={(params) =>
                                            <TextField {...params} placeholder="Event Group(s)" variant="outlined"
                                                onChange={(e) => this.HandleEventClusterNameChange(e)} />}
                                    />
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Card style={{ backgroundColor: "white" }} >
                            <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <CardContent>
                                <FormControl variant="outlined" style={{ marginLeft: "10px", flexDirection: "row" }}>
                                    <InputLabel>Year</InputLabel>
                                    <Select
                                        value={this.state.reportYear}
                                        label="Chart Type"
                                        onChange={(e) => this.ChangeReportYear(e)}
                                    >
                                        {
                                            this.state.reportYears.map((data: any) => (
                                                <MenuItem value={data}>{data}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    &nbsp;
                                    <Button variant="contained" className='btn-primary' onClick={this.GenerateReport}  >
                                        Generate Report
                                    </Button>

                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} style={{ flexGrow: "1" }} alignItems="flex-end">
                        <Card style={{ backgroundColor: "white", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }} >
                            <CardContent style={{ textAlign: "center", marginTop: "auto", marginBottom: "auto" }}>
                                <div style={{ width: "100%", verticalAlign: "middle", textAlign: "center" }}>
                                    <div style={{ fontWeight: "bold", fontSize: "26px" }}>
                                        Total Ticket Sold
                                    </div>
                                    <div style={{ width: "100%", fontSize: "20px" }}>
                                        {this.state.totalTicketsSold}
                                    </div>
                                    <div style={{ fontWeight: "bold", fontSize: "26px" }}>
                                        Tickets Per Month
                                    </div>
                                    <div style={{ width: "100%", fontSize: "20px" }}>
                                        {Math.round(this.state.ticketsPerMonth * 100) / 100}
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                        <Card style={{ backgroundColor: "white" }}>
                            <CardContent style={{}}>
                                <div style={{ width: "100%", verticalAlign: "middle" }}>
                                    <div style={{ width: "100%", textAlign: "center", fontWeight: "bold", fontSize: "26px" }}>
                                        Total Revenue
                                    </div>
                                    <div style={{ width: "100%", fontSize: "16px" }}>
                                        <table>
                                            <tr>
                                                <td>
                                                    Service Fee Total<br />
                                                    ($2/ticket)
                                                </td>
                                                <td>
                                                    ${this.state.serviceFeeTotal.toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Sales Tax Total
                                                </td>
                                                <td>
                                                    ${this.state.salesTaxTotal.toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Processing Fee Total<br />
                                                    (3% Stripe Processing Fee)
                                                </td>
                                                <td>
                                                    ${this.state.processingFeeTotal.toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Estimated Payout Total
                                                </td>
                                                <td>
                                                    ${this.state.estimatedPayoutTotal.toFixed(2)}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Card style={{ backgroundColor: "white", marginTop: "10px" }} className="ticketingChartCard">
                    <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <CardContent>
                        <FormControl variant="outlined" className="chartTypeContainer" style={{ marginLeft: "10px" }}>
                            <InputLabel>Chart Type</InputLabel>
                            <Select
                                value={this.state.currentChartType}
                                label="Chart Type"
                                onChange={(e) => this.ChangeChartType(e)}
                            >
                                {
                                    this.state.chartTypes.map((data: any) => (
                                        <MenuItem value={data.value}>{data.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                            <InputLabel>Chart Data</InputLabel>
                            <Select
                                value={this.state.currentChartDataType}
                                label="Chart Data"
                                onChange={(e) => this.ChangeChartDataType(e)}
                            >
                                {
                                    this.state.chartData.map((data: any) => (
                                        <MenuItem value={data.value}>{data.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ marginLeft: "10px", width: "140px" }}>
                            <InputLabel>Date Range Type</InputLabel>
                            <Select
                                value={this.state.currentDateRangeType}
                                label="Month"
                                onChange={(e) => this.ChangeDateRangeType(e)}

                            >
                                {
                                    this.state.chartDateRangeTypes.map((data: any) => (
                                        <MenuItem value={data.value}>{data.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                            <InputLabel>Date Ranges</InputLabel>
                            <Select
                                value={this.state.currentDateRange}
                                label="Date Ranges"
                                onChange={(e) => this.ChangeDateRange(e)}
                            >
                                {
                                    this.state.dateRanges.map((dateRange: any) => (
                                        <MenuItem value={dateRange.startDate}>{Moment(dateRange.startDate).local().format('MM/DD/YYYY') + " - " + Moment(dateRange.endDate).local().format('MM/DD/YYYY')}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <div style={{ color: "orange", paddingTop: "10px" }}>
                            ***Chart dates are based on your browser timezone
                        </div>
                    </CardContent>
                    <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <CardContent className="ticketChartDesktopTable">
                        {this.state.dateRanges.length == 0 && <div style={{ color: "red" }}>
                            No Ticketing Records Available.
                        </div>}
                        {this.state.currentChartType == ChartTypeCode.Column &&
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart style={{ backgroundColor: 'white' }}
                                    height={300}
                                    data={this.state.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="x" />
                                    <YAxis allowDecimals={this.state.currentChartDataType != TicketingChartDataTypeCode.TicketsSold}
                                        tickFormatter={this.formatYAxisTick} />
                                    <Tooltip formatter={(value: any) =>
                                        this.state.currentChartDataType == TicketingChartDataTypeCode.TicketsSold
                                            ? value
                                            : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
                                    } />
                                    <Legend verticalAlign="top" height={40} />
                                    <Bar dataKey="y" fill="#FF6358" name={chartTitle} />
                                </BarChart>
                            </ResponsiveContainer>
                        }
                        {this.state.currentChartType == ChartTypeCode.Line &&
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart style={{ backgroundColor: 'white' }}
                                    height={300}
                                    data={this.state.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="x" />
                                    <YAxis />
                                    <Tooltip formatter={(value: any) =>
                                        this.state.currentChartDataType == TicketingChartDataTypeCode.TicketsSold
                                            ? value
                                            : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
                                    } />
                                    <Legend verticalAlign="top" height={40} />
                                    <Line dataKey="y" fill="#FF6358" stroke="#FF6358" name={chartTitle} />
                                </LineChart >
                            </ResponsiveContainer>
                        }
                    </CardContent>
                    <CardContent className="ticketChartMobileTable">
                        <table>
                            <tr>
                                <th>{this.state.currentDateRangeType == ChartDateRangeTypeCode.Month ? "Days" : "Months"}</th>
                                <th>{this.state.currentChartDataType == TicketingChartDataTypeCode.Revenue ? "Revenue" : "Tickets Sold"}</th>
                            </tr>
                            {this.state.data.map((data: any, index: number) => (
                                <tr>
                                    <td>{data.x}</td>
                                    <td>{this.GetToolTip(data.y)}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </CardContent>
                </Card>
            </div>
        );
    }
}