import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { GeneralPredictiveAnalyticsSection } from "./GeneralPredictiveAnalyticsSection";

import { TicketingChart } from './TicketingChart';
import { EventCustomersTable } from '../SubComponents/EventCustomersTable';
interface IAnalyticsDashboardProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class AnalyticsDashboard extends React.Component<IAnalyticsDashboardProps> {
    state = {
        currentTab: 0
    }

    HandleChange = (newValue: number) => {
        this.setState({ ...this.state, currentTab: newValue });
    }

    A11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    render() {
        return (
            <div style={{ height: '100%', width: "100%" }}>
                <div style={{ width: "100%", marginTop: "10px" }}>
                    <Card style={{ backgroundColor: "#ededed" }}>
                        <CardHeader title="Analytics" />
                        <CardContent>
                            <Paper square
                                style={{ width: "100%" }}
                            >
                                <Tabs
                                    value={this.state.currentTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    onChange={(e, v) => this.HandleChange(v)}
                                    style={{ width: "100%" }}
                                    className="tabs-split-3"
                                    centered={true}
                                >
                                    <Tab label="Financial"  {...this.A11yProps(0)} />
                                    <Tab className="MuiTab-root-override" label="Predictive" {...this.A11yProps(1)} />
                                    <Tab className="MuiTab-root-override" label="Customers" {...this.A11yProps(2)} />
                                </Tabs>
                            </Paper>
                            {this.state.currentTab === 0 && <Box p={3} style={{ height: "100%" }} className="analytics-tab-content">
                                <Typography style={{ height: "100%" }} >
                                    <TicketingChart
                                        changePage={this.props.changePage}
                                        loadingOn={this.props.loadingOn}
                                        loadingOff={this.props.loadingOff}
                                    />
                                </Typography>
                            </Box>}
                            {this.state.currentTab === 1 && <Box p={3} style={{ height: "100%" }} className="analytics-tab-content">
                                <Typography style={{ height: "100%" }}>
                                    <GeneralPredictiveAnalyticsSection
                                        loadingOn={this.props.loadingOn}
                                        loadingOff={this.props.loadingOff}
                                        changePage={this.props.changePage}
                                    />
                                </Typography>
                            </Box>}
                            {this.state.currentTab === 2 && <Box p={3} style={{ height: "100%" }} className="analytics-tab-content">
                                <EventCustomersTable
                                    loadingOn={this.props.loadingOn}
                                    loadingOff={this.props.loadingOff}
                                    changePage={this.props.changePage}
                                    eventId=''
                                >
                                </EventCustomersTable>
                        </Box>}
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}