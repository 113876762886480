import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const saveTicketOptions = async(request: ISaveTicketOptionsRequest): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/ticketoptions`, request, tokenConfig);
}

export const getTicketOptions = async(eventId: any): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/ticketoptions?eventId=${eventId}`, tokenConfig);
}

export const getPurchaseTicketOptions = async(eventId: any, orderId: any): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var baseAPIUrl = process.env.REACT_APP_MOBILE_API_BASE_URL;

    var fullUrl = `${baseAPIUrl}/api/ticketoptions?eventId=${eventId}`;

    if(orderId != undefined && orderId != ""){
        fullUrl += `&orderId=${orderId}`;
    }

    return await axios.get(fullUrl);
}