import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { SalesTaxFlagCode } from './Models/SalesTaxFlagCode';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const getEventSalesTaxSettings = async(eventId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    return await axios.get(`${baseAPIUrl}/api/eventSalesTax?eventId=${eventId}`, tokenConfig);
}

export const updateEventSalesTaxSettings = async(eventId: string, salesTaxFlagCode: SalesTaxFlagCode, salesTaxExempt: boolean) : Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    return await axios.patch(`${baseAPIUrl}/api/eventSalesTax`, { eventId: eventId, salesTaxFlagCode: salesTaxFlagCode, salesTaxExempt: salesTaxExempt }, tokenConfig);
}
