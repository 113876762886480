import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { PortalUserRoleCode } from './Models/PortalUserRoleCode';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig => {
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };

    return axiosConfig;
}

export const authenticate = async (username: string, password: string, stayLoggedIn: boolean): Promise<AxiosResponse> =>  {

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
      };

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/authenticate`, { username: username, password: password, stayLoggedIn: stayLoggedIn }, axiosConfig);
}

export const becomeUser = async (username: string): Promise<AxiosResponse> =>  {

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/become`, { username: username }, tokenConfig);
}

export const getAuthStatus = async (): Promise<AxiosResponse> => {

    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    let axiosConfig = GetTokenConfig();

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/portalusers/authStatus`, axiosConfig);
}

export const sendPasswordResetEmail = async (username: string): Promise<AxiosResponse> => {

    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/resetPasswordEmail`, { username: username });
}

export const signUpNewUser = async (signUpRequest: ISignUpRequest): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/signup`, signUpRequest);
}

export const getAccountInfo = async (): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var axiosConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/portalusers`, axiosConfig);
}

export const updateAccountInfo = async (updateAccountInfo: IUpdateAccountInfoRequest): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var axiosConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers`, updateAccountInfo, axiosConfig);
}

export const updateAccountInfoPassword = async (password: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var axiosConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/password`, { password: password }, axiosConfig);
}

export const confirmRegistration = async (token: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/confirmationAuthStatus`, {}, axiosConfig);
}

export const resendConfirmationEmail = async (username: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/emailConfirmation`, { username: username });
}

const getAllPortalUsersResponse = async (gridState: ITableDef): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var formData = GetGridStateFormData(gridState);

    return await axios.post(`${baseAPIUrl}/api/portalUsers/list`, formData, tokenConfig);
}

export const getAllPortalUsers = AwesomeDebouncePromise(getAllPortalUsersResponse, 500);

const searchPortalUsersResponse = async (searchTerm: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    const formData = new FormData();
    formData.append('searchTerm', searchTerm);

    return await axios.post(`${baseAPIUrl}/api/portalUsers/search`, formData, tokenConfig);
}

export const searchPortalUsers = AwesomeDebouncePromise(searchPortalUsersResponse, 500);

export const GetGridStateFormData = (gridState: ITableDef): FormData => {

    const formData = new FormData();
    formData.append('skip', JSON.stringify(gridState.skip));
    formData.append('take', JSON.stringify(gridState.take));
    formData.append('sort', JSON.stringify(gridState.sort));
    formData.append('filter', JSON.stringify(gridState.filter));

    return formData;
}

export const updatePortalUserRole = async (portalUserId: string, portalUserRoleCode: PortalUserRoleCode): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/role`, { portalUserId: portalUserId, portalUserRoleCode: portalUserRoleCode }, tokenConfig);
}

export const getPortalUserNotificationsResponse = async (): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var formData = {};

    return await axios.post(`${baseAPIUrl}/api/portalUsers/notifications`, formData, tokenConfig);
}

export const getPortalUserNotifications = AwesomeDebouncePromise(getPortalUserNotificationsResponse, 700);

export const dismissPortalUserNotification = async (notificationId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var formData = {
        notificationId: notificationId
    };

    return await axios.post(`${baseAPIUrl}/api/portalUsers/dismissNotification`, formData, tokenConfig);
}

export const changeNotificationsSettings = async (suppressPaymentConfirmationEmailToCreator: boolean): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();
    
    var data = {
        suppressPaymentConfirmationEmailToCreator: suppressPaymentConfirmationEmailToCreator
    };

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/portalusers/changeNotificationsSettings`, data, tokenConfig);
}

export const getPortalUserNotificationsReviewedResponse = async (): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var formData = {};

    return await axios.post(`${baseAPIUrl}/api/portalUsers/notificationsReviewed`, formData, tokenConfig);
}