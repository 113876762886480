import * as React from 'react';
import { FormControlLabel, FormLabel, Grid, RadioGroup, Radio, InputLabel, Select, MenuItem, TextField, Button } from '@material-ui/core';
import { savePaidTicketing } from '../../../data/OnboardingRepository';
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

export class PaidTicketingStage extends React.Component<IOnboardingStageProps> {
    public static defaultProps: Partial<IOnboardingStageProps> = {
    }

    HandleNextButtonClick = async () => {
        var response = await savePaidTicketing(this.props.onboardingState.paidTicketing);
        if (response != null && response.status == 401) {
            this.props.changePage("/login");
        } else if (response.status != 200) {
            return;
        }
        this.props.onNextStage(response.data);
    }

    HandleChangeOnPaidTicketing = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onboardingState.paidTicketing = event.target.value == 'true';
        this.forceUpdate();
    }


    render() {
        return (
            <React.Fragment>
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">Onboarding</Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <h1>Are you going to use paid ticketing?</h1>
                    <div>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={this.props.onboardingState.paidTicketing}
                            onChange={this.HandleChangeOnPaidTicketing}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </div>
                </DialogContent>
                <DialogActions disableSpacing={true} style={{ textAlign: "center", display: "block"}}>
                    <Button onClick={this.HandleNextButtonClick} className='btn-primary' size="large" style={{marginTop: "10px", marginBottom: "10px"}}>
                        Next
                    </Button>
                </DialogActions>
           </React.Fragment>
        );
    }
}