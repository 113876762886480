export enum TicketOptionType{
    GeneralAdmission = 0,
    AssignedSeating = 1
}

export enum EventStatusCode{
    OnSale = 0,
    Cancelled = 1,
    SalesEnded = 2,
    SoldOut = 3,
    Postponed = 4
}