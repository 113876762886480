import React from 'react';
import {  EventClusterTable } from '../EventClusterTable';
import { PortalUserEventClusterInviteTable } from '../PortalUserEventClusterInviteTable';
import {PortalUserRoleCode} from "../../../data/Models/PortalUserRoleCode";
import { requestHostAccess } from '../../../data/PortalUserRolesRepository';
import { getEventClusters } from '../../../data/EventClusterRepository';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';

var Enumerable = require('linq');

interface IEventClustersDashboardProps{
    portalUserRole: PortalUserRoleCode,
    changePage: (page: string) => void
}

const getInitTableDef = () =>{
    let sortDef: ISortDef = {
        field: "eventClusterName",
        dir: "asc"
    };

    let filterArray = [
        {
            field: "eventClusterName",
            operator: "eq",
            value: ""
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let gridState: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return gridState;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 2,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class EventClustersDashboard extends React.Component<IEventClustersDashboardProps> {

    state = {
        tableDef: getInitTableDef(),
        pageable: getInitPageable(),
        eventClusters: [],
        eventClustersTotal: 0,
        hostAccessRequestable: false,
        loading: false,
        eventClusterServerError: '',
        requestHostAccessSuccess: false,
        requestHostAccessServerError: ''
    }

    async componentDidMount(){
        this.LoadingOn();

        this.RerenderEventClusterTable(this.state.tableDef);       
        this.LoadingOff();
    }

    RerenderEventClusterTable = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let eventClusterServerError = ''

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "")).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        let response = await getEventClusters(tempTableDef);

        let eventClusters = [];
        let eventClustersTotal = 0;
        let hostAccessRequestable = false;

        if(response != null && response.status == 401){
            this.props.changePage("/login?redirect=event-group-dashboard");
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                eventClusterServerError = response.data.errorMsg;
            }
            else{
                eventClusters = response.data.eventClusters;
                eventClustersTotal = response.data.total;
                hostAccessRequestable = response.data.hostAccessRequestable;
            }
        }
        else{
            eventClusterServerError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, 
            eventClusters: eventClusters, 
            eventClusterServerError: eventClusterServerError, 
            eventClustersTotal: eventClustersTotal, 
            tableDef: tableDef,
            hostAccessRequestable: hostAccessRequestable});
    }

    OnEventClustersTableOnSortChange = async (sort: any) => {
        let tableDef = this.state.tableDef;

        if(tableDef != null && tableDef.sort!.length > 0){
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.RerenderEventClusterTable(tableDef);
        }
    }

    OnEventClustersTableFilterChange = async(filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({f, index})).firstOrDefault((fi: any) => fi.f.field == filter);

        filterDef.f.value = value;
        
        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.RerenderEventClusterTable(tableDef);
    }

    OnEventClustersTablePageChange = async (newPage: any) =>{
        this.LoadingOn();
        
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.RerenderEventClusterTable(tableDef);

        this.LoadingOff();
    }

    HandleChangeEventClusterTableRowsPerPage = async (event: any) => {
        this.LoadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.RerenderEventClusterTable(tableDef);
        this.LoadingOff();
    };

    OnRequestHostElevation = async () => {
        this.LoadingOn();
        var serverError = "";
        var success = false;

        var response = await requestHostAccess();

        if(response != null && response.status == 401){
            this.props.changePage("/login?redirect=event-group-dashboard");
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                await this.RerenderEventClusterTable(this.state.tableDef);
                success = true;
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, requestHostAccessSuccess: success, requestHostAccessServerError: serverError});
        this.LoadingOff();
    }

    LoadingOn = () => {
        this.setState({...this.state, loading: true});
    }

    LoadingOff = () => {
        this.setState({...this.state, loading: false});
    }

    CloseRequestHostAccessSuccessModal = () => {
        this.setState({...this.state, requestHostAccessSuccess: false});
    }

    CloseRequestHostAccessErrorModal = () => {
        this.setState({...this.state, requestHostAccessServerError: ''});
    }

    RefreshEventClusterTable = async () => {
        await this.RerenderEventClusterTable(this.state.tableDef)
    }
   
    render()
    {
        return (
            <div style={{ height: '100%', width: '100%', paddingTop: "20px" }}>
                <div className="eventClusterGridContainer">
                    <EventClusterTable 
                        eventClusters={this.state.eventClusters}
                        eventClustersTotal={this.state.eventClustersTotal}
                        tableDef={this.state.tableDef}
                        hostAccessRequestable={this.state.hostAccessRequestable}
                        onFilterChange={this.OnEventClustersTableFilterChange}
                        onPageChange={this.OnEventClustersTablePageChange}
                        onSortChange={this.OnEventClustersTableOnSortChange}
                        handleChangeRowsPerPage={this.HandleChangeEventClusterTableRowsPerPage}
                        changePage={this.props.changePage} 
                        onRequestHostElevation={this.OnRequestHostElevation}
                        eventClusterServerError={this.state.eventClusterServerError}
                        portalUserRole={this.props.portalUserRole}
                        loading={this.state.loading}
                        />
                </div>
                <div className="eventClusterInviteGridContainer">
                    <PortalUserEventClusterInviteTable changePage={this.props.changePage} refreshParent={this.RefreshEventClusterTable}/>
                </div>
                <GenericSuccessModal 
                    hidden={this.state.requestHostAccessSuccess == false}
                    message="Event group hosting access requested successfully."
                    closeModal={this.CloseRequestHostAccessSuccessModal}
                    />
                <GenericErrorModal hidden={this.state.requestHostAccessServerError == ''}
                    message={this.state.requestHostAccessServerError}
                    closeModal={this.CloseRequestHostAccessErrorModal} />   
            </div>
        )
    }
}