import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const scanUserEventTicket = async(userEventTicketId: string, saveScan?: boolean): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var url = `${baseAPIUrl}/api/userEventTicketScans/scan?userEventTicketId=${userEventTicketId}`;

    if(saveScan != null){
        url += `&saveScan=${saveScan}`;
    }

    return await axios.get(url, tokenConfig);
}
