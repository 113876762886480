/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import TextField from '@material-ui/core/TextField';
import CurrencyInput from 'react-currency-input-field';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import '../../Styles/EventTicketingFormSection.css';
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
    MuiPickersUtilsProvider,
    DateTimePicker 
  } from '@material-ui/pickers';

interface IPricingAdjustmentFieldsContainerProps{
    pricingAdjustmentName: string,
    pricingAdjustmentNameError: string,
    pricingAdjustmentAmount: number,
    pricingAdjustmentAmountError: string,
    pricingAdjustmentAmountCode: number,
    pricingAdjustmentAmountCodeError: string,
    pricingAdjustmentCode: number,
    pricingAdjustmentCodeError: string,
    pricingAdjustmentStartDate: MaterialUiPickersDate,
    pricingAdjustmentStartDateError: string,
    pricingAdjustmentEndDate: MaterialUiPickersDate,
    pricingAdjustmentEndDateError: string,
    pricingAdjustmentCoupon: string,
    pricingAdjustmentCouponError: string,
    pricingAdjustmentAmountCodes: any[],
    pricingAdjustmentCodes: any[],
    handlePricingAdjustmentNameChange: (value: any) => void,
    handlePricingAdjustmentAmountChange: (value: any) => void,
    handlePricingAdjustmentAmountCodeChange: (value: any) => void,
    handlePricingAdjustmentCodeChange: (value: any, pricingAdjustmentCoupon: any) => void,
    handlePricingAdjustmentCouponChange: (value: any) => void,
    handleAdjustmentStartDateChange: (date: MaterialUiPickersDate) => void,
    handleAdjustmentEndDateChange: (date: MaterialUiPickersDate) => void,
}

export class PricingAdjustmentFieldsContainer extends React.Component<IPricingAdjustmentFieldsContainerProps> {
    
    state = {
        discountAmountFocused: false
    }
    HandlePricingAdjustmentNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.handlePricingAdjustmentNameChange(event.target.value);
    }

    HandlePricingAdjustmentCouponChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.handlePricingAdjustmentCouponChange(event.target.value);
    }

    HandlePricingAdjustmentAmountChange = (event: any) => {

        const inputField = event.currentTarget;
        let cursorPosition = inputField.selectionStart;
        let value = inputField.value;
    
        // Adjust cursor position to reflect position before new character is added
    
        if(this.props.pricingAdjustmentAmountCode === 0){
            cursorPosition = cursorPosition - 1;
        }
        // Remove non-numeric characters for processing
        value = value.replace(/[^\d.]/g, '');
    
        // Get the character the user has just typed
        const typedChar = event.nativeEvent.data;
        const decimalPosition = value.indexOf('.');

        if (typedChar && !isNaN(typedChar)) {
            if (this.props.pricingAdjustmentAmountCode === 1) {
                // For percentage
                if (decimalPosition !== -1 && (cursorPosition > decimalPosition || cursorPosition < decimalPosition)) {
                    // Replace digit after the decimal point
                    value = value.substring(0, cursorPosition - 1) + typedChar + value.substring(cursorPosition + 1);
                } 
            } else if (this.props.pricingAdjustmentAmountCode === 0) {
                // For currency
                if (decimalPosition !== -1 && cursorPosition > decimalPosition) {
                    // Replace digits after the decimal
                    value = value.substring(0, cursorPosition -1) + typedChar + value.substring(cursorPosition + 1);
                } 
            }
        }

        let setValue = this.props.pricingAdjustmentAmount;

        if(decimalPosition != -1){
            if(value == undefined){
                setValue = 0.00;
            }else if(this.props.pricingAdjustmentAmountCode == 0){
                if (value && !Number.isNaN(Number(value)) && Number(value) <= 1000000000) {
                    setValue = parseFloat(parseFloat(value).toFixed(2));
                }
            }
            else if(this.props.pricingAdjustmentAmountCode == 1){
                if (value && !Number.isNaN(Number(value)) && Number(value) <= 1000) {
                    setValue = parseFloat(parseFloat(value).toFixed(2));
                }
            }
        }
        
        this.props.handlePricingAdjustmentAmountChange(setValue);

        setTimeout(() => {
            if (this.props.pricingAdjustmentAmountCode === 0) {
                inputField.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
            } else {
                inputField.setSelectionRange(cursorPosition, cursorPosition);
            }
        }, 0);
    }

    HandlePricingAdjustmentCodeChange = (value: any) => {
        let pricingAdjustmentCoupon = this.props.pricingAdjustmentCoupon;

        if(value != 1){
            pricingAdjustmentCoupon = '';
        }

        this.props.handlePricingAdjustmentCodeChange(value, pricingAdjustmentCoupon);
    }

    DiscountAmountFocus = (focused: boolean) => {
        this.setState({...this.state, discountAmountFocused: focused})
    }

    render(){
        let discountAmountValue = "0";

        if(this.props.pricingAdjustmentAmountCode == 0){
            discountAmountValue = "$" + ((this.props.pricingAdjustmentAmount ?? 0)).toFixed(2);
        }
        else if(this.props.pricingAdjustmentAmountCode == 1){
            discountAmountValue =  ((this.props.pricingAdjustmentAmount ?? 0)).toFixed(2) + "%";
        }

        return (
            <div style={{width: "100%"}}>
                <div style={{color: '#f44336', fontSize: '0.75rem'}} hidden={this.props.pricingAdjustmentNameError == '' && this.props.pricingAdjustmentNameError != null}>
                    {this.props.pricingAdjustmentNameError}
                </div>
                <div style={{color: '#f44336', fontSize: '0.75rem'}} hidden={this.props.pricingAdjustmentAmountError == '' && this.props.pricingAdjustmentAmountError != null}>
                    {this.props.pricingAdjustmentAmountError}
                </div>
                <div style={{color: '#f44336', fontSize: '0.75rem'}} hidden={this.props.pricingAdjustmentStartDateError == '' && this.props.pricingAdjustmentStartDateError != null}>
                    {this.props.pricingAdjustmentStartDateError}
                </div>
                <div style={{color: '#f44336', fontSize: '0.75rem'}} hidden={this.props.pricingAdjustmentEndDateError == '' && this.props.pricingAdjustmentEndDateError != null}>
                    {this.props.pricingAdjustmentEndDateError}
                </div>
                <div style={{color: '#f44336', fontSize: '0.75rem'}} hidden={this.props.pricingAdjustmentCouponError == '' && this.props.pricingAdjustmentCouponError != null}>
                    {this.props.pricingAdjustmentCouponError}
                </div>
                            
                <table  className="PricingAdjustmentModalFieldTable">
                    <tr style={{width: "100%"}}>
                        <td style={{width: "33.3%"}}>
                            <TextField 
                                error={this.props.pricingAdjustmentNameError !== "" && this.props.pricingAdjustmentNameError !== undefined}
                                style={{width: '100%'}}
                                id="pricingAdjustmentName"
                                label="Name"
                                placeholder="Name"
                                margin="normal"
                                FormHelperTextProps={{ color: "red" }}
                                value={this.props.pricingAdjustmentName}
                                onChange={(e) => this.HandlePricingAdjustmentNameChange(e)}
                                variant="outlined"
                            />
                        </td>
                        <td style={{width: "33.3%"}}>
                            <GenericDropdown 
                                label="Amount Discount Type"
                                value={this.props.pricingAdjustmentAmountCode}
                                error={this.props.pricingAdjustmentAmountCodeError}
                                data={this.props.pricingAdjustmentAmountCodes}
                                onChange={this.props.handlePricingAdjustmentAmountCodeChange}
                                style={{marginTop: "10px"}}
                            />
                        </td>
                        <td style={{width: "33.3%"}}>
                            <TextField
                                style={{ width: '100%' }}
                                label="Discount Amount"
                                margin="normal"
                                value={discountAmountValue}
                                inputProps={{ maxLength: 200 }}
                                variant="outlined"
                                onChange={(e) => this.HandlePricingAdjustmentAmountChange(e)}
                                error={this.props.pricingAdjustmentAmountError !== "" && this.props.pricingAdjustmentAmountError !== undefined}
                            />
                        </td>
                    </tr>
                </table>
                <table  style={{width: "100%"}}>
                    <tr>
                        {this.props.pricingAdjustmentCode == 1 && <td style={{width: "50%"}}>
                            <TextField 
                                    error={this.props.pricingAdjustmentCouponError !== "" && this.props.pricingAdjustmentCouponError !== undefined}
                                    style={{width: '100%'}}
                                    id="pricingAdjustmentCoupon"
                                    label="Coupon Code"
                                    placeholder="Coupon Code"
                                    margin="normal"
                                    FormHelperTextProps={{ color: "red" }}
                                    value={this.props.pricingAdjustmentCoupon}
                                    onChange={(e) => this.HandlePricingAdjustmentCouponChange(e)}
                                    variant="outlined"
                                />
                        </td>}
                    </tr>
                </table>
                <table style={{width: "100%"}}>
                    <tr>
                        <td style={{width: "50%"}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}> 
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Start Date/Time"
                                    inputVariant="outlined"
                                    defaultValue={this.props.pricingAdjustmentStartDate}
                                    value={this.props.pricingAdjustmentStartDate}
                                    onChange={(e) => this.props.handleAdjustmentStartDateChange(e)}
                                    error={this.props.pricingAdjustmentStartDateError !== "" && this.props.pricingAdjustmentStartDateError !== undefined}
                                    FormHelperTextProps={{ color: "red" }}
                                    format="MM/dd/yyyy hh:mm a"
                                    style={{width: "100%"}}
                                />
                                </MuiPickersUtilsProvider>
                        </td>
                        <td style={{width: "50%"}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="End Date"
                                    inputVariant="outlined"
                                    defaultValue={this.props.pricingAdjustmentEndDate}
                                    value={this.props.pricingAdjustmentEndDate}
                                    onChange={(e) => this.props.handleAdjustmentEndDateChange(e)}
                                    error={this.props.pricingAdjustmentEndDateError !== "" && this.props.pricingAdjustmentEndDateError !== undefined}
                                    FormHelperTextProps={{ color: "red" }}
                                    format="MM/dd/yyyy hh:mm a"
                                    style={{width: "100%"}}
                                />
                            </MuiPickersUtilsProvider>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }
}