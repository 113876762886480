import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig => {
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };

    return axiosConfig;
}

export const getEventClusterRoles = async (eventClusterId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/EventClusterRoles/list?eventClusterId=${eventClusterId}`, tokenConfig);
}

export const addEventClusterRole = async (eventClusterId: string, name: string, eventClusterPermissionIds: any[]): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/EventClusterRoles`, { eventClusterId: eventClusterId, name: name, eventClusterPermissionIds: eventClusterPermissionIds }, tokenConfig);
}

export const updateEventClusterRole = async (eventClusterRoleId: string, name: string, eventClusterPermissionIds: any[]): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/EventClusterRoles`, { eventClusterRoleId: eventClusterRoleId, name: name, eventClusterPermissionIds: eventClusterPermissionIds }, tokenConfig);
}

export const deleteEventClusterRole = async (eventClusterRoleId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.delete(`${baseAPIUrl}/api/EventClusterRoles?eventClusterRoleId=${eventClusterRoleId}`, tokenConfig);
}

export const getPortalUserEventClusterRole = async (eventClusterId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/EventClusterRoles/portalUserEventClusterRole?eventClusterId=${eventClusterId}`, tokenConfig);
}

export const getPortalUserEventClusterRoleByEventId = async (eventId: string | null): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/EventClusterRoles/portalUserEventClusterRoleByEventId?eventId=${eventId}`, tokenConfig);
}