import * as React from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';

interface IEventFinancialsTableProps{
    totalIncome: number,
    totalFees: number,
    totalTicketsPurchased: number,
    payoutDateTime: Date | undefined,
    payoutAttempts: number,
    payoutSuccessful: boolean
}

export class EventFinancialsTable extends React.Component<IEventFinancialsTableProps> {

    render() {
        return (
            <table style={{width: "90%"}}>
                <tr>
                    <td className="financialsLabel">Total Income:</td> 
                    <td>
                        <NumberFormat value={this.props.totalIncome.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </td>
                </tr>
                <tr>
                    <td className="financialsLabel">Total Nrby Fees (Including Sales Tax):</td> 
                    <td>
                        <NumberFormat value={this.props.totalFees.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </td>
                </tr>
                <tr>
                    <td className="financialsLabel">Total Tickets Purchased:</td> 
                    <td>{this.props.totalTicketsPurchased}</td>
                </tr>
                <tr>
                    <td className="financialsLabel">{this.props.payoutSuccessful ? "Payout Date/Time:": "Next Scheduled Payout Attempt:"}</td>
                    <td>{this.props.payoutDateTime != undefined ? Moment(this.props.payoutDateTime).local().format('MM/DD/YYYY h:mm A') : "No payout scheduled"}</td>
                </tr>
                <tr>
                    <td className="financialsLabel">Payout Attempts (Limit of 15)</td>
                    <td>{this.props.payoutAttempts}</td>
                </tr>
                <tr>
                    <td className="financialsLabel">Payout Successful:</td>
                    <td>{this.props.payoutSuccessful.toString()}</td>
                </tr>
            </table>
        );
    }
}