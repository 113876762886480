import * as React from 'react';
import { getAuthStatus } from '../../data/PortalUserRepository';
import {PortalUserRoleCode} from "../../data/Models/PortalUserRoleCode";
import { OnboardingStageCode } from '../../data/Models/OnboardingStageCode';

interface IAuthCheckProps{
    setAuthorization: (authenticated: boolean, portalUserRole: PortalUserRoleCode, onboardingComplete: boolean, onboardingCompletedCount: number, onboardingCurrentStage: OnboardingStageCode, newNotifications: boolean) => void
}

export class AuthCheck extends React.Component<IAuthCheckProps> {

    async componentDidMount() {
        const response = await getAuthStatus();
        
        let authenticated = true;
        let onboardingComplete = false;
        let onboardingCompletedCount = 0;
        let onboardingCurrentStage = OnboardingStageCode.PaidTicketing;
        let role: PortalUserRoleCode = PortalUserRoleCode.None;
        let newNotifications = false;

        if(response == undefined || response.status != 200)
        {
            localStorage.removeItem("accessToken");
            authenticated = false;
        }
        else{
            localStorage.setItem("accessToken", response.data.token);
            role = response.data.role;
            onboardingComplete = response.data.onboardingComplete;
            onboardingCompletedCount = response.data.onboardingCompletedCount;
            onboardingCurrentStage = response.data.onboardingCurrentStage;
            newNotifications = response.data.newNotifications;
        }
        
        this.props.setAuthorization(authenticated, role, onboardingComplete, onboardingCompletedCount, onboardingCurrentStage, newNotifications);
    }

    render() {
        return null;
    }
}