import * as React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

interface IEventClusterCellProps{
    eventClusterId: string
    changePage: (page: string) => void
}

export class EventClusterActionCell extends React.Component<IEventClusterCellProps> {

    NavigateToButtonClick = () => {
        this.props.changePage("/event-group?id=" + this.props.eventClusterId);
    }

    render() {
        return (
            <td>
                <div style={{textAlign: "center"}}>
                    <Button variant="contained" color="secondary" onClick={this.NavigateToButtonClick}  >
                        View
                    </Button>
                </div>
            </td>
        );
    }
}