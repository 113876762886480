import React, { useEffect, useState } from "react";
import { GenericConfirmationModal} from '../GenericConfirmationModal';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function StripeCheckoutForm(props: any) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);

  const [confirmationModalVisible, setConfirmationModalVisible] = useState<any>(false);

  const [paymentAttempts, setPaymentAttemps] = useState<any>(0);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.clientSecret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          if(paymentAttempts > 0){
            setMessage("Your payment was not successful, please try again.");
          }
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async () => {
    setConfirmationModalVisible(true);
  }

  const closeConfirmationModal = async () => {
    setConfirmationModalVisible(false);
  }

  const handleSubmitConfirmation = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    closeConfirmationModal();

    let errored = false;

    var currentDateTime = new Date();

    var orderExpirationDateTime = new Date(props.orderExpirationDateTime);
    if(orderExpirationDateTime < currentDateTime){
      setMessage("Your order has expired. Please go back to the beginning and try again.");
      errored = true;
    }
    else{
      setMessage("");
    }

    if(errored){
      setIsLoading(false);
      return;
    }
    
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_BASE_URL}/order/confirmation?orderId=${props.orderId}&email=${props.email}&firstName=${props.firstName}&lastName=${props.lastName}&phoneNumber=${props.phoneNumber}&dateTimeSubmitted=${currentDateTime.toString()}`,
      },
    });

    setPaymentAttemps((previous: number) => previous + 1);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <div style={{width: "100%"}}> 
      <div id="payment-form" >
        <div>
          <PaymentElement id="payment-element" />
          <div style={{width: "100%", textAlign: "center"}}>
          <button disabled={isLoading || !stripe || !elements} id="submit" className="paymentSubmit" style={{width: "80%", display: "inline-block"}} onClick={handleSubmit}>
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "PAY NOW"}
            </span>
          </button>
          </div>
          {/* Show any error or success messages */}
          {message && <div id="payment-message" style={{color: "red"}}>{message}</div>}
          <GenericConfirmationModal 
            hidden={confirmationModalVisible == false}
            cancelButtonText="Cancel" confirmButtonText="Yes"
            onConfirm={handleSubmitConfirmation}
            closeModal={closeConfirmationModal}
            message="Are you sure you want to finalize this order?"
          />
        </div>
      </div>
    </div>
  );
}