import * as React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

interface IInviteUserActionCellProps{
    dataIndex: number,
    deleteInviteUser: (dataIndex: number) => void
}

export class InviteUserActionCell extends React.Component<IInviteUserActionCellProps> {

    state={
        email: '',
        emailError: ''
    }

    DeleteButtonClick = () => {
        this.props.deleteInviteUser(
            this.props.dataIndex
        );
    }

    render() {
        return (
            <td>
                <Button variant="contained" color="secondary" onClick={this.DeleteButtonClick}  >
                    Delete
                </Button>
            </td>
        );
    }
}