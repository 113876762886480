import React from 'react';
import { TicketingChart } from '../Analytics/TicketingChart';
import { UpcomingEventsSection } from './UpcomingEventsSection';

interface IMainDashboardProps{
    changePage: (page: string) => void
}

export class MainDashboard extends React.Component<IMainDashboardProps> {
    render()
    {
        return (
            <div style={{height: '100%', width: "100%"}}>
                <div style={{width: "100%"}}>
                    <UpcomingEventsSection changePage={this.props.changePage} />
                </div>
            </div>
        );
    }
}