/// <reference path="../../../data/DataModels/types.d.ts" />
import { getSimilarEvents } from '../../../data/AnalyticsRepository'
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { KeywordDisplayCell } from './KeywordDisplayCell';
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import React from 'react';
import { DateRangeFilter } from '../SubComponents/DateRangeFilter';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {stableSort, getComparator } from '../../Shared/MuiStaticTableFunctions';
import FormControl from '@material-ui/core/FormControl';

var Enumerable = require('linq');

interface ISimilarEventsTableProps{
    similarEvents: any[],
    keywordsList: any[],
    loadSimilarEvents: (startDateRange: any, finishDateRange: any, filterByKeyword: string) => void,
    changePage: (page: string) => void
}

interface ISimilarEventsTableState {
    eventId: string,
    pageable: any,
    tableDef: ITableDef,
    headCells: any[],
    mobileFiltersVisible: boolean,
    startDateRange?: Date,
    finishDateRange?: Date,
    filterByKeyword: string,
}

const getInitTableDef = () =>{
    let softDef: ISortDef = {
        field: "eventName",
        dir: "asc"
    };

    let filterArray = [
        {
            field: "eventName",
            operator: "eq",
            value: ""
        },
        {
            field: "startDateTimeLocal",
            operator: "eq",
            value: ""
        },
        {
            field: "distance",
            operator: "eq",
            value: ""
        },
        {
            field: "sharedKeywordCount",
            operator: "eq",
            value: ""
        }
    ];
    
    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let gridState: ITableDef = {
        skip: 0,
        take: 10,
        sort: [softDef],
        filter: tableFilters
    };

    return gridState;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class SimilarEventsTable extends React.Component<ISimilarEventsTableProps, ISimilarEventsTableState> {
   
    state: ISimilarEventsTableState = {
        eventId: '',
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
                id: 'eventName',
                label: 'Event Name',
            },
            {
                id: 'startDateTimeLocal',
                label: 'Start Date/Time',
            },
            {
                id: 'distance',
                label: 'Distance',
            },
            {
                id: 'sharedKeywordCount',
                label: 'Shared Keyword Count'
            },
            {
                id: 'keywords',
                label: 'Keywords'
            }
        ],
        mobileFiltersVisible: false,
        startDateRange: undefined,
        finishDateRange: undefined,
        filterByKeyword: '',
    };

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        if (filter == "salesTaxRateAdjustment") {
            if (value == null || value == "") {
                filterDef.f.value = "";
            }
            else {
                filterDef.f.value = parseFloat(value);
            }
        }
        else {
            filterDef.f.value = value;
        }

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        this.setState({...this.state, tableDef: tableDef});
    }

    OnSortChange = async (sort: any) => {
        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            this.setState({...this.state, tableDef: tableDef});
        }
    }

    PageChange = async (newPage: any) => {
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        this.setState({...this.state, tableDef: tableDef});
    }

    HandleChangeRowsPerPage = async (event: any) => {
        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        this.setState({...this.state, tableDef: tableDef});
    };

    ToggleFilterVisibility = () => {
        this.setState({...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible});
    }
  
    handleKeywordFilterChange = async(obj:any)=>{
        this.setState({...this.state, filterByKeyword: obj});
        await this.props.loadSimilarEvents(this.state.startDateRange, this.state.finishDateRange, obj);
    }

    handleDateFilterChange = async (event: any) => {
        if (event.operator === "gt") {
          this.state.startDateRange = event.value;
        } else {
          this.state.finishDateRange = event.value;
        }
        this.setState({...this.state, startDateRange: this.state.startDateRange, finishDateRange: this.state.finishDateRange});
        await this.props.loadSimilarEvents(this.state.startDateRange, this.state.finishDateRange, this.state.filterByKeyword);
    };
   
    render()
    {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "eventName";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        var filters = this.state.tableDef.filter != null ? Enumerable.from(this.state.tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "") || (f.value === false || f.value === true)).toArray() : [];

        var eventNameFilter = Enumerable.from(filters).firstOrDefault((f: any) => f.field == "eventName")?.value.toLowerCase();
        var distanceFilter = Enumerable.from(filters).firstOrDefault((f: any) => f.field == "distance")?.value;
        var sharedKeywordCountFilter = Enumerable.from(filters).firstOrDefault((f: any) => f.field == "sharedKeywordCount")?.value;

        var filteredSimilarEvents = Enumerable.from(this.props.similarEvents).where((s: any) => (eventNameFilter == null || s.eventName.toLowerCase().indexOf(eventNameFilter) > -1) && 
                        (distanceFilter == null || s.distance == distanceFilter) && 
                        (sharedKeywordCountFilter == null || s.sharedKeywordCount == sharedKeywordCountFilter)).toArray();

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <h3>
                    Similar Events
                </h3>
                <div style={{height: "100%"}}>
                <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility}/>
                    <Box style={{ width: '100%' }}>
                        <Paper style={{ width: '100%' }}>
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    style={{ marginLeft: "0.5%", width: "99%" }}
                                    className={this.state.mobileFiltersVisible ? "table-filters-visible event-comparison-table" : "table-filters-hidden"}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {this.state.headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                    className="table-header-border show-on-mobile"
                                                >
                                                    <TableSortLabel
                                                        active={currentSortName === headCell.id}
                                                        direction={currentSortName === headCell.id ? currentSortDir : null}
                                                        hideSortIcon={currentSortName !== headCell.id}
                                                        onClick={() => this.OnSortChange(headCell.id)}
                                                        style={{ width: "100%" }}
                                                    >
                                                        {headCell.label}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                                <TableCell className="table-header-border show-on-mobile" >
                                                    {headCell.field != "startDateTimeLocal" ? <TextField
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                        value={headCell.value}
                                                        type={headCell.field == "salesTaxRateAdjustment" ? "number" : "string"}
                                                        variant="outlined"
                                                    /> :
                                                    <DateRangeFilter 
                                                        min={this.state.startDateRange}
                                                        max={this.state.finishDateRange}
                                                        onDateFilterChange={this.handleDateFilterChange} />
                                                        }
                                                </TableCell>
                                            ))}
                                            <TableCell className="table-header-border show-on-mobile">
                                                <FormControl variant="filled" size="small" fullWidth>
                                                    <Select
                                                        value={this.state.filterByKeyword}
                                                        label="Keywords"
                                                        onChange={(e) => this.handleKeywordFilterChange(e.target.value)}
                                                        variant="outlined"
                                                        margin="dense"
                                                        style={{ marginTop: "5px" }}
                                                        fullWidth
                                                    >
                                                        <MenuItem value="">(All)</MenuItem>
                                                        {this.props.keywordsList.map((keyword: any) => (
                                                        <MenuItem value={keyword}>{keyword}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stableSort(filteredSimilarEvents, getComparator(currentSortDir, currentSortName))
                                            .slice(this.state.tableDef.skip, this.state.tableDef!.skip! + this.state.tableDef!.take!)
                                            .map((row: any, index: any) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                >
                                                    <TableCell align="right"><GenericDisplayCell title="Event Name" value={row.eventName} /></TableCell>
                                                    <TableCell align="right"><GenericDisplayCell title="Start Date/Time" value={row.startDateTimeLocal}/></TableCell>
                                                    <TableCell align="right"><GenericDisplayCell title="Distance" value={row.distance} /></TableCell>
                                                    <TableCell align="right"><GenericDisplayCell title="Shared Keyword Count" value={String(row.sharedKeywordCount)} /></TableCell>
                                                    <TableCell align="right"> <KeywordDisplayCell title="Keywords" keywords={row.keywords} /></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={this.props.similarEvents.length}
                                rowsPerPage={this.state.tableDef.take!}
                                page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                onChangePage={(e, page) => this.PageChange(page)}
                                onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                            />
                        </Paper>
                    </Box>
                </div>
            </div>
        )
    }
}

