import * as React from 'react';
import TextField from '@material-ui/core/TextField';

interface ITextFieldProps{
    title: any,
    dataIndex: number,
    focusedId: string,
    inputCursorStart: number | null,
    idPrefix: string
    value: string,
    placeHolder: string
    error: string,
    editMode: boolean,
    onChange: (dataIndex: number, value: any, id: string, inputCursorStart: number | null) => void;
    removeFocusedId: () => void
}

export class TextFieldCell extends React.Component<ITextFieldProps> {

    HandleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        this.props.onChange(
            this.props.dataIndex,
            event.target.value,
            event.target.id,
            event.target.selectionStart
        );
    }


    render() {
        return (
            <td>
                <div className="genericDisplayCellTitle">
                    {this.props.title}:
                </div>
                <div className="genericDisplayCellContent">
                    {this.props.editMode == false && <div>
                        {this.props.value}
                    </div>}
                    {this.props.editMode && <TextField 
                        error={this.props.error !== "" && this.props.error != undefined}
                        id={this.props.idPrefix + this.props.dataIndex}
                        placeholder={this.props.placeHolder}
                        fullWidth
                        margin="normal"
                        helperText={this.props.error}
                        FormHelperTextProps={{ color: "red" }}
                        value={this.props.value}
                        onChange={(e) => this.HandleChange(e)}
                        autoFocus={this.props.focusedId == (this.props.idPrefix + this.props.dataIndex)}
                        onFocus={(e) => {
                            e.target.selectionStart = this.props.inputCursorStart;
                            e.target.selectionEnd = this.props.inputCursorStart;
                        }}
                        variant="outlined"
                        //onBlur={this.props.removeFocusedId}
                        />}
                </div>
            </td>
        );
    }
}