import React from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup, Radio, InputLabel, Select, MenuItem, TextField, Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions } from '@material-ui/core';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import CloseIcon from '@material-ui/icons/Close';

interface IEmbedEventsHtmlModalProps {
    eventIds: Array<string>,
    visible: boolean,
    closeModal: () => void
}
export class EmbedEventsHtmlModal extends React.Component<IEmbedEventsHtmlModalProps> {

    state = {
        htmlCode: '',
        platformType: "standard",
        doNotShowCancelledEvents: false,
        useReadMore: false,
        hideVenueAndAddress: false,
        hideAgeRestrictions: false,
        hideDescription: false,
        widgetTheme: '',
        customBackgroundColor: false,
        backgroundColor: createColor('black'),
        customEventNameTextColor: false,
        eventNameTextColor: createColor('black'),
        customOtherTextColor: false,
        otherTextColor: createColor('black'),
        customFontFamily: false,
        fontFamily: '',
        customFontScale: false,
        fontScale: "1.0",
        customSpacing: false,
        spacingValue: '',
        spacingUnits: '',
        customSpacingBackground: false,
        spacingBackground: createColor('black'),
        customReadMoreColor: false,
        readMoreColor: createColor('black'),
        customReadMoreBackgroundColor: false,
        readMoreBackgroundColor: createColor('black'),
        customTicketsButtonColor: false,
        ticketsButtonColor: createColor('black'),
        customTicketsButtonBackgroundColor: false,
        ticketsButtonBackgroundColor: createColor('black'),
    }
    componentDidMount() {
        const script = document.createElement("script");
        var baseAppUrl = process.env.REACT_APP_API_BASE_URL;
        script.src = `${baseAppUrl}/js/embed/widget.js`;
        script.async = true;

        document.body.appendChild(script);
    }

    GenerateHtml = (preview: boolean) => {
        var baseAppUrl = process.env.REACT_APP_API_BASE_URL;

        let htmlCode = "";

        let url = "";
        if (this.props.eventIds.length > 0) {
            let idsString = 'eventId=' + this.props.eventIds.join('&eventId=');

            let extraParams = '';
            if (this.state.doNotShowCancelledEvents)
                extraParams = extraParams + "&hideCancelled=true";
            if (this.state.useReadMore)
                extraParams = extraParams + "&useReadMore=true";

            if (this.state.hideVenueAndAddress)
                extraParams = extraParams + "&hideVenueAndAddress=true";

            if (this.state.hideAgeRestrictions)
                extraParams = extraParams + "&hideAgeRestrictions=true";

            if (this.state.hideDescription)
                extraParams = extraParams + "&hideDescription=true";

            if (this.state.widgetTheme != '')
                extraParams = extraParams + "&theme=" + this.state.widgetTheme;

            if (this.state.customFontFamily && this.state.fontFamily != "")
                extraParams = extraParams + "&ff=" + this.state.fontFamily;

            if (this.state.customFontScale && this.state.fontScale != "1.0")
                extraParams = extraParams + "&fs=" + this.state.fontScale;

            if (this.state.customBackgroundColor && this.state.backgroundColor != null)
                extraParams = extraParams + "&bgnd=" + this.state.backgroundColor.hex

            if (this.state.customSpacingBackground && this.state.spacingBackground != null)
                extraParams = extraParams + "&sbgnd=" + this.state.spacingBackground.hex

            if (this.state.customEventNameTextColor && this.state.eventNameTextColor != null)
                extraParams = extraParams + "&enc=" + this.state.eventNameTextColor.hex

            if (this.state.customOtherTextColor && this.state.otherTextColor != null)
                extraParams = extraParams + "&otc=" + this.state.otherTextColor.hex

            if (this.state.customReadMoreColor && this.state.readMoreColor != null)
                extraParams = extraParams + "&rmbc=" + this.state.readMoreColor.hex

            if (this.state.customReadMoreBackgroundColor && this.state.readMoreBackgroundColor != null)
                extraParams = extraParams + "&rmbb=" + this.state.readMoreBackgroundColor.hex

            if (this.state.customTicketsButtonColor && this.state.ticketsButtonColor != null)
                extraParams = extraParams + "&tbc=" + this.state.ticketsButtonColor.hex

            if (this.state.customTicketsButtonBackgroundColor && this.state.ticketsButtonBackgroundColor != null)
                extraParams = extraParams + "&tbb=" + this.state.ticketsButtonBackgroundColor.hex

            if (this.state.customSpacing && this.state.spacingUnits != '' && this.state.spacingValue)
                extraParams = extraParams + "&sv=" + this.state.spacingValue + "&su=" + this.state.spacingUnits;

            let platformType = this.state.platformType != null && this.state.platformType != "" ? this.state.platformType : "standard";

            extraParams = extraParams + "&platformType=" + platformType;

            url = `${baseAppUrl}/widgets/index?${idsString}`;
            if (extraParams != '')
                url = url + extraParams;

            let scriptUrl = `${baseAppUrl}/js/embed/widget.js`;
            if (this.state.platformType == "standard") {

                if (!this.state.useReadMore) {
                    htmlCode = `<iframe src="${url}" class="bnb-event-widget" scrolling="yes" style="border: 0;width: 100%;" height="100%"></iframe>\n`;

                }
                else {
                    htmlCode = `<iframe src="${url}" class="bnb-event-widget" scrolling="no" style="border: 0;width: 100%;" height="100%"></iframe>\n`;

                    htmlCode += `<script type="text/javascript" src="${scriptUrl}"></script>`;
                }
            }
            else if (this.state.platformType == "wix") {
                htmlCode = "<!--Insert below url in an embed element in Wix. Choose the \"Embed a site\" option. Adjust the element dimensions accordingly. -->\n";

                if (preview) {
                    htmlCode += `<iframe src="${url}" class="bnb-event-widget" scrolling="yes" style="border: 0;width: 100%;" height="100%"></iframe>\n`;
                }
                else {
                    htmlCode += `${url}\n`;

                    if (this.state.useReadMore) {
                        htmlCode += "\n";
                        htmlCode += "<!--Below code is if you intend on using the \"Read More\" button, which requires javascript to work.->\n";
                        htmlCode += "<!--Insert below code into Wix's Settings -> Advanced Settings -> Custom Code -> \"Body - End\"-->\n";
                        htmlCode += `<script type="text/javascript" src="${scriptUrl}"></script>`;
                    }
                }


            }

        }
        return ({ htmlCode, url });
    }

    OnCopyCodeClick = () => {
        var result = this.GenerateHtml(false);
        navigator.clipboard.writeText(result.htmlCode);
    }

    OnChangeDoNotShowCancelledEvents = () => {
        this.setState({ ...this.state, doNotShowCancelledEvents: !this.state.doNotShowCancelledEvents });
    }

    OnChangeUseReadMore = () => {
        this.setState({ ...this.state, useReadMore: !this.state.useReadMore });
    }

    OnChangeHideVenueAndAddress = () => {
        this.setState({ ...this.state, hideVenueAndAddress: !this.state.hideVenueAndAddress });
    }

    OnChangeHideAgeRestrictions = () => {
        this.setState({ ...this.state, hideAgeRestrictions: !this.state.hideAgeRestrictions });
    }

    OnChangeHideDescription = () => {
        this.setState({ ...this.state, hideDescription: !this.state.hideDescription });
    }

    OnThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, widgetTheme: event.target.value });
    }

    HandleChangeCustomBackgroundColor = () => {
        this.setState({ ...this.state, customBackgroundColor: !this.state.customBackgroundColor });
    }

    HandleChangeBackgroundColor = (e: any) => {
        this.setState({ ...this.state, backgroundColor: e });
    }

    HandleChangeCustomSpacingBackground = () => {
        this.setState({ ...this.state, customSpacingBackground: !this.state.customSpacingBackground });
    }

    HandleChangeSpacingBackground = (e: any) => {
        this.setState({ ...this.state, spacingBackground: e });
    }
    HandleChangeCustomEventNameTextColor = () => {
        this.setState({ ...this.state, customEventNameTextColor: !this.state.customEventNameTextColor });
    }

    HandleChangeEventNameTextColor = (e: any) => {
        this.setState({ ...this.state, eventNameTextColor: e });
    }

    HandleChangeCustomOtherTextColor = () => {
        this.setState({ ...this.state, customOtherTextColor: !this.state.customOtherTextColor });
    }

    HandleChangeOtherTextColor = (e: any) => {
        this.setState({ ...this.state, otherTextColor: e });
    }

    HandleChangeCustomSpacing = () => {
        this.setState({ ...this.state, customSpacing: !this.state.customSpacing });
    }

    HandleSpacingValueChange = (e: any) => {
        this.setState({ ...this.state, spacingValue: e.target.value });
    }

    HandleChangeSpacingUnits = (e: any) => {
        this.setState({ ...this.state, spacingUnits: e.target.value });
    }

    HandleChangeFontFamily = (e: any) => {
        this.setState({ ...this.state, fontFamily: e.target.value });
    }

    HandleChangeCustomFontFamily = () => {
        this.setState({ ...this.state, customFontFamily: !this.state.customFontFamily });
    }

    HandleChangeCustomFontScale = () => {
        this.setState({ ...this.state, customFontScale: !this.state.customFontScale });
    }

    HandleChangeFontScale = (e: any) => {
        this.setState({ ...this.state, fontScale: e.target.value });
    }


    HandleChangeReadMoreColor = (e: any) => {
        this.setState({ ...this.state, readMoreColor: e });
    }

    HandleChangeCustomReadMoreColor = () => {
        this.setState({ ...this.state, customReadMoreColor: !this.state.customReadMoreColor });
    }

    HandleChangeReadMoreBackgroundColor = (e: any) => {
        this.setState({ ...this.state, readMoreBackgroundColor: e });
    }

    HandleChangeCustomReadMoreBackgroundColor = () => {
        this.setState({ ...this.state, customReadMoreBackgroundColor: !this.state.customReadMoreBackgroundColor });
    }

    HandleChangeTicketsButtonColor = (e: any) => {
        this.setState({ ...this.state, ticketsButtonColor: e });
    }

    HandleChangeCustomTicketsButtonColor = () => {
        this.setState({ ...this.state, customTicketsButtonColor: !this.state.customTicketsButtonColor });
    }

    HandleChangeTicketsButtonBackgroundColor = (e: any) => {
        this.setState({ ...this.state, ticketsButtonBackgroundColor: e });
    }

    HandleChangeCustomTicketsButtonBackgroundColor = () => {
        this.setState({ ...this.state, customTicketsButtonBackgroundColor: !this.state.customTicketsButtonBackgroundColor });
    }

    OnChangePlatformTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, platformType: event.target.value })
    }

    render() {
        const fontNames: any[] = [
            { name: "Arial", value: "Arial" },
            { name: "Verdana", value: "Verdana" },
            { name: "Helvetica", value: "Helvetica" },
            { name: "Tahoma", value: "Tahoma" },
            { name: "Trebuchet MS", value: "Trebuchet MS" },
            { name: "Times New Roman", value: "Times New Roman" },
            { name: "Georgia", value: "Georgia" },
            { name: "Garamond", value: "Garamond" },
            { name: "Courier New", value: "Courier New" },
            { name: "Brush Script MT", value: "Brush Script MT" },
        ];
        const fontScales: any[] = [
            { name: "0.5x", value: "0.5" },
            { name: "0.75x", value: "0.75" },
            { name: "1.0x", value: "1.0" },
            { name: "1.25x", value: "1.25" },
            { name: "1.5x", value: "1.5" },
        ];

        const spacingUnits: any[] = [
            { name: "px", value: "px" },
            { name: "em", value: "em" },
            { name: "%", value: "%" },
        ];

        var codeAreaColumns = 6;

        if (this.state.useReadMore && this.state.platformType == "wix") {
            codeAreaColumns = 16;
        }

        return (
            <div>
                <Dialog
                    onClose={this.props.closeModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="embedHtmlDialog"
                    open={this.props.visible}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Widget</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.props.closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        {(this.props.eventIds == null || this.props.eventIds.length == 0) && <div style={{ color: "red", paddingBottom: "10px" }}>
                            No events selected. Please select at least one event from the "Events Widget" column.
                        </div>
                        }
                        <div>
                            Copy HTML code:
                        </div>
                        <textarea style={{ width: "100%" }} readOnly rows={codeAreaColumns} value={this.GenerateHtml(false).htmlCode} />
                        <div style={{ paddingTop: "10px" }}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Platform Configurations</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={this.state.platformType}
                                    onChange={this.OnChangePlatformTypeChange}
                                >
                                    <FormControlLabel value="standard" control={<Radio />} label="Standard" />
                                    <FormControlLabel value="wix" control={<Radio />} label="Wix" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {/* <div>
                        <FormControlLabel control={
                            <Checkbox checked={this.state.doNotShowCancelledEvents}
                                onChange={this.OnChangeDoNotShowCancelledEvents} />}
                            label="Hide Cancelled Events" />
                    </div> */}
                        <Grid container spacing={0}>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.doNotShowCancelledEvents}
                                        onChange={this.OnChangeDoNotShowCancelledEvents} />

                                    <FormLabel>&nbsp;Hide Cancelled Events</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.useReadMore}
                                        onChange={this.OnChangeUseReadMore} />

                                    <FormLabel>&nbsp;Use Read More Button</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.hideVenueAndAddress}
                                        onChange={this.OnChangeHideVenueAndAddress} />

                                    <FormLabel>&nbsp;Hide Venue and Address</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.hideAgeRestrictions}
                                        onChange={this.OnChangeHideAgeRestrictions} />

                                    <FormLabel>&nbsp;Hide Age Restrictions</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.hideDescription}
                                        onChange={this.OnChangeHideDescription} />

                                    <FormLabel>&nbsp;Hide Description</FormLabel>
                                </div>
                            </Grid>
                        </Grid>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Theme</FormLabel>
                            <RadioGroup onChange={this.OnThemeChange} row={true}
                                aria-label="theme"
                                defaultValue=""
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="" control={<Radio />} label="Default" />
                                <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                            </RadioGroup>
                        </FormControl>
                        <FormLabel style={{ display: "block" }}>Custom Styles</FormLabel>
                        <Grid container spacing={0}>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customBackgroundColor}
                                        onChange={this.HandleChangeCustomBackgroundColor} />
                                    {this.state.customBackgroundColor &&
                                        <span className='color-picker-container'>
                                            <ColorPicker 
                                                onChange={this.HandleChangeBackgroundColor}
                                                value={this.state.backgroundColor}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customBackgroundColor &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;Background color</FormLabel>
                                </div>
                            </Grid>

                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customOtherTextColor}
                                        onChange={this.HandleChangeCustomOtherTextColor} />
                                    {this.state.customOtherTextColor &&
                                        <span className='color-picker-container'>
                                            <ColorPicker onChange={this.HandleChangeOtherTextColor}
                                                value={this.state.otherTextColor}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customOtherTextColor &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;Default text color</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customEventNameTextColor}
                                        onChange={this.HandleChangeCustomEventNameTextColor} />
                                    {this.state.customEventNameTextColor &&
                                        <span className='color-picker-container'>
                                            <ColorPicker onChange={this.HandleChangeEventNameTextColor}
                                                value={this.state.eventNameTextColor}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customEventNameTextColor &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;Event name text color</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customSpacing}
                                        onChange={this.HandleChangeCustomSpacing} />
                                    <TextField
                                        disabled={!this.state.customSpacing}
                                        value={this.state.spacingValue}
                                        onChange={this.HandleSpacingValueChange}
                                        style={{ width: "58px" }}
                                    />
                                    <Select
                                        value={this.state.spacingUnits}
                                        disabled={!this.state.customSpacing}
                                        onChange={(e) => this.HandleChangeSpacingUnits(e)}>
                                        {
                                            spacingUnits.map((data: any) => (
                                                <MenuItem value={data.value}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormLabel>&nbsp;Spacing units</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customSpacingBackground}
                                        onChange={this.HandleChangeCustomSpacingBackground} />
                                    {this.state.customSpacingBackground &&
                                        <span className='color-picker-container'>
                                            <ColorPicker onChange={this.HandleChangeSpacingBackground}
                                                value={this.state.spacingBackground}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customSpacingBackground &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;Spacing background color</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customFontFamily}
                                        onChange={this.HandleChangeCustomFontFamily} />

                                    <Select
                                        value={this.state.fontFamily}
                                        disabled={!this.state.customFontFamily}
                                        onChange={(e) => this.HandleChangeFontFamily(e)}
                                    >
                                        {
                                            fontNames.map((data: any) => (
                                                <MenuItem value={data.value}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Select>

                                    <FormLabel>&nbsp;Font family</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customFontScale}
                                        onChange={this.HandleChangeCustomFontScale} />
                                    <Select
                                        value={this.state.fontScale}
                                        disabled={!this.state.customFontScale}
                                        onChange={(e) => this.HandleChangeFontScale(e)}>
                                        {
                                            fontScales.map((data: any) => (
                                                <MenuItem value={data.value}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormLabel>&nbsp;Font scale</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customReadMoreColor}
                                        onChange={this.HandleChangeCustomReadMoreColor} />
                                    {this.state.customReadMoreColor &&
                                        <span className='color-picker-container'>
                                            <ColorPicker onChange={this.HandleChangeReadMoreColor}
                                                value={this.state.readMoreColor}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customReadMoreColor &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;Read More Button text color</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customReadMoreBackgroundColor}
                                        onChange={this.HandleChangeCustomReadMoreBackgroundColor} />
                                    {this.state.customReadMoreBackgroundColor &&
                                        <span className='color-picker-container'>
                                            <ColorPicker onChange={this.HandleChangeReadMoreBackgroundColor}
                                                value={this.state.readMoreBackgroundColor}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customReadMoreBackgroundColor &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;Read More Button Background color</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customTicketsButtonColor}
                                        onChange={this.HandleChangeCustomTicketsButtonColor} />
                                    {this.state.customTicketsButtonColor &&
                                        <span className='color-picker-container'>
                                            <ColorPicker onChange={this.HandleChangeTicketsButtonColor}
                                                value={this.state.ticketsButtonColor}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customTicketsButtonColor &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;"View In NRBY App" Button Text Color</FormLabel>
                                </div>
                            </Grid>
                            <Grid item sm={4}>
                                <div>
                                    <Checkbox checked={this.state.customTicketsButtonBackgroundColor}
                                        onChange={this.HandleChangeCustomTicketsButtonBackgroundColor} />
                                    {this.state.customTicketsButtonBackgroundColor &&
                                        <span className='color-picker-container'>
                                            <ColorPicker onChange={this.HandleChangeTicketsButtonBackgroundColor}
                                                value={this.state.ticketsButtonBackgroundColor}
                                                hideTextfield
                                                disableAlpha={true}
                                            />
                                        </span>
                                    }
                                    {!this.state.customTicketsButtonBackgroundColor &&
                                        <span className='color-picker-disabled-button'></span>
                                    }
                                    <FormLabel>&nbsp;"View In NRBY App" Button Background color</FormLabel>
                                </div>
                            </Grid>
                        </Grid>

                        <div style={{ height: "100%" }}>
                            <div dangerouslySetInnerHTML={{ __html: this.GenerateHtml(true).htmlCode }} style={{ height: "100%" }}>

                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true} >
                        <Button onClick={this.OnCopyCodeClick}  className='two-action-buttons'>
                            Copy Code
                        </Button>
                        <Button onClick={this.props.closeModal}  className='two-action-buttons'>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}