import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { EventClusterPermissionCode } from './Models/EventClusterPermissionCode';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const getAllEventClusterPermissions = async(): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/EventClusterPermissions/all`, tokenConfig);
}

const getEventClusterPermissionsResponse = async(gridState: ITableDef): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var formData = GetGridStateFormData(gridState);

    return await axios.post(`${baseAPIUrl}/api/EventClusterPermissions/paginated`, formData, tokenConfig);
}

export const getEventClusterPermissions = AwesomeDebouncePromise(getEventClusterPermissionsResponse, 500);

export const GetGridStateFormData = (gridState: ITableDef): FormData => {

    const formData = new FormData();
    formData.append('skip', JSON.stringify(gridState.skip));
    formData.append('take', JSON.stringify(gridState.take));
    formData.append('sort', JSON.stringify(gridState.sort));
    formData.append('filter', JSON.stringify(gridState.filter));
    
    return formData;
}

export const updateEventClusterPermission = async(id: EventClusterPermissionCode, name: string, sortOrder: number): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/EventClusterPermissions`, { id: id, name: name, sortOrder: sortOrder }, tokenConfig);
}
