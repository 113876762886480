import * as React from 'react';
import TextField from '@material-ui/core/TextField';

interface IGenericDisplayCellProps{
    value: any,
    title: string
}

export class GenericDisplayCell extends React.Component<IGenericDisplayCellProps> {

    render() {
        return (
            <td>
                <div className="genericDisplayCellTitle">
                    {this.props.title}:
                </div>
                <div className="genericDisplayCellContent">
                    {this.props.value}
                </div>
            </td>
        );
    }
}