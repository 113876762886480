/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { deleteEvent, cancelEvent, getAllEventsForAdmin, sendEventPromoNotifications } from '../../../data/EventRepository';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { EventStatusCode } from '../../../data/Models/Enums';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { EventFinancialsModal } from '../SubComponents/EventFinancialsModal';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import { GenericDisplayTrueFalseIconCell } from '../SubComponents/GenericDisplayTrueFalseIconCell';
import { PortalUserRoleCode } from "../../../data/Models/PortalUserRoleCode";
import { getAuthStatus } from '../../../data/PortalUserRepository';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DateFilterCell } from '../SubComponents/DateFilterCell';
import { Chip, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grow, IconButton, ListItemIcon, ListItemText, Menu, MenuList, Popper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

var Enumerable = require('linq');


interface IAllEventsPageProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void

}

interface IAllEventsSectionState {
    events: any[],
    eventsTotal: number,
    pageable: any,
    tableDef: ITableDef,
    serverError: string,
    deleteEventConfirmationDialogOpen: boolean,
    cancelEventConfirmationDialogOpen: boolean,
    cancelEventWithOrdersConfirmationDialogOpen: boolean,
    activeEventId: string,
    deleteEventSuccess: boolean,
    cancelEventSuccess: boolean,
    financialsModalOpen: boolean,
    totalIncome: number,
    totalFees: number,
    totalTicketsPurchased: number,
    payoutDateTime: Date | undefined,
    payoutAttempts: number,
    payoutSuccessful: boolean,
    mobileFiltersVisible: boolean,
    actionsMenuAnchorEl: null | HTMLElement,
    skipClosingMenuOneTime: boolean,
    openedActionMenuRow: number,
    sendNotificationsConfirmationDialogOpen: boolean,
    notificationsCount: number,
    showNotificationResultModal: boolean,
    pushNotificationText: string,
    pushNotificationTextError: string,
}

const getInitTableDef = () => {
    let sortDef: ISortDef = {
        field: "StartDateTime",
        dir: "desc"
    };

    let filterArray = [
        {
            field: "clusterName",
            operator: "eq",
            value: ""
        },
        {
            field: "name",
            operator: "eq",
            value: ""
        },
        {
            field: "venue",
            operator: "eq",
            value: ""
        },
        {
            field: "address",
            operator: "eq",
            value: ""
        },
        {
            field: "published",
            operator: "eq",
            value: null
        },
        {
            field: "startDateTimeLocal",
            operator: "eq",
            value: null
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class AdminAllEventsPage extends React.Component<IAllEventsPageProps, IAllEventsSectionState> {

    state = {
        events: [],
        eventsTotal: 0,
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
                id: 'clusterName',
                numeric: false,
                label: 'Event Group',
            },
            {
                id: 'name',
                numeric: false,
                label: 'Name',
            },
            {
                id: 'venue',
                numeric: false,
                label: 'Venue',
            },
            {
                id: 'address',
                numeric: false,
                disablePadding: false,
                label: 'Address',
            },
            {
                id: 'published',
                label: 'Published'
            },
            {
                id: 'startDateTimeLocal',
                label: 'Start Date/Time'
            }
        ],
        serverError: '',
        deleteEventConfirmationDialogOpen: false,
        cancelEventConfirmationDialogOpen: false,
        cancelEventWithOrdersConfirmationDialogOpen: false,
        activeEventId: '',
        deleteEventSuccess: false,
        cancelEventSuccess: false,
        financialsModalOpen: false,
        totalIncome: 0,
        totalFees: 0,
        totalTicketsPurchased: 0,
        payoutDateTime: undefined,
        payoutAttempts: 0,
        payoutSuccessful: false,
        mobileFiltersVisible: false,
        actionsMenuAnchorEl: null,
        skipClosingMenuOneTime: false,
        openedActionMenuRow: -1,
        sendNotificationsConfirmationDialogOpen: false,
        notificationsCount: 0,
        showNotificationResultModal: false,
        pushNotificationText: "",
        pushNotificationTextError: "",
    }

    async componentDidMount() {
        this.props.loadingOn();

        let role: PortalUserRoleCode = PortalUserRoleCode.None;

        const response = await getAuthStatus();

        if (response == undefined || response.status != 200) {
            this.props.changePage("/login?redirect=admin-all-events");
        }

        role = response.data.role;

        if (role == PortalUserRoleCode.Admin) {
            await this.LoadAllEvents(this.state.tableDef);
        }
        else {
            this.props.changePage("/dashboard");
        }

        this.props.loadingOff();
    }

    LoadAllEvents = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let events: any = [];
        let eventsTotal = 0;
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "") || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getAllEventsForAdmin(tempTableDef);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=admin-all-events");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                events = response.data.events;
                eventsTotal = response.data.total;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({ ...this.state, events: events, eventsTotal: eventsTotal, tableDef: tableDef, serverError: serverError });
    }

    EditEventClick = (eventId: any, eventClusterId: any) => {
        this.props.changePage(`/event?id=${eventId}&eventGroupId=${eventClusterId}`);
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadAllEvents(tableDef);
        }

        this.props.loadingOff();
    }


    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        if (filter == "published") {
            if (value == "false") {
                filterDef.f.value = false;
            }
            else if (value == "true") {
                filterDef.f.value = true;
            }
            else {
                filterDef.f.value = null;
            }
        }
        else if (filter == "startDateTimeLocal" && value != null && value != "") {
            filterDef.f.value = value.toLocaleDateString();
        }
        else {
            filterDef.f.value = value;
        }

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadAllEvents(tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadAllEvents(tableDef);
        this.props.loadingOff();
    }

    CreateEventClick = (eventId: string, eventClusterId: string) => {
        var createEventUrl = `/create-event?eventGroupId=${eventClusterId}`;

        if (eventId != undefined && eventId != "") {
            createEventUrl += `&copyEventId=${eventId}`;
        }

        this.props.changePage(createEventUrl);
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: "" });
    };

    DeleteEventClick = (eventId: any) => {
        this.setState({ ...this.state, deleteEventConfirmationDialogOpen: true, activeEventId: eventId });
    }

    CancelEventClick = (eventId: any, hasOrders: boolean) => {
        if (hasOrders)
            this.setState({ ...this.state, cancelEventWithOrdersConfirmationDialogOpen: true, activeEventId: eventId });
        else
            this.setState({ ...this.state, cancelEventConfirmationDialogOpen: true, activeEventId: eventId });
    }

    DeleteEvent = async () => {
        this.props.loadingOn();

        var response = await deleteEvent(this.state.activeEventId);
        let serverError = '';
        let deleteEventSuccess = false;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=all-events");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                deleteEventSuccess = true;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({ ...this.state, serverError: serverError, deleteEventSuccess: deleteEventSuccess, deleteEventConfirmationDialogOpen: false });

        await this.LoadAllEvents(this.state.tableDef);
        this.props.loadingOff();
    }

    CancelEvent = async () => {
        this.props.loadingOn();

        var response = await cancelEvent(this.state.activeEventId);
        let serverError = '';
        let cancelEventSuccess = false;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=all-events");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                cancelEventSuccess = true;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            serverError: serverError,
            cancelEventSuccess: cancelEventSuccess,
            cancelEventConfirmationDialogOpen: false,
            cancelEventWithOrdersConfirmationDialogOpen: false
        });

        if (serverError === '') {
            await this.LoadAllEvents(this.state.tableDef);
        }

        this.props.loadingOff();
    }

    CloseDeleteEventModal = () => {
        this.setState({ ...this.state, deleteEventConfirmationDialogOpen: false });
    }

    CloseCancelEventModal = () => {
        this.setState({ ...this.state, cancelEventConfirmationDialogOpen: false, cancelEventWithOrdersConfirmationDialogOpen: false });
    }

    CloseSuccessModal = () => {
        this.setState({ ...this.state, deleteEventSuccess: false, cancelEventSuccess: false });
    }

    CloseNotificationResultModal = () => {
        this.setState({ ...this.state, showNotificationResultModal: false });
    }

    OpenFinancialsModal = (eventFinancials: any) => {
        this.setState({
            ...this.state,
            financialsModalOpen: true,
            totalIncome: eventFinancials.totalIncome,
            totalFees: eventFinancials.totalFees,
            totalTicketsPurchased: eventFinancials.totalTicketsPurchased,
            payoutDateTime: eventFinancials.payoutDateTime,
            payoutAttempts: eventFinancials.payoutAttempts,
            payoutSuccessful: eventFinancials.payoutSuccessful
        });
    }

    CloseFinancialsModal = () => {
        this.setState({
            ...this.state,
            financialsModalOpen: false,
            totalIncome: 0,
            totalFees: 0,
            totalTicketsPurchased: 0,
            payoutDateTime: undefined,
            payoutAttempts: 0,
            payoutSuccessful: false
        });
    }

    ToggleFilterVisibility = () => {
        this.setState({ ...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible });
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadAllEvents(tableDef);

        this.props.loadingOff();
    };

    IsSelected = (id: any) => {
        return Enumerable.from(this.state.events).any((p: any) => p.id == id && p.checked == true);
    }

    handleClickActionsMenu = (event: React.MouseEvent<HTMLButtonElement>, rowId: any) => {
        if (this.state.openedActionMenuRow == rowId) {
            this.state.skipClosingMenuOneTime = false;
            this.setState({ ...this.state, actionsMenuAnchorEl: null, openedActionMenuRow: -1 });
            return;
        }
        if (this.state.actionsMenuAnchorEl)
            this.state.skipClosingMenuOneTime = true;
        this.setState({ ...this.state, openedActionMenuRow: rowId, actionsMenuAnchorEl: event.currentTarget });
    };

    handleCloseActionsMenuOnClick = (e: any) => {
        if (this.state.skipClosingMenuOneTime) {
            this.state.skipClosingMenuOneTime = false;
            return;
        }
        this.setState({ ...this.state, actionsMenuAnchorEl: null, openedActionMenuRow: -1 });
    }

    handleSendPushNotificationsClick = (eventData: any) => {
        this.setState({
            ...this.state,
            sendNotificationsConfirmationDialogOpen: true,
            activeEventId: eventData.id,
            pushNotificationText: `${eventData.name} @ ${eventData.venue}`,
        });
    }

    closeSendNotificationsConfirmationDialog = () => {
        this.setState({ ...this.state, sendNotificationsConfirmationDialogOpen: false });
    }

    HandleChangePushNotificationMessage = (event: any) => {
        let value = event.currentTarget.value;
        let error = "";
        if (!value || value == '') {
            error = "Please add message";
        }
        this.setState({ ...this.state, pushNotificationText: value, pushNotificationTextError: error });
    }

    SendPushNotifications = async () => {
        this.props.loadingOn();

        if (!this.state.pushNotificationText || this.state.pushNotificationText == '') {
            this.setState({
                ...this.state,
                pushNotificationTextError: "Please add message"
            });
            return;
        }

        var response = await sendEventPromoNotifications(this.state.activeEventId, this.state.pushNotificationText);
        let serverError = '';
        let notificationsCount = 0;
        let showNotificationResultModal = false;

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=all-events");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                notificationsCount = response.data.notificationsCount;
                showNotificationResultModal = true;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            serverError: serverError,
            notificationsCount: notificationsCount,
            showNotificationResultModal: showNotificationResultModal,
            sendNotificationsConfirmationDialogOpen: false,
        });

        if (serverError === '') {
            await this.LoadAllEvents(this.state.tableDef);
        }

        this.props.loadingOff();
    }

    render() {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "StartDateTimeLocal";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{ width: "100%" }}>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <CardHeader title="Events" />
                    <CardContent>
                        <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility} />
                        <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className={this.state.mobileFiltersVisible ? "table-filters-visible all-events-table" : "table-filters-hidden"}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        <TableSortLabel
                                                            active={currentSortName === headCell.id}
                                                            direction={currentSortName === headCell.id ? currentSortDir : null}
                                                            hideSortIcon={currentSortName !== headCell.id}
                                                            onClick={() => this.OnSortChange(headCell.id)}
                                                            style={{ width: "100%" }}
                                                        >
                                                            {headCell.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                                <TableCell className="table-header-border no-show-on-mobile"
                                                >
                                                    Financials
                                                </TableCell>
                                                <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                                    <TableCell className="table-header-border show-on-mobile" >
                                                        {headCell.field != "published" && headCell.field != "startDateTimeLocal" &&
                                                            <TextField
                                                                fullWidth
                                                                margin="dense"
                                                                size="small"
                                                                onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                                value={headCell.value}
                                                                variant="outlined"
                                                            />
                                                        }
                                                        {headCell.field == "published" &&
                                                            <FormControl variant="filled" size="small" fullWidth>
                                                                <Select
                                                                    value={headCell.value}
                                                                    label="Published"
                                                                    onChange={(e) => this.OnFilterChange(headCell.field, e.target.value)}
                                                                    variant="outlined"
                                                                    style={{ marginTop: "5px" }}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">Both</MenuItem>
                                                                    <MenuItem value="false">False</MenuItem>
                                                                    <MenuItem value="true">True</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                        {headCell.field == "startDateTimeLocal" &&
                                                            <DateFilterCell value={headCell.value} field={headCell.field} onChange={this.OnFilterChange} />
                                                        }
                                                        {/* {headCell.id == "startDateTimeLocal" &&

                                        } */}
                                                    </TableCell>
                                                ))}
                                                <TableCell className="table-header-border no-show-on-mobile">

                                                </TableCell>
                                                <TableCell className="table-header-border no-show-on-mobile">

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.events.map((row: any, index: any) => {
                                                const isItemSelected = this.IsSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        // onClick={(event) => this.HandleClick(event, row.id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell align="right"><GenericDisplayCell title="Event Group" value={row.clusterName} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Name" value={row.name} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Venue" value={row.venue} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Address" value={row.address} /></TableCell>
                                                        <TableCell align="right">
                                                            <GenericDisplayTrueFalseIconCell title="Published" isTrue={row.published} />
                                                            {row.statusCode == EventStatusCode.Cancelled && <Chip label="Cancelled" color='secondary' size="small" />}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <GenericDisplayCell title="Start Date/Time" value={row.startEventDateTimeLocal} />
                                                        </TableCell>
                                                        <TableCell style={{ color: "#1976d2", textDecoration: "underline" }} align="center">
                                                            {row.eventFinancials != null && <Typography><Link href="#" onClick={() => this.OpenFinancialsModal(row.eventFinancials)}>View Financials</Link></Typography>}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: "center" }}>
                                                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleClickActionsMenu(e, row.id)}
                                                                variant="contained" className="btn-default" size='small'>
                                                                Actions
                                                            </Button>
                                                            <Popper open={Boolean(this.state.openedActionMenuRow == row.id)} anchorEl={this.state.actionsMenuAnchorEl} role={undefined} transition >
                                                                {({ TransitionProps, placement }) => (
                                                                    <Grow
                                                                        {...TransitionProps}
                                                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                                    >
                                                                        <Paper>
                                                                            <ClickAwayListener onClickAway={this.handleCloseActionsMenuOnClick}>
                                                                                <MenuList autoFocusItem={Boolean(this.state.actionsMenuAnchorEl)} id="menu-list-grow" >
                                                                                    {row.editable && <MenuItem onClick={() => this.EditEventClick(row.id, row.clusterId)}>
                                                                                        <ListItemIcon>
                                                                                            <EditIcon fontSize="small" />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText primary="Edit" />
                                                                                    </MenuItem>}

                                                                                    {row.editable && <MenuItem onClick={() => this.CreateEventClick(row.id, row.clusterId)}>
                                                                                        <ListItemIcon>
                                                                                            <FileCopyIcon fontSize="small" />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText primary="Copy" />
                                                                                    </MenuItem>}
                                                                                    {row.editable && row.published && row.statusCode != EventStatusCode.Cancelled && <MenuItem onClick={() => this.handleSendPushNotificationsClick(row)}>
                                                                                        <ListItemIcon>
                                                                                            <NotificationsNoneIcon fontSize="small" />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText primary="Send Promo Notifications" />
                                                                                    </MenuItem>}
                                                                                    {(row.deletable || row.cancelable) && <Divider />}
                                                                                    {row.deletable && !row.published &&
                                                                                        <MenuItem onClick={() => this.DeleteEventClick(row.id)}>
                                                                                            <ListItemIcon>
                                                                                                <CloseIcon fontSize="small" />
                                                                                            </ListItemIcon>
                                                                                            <ListItemText primary="Delete" />
                                                                                        </MenuItem>}
                                                                                    {row.cancelable && row.published &&
                                                                                        row.statusCode != EventStatusCode.Cancelled &&
                                                                                        <MenuItem onClick={() => this.CancelEventClick(row.id, row.hasOrders)}>
                                                                                            <ListItemIcon>
                                                                                                <CloseIcon fontSize="small" />
                                                                                            </ListItemIcon>
                                                                                            <ListItemText primary="Cancel Event" />
                                                                                        </MenuItem>}

                                                                                </MenuList>
                                                                            </ClickAwayListener>
                                                                        </Paper>
                                                                    </Grow>
                                                                )}
                                                            </Popper>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={this.state.eventsTotal}
                                    rowsPerPage={this.state.tableDef.take!}
                                    page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                    onChangePage={(e, page) => this.PageChange(page)}
                                    onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                                />
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>

                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
                <Dialog
                    onClose={this.CloseDeleteEventModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.deleteEventConfirmationDialogOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Delete Event</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseDeleteEventModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this event?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseDeleteEventModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.DeleteEvent} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog onClose={this.CloseCancelEventModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.cancelEventConfirmationDialogOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Cancel Event</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseCancelEventModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to cancel this event?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseCancelEventModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.CancelEvent} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <GenericConfirmationModal hidden={!this.state.cancelEventWithOrdersConfirmationDialogOpen}
                    cancelButtonText="Cancel" confirmButtonText="Ok"
                    onConfirm={this.CancelEvent}
                    closeModal={this.CloseCancelEventModal}
                    message="Are you sure that you want to cancel this event? Any tickets currently purchased for this event will be automatically refunded without the Processing Fee we charge to cover the cost Stripe Processing Fees and Account Payout Fees. This Processing Fee equates to $0.30 plus 3.25% * (Ticket Price + Service Fee + Sales Tax) per Order."
                />

                {/* <GenericConfirmationModal hidden={!this.state.sendNotificationsConfirmationDialogOpen}
                    cancelButtonText="Cancel" confirmButtonText="Send"
                    onConfirm={this.SendPushNotifications}
                    closeModal={this.closeSendNotificationsConfirmationDialog}
                    message="Are you sure that you want to send a push notification for this event to all users in the events region?"
                /> */}

                <Dialog
                    onClose={this.closeSendNotificationsConfirmationDialog}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="newPushNotificationsDialog"
                    open={this.state.sendNotificationsConfirmationDialogOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Push notifications confirmation</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.closeSendNotificationsConfirmationDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <TextField placeholder="Push notification text"
                            value={this.state.pushNotificationText}
                            error={this.state.pushNotificationTextError !== ""}
                            helperText={this.state.pushNotificationTextError}
                            FormHelperTextProps={{ color: "red" }}
                            variant="outlined" fullWidth={true}
                            onChange={this.HandleChangePushNotificationMessage} />
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.closeSendNotificationsConfirmationDialog} className='two-action-buttons'>
                            Cancel
                        </Button>
                        <Button onClick={this.SendPushNotifications} className='two-action-buttons'>
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>

                <EventFinancialsModal
                    totalIncome={this.state.totalIncome}
                    totalFees={this.state.totalFees}
                    totalTicketsPurchased={this.state.totalTicketsPurchased}
                    modalOpen={this.state.financialsModalOpen}
                    payoutDateTime={this.state.payoutDateTime}
                    payoutAttempts={this.state.payoutAttempts}
                    payoutSuccessful={this.state.payoutSuccessful}
                    closeModal={this.CloseFinancialsModal}
                />
                <GenericSuccessModal
                    hidden={this.state.deleteEventSuccess == false}
                    message="The event was deleted successfully."
                    closeModal={this.CloseSuccessModal}
                />
                <GenericSuccessModal
                    hidden={this.state.cancelEventSuccess == false}
                    message="The event was cancelled successfully."
                    closeModal={this.CloseSuccessModal}
                />
                <GenericSuccessModal
                    hidden={!this.state.showNotificationResultModal}
                    message={`Sending ${this.state.notificationsCount} notification(s) has been scheduled`}
                    closeModal={this.CloseNotificationResultModal}
                />
            </div>
        )
    }
}