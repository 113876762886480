/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import '../../Styles/EventTicketingFormSection.css';
import { EditDeleteActionCell } from '../SubComponents/EditDeleteActionCell'
import { PricingAdjustmentFieldsContainer } from './PricingAdjustmentFieldsContainer';
import Moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CheckboxGridCell } from '../SubComponents/CheckboxGridCell';

var Enumerable = require('linq');

interface IPricingAdjustmentDetailsProps {
    pricingAdjustments: any[],
    pricingAdjustmentAmountCodes: any[],
    pricingAdjustmentCodes: any[],
    ticketOptions: any[],
    updatePricingAdjustments: (pricingAdjustments: any[]) => void,
    openPricingAdjustmentModal: (currentIndex: any) => void
}

export class PricingAdjustmentDetails extends React.Component<IPricingAdjustmentDetailsProps> {

    state = {
        deleteAdjustmentModalOpen: false,
        currentIndex: undefined
    }
    MockSave = () => {

    }

    OnDeleteAdjustmentButtonClick = (dataIndex: number) => {
        this.setState({ ...this.state, deleteAdjustmentModalOpen: true, currentIndex: dataIndex });
    }

    CloseDeleteAdjustmentModal = () => {
        this.setState({ ...this.state, deleteAdjustmentModalOpen: false, currentIndex: undefined });
    }

    DeleteAdjustmentClick = () => {
        let pricingAdjustments: any[] = this.props.pricingAdjustments;

        let pricingAdjustmentsNew: any[] = [];
        pricingAdjustments.forEach((v: any, i) => {
            if (i != this.state.currentIndex) {
                pricingAdjustmentsNew.push(v);
            }
        });

        this.props.updatePricingAdjustments(pricingAdjustmentsNew);
        this.setState({ ...this.state, deleteAdjustmentModalOpen: false, currentIndex: undefined });
    }

    render() {
        return (
                <TableContainer>
                    <h4>Pricing Adjustments</h4>
                    <Table
                        aria-labelledby="tableTitle"
                        style={{ width: "100%" }}
                        className="TicketOptionsGrid table-filters-hidden"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-header-border show-on-mobile">
                                    Name
                                </TableCell>
                                <TableCell className="table-header-border no-show-on-mobile">
                                    Discount
                                </TableCell>
                                <TableCell className="table-header-border no-show-on-mobile">
                                    Start Date/Time
                                </TableCell>
                                <TableCell className="table-header-border no-show-on-mobile">
                                    End Date/Time
                                </TableCell>
                                <TableCell className="table-header-border no-show-on-mobile">
                                    Coupon
                                </TableCell>
                                <TableCell className="table-header-border no-show-on-mobile">

                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.props.pricingAdjustments.map((row: any, index: any) =>{
                                    return (<TableRow>
                                        <TableCell>
                                            {row.name}
                                        </TableCell>
                                        <TableCell>
                                            {row.discountAmountCode == 0 && "$" + Number(row.discountAmount).toFixed(2)}
                                            {row.discountAmountCode == 1 && row.discountAmount + "%"}
                                        </TableCell>
                                        <TableCell>
                                            {Moment(row.startDateTime).format('MM/DD/yyyy hh:mm A')}
                                        </TableCell>
                                        <TableCell>
                                            {Moment(row.endDateTime).format('MM/DD/yyyy hh:mm A')}
                                        </TableCell>
                                        <TableCell>
                                            {row.coupon}
                                        </TableCell>
                                        <TableCell>
                                            <EditDeleteActionCell
                                                dataIndex={index}
                                                onEditButtonClick={() => this.props.openPricingAdjustmentModal(index)}
                                                onSaveButtonClick={this.MockSave}
                                                editMode={false}
                                                hideEditButton={false}
                                                hideDeleteButton={false}
                                                onDeleteButtonClick={this.OnDeleteAdjustmentButtonClick} />
                                        </TableCell>
                                    </TableRow>);
                                })
                            }
                        </TableBody>
                    </Table>
                <Dialog
                    onClose={this.CloseDeleteAdjustmentModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.deleteAdjustmentModalOpen}
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Delete Pricing Adjustment</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseDeleteAdjustmentModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this pricing adjustment?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseDeleteAdjustmentModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.DeleteAdjustmentClick} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                </TableContainer>
        );
    }
}