import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const getOrganization = async(id: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/organizations?id=${id}`, tokenConfig);
}

const getOrganizationsResponse = async(gridState: ITableDef, includeArchivedOrganizations: boolean): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/organizations/list?includeArchived=${includeArchivedOrganizations?"true":"false"}`, gridState, tokenConfig);
}

export const getOrganizations = AwesomeDebouncePromise(getOrganizationsResponse, 500);

export const getStripeStatuses= (): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return axios.get(`${baseAPIUrl}/api/organizations/stripeStatuses`, tokenConfig);
}

export const getStripeAccountTypes= (): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return axios.get(`${baseAPIUrl}/api/organizations/accountTypes`, tokenConfig);
}


export const getAllCreatedOrganizations = async(filterActiveOnly: boolean, portalUserId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/organizations/created-list?activeOnly=${filterActiveOnly}&portalUserId=${portalUserId}`, tokenConfig);
}

export const createOrganization = async (request: ICreateOrganization): Promise<AxiosResponse>=>{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();
    
    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/organizations`, request, tokenConfig);
}

export const archiveOrganization = async (organizationId: string): Promise<AxiosResponse>=>{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();
    
    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.delete(`${baseAPIUrl}/api/organizations/archive?id=${organizationId}`, tokenConfig);
}

export const updateOrganization = async (request: IUpdateOrganization): Promise<AxiosResponse>=>{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();
    
    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/organizations`, request, tokenConfig);
}

export const getOrganizationPublicProfile = async(id: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/organizations/public-profile?id=${id}`);
}
