import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { getAdminFinancialsChart, getAdminFinancialsFile } from '../../../data/UserEventTicketRepository';
import { getAdminEventClustersAutocomplete } from '../../../data/EventClusterRepository';
import Moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { AdminFinancialsChartDataTypeCode } from "../../../data/Models/AdminFinancialsChartDataTypeCode";
import { ChartDateRangeTypeCode } from "../../../data/Models/ChartDateRangeTypeCode";
import { ChartTypeCode } from "../../../data/Models/ChartTypeCode";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Button, Grid, Typography } from '@material-ui/core';
import { searchPortalUsers } from '../../../data/PortalUserRepository';
import { PortalUserRoleCode } from "../../../data/Models/PortalUserRoleCode";
import { getAuthStatus } from '../../../data/PortalUserRepository';
import { saveAs } from 'file-saver';

var Enumerable = require('linq');

interface IFinancialsChartProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class FinancialsChart extends React.Component<IFinancialsChartProps> {

    state = {
        currentChartType: ChartTypeCode.Column,
        currentDateRangeType: ChartDateRangeTypeCode.Month,
        currentChartDataType: AdminFinancialsChartDataTypeCode.TicketsSold,
        currentDateRange: "",
        currentState: "",
        currentZipcode: "",
        chartData: [],
        data: [],
        total: 0,
        chartTypes: [],
        eventClusters: [],
        portalUsers: [],
        portalUserName: "",
        portalUserId: "",
        eventClusterId: "",
        eventClusterName: "",
        chartDateRangeTypes: [],
        dateRanges: [],
        states: [],
        zipcodes: [],
        totalTicketsSold: 0,
        ticketsPerMonth: 0,
        serviceFeeTotal: 0,
        salesTaxTotal: 0,
        processingFeeTotal: 0,
        estimatedPayoutTotal: 0,
        financialReportYear: "",
        financialReportYearError: "",
        serverError: '',

    }

    async componentDidMount() {
        this.props.loadingOn();

        let role: PortalUserRoleCode = PortalUserRoleCode.None;

        const response = await getAuthStatus();

        if (response == undefined || response.status != 200) {
            this.props.changePage("/login?redirect=admin-financials");
        }

        role = response.data.role;

        if (role == PortalUserRoleCode.Admin) {
            await this.LoadPortalUsers(this.state.portalUserName);
            await this.LoadEventClusters(this.state.eventClusterName, this.state.portalUserId);
            await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, this.state.currentState, this.state.currentZipcode, this.state.eventClusterId, this.state.portalUserId);
        }
        else {
            this.props.changePage("/dashboard");
        }

        this.props.loadingOff();
    }

    LoadPortalUsers = async (portalUserName: string) => {
        let portalUsers: any[] = [];
        let serverError = '';

        var response = await searchPortalUsers(portalUserName);

        if (response.status == 401) {
            this.props.changePage("/login?redirect=admin-financials");
        }
        else if (response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                if (response.data.portalUsers != null)
                    portalUsers = response.data.portalUsers;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            portalUsers: portalUsers,
            serverError: serverError
        });
    }

    LoadData = async (currentDateRangeType: any, currentChartDataType: any, currentDateRange: any, currentState: any, currentZipcode: any,
        eventClusterId: string, portalUserId: string) => {

        let data: any[] = [];
        let dateRanges: any[] = [];
        let states: any[] = [];
        let zipcodes: any[] = [];
        let chartData: any[] = [
            { name: "Tickets Sold", value: AdminFinancialsChartDataTypeCode.TicketsSold },
            { name: "Revenue", value: AdminFinancialsChartDataTypeCode.Revenue },
            { name: "Sales Taxes", value: AdminFinancialsChartDataTypeCode.SalesTaxes }
        ];
        let chartTypes: any[] = [{ name: "Column", value: ChartTypeCode.Column }, { name: "Line", value: ChartTypeCode.Line }];
        let chartDateRangeTypes: any[] = [{ name: "Month", value: ChartDateRangeTypeCode.Month }, { name: "Year", value: ChartDateRangeTypeCode.Year }];
        let serverError = '';
        let total = 0;

        var response = await getAdminFinancialsChart(currentDateRange, currentState, currentZipcode, currentDateRangeType, currentChartDataType, eventClusterId, portalUserId);

        if (response.status == 401) {
            this.props.changePage("/login?redirect=admin-financials");
        }
        else if (response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                dateRanges = response.data.searchableDateRanges;
                states = response.data.searchableStates;
                zipcodes = response.data.searchableZipcodes;
                data = Enumerable.from(response.data.chartDataList)
                    .select((d: any) => ({
                        x: Moment(d.xAxisDate).local().format(currentDateRangeType == ChartDateRangeTypeCode.Month ? "MM/DD" : "MM/YYYY"),
                        y: parseFloat(d.yValue)
                    }))
                    .toArray();
                total = response.data.total;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        currentDateRange = (currentDateRangeType != this.state.currentDateRangeType ||
            currentDateRange == "" || currentDateRange == undefined) && dateRanges.length > 0 ? dateRanges[0].startDate : currentDateRange;
       
        this.setState({
            ...this.state,
            currentDateRangeType: currentDateRangeType,
            currentChartDataType: currentChartDataType,
            currentDateRange: currentDateRange,
            currentState: currentState,
            currentZipcode: currentZipcode,
            data: data,
            total: total,
            chartTypes: chartTypes,
            chartData: chartData,
            dateRanges: dateRanges,
            states: states,
            zipcodes: zipcodes,
            chartDateRangeTypes: chartDateRangeTypes,
            serverError: serverError,
            eventClusterId: eventClusterId
        });
    }

    ChangeChartType = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({
            ...this.state,
            currentChartType: event.target.value
        });
    }

    ChangeChartDataType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        this.props.loadingOn();
        await this.LoadData(this.state.currentDateRangeType, event.target.value, this.state.currentDateRange, this.state.currentState, this.state.currentZipcode, this.state.eventClusterId, this.state.portalUserId);
        this.props.loadingOff();
    }

    ChangeDateRangeType = async (event: React.ChangeEvent<{ value: unknown }>) => {
        this.props.loadingOn();
        await this.LoadData(event.target.value, this.state.currentChartDataType, "", "", "", this.state.eventClusterId, this.state.portalUserId);
        this.props.loadingOff();
    }

    ChangeDateRange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        this.props.loadingOn();
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, event.target.value, "", "", this.state.eventClusterId, this.state.portalUserId);
        this.props.loadingOff();
    }

    ChangeState = async (event: React.ChangeEvent<{ value: unknown }>) => {
        this.props.loadingOn();
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, event.target.value, "", this.state.eventClusterId, this.state.portalUserId);
        this.props.loadingOff();
    }

    ChangeZipcode = async (event: React.ChangeEvent<{ value: unknown }>) => {
        this.props.loadingOn();
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, this.state.currentState, event.target.value, this.state.eventClusterId, this.state.portalUserId);
        this.props.loadingOff();
    }

    GetToolTip = (point: any) => {
        let value = this.state.currentChartDataType == AdminFinancialsChartDataTypeCode.Revenue ? `$${Number(point).toFixed(2)}` : point;

        return value;
    }


    LoadEventClusters = async (name: string, portalUserId: string) => {
        let eventClusters: any[] = [];
        let serverError = '';

        var response = await getAdminEventClustersAutocomplete(name, portalUserId);

        if (response.status == 401) {
            this.props.changePage("/login?redirect=admin-financials");
        }
        else if (response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                response.data.eventClusters.forEach((c: any) => {
                    eventClusters.push(c);
                });
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            eventClusters: eventClusters,
            serverError: serverError
        });
    }

    HandleEventClusterNameChange = async (event: any) => {
        let eventClusterName = event.currentTarget.value;
        this.setState({ ...this.state, eventClusterId: '', eventClusterName: eventClusterName });
        this.LoadEventClusters(eventClusterName, this.state.portalUserId);
    }

    HandlePortalUserNameChange = async (event: any) => {
        let userName = event.currentTarget.value;
        this.setState({ ...this.state, portalUserName: userName });
        await this.LoadPortalUsers(userName);
    }

    SelectPortalUser = async (portalUser: any) => {
        this.props.loadingOn();
        let portalUserId = portalUser?.id ?? '';
        let eventClusterId = '';
        let eventClusterName = '';
        let portalUserName = portalUser != null ? `${portalUser.firstName} ${portalUser.lastName} (${portalUser.username})` : "";

        this.setState({ ...this.state, eventClusterId: eventClusterId, eventClusterName: eventClusterName, portalUserId: portalUserId, portalUserName: portalUserName });
        await this.LoadEventClusters(eventClusterName, portalUserId);
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, this.state.currentState, this.state.currentZipcode, eventClusterId, portalUserId);

        if (portalUserName == "") {
            await this.LoadPortalUsers(portalUserName);
        }
        this.props.loadingOff();
    }

    SelectEventCluster = async (cluster: any) => {
        this.props.loadingOn();
        let eventClusterId = cluster?.eventClusterId ?? '';
        let eventClusterName = cluster?.eventClusterName ?? '';
        this.setState({ ...this.state, eventClusterId: eventClusterId, eventClusterName: eventClusterName });
        await this.LoadData(this.state.currentDateRangeType, this.state.currentChartDataType, this.state.currentDateRange, this.state.currentState, this.state.currentZipcode, eventClusterId, this.state.portalUserId);
        this.props.loadingOff();
    }

    HandleFinancialReportYearChange = async (event: any) => {
        let financialReportYear = event.currentTarget.value;
        let financialReportYearError = "";

        if (financialReportYear == undefined || financialReportYear.length != 4 || isNaN(financialReportYear)) {
            financialReportYearError = "The report year must be 4 digits";
        }

        this.setState({ ...this.state, financialReportYear: financialReportYear, financialReportYearError: financialReportYearError });
    }

    DownloadFinancialsReport = async () => {
        this.props.loadingOn();
        let serverError = '';

        var response = await getAdminFinancialsFile(this.state.financialReportYear, this.state.eventClusterId, this.state.portalUserId);

        if (response.status == 401) {
            this.props.changePage("/login?redirect=admin-financials");
        }
        else if (response.status == 200) {
            const blob = new Blob([response.data], {
                type: 'text.csv',
            });
            saveAs(blob, `Nrby-Financials-${this.state.financialReportYear}.csv`)
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            serverError: serverError
        });
        this.props.loadingOff();
    }

    render() {
        let chartTitle = Enumerable.from(this.state.chartData).where((d: any) => d.value == this.state.currentChartDataType).firstOrDefault()?.name;

        return (
            <div>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <CardContent>
                        <TextField
                            error={this.state.financialReportYearError !== ""}
                            label="Year (ex: 2020)"
                            placeholder="Year (ex: 2020)"
                            margin="normal"
                            helperText={this.state.financialReportYearError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.financialReportYear}
                            onChange={this.HandleFinancialReportYearChange}
                            inputProps={{ maxLength: 200 }}
                            variant="outlined"
                        />
                        <Button className="btn-primary"
                            style={{ borderRadius: "20px", fontSize: "11px", marginTop: "26px", marginLeft: "10px" }}
                            onClick={this.DownloadFinancialsReport}
                            disabled={this.state.financialReportYear == "" || this.state.financialReportYearError !== ""}
                        >
                            Generate Yearly Report
                        </Button>
                    </CardContent>
                </Card>
                <Card style={{ backgroundColor: "#ededed", marginTop: "10px" }} className="ticketingChartCard">

                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item sm={6}>
                                <FormControl variant="outlined" className="chartTypeContainer" style={{ width: "100%" }}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.portalUsers}
                                        style={{ width: "100%" }}
                                        getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} (${option.username})`}
                                        onChange={(e, portalUser) => this.SelectPortalUser(portalUser)}
                                        inputValue={this.state.portalUserName}
                                        renderInput={(params) =>
                                            <TextField {...params} placeholder="Portal Users" variant="outlined"
                                                onChange={(e) => { this.HandlePortalUserNameChange(e) }} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                                <FormControl variant="outlined" className="chartTypeContainer" style={{ width: "100%" }}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.state.eventClusters}
                                        style={{ width: "100%" }}
                                        getOptionLabel={(option: any) => option.eventClusterName}
                                        onChange={(e, cluster) => this.SelectEventCluster(cluster)}
                                        inputValue={this.state.eventClusterName}
                                        renderInput={(params) =>
                                            <TextField {...params} placeholder="Event Group(s)" variant="outlined"
                                                onChange={(e) => this.HandleEventClusterNameChange(e)} />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
                <Card style={{ backgroundColor: "#ededed", marginTop: "10px" }} className="ticketingChartCard">
                    <CardContent>
                        <Grid justify="space-between" container spacing={1}>
                            <Grid item>
                                <FormControl variant="outlined" className="chartTypeContainer" style={{ marginLeft: "10px" }}>
                                    <InputLabel>Chart Type</InputLabel>
                                    <Select
                                        value={this.state.currentChartType}
                                        label="Chart Type"
                                        onChange={(e) => this.ChangeChartType(e)}
                                    >
                                        {
                                            this.state.chartTypes.map((data: any) => (
                                                <MenuItem value={data.value}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                                    <InputLabel>Chart Data</InputLabel>
                                    <Select
                                        value={this.state.currentChartDataType}
                                        label="Chart Data"
                                        onChange={(e) => this.ChangeChartDataType(e)}
                                    >
                                        {
                                            this.state.chartData.map((data: any) => (
                                                <MenuItem value={data.value}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" style={{ marginLeft: "10px", width: "140px" }}>
                                    <InputLabel>Date Range Type</InputLabel>
                                    <Select
                                        value={this.state.currentDateRangeType}
                                        label="Month"
                                        onChange={(e) => this.ChangeDateRangeType(e)}

                                    >
                                        {
                                            this.state.chartDateRangeTypes.map((data: any) => (
                                                <MenuItem value={data.value}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                                    <InputLabel>Date Ranges</InputLabel>
                                    <Select
                                        value={this.state.currentDateRange}
                                        label="Date Ranges"
                                        onChange={(e) => this.ChangeDateRange(e)}
                                    >
                                        {
                                            this.state.dateRanges.map((dateRange: any) => (
                                                <MenuItem value={dateRange.startDate}>{Moment(dateRange.startDate).local().format('MM/DD/YYYY') + " - " + Moment(dateRange.endDate).local().format('MM/DD/YYYY')}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                                    <InputLabel>States</InputLabel>
                                    <Select
                                        value={this.state.currentState}
                                        label="States"
                                        onChange={(e) => this.ChangeState(e)}
                                    >
                                        {
                                            this.state.states.map((state: any) => (
                                                <MenuItem value={state}>{state}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" style={{ marginLeft: "10px" }}>
                                    <InputLabel>Zip Codes</InputLabel>
                                    <Select
                                        value={this.state.currentZipcode}
                                        label="Zip Codes"
                                        onChange={(e) => this.ChangeZipcode(e)}
                                    >
                                        {
                                            this.state.zipcodes.map((zip: any) => (
                                                <MenuItem value={zip}>{zip}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                {this.state.total > 0 && 
                                    <Typography>Total: {this.state.currentChartDataType == AdminFinancialsChartDataTypeCode.TicketsSold
                                            ? this.state.total
                                            : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.state.total)}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                        <div style={{ color: "orange", paddingTop: "10px" }}>
                            ***Chart dates are based on your browser timezone
                        </div>
                    </CardContent>

                    <CardContent className="ticketChartDesktopTable">
                        {this.state.dateRanges.length == 0 && <div style={{ color: "red" }}>
                            No Ticketing Records Available.
                        </div>}

                        {this.state.currentChartType == ChartTypeCode.Column &&
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart style={{ backgroundColor: 'white' }}
                                    height={300}
                                    data={this.state.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="x" />
                                    <YAxis />
                                    <Tooltip formatter={(value: any) =>
                                        this.state.currentChartDataType == AdminFinancialsChartDataTypeCode.TicketsSold
                                            ? value
                                            : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
                                    } />
                                    <Legend verticalAlign="top" height={40} />
                                    <Bar dataKey="y" fill="#FF6358" name={chartTitle} />
                                </BarChart>
                            </ResponsiveContainer>
                        }

                        {this.state.currentChartType == ChartTypeCode.Line &&
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart style={{ backgroundColor: 'white' }}
                                    height={300}
                                    data={this.state.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="x" />
                                    <YAxis />
                                    <Tooltip formatter={(value: any) =>
                                        this.state.currentChartDataType == AdminFinancialsChartDataTypeCode.TicketsSold
                                            ? value
                                            : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
                                    } />
                                    <Legend verticalAlign="top" height={40} />
                                    <Line dataKey="y" fill="#FF6358" stroke="#FF6358" name={chartTitle}/>
                                </LineChart >
                            </ResponsiveContainer>
                        }
                    </CardContent>
                    <CardContent className="ticketChartMobileTable">
                        <table>
                            <tr>
                                <th>{this.state.currentDateRangeType == ChartDateRangeTypeCode.Month ? "Days" : "Months"}</th>
                                <th>{this.state.currentChartDataType == AdminFinancialsChartDataTypeCode.Revenue ? "Revenue" : "Tickets Sold"}</th>
                            </tr>
                            {this.state.data.map((data: any) => (
                                <tr>
                                    <td>{data.x}</td>
                                    <td>{this.GetToolTip(data.y)}
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </CardContent>
                </Card>
            </div >
        );
    }
}