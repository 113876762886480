import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { Badge } from '@material-ui/core';

interface INameWithBadgeDisplayCellProps{
    value: any,
    title: string,
    badge: string | undefined,
}

export class NameWithBadgeDisplayCell extends React.Component<INameWithBadgeDisplayCellProps> {

    render() {
        return (
            <td>
                <div className="genericDisplayCellTitle">
                    {this.props.title}:
                </div>
                <div className="genericDisplayCellContent">
                    {this.props.value}
                    <div style={{display: 'inline-block'}}>
                        {this.props.badge != undefined && <Badge badgeContent={this.props.badge} color="secondary"
                        className='badge-as-label'>
                        </Badge>}
                    </div>
                </div>
            </td>
        );
    }
}