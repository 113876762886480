import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig => {
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    };

    return axiosConfig;
}

export const GetGridStateFormData = (gridState: ITableDef): FormData => {

    const formData = new FormData();
    formData.append('skip', JSON.stringify(gridState.skip));
    formData.append('take', JSON.stringify(gridState.take));
    formData.append('sort', JSON.stringify(gridState.sort));
    formData.append('filter', JSON.stringify(gridState.filter));

    return formData;
}

export const createEvent = async (request: ICreateEventRequest): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/events`, request, tokenConfig);
}

export const deleteEvent = async (eventId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.delete(`${baseAPIUrl}/api/events?eventId=${eventId}`, tokenConfig);
}

export const cancelEvent = async (eventId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/events/cancel?eventId=${eventId}`, null, tokenConfig);
}

export const updateEvent = async (request: IUpdateEventRequest): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/events`, request, tokenConfig);
}

export const getEventClusterEvents = async (eventClusterId: any, gridState: ITableDef): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var formData = GetGridStateFormData(gridState);

    return await axios.post(`${baseAPIUrl}/api/events/cluster?eventClusterId=${eventClusterId}`, formData, tokenConfig);
}

const getAllEventsResponse = async (gridState: ITableDef, upcomingEventsOnly: boolean = false): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/events/list`, { gridState: gridState, upcomingEventsOnly: upcomingEventsOnly }, tokenConfig);
}

export const getAllEvents = AwesomeDebouncePromise(getAllEventsResponse, 500);

const getAllEventsForAdminResponse = async (gridState: ITableDef): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/events/all`, gridState, tokenConfig);
}

export const getAllEventsForAdmin = AwesomeDebouncePromise(getAllEventsForAdminResponse, 500);

export const getEvent = async (eventId: any): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/events?eventId=${eventId}`, tokenConfig);
}

export const publishEvent = async (eventId: any): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/events/publish`, { eventId: eventId }, tokenConfig);
}

export const getEventPublicDetails = async (eventId: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/events/public?eventId=${eventId}`, tokenConfig);
}

export const sendEventPromoNotifications = async (eventId: string, message: string): Promise<AxiosResponse> => {
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var request = {
        eventId: eventId,
        message: message,
    };

    return await axios.post(`${baseAPIUrl}/api/events/sendEventPromoNotifications`, request, tokenConfig);
}