import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
      };
      
    return axiosConfig;
}

export const getEventSuccessPrediction = async(eventId: any, latitude: any = null, longitude: any = null, startDateTime: any = "", keywords: any[] = [], dateRange: any = 2): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var params = "";

    if(latitude != undefined && latitude != ""){
        params += `&latitude=${latitude}`;
    }

    if(longitude != undefined && longitude != ""){
        params += `&longitude=${longitude}`;
    }

    if(startDateTime != undefined && startDateTime != ""){
        params += `&startDateTime=${startDateTime}`;
    }

    if(keywords != undefined && keywords.length > 0){
        var keywordsString = keywords.join(",");
        params += `&keywords=${encodeURIComponent(keywordsString)}`;
    }
    

    if(dateRange != undefined){
        params += `&dateRange=${dateRange}`;
    }

    return await axios.get(`${baseAPIUrl}/api/eventSuccessPrediction?eventId=${eventId}${params}`, tokenConfig);
}
