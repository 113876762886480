/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { updateEventCluster, getEventCluster } from '../../../data/EventClusterRepository';
import {EventClusterPermissionCode} from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { GenericErrorModal } from '../../Components/GenericErrorModal';
import { getAllCreatedOrganizations } from '../../../data/OrganizationRepository';
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import { StripeAccountTypeCode } from '../../../data/Models/StripeAccountTypeCode';

var Enumerable = require('linq');

interface IEventClusterSectionProps{
    userPermissions: EventClusterPermission[],
    isClusterOwner: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class EventClusterSection extends React.Component<IEventClusterSectionProps> {

    state = {
        clusterId: '',
        clusterName: '',
        organizationId: '',
        description: '',
        clusterNameError: '',
        descriptionError: '',
        organizationIdError: '',
        organizations: [],
        eventClusterServerError: '',
        eventClusterUpdateSuccessMsg: ''
    }

    async componentDidMount(){
        
        this.props.loadingOn();
        var eventClusterServerError = "";
        var clusterName = "";
        var organizationId = "";
        var description = "";
        let ownerPortalUserId = "";

        const query = new URLSearchParams(window.location.search);

        var eventClusterId: string = query.get('id') ?? "";

        var response = await getEventCluster(eventClusterId);

        if(response != null && response.status == 401){
            this.props.changePage(`/login?redirect=event-group?id=${eventClusterId}`);
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                eventClusterServerError = response.data.errorMsg;
            }
            else{
                eventClusterId = response.data.id;
                clusterName = response.data.name;
                organizationId = response.data.organizationId;
                description = response.data.description;
                ownerPortalUserId = response.data.ownerPortalUserId;
            }
        }
        else{
            eventClusterServerError = "An unknown error occured. Please try again later";
        }

        let organizations = [];

        var organizationsResponse = await getAllCreatedOrganizations(true, ownerPortalUserId);

        if(organizationsResponse != null && organizationsResponse.status == 401){
            this.props.changePage(`/login?redirect=event-group?id=${eventClusterId}`);
        }
        else if(organizationsResponse != null && organizationsResponse.status == 200)
        {
            if(!organizationsResponse.data.success)
            {
                eventClusterServerError = organizationsResponse.data.errorMsg;
            }
            else{
                organizations = Enumerable.from(organizationsResponse.data.organizations)
                    .select((o: any) => 
                        ({
                            id: o.id, 
                            name: o.stripeAccountTypeCode == StripeAccountTypeCode.None
                                ? o.name + " (free tickets only)"
                                : o.name + " - " + o.bankRoutingNumber ?? "N/A" + " - ***" + o.bankAccountNumber}))
                    .toArray();
            }
        }
        else{
            eventClusterServerError = "An unknown error occured. Please try again later";
        }
        

        this.setState({...this.state, 
            clusterId: eventClusterId, 
            clusterName: clusterName, 
            organizationId: organizationId,
            description: description, 
            organizations: organizations,
            eventClusterServerError: eventClusterServerError
        });
        this.props.loadingOff();
    }

    HandleClusterNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, clusterName: event.currentTarget.value})
    }

    HandleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, description: event.currentTarget.value})
    }

    UpdateClusterClick = async () =>
    {
        this.props.loadingOn();
        let clusterNameError = '';
        let organizationIdError = '';
        let errored = false;

        let serverErrorText = '';
        let eventClusterUpdateSuccessMsg = '';

        if(this.state.clusterName.length == 0){
            clusterNameError = 'Group name is required.';
            errored = true;
        }

        if(this.state.organizationId == undefined || this.state.organizationId.length == 0){
            organizationIdError = 'Organization is required.';
            errored = true;
        }

        if(errored == false)
        {
            let request: IUpdateEventClusterReqeust = {
                eventClusterId: this.state.clusterId,
                name: this.state.clusterName,
                organizationId: this.state.organizationId,
                description: this.state.description
            };

            var response = await updateEventCluster(request);

            if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverErrorText = response.data.errorMsg;
                }
                else{
                    eventClusterUpdateSuccessMsg = "The group has been updated successfully."
                }
            }
            else{
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }
        
        this.setState({...this.state, clusterNameError: clusterNameError,  eventClusterUpdateSuccessMsg: eventClusterUpdateSuccessMsg, eventClusterServerError: serverErrorText});
        this.props.loadingOff();
    }

    CloseSuccessModal = () => {
        this.setState({...this.state, eventClusterUpdateSuccessMsg: ""});
    };

    CloseErrorModal = () => {
        this.setState({...this.state, eventClusterServerError: "" });
    };

    OnOrganizationChange = (value: any) => {
        this.setState({...this.state, organizationId: value})
    }

    render()
    {
        let editEventClusterDetailsAccess = this.props.isClusterOwner || Enumerable.from(this.props.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.EditEventClusterDetails);

        return (
            <Card style={{width: "100%", backgroundColor: "#ededed"}}>
                <CardHeader title="Event Group" />
                <CardContent >
                    <TextField 
                        error={this.state.clusterNameError !== ""}
                        style={{width: '100%'}}
                        id="clusterName"
                        label="Group Name"
                        placeholder="Group Name"
                        margin="normal"
                        helperText={this.state.clusterNameError}
                        FormHelperTextProps={{ color: "red" }}
                        value={this.state.clusterName}
                        onChange={(e) => this.HandleClusterNameChange(e)}
                        variant="outlined"
                        disabled={!editEventClusterDetailsAccess}
                        />
                    {this.props.isClusterOwner && <GenericDropdown 
                            label="Payout Organization (Bank Account)"
                            value={this.state.organizationId}
                            error={this.state.organizationIdError}
                            data={this.state.organizations}
                            onChange={this.OnOrganizationChange}
                            style={{marginTop: "10px"}}
                        />}
                    <TextField 
                            error={this.state.descriptionError !== ""}
                            style={{width: '100%', backgroundColor: "white"}}
                            multiline={true}
                            rowsMax={5}
                            id="description"
                            label="Description (optional)"
                            placeholder="Description (optional)"
                            margin="normal"
                            helperText={this.state.descriptionError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.description}
                            onChange={(e) => this.HandleDescriptionChange(e)}
                            variant="outlined"
                            disabled={!editEventClusterDetailsAccess}
                            />
                </CardContent>
                <div style={{padding: '16px 16px 16px 16px'}}>
                    {editEventClusterDetailsAccess && <Button variant="contained" className='btn-primary' onClick={this.UpdateClusterClick} >
                        Update Group
                    </Button>}
                </div>
                <GenericSuccessModal 
                    hidden={this.state.eventClusterUpdateSuccessMsg == ""}
                    message={this.state.eventClusterUpdateSuccessMsg}
                    closeModal={this.CloseSuccessModal}
                    />
                <GenericErrorModal hidden={this.state.eventClusterServerError == ''}
                    message={this.state.eventClusterServerError}
                    closeModal={this.CloseErrorModal} />   
            </Card>
        )
    }
}