import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />
import AwesomeDebouncePromise from 'awesome-debounce-promise';

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const searchVenueAddress = async (venueAddress: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    var response = await axios(`${baseAPIUrl}/api/locations/venueAddressSearch?venueAddress=${encodeURIComponent(venueAddress)}`, tokenConfig);
    return response;

}

export const debounceSearchVenueAddress = AwesomeDebouncePromise(searchVenueAddress, 500);