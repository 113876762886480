import * as React from 'react';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { Button, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { GenericDisplayCell } from './GenericDisplayCell';
import { getCustomers, getCustomersFile } from '../../../data/UserEventTicketRepository';
import Link from '@material-ui/core/Link';
import { getAllEventClustersAutocomplete } from '../../../data/EventClusterRepository';
import { EventClusterPermissionCode } from '../../../data/Models/EventClusterPermissionCode';
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import saveAs from 'file-saver';
import { PortalUserRoleCode } from '../../../data/Models/PortalUserRoleCode';
import { getAuthStatus } from '../../../data/PortalUserRepository';
import { TableFilterToggleButton } from './TableFilterToggleButton';

var Enumerable = require('linq');



interface IEventCustomersTableProps {
    eventId: string,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IEventCustomersTableState {
    customers: any[],
    eventClusters: any[],
    customersTotal: number,
    serverError: string,
    tableDef: ITableDef,
    mobileFiltersVisible: boolean
}

const getInitTableDef = () => {
    let sortDef: ISortDef = {
        field: "ticketsCount",
        dir: "desc"
    };

    let filterArray = [
        {
            field: "eventClusterId",
            operator: "eq",
            value: ""
        },
        {
            field: "eventName",
            operator: "eq",
            value: ""
        },
        {
            field: "email",
            operator: "eq",
            value: ""
        },
        {
            field: "firstName",
            operator: "eq",
            value: ""
        },
        {
            field: "lastName",
            operator: "eq",
            value: ""
        },
        {
            field: "ticketsCount",
            operator: "eq",
            value: ""
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 25,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class EventCustomersTable extends React.Component<IEventCustomersTableProps, IEventCustomersTableState> {

    state = {
        customers: [],
        customersTotal: 0,
        serverError: '',
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        eventClusters: [],
        isAdmin: false,
        headCells: [
            {
                id: 'eventClusterName',
                label: 'Event group',
            },
            {
                id: 'eventName',
                label: 'Event',
            },
            {
                id: 'email',
                label: 'Email',
            },
            {
                id: 'firstName',
                label: 'First Name',
            },
            {
                id: 'lastName',
                label: 'Last Name'
            },
            {
                id: 'phoneNumber',
                label: 'Phone Number'
            },
            {
                id: 'ticketsCount',
                label: 'Tickets Count'
            },
        ],
        mobileFiltersVisible: false,
    }

    async componentDidMount() {
        this.props.loadingOn();

        let role: PortalUserRoleCode = PortalUserRoleCode.None;

        const getAuthResponse = await getAuthStatus();

        if (getAuthResponse == undefined || getAuthResponse.status != 200) {
            this.props.changePage("/login?redirect=analytics");
        }

        role = getAuthResponse.data.role;

        if (role == PortalUserRoleCode.Admin) {
            this.state.isAdmin = true;          
        }
        else{
            this.state.headCells = this.state.headCells.filter((cell: any)=>cell.id != 'phoneNumber');
        }

        await this.LoadCustomers(this.state.tableDef);
        await this.LoadEventClusters();
        this.props.loadingOff();
    }

    LoadCustomers = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let customers: any = [];
        let customersTotal = 0;
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "" && f.value != '') || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getCustomers(tempTableDef, this.props.eventId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=portal-users-dashboard");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                customers = response.data.customers;
                customersTotal = response.data.total;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({ ...this.state, customers: customers, customersTotal: customersTotal, tableDef: tableDef, serverError: serverError });
    }

    LoadEventClusters = async () => {
        let eventClusters: any[] = [];
        let serverError = '';

        eventClusters.push({ eventClusterName: 'All', eventClusterId: '' });

        var response = await getAllEventClustersAutocomplete('');

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=analytics");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                response.data.eventClusters.forEach((c: any) => {
                    let codeValuePermissions = EventClusterPermissionCode.ViewEventAnalytics;
                    var hasPermission = c.role.isOwnerRole || Enumerable.from(c.role.eventClusterPermissions).any((p: EventClusterPermission) => p.id == codeValuePermissions);

                    if (hasPermission == true) {
                        eventClusters.push(c);
                    }
                });
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            eventClusters: eventClusters,
            serverError: serverError
        });
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadCustomers(tableDef);
        this.props.loadingOff();
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadCustomers(tableDef);

        this.props.loadingOff();
    };

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadCustomers(tableDef);
        }

        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        if (filter == "userConfirmedRegistration") {
            if (value == "false") {
                filterDef.f.value = false;
            }
            else if (value == "true") {
                filterDef.f.value = true;
            }
            else {
                filterDef.f.value = null;
            }
        }
        else {
            filterDef.f.value = value;
        }

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadCustomers(tableDef);
    }

    GenerateReport = async () => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "" && f.value != '') || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getCustomersFile(tempTableDef, this.props.eventId);

        if (response.status == 401) {
            this.props.changePage("/login?redirect=admin-financials");
        }
        else if (response.status == 200) {
            const blob = new Blob([response.data], {
                type: 'text.csv',
            });
            let filename = response.headers['content-disposition'].match(/filename\=\"?([^;\"]+)\"?\;/)[1];
            saveAs(blob, filename);
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            serverError: serverError
        });
        this.props.loadingOff();
    }

    ToggleFilterVisibility = () => {
        this.setState({ ...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible });
    }

    render() {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "LastName";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <Paper style={{ width: '100%' }}>
                <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        style={{ marginLeft: "0.5%", width: "99%" }}
                        className={this.state.mobileFiltersVisible ? "table-filters-visible all-events-table" : "table-filters-hidden"}
                    >
                        <TableHead>
                            <TableRow>
                                {this.state.headCells.map((headCell) =>
                                    <React.Fragment>
                                        {(headCell.id != "eventName" && headCell.id != "eventClusterName" || this.props.eventId == '') &&
                                            <TableCell size="small"
                                                key={headCell.id}
                                                sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                className="table-header-border show-on-mobile"
                                            >
                                                <TableSortLabel
                                                    active={currentSortName === headCell.id}
                                                    direction={currentSortName === headCell.id ? currentSortDir : null}
                                                    hideSortIcon={currentSortName !== headCell.id}
                                                    onClick={() => this.OnSortChange(headCell.id)}
                                                    style={{ width: "100%" }}
                                                >
                                                    {headCell.label}
                                                </TableSortLabel>
                                            </TableCell>}
                                    </React.Fragment>
                                )}
                            </TableRow>
                            <TableRow>
                                {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                    <React.Fragment>
                                        {headCell.field == "eventClusterId" && this.props.eventId == '' &&
                                            <TableCell size="small" className="table-header-border show-on-mobile" >
                                                <FormControl variant="filled" size="small" fullWidth={true}>
                                                    <Select variant='outlined' fullWidth={true}

                                                        // value={this.state.eventCluster}

                                                        onChange={(e) => this.OnFilterChange('eventClusterId', e.target.value)}
                                                    >
                                                        {
                                                            this.state.eventClusters.map((data: any) => (
                                                                <MenuItem value={data.eventClusterId}>{data.eventClusterName}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                        }
                                        {headCell.field != "eventClusterId" && (headCell.field != "eventName" || this.props.eventId == '') &&
                                            <TableCell size="small" className="table-header-border show-on-mobile" >
                                                <TextField
                                                    fullWidth
                                                    margin="dense"
                                                    size="small"
                                                    onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                    value={headCell.value}
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                        }
                                    </React.Fragment>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.customers.map((row: any, index: any) => {

                                return (
                                    <TableRow
                                        hover
                                    >
                                        {this.props.eventId == '' &&
                                            <TableCell align="right"><GenericDisplayCell title="Event Group" value={row.eventClusterName} /></TableCell>
                                        }
                                        {this.props.eventId == '' &&
                                            <TableCell align="right"><GenericDisplayCell title="Event Name" value={row.eventName} /></TableCell>
                                        }
                                        <TableCell align="right"><Link href={"mailto:" + row.email} >{row.email}</Link></TableCell>
                                        <TableCell align="right"><GenericDisplayCell title="First Name" value={row.firstName} /></TableCell>
                                        <TableCell align="right"><GenericDisplayCell title="Last Name" value={row.lastName} /></TableCell>
                                        {this.state.isAdmin && <TableCell align="right"><GenericDisplayCell title="Phone Number" value={row.phoneNumber} /></TableCell>}
                                        <TableCell align="right"><GenericDisplayCell title="Tickets Count" value={row.ticketsCount} /></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>
                    <TablePagination
                        style={{ display: "inline-block", padding: "0" }}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        count={this.state.customersTotal}
                        rowsPerPage={this.state.tableDef.take!}
                        page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                        onChangePage={(e, page) => this.PageChange(page)}
                        onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                    />

                    <Button style={{ display: "inline-block", verticalAlign: "top", marginTop: "10px" }}
                        variant="contained" className='btn-primary' onClick={this.GenerateReport}>Export to CSV</Button>

                </div>


            </Paper>
        );
    }
}