import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

interface IEventClusterDropdownProps {
    eventClusterId: string,
    eventClusterIdError: string
    eventClusters: any[],
    disabled: boolean,
    onChange: (eventClusterId: any) => void;
}

export class EventClusterDropdown extends React.Component<IEventClusterDropdownProps> {

    public static defaultProps: Partial<IEventClusterDropdownProps> = {
        disabled: false
    };

    HandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.preventDefault();
        this.props.onChange(
            event.target.value
        );
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">Event Group</InputLabel>
                    <Select
                        title="Event Group"
                        label="Event Group"
                        id="demo-simple-select-outlined"
                        style={{ width: '100%' }}
                        fullWidth={true}
                        disabled={this.props.disabled}
                        value={this.props.eventClusterId}
                        onChange={(e) => this.HandleChange(e)}
                        error={this.props.eventClusterIdError !== "" && this.props.eventClusterIdError != null}
                    >
                        {
                            this.props.eventClusters.map((cluster: any) => (
                                <MenuItem value={cluster.eventClusterId}>
                                    {cluster.eventClusterName}
                                    {cluster.freeTicketsOnly && <span>&nbsp;(Free Tickets Only)</span>}
                                    {!cluster.freeTicketsOnly && <span>&nbsp;(Paid + Free Tickets)</span>}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <div style={{ color: '#f44336', fontSize: '0.75rem' }} hidden={this.props.eventClusterIdError == '' && this.props.eventClusterIdError != null}>
                    {this.props.eventClusterIdError}
                </div>
            </div>
        );
    }
}