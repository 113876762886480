import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';
import { confirmRegistration } from '../../data/PortalUserRepository';

interface IConfirmRegistrationPage {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class ConfirmRegistrationPage extends React.Component<IConfirmRegistrationPage> {

    state = {
        error: false,
        success: false
    }

    async componentDidMount() {
        this.props.loadingOn();

        const query = new URLSearchParams(window.location.search);

        var token = query.get('token');

        let success = false;
        let error = false;

        if (token != null && token != '') {
            var response = await confirmRegistration(token || '');

            if (response != null && response.status == 200 && response.data.success == true) {
                success = true;
            }
        }

        error = success == false;

        this.setState({ ...this.state, success: success, error: error });
        this.props.loadingOff();
    }

    render() {
        return (
            <div>
                <Card className='cardOverwrite' >
                    <CardHeader style={{ textAlign: 'center', background: '#212121', color: '#fff' }} title="Event Creator Portal" />
                    <CardContent style={{ textAlign: 'center' }}>
                        <div hidden={!this.state.error} style={{fontSize: "21px"}}>
                            Sorry, this is not a valid account registration confirmation. Please check your email for your registration confirmation email. Thanks!!!
                        </div>
                        <div hidden={!this.state.success} style={{fontSize: "21px"}}>
                            Thank you for confirming your account registration. To login navigate&nbsp;
                            <a href="#" onClick={() => this.props.changePage("/login")}>here</a>.
                        </div>
                    </CardContent>
                </Card>

            </div>
        );
    }
}