import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
/// <reference path="./models/RequestModels.d.ts" />

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const createEventCluster = async(request: ICreateEventClusterReqeust): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.post(`${baseAPIUrl}/api/eventclusters`, request, tokenConfig);
}

export const updateEventCluster = async(request: IUpdateEventClusterReqeust): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.patch(`${baseAPIUrl}/api/eventclusters`, request, tokenConfig);
}

export const getAllEventClusters = async(): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    
    return await axios.post(`${baseAPIUrl}/api/eventclusters/fullList`, null, tokenConfig);
}

export const getAllEventClustersAutocomplete = async(name: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    let requestData = {
            name: name
    };
    return await axios.post(`${baseAPIUrl}/api/eventclusters/autocomplete`, requestData, tokenConfig);
}

export const getAdminEventClustersAutocomplete = async(name: string, portalUserId: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    let requestData = {
            name: name,
            portalUserId: portalUserId,
    };
    return await axios.post(`${baseAPIUrl}/api/eventclusters/adminAutocomplete`, requestData, tokenConfig);
}

const getEventClustersResponse = async(gridState: ITableDef): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;
    
    return await axios.post(`${baseAPIUrl}/api/eventclusters/list`, gridState, tokenConfig);
}

export const getEventClusters = AwesomeDebouncePromise(getEventClustersResponse, 500);

export const getEventCluster = async(id: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );
    
    var tokenConfig = GetTokenConfig();

    var baseAPIUrl = process.env.REACT_APP_API_BASE_URL;

    return await axios.get(`${baseAPIUrl}/api/eventclusters?eventClusterId=${id}`, tokenConfig);
}

