import * as React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from "@material-ui/core/AppBar";
import Box from '@material-ui/core/Box';

interface MobileFriendlyTabsProps{
    titles: string[],
    value: any,
    handleChange: (newValue: any) => void
}

export class MobileFriendlyTabs extends React.Component<MobileFriendlyTabsProps> {

    A11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
          };
    }

    GetMobileGroups = () => {
        var groupedTitles: any[] = [];

        var groupedTitleIndex = 0;

        this.props.titles.forEach((v: any, index: number) => {
            var group:any[] = groupedTitles[groupedTitleIndex];

            if(group == null){
                group = [];
            }

            group.push(v);

            groupedTitles[groupedTitleIndex] = group;

            if(index != 0 && (index + 1) % 3 == 0){
                groupedTitleIndex += 1;
            }
        });

        return groupedTitles;
    }

    render() {

        var mobileGroups = this.GetMobileGroups();
        return (
            <Box style={{ width: '100%' }}>
                <AppBar position="static" color="inherit" className="tabs-desktop-hidden">
                    <Tabs 
                            value={this.props.value} 
                            onChange={(e, v) => this.props.handleChange(v)} 
                            textColor="primary" 
                            indicatorColor="primary"
                    >
                        {this.props.titles.map((value: any, index: number) => (
                            <Tab label={value} {...this.A11yProps(index)}  />
                            ))
                        }
                    </Tabs>
                </AppBar>
                {
                    mobileGroups.map((group: any[], groupIndex: number) => (
                        <AppBar position="static" color="inherit" className="tabs-mobile-hidden">
                            <Tabs 
                                    value={ (groupIndex + 1) * 3 > this.props.value && groupIndex * 3 <= this.props.value ? this.props.value : null} 
                                    onChange={(e, v) => this.props.handleChange(v)} 
                                    textColor="primary" 
                                    indicatorColor="primary"
                            >
                                {
                                    group.map((title, index: number) => ( 
                                        <Tab label={title} 
                                            {...this.A11yProps((groupIndex*3) + index )} 
                                            value={(groupIndex*3) + index}
                                            />
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    ))
                }
            </Box>
        );
    }
}