/// <reference path="../../../data/DataModels/types.d.ts" />
import React, {useEffect, useRef} from 'react';
import * as PropTypes from 'prop-types';
import {TicketOptionSelectionSection} from './TicketOptionSelectionSection';
import {TicketPurchaseCheckoutSection} from './TicketPurchaseCheckoutSection';
import { getEventPublicDetails } from '../../../data/EventRepository';
import {confirmPaymentSuccessful} from '../../../data/UserEventTicketRepository';
import {EventFileTypeCode} from "../../../data/Models/EventFileTypeCode";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import logo from '../../../Images/logo.png';
import TicketPurchaseTotalsReviewSection from './TicketPurchaseTotalsReviewSection';
import AndroidIcon from '@material-ui/icons/Android'
import AppleIcon from '@material-ui/icons/Apple';
var Enumerable = require('linq');

interface ITicketPurchaseSuccessPageProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}


export class TicketPurchaseSuccessPage extends React.Component<ITicketPurchaseSuccessPageProps> {

    state = {
        eventId: '',
        eventName: '',
        venueName: '',
        address: '',
        description: '',
        eventStartDateTime: new Date(),
        eventAgeRestriction: '',
        eventImageUrl: '',
        orderId: "",
        orderNo: "",
        basePriceTotal: 0,
        couponCode: "",
        couponCodeDiscountAmount: 0,
        serviceFeeTotal: 0,
        salesTaxTotal: 0,
        processingFeeTotal: 0,
        totalPrice: 0,
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        dateTimeSubmitted: new Date(),
        serverError: ""
    }

    async componentDidMount(){
        this.props.loadingOn();

        const query = new URLSearchParams(window.location.search);

        var orderId: string = query.get('orderId') ?? "";
        var email: string = query.get('email') ?? "";
        var firstName: string = query.get('firstName') ?? "";
        var lastName: string = query.get('lastName') ?? "";
        var phoneNumber: string = query.get('phoneNumber') ?? "";
        var dateTimeSubmitted: Date = new Date(query.get('dateTimeSubmitted') ?? "");

        localStorage.removeItem("orderEventId");
        localStorage.removeItem("orderId");

        this.setState({
            ...this.state,
            orderId: orderId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            dateTimeSubmitted: dateTimeSubmitted
        });
        
        await this.ConfirmPaymentSuccess(orderId, email, firstName, lastName, phoneNumber, dateTimeSubmitted);

        await this.LoadEventDetails(this.state.eventId);

        this.props.loadingOff();
    }

    LoadEventDetails = async (eventId: string) => {
        
        var eventName = '';
        var eventStartDateTime = new Date();
        var eventAgeRestriction = '';
        var venueName = '';
        var address = '';
        var description = '';
        var eventImageUrl = '';
        var serverError = '';

        var response = await getEventPublicDetails(eventId);

        if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                eventName = response.data.event.name;
                eventAgeRestriction = response.data.event.ageRestriction;
                venueName = response.data.event.venueName;
                address = response.data.event.address;
                description = response.data.event.description;
                eventStartDateTime = response.data.event.startDateTimeLocal;

                eventImageUrl = Enumerable.from(response.data.event.eventImageFiles).where((f: any) => f.eventFileTypeCode == EventFileTypeCode.EventImage).firstOrDefault()?.fileUrl;
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            eventName: eventName,
            eventAgeRestriction: eventAgeRestriction,
            venueName: venueName,
            address: address,
            description: description,
            eventStartDateTime: eventStartDateTime,
            eventImageUrl: eventImageUrl,
            serverError: serverError
        });
    }

    ConfirmPaymentSuccess = async (orderId: string, email: string, firstName: string, lastName: string, phoneNumber: string, dateTimeSubmitted: Date) => {
        
        let serverError = '';

        var request: IFinalizeUserEventTicketOrderRequest = {
            orderId: orderId,
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            dateTimeSubmitted: dateTimeSubmitted
        };

        let eventId = "";
        let orderNo = "";
        let basePriceTotal = 0;
        let couponCode = "";
        let couponCodeDiscountAmount = 0;
        let serviceFeeTotal = 0;
        let salesTaxTotal = 0;
        let processingFeeTotal = 0;
        let totalPrice = 0;

        var response = await confirmPaymentSuccessful(request);

        if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                eventId = response.data.eventId;
                orderNo = response.data.orderNo;
                basePriceTotal = response.data.basePriceTotal;
                couponCode = response.data.couponCode;
                couponCodeDiscountAmount = response.data.couponCodeDiscountAmount;
                serviceFeeTotal = response.data.serviceFeeTotal;
                salesTaxTotal = response.data.salesTaxTotal;
                processingFeeTotal = response.data.processingFeeTotal;
                totalPrice = response.data.totalPrice;
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({...this.state, 
            eventId: eventId,
            orderNo: orderNo,
            basePriceTotal: basePriceTotal,
            couponCode: couponCode,
            couponCodeDiscountAmount: couponCodeDiscountAmount,
            serviceFeeTotal: serviceFeeTotal,
            salesTaxTotal: salesTaxTotal,
            processingFeeTotal: processingFeeTotal,
            totalPrice: totalPrice,
            serverError: serverError});
    }

    DownloadViaIOS = () => {
        window.open("https://apps.apple.com/us/app/nrby-io/id1525909483", '_blank');
    }

    DownloadViaAndroid = () => {
        window.open("https://play.google.com/store/apps/details?id=com.ByNearBy&hl=en_US&gl=US&pli=1", '_blank');
    }

    render()
    {
        return (
            <div style={{width: "100%", fontFamily: "sans-serif"}}>
               <div className="ticketPurchasePage">
                    <Card style={{width: "100%", height: "70px", backgroundColor: "#ededed", color: "white", marginBottom: "10px"}}>
                        <CardContent style={{height: "70px", padding: "0px", textAlign: "center"}}>   
                            <span style={{height: "70px", padding: "0px 0px 0px 0px"}}>
                                <Button style={{ backgroundColor: 'transparent', padding: "0px" }} >
                                    <img src={logo} style={{ maxHeight: '55px', width: 'auto', objectFit: 'contain', padding: "10px 10px 5px 10px"}}/>
                                </Button>
                            </span>
                        </CardContent>
                    </Card>
                    <Card style={{width: "100%", backgroundColor: "#ededed", marginBottom: "10px"}}>
                        <CardContent>
                            <table style={{width: "100%"}}>
                                <tr style={{width: "100%"}}>
                                    {this.state.eventImageUrl != undefined && this.state.eventImageUrl != "" && <td style={{ width: "15%", paddingRight: "20px" }}><img className="ticketPurchasePageImage" src={this.state.eventImageUrl} /></td>}
                                    <td style={{width: "85%", verticalAlign: "top"}}>
                                        <div style={{fontSize: "25px", paddingBottom: "10px"}}>
                                            {this.state.eventName}
                                        </div>
                                        
                                        <div style={{paddingBottom: "5px"}}>
                                            {Moment(this.state.eventStartDateTime).format("MM/DD/yyyy") + " @ " + Moment(this.state.eventStartDateTime).format("h:mm A")}
                                        </div>
                                        <div style={{ paddingBottom: "5px" }} >
                                                {this.state.venueName}
                                            </div>
                                            {this.state.address && <div style={{ paddingBottom: "5px" }} >
                                                {this.state.address}
                                            </div>
                                            }
                                            <div style={{ paddingBottom: "5px" }} >
                                                Age restriction: {this.state.eventAgeRestriction}
                                            </div>
                                            {this.state.description && <div style={{ paddingBottom: "5px" }} >
                                                <hr />
                                                <div dangerouslySetInnerHTML={{ __html: this.state.description }} >
                                                </div>
                                            </div>
                                            }
                                    </td>
                                </tr>
                            </table>
                        </CardContent>
                        {!this.state.serverError && <CardContent style={{fontSize: "18px"}}>
                            <div style={{width: "100%", marginBottom: "10px"}}>
                                <strong>Phone number will automatically connect your tickets to your Nrby.io mobile app account.</strong>
                            </div>
                            <div style={{width: "100%"}}>
                                <strong>Your order was successful! You will receive an email soon with a receipt and link to you tickets. Please check vour junk email as well.</strong>
                            </div>
                        </CardContent>}
                        <CardContent>
                            <table>
                                <tr>
                                    <td>Order #:</td>
                                    <td>{this.state.orderNo}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>{this.state.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number:</td>
                                    <td>{this.state.phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td>Name:</td>
                                    <td>{this.state.firstName + " " + this.state.lastName}</td>
                                </tr>
                                {this.state.couponCode != null && this.state.couponCode != "" && <tr>
                                    <td>
                                        Coupon Code:
                                    </td>
                                    <td>
                                        {this.state.couponCode}
                                    </td>
                                </tr>}
                            </table>
                        </CardContent>
                        <CardContent>
                            <TicketPurchaseTotalsReviewSection 
                                basePriceTotal={this.state.basePriceTotal}
                                couponCodeDiscountAmount={this.state.couponCodeDiscountAmount}
                                serviceFeeTotal={this.state.serviceFeeTotal}
                                salesTaxTotal={this.state.salesTaxTotal}
                                processingFeeTotal={this.state.processingFeeTotal}
                                totalPrice={this.state.totalPrice}
                            />
                        </CardContent>
                        <CardContent style={{fontSize: "18px"}}>
                            <div style={{marginBottom: "10px"}}> 
                                <strong>Instructions on linking your tickets to your Nrby account.</strong>
                            </div>
                            <div style={{marginBottom: "10px"}}>
                                To access your tickets in one handy place, please login to the Nrby.io app, sign-in, and confirm your phone number in app. 
                                Your tickets will automatically sync up in your account.
                            </div>  
                            <div style={{marginBottom: "10px"}}>
                                <img style={{height: "auto", maxWidth: "400px", width: "100%"}} src="https://nrbystorage.blob.core.windows.net/guest-ticket-purchase-instructions/guestPurchaseTicketMobileAppSync1.jpg" />
                            </div>
                            <div style={{marginBottom: "10px"}}>
                                <img style={{height: "auto", maxWidth: "400px", width: "100%"}} src="https://nrbystorage.blob.core.windows.net/guest-ticket-purchase-instructions/guestPurchaseTicketMobileAppSync2.jpg" />
                            </div>
                            <div style={{marginBottom: "10px"}}>
                                <img style={{height: "auto", maxWidth: "400px", width: "100%"}} src="https://nrbystorage.blob.core.windows.net/guest-ticket-purchase-instructions/guestPurchaseTicketMobileAppSync3.jpg" />
                            </div>
                            <div style={{marginBottom: "20px"}}>
                                If your phone number is already linked to your Nrby.io account then your tickets have been automatically sent to your account. 
                                Please make sure to use the same phone that was used to purchase your tickets.
                            </div>
                            <div style={{marginBottom: "10px"}}> 
                                <strong>Download Nrby.io Mobile App Below</strong>
                            </div>
                            <div style={{marginBottom: "10px"}}>
                                <Button onClick={this.DownloadViaIOS} className="btn-primary">
                                    Download for iOS &#9;
                                    <AppleIcon/>
                                </Button>
                            </div>
                            <div style={{marginBottom: "10px"}}>
                                <Button onClick={this.DownloadViaAndroid} className="btn-primary">
                                    Download for Android &#9;
                                    <AndroidIcon/>
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                    <div style={{paddingTop: "10px", color: "red"}}>
                        {(this.state.serverError != undefined || this.state.serverError != "") &&
                            <div>
                                {this.state.serverError}
                            </div>
                        }
                    </div>
               </div>
            </div>
        )
    }
}
