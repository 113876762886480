/// <reference path="../../../data/DataModels/types.d.ts" />
/// <reference path="../../../data/Models/TicketOption.d.ts" />
import React from 'react';
import NumberFormat from 'react-number-format';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import StripeCheckoutLoader  from './StripeCheckoutLoader';
import TicketPurchaseTotalsReviewSection from './TicketPurchaseTotalsReviewSection';
import { GenericConfirmationModal} from '../GenericConfirmationModal';

interface ITicketPurchaseCheckoutSectionProps{
    gaTicketOptions: any[],
    orderId: string,
    orderNo: string,
    basePriceTotal: number,
    serviceFeeTotal: number,
    salesTaxTotal: number,
    processingFeeTotal: number,
    couponCodeDiscountAmount: number,
    totalPrice: number,
    paymentIntentClientSecret: string,
    orderExpirationDateTime: Date,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    couponCode: string,
    backButtonClick: () => void
}
export class TicketPurchaseCheckoutSection extends React.Component<ITicketPurchaseCheckoutSectionProps> {

    state = {
        orderErrorMessage: "",
        confirmationModalVisible: false
    }

    OpenSubmitFreePaymentConfirmationModal = () =>{
        this.setState({...this.state,
            confirmationModalVisible: true
        });
    }

    CloseSubmitFreePaymentConfirmationModal = () => {
        this.setState({...this.state,
            confirmationModalVisible: false
        });
    }

    SubmitFreePayment = async () => {
        let orderErrorMessage = "";

        var currentDateTime = new Date();

        var orderExpirationDateTime = new Date(this.props.orderExpirationDateTime);
        if(orderExpirationDateTime < currentDateTime){
            orderErrorMessage = "Your order has expired. Please go back to the beginning and try again.";
            this.setState({
                ...this.state,
                orderErrorMessage: orderErrorMessage
            });
            return;
        }

        var finalizeOrderLink = `${process.env.REACT_APP_BASE_URL}/order/confirmation?orderId=${this.props.orderId}&email=${this.props.email}&firstName=${this.props.firstName}&lastName=${this.props.lastName}&phoneNumber=${this.props.phoneNumber}&dateTimeSubmitted=${currentDateTime.toString()}`;

        window.location.href = finalizeOrderLink;
    }

    render()
    {
        return (
            
            <div style={{width: "100%"}}>
               {this.props.gaTicketOptions.length > 0 && 
                    <Card style={{width: "100%", backgroundColor: "#ededed"}}>
                        <CardContent>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <Button className="btn-primary" style={{width: "80%", height: "43px"}} onClick={this.props.backButtonClick}><strong>Back</strong></Button>
                            </div>
                        </CardContent>
                        <CardContent>
                            <table>
                                <tr>
                                    <td>Order #:</td>
                                    <td>{this.props.orderNo}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>{this.props.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number:</td>
                                    <td>{this.props.phoneNumber}</td>
                                </tr>
                                <tr>
                                    <td>Name:</td>
                                    <td>{this.props.firstName + " " + this.props.lastName}</td>
                                </tr>
                                {this.props.couponCode != null && this.props.couponCode != "" && <tr>
                                    <td>
                                        Coupon Code:
                                    </td>
                                    <td>
                                        {this.props.couponCode}
                                    </td>
                                </tr>}
                            </table>
                        </CardContent>
                        <CardContent>
                            <table>
                            {
                                
                                this.props.gaTicketOptions.map((ticketOption: TicketOption) => (
                                    ticketOption.qtySelected > 0 && <tr>
                                        <td>
                                                {ticketOption.name + " - "} 
                                                <NumberFormat value={ticketOption.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                {" - " + ticketOption.qtySelected + "x"} 
                                        </td>
                                    </tr>
                            ))}
                            </table>
                        </CardContent>
                        <CardContent>
                            <TicketPurchaseTotalsReviewSection 
                                basePriceTotal={this.props.basePriceTotal}
                                couponCodeDiscountAmount={this.props.couponCodeDiscountAmount}
                                serviceFeeTotal={this.props.serviceFeeTotal}
                                salesTaxTotal={this.props.salesTaxTotal}
                                processingFeeTotal={this.props.processingFeeTotal}
                                totalPrice={this.props.totalPrice}
                            />
                        </CardContent>
                        <CardContent>
                            {this.props.totalPrice > 0 ? <StripeCheckoutLoader 
                                orderId={this.props.orderId} 
                                clientSecret={this.props.paymentIntentClientSecret} 
                                orderExpirationDateTime={this.props.orderExpirationDateTime} 
                                email={this.props.email}
                                firstName={this.props.firstName}
                                lastName={this.props.lastName}
                                phoneNumber={this.props.phoneNumber}
                                /> : 
                                <div style={{width: "100%", textAlign: "center", paddingBottom: "20px"}}>
                                    <Button style={{width: "80%", backgroundColor: "#5469d4", color: "white"}} onClick={this.OpenSubmitFreePaymentConfirmationModal}>Finalize</Button>
                                    {this.state.orderErrorMessage && <div id="payment-message" style={{color: "red"}}>{this.state.orderErrorMessage}</div>}
                                </div>
                                }
                                <GenericConfirmationModal 
                                    hidden={this.state.confirmationModalVisible == false}
                                    cancelButtonText="Cancel" confirmButtonText="Yes"
                                    onConfirm={this.SubmitFreePayment}
                                    closeModal={this.CloseSubmitFreePaymentConfirmationModal}
                                    message="Are you sure you want to finalize this order?"
                                />
                        </CardContent>
                        <div style={{float: "right", margin: "10px"}}>
                                Powered by <a href="https://stripe.com" target='_blank'>Stripe</a>
                            </div>
                    </Card>
               }
            </div>
        )
    }
}