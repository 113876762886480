import React from 'react';
import '../App.css';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
/// <reference path="./models/RequestModels.d.ts" />

interface ISignUpSuccessPageProps{
    changePage: (page: string) => void
}

export class SignUpSuccessPage extends React.Component<ISignUpSuccessPageProps> {
    render(){
        
        return (
            <div>
                <Card className='cardOverwrite' >
                    <CardHeader style={{ textAlign: 'center', background: '#212121', color: '#fff'}} title="Event Creator Portal" />
                    <CardContent style={{ textAlign: 'center'}}>
                        <p>
                            An email was sent to you with a link to confirm your account set up. Please check your email and click the provided link to confirm before attempting to login. <strong style={{color: "red"}}>Check your spam/junk folders if you do not receive an email.</strong>
                        </p>
                        <Button variant="contained" className='btn-primary' onClick={() => this.props.changePage("/login")}>
                            Login
                        </Button>    
                    </CardContent>
                </Card>
            </div>
        );
    }
}