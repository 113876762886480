import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { getUserPendingEventClusterInvites, acceptEventClusterInvite, declineEventClusterInvite } from '../../data/EventClusterInviteRepository';
import { PortalUserPendingClusterInviteActionCell } from './SubComponents/PortalUserPendingClusterInviteActionCell';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GenericDisplayCell } from './SubComponents/GenericDisplayCell';
import { TableFilterToggleButton } from './SubComponents/TableFilterToggleButton';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

var Enumerable = require('linq');

interface IPortalUserEventClusterInviteTableProps {
    changePage: (page: string) => void,
    refreshParent: () => void
}

const getInitTableDef = () => {
    let sortDef: ISortDef = {
        field: "eventClusterName",
        dir: "desc"
    };

    let filterArray = [
        {
            field: "eventClusterName",
            operator: "eq",
            value: ""
        }
    ];


    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 2,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class PortalUserEventClusterInviteTable extends React.Component<IPortalUserEventClusterInviteTableProps> {

    state = {
        eventClusterInvites: [],
        invitesTotal: 0,
        deleteInviteIndex: -1,
        deleteInviteModalOpen: false,
        eventClusterInviteServerError: '',
        serverSuccessMsg: '',
        tableDef: getInitTableDef(),
        pageable: getInitPageable(),
        headCells: [
            {
              id: 'eventClusterName',
              label: 'Event Group Name',
            },
            {
              id: 'role',
              label: 'Role',
            }
          ],
        loading: false,
        mobileFiltersVisible: false
    }

    async componentDidMount() {
        this.LoadingOn();
        await this.UpdateTable(this.state.tableDef);
        this.LoadingOff();
    }

    async UpdateTable(tableDef: ITableDef) {
        this.setState({ ...this.state, tableDef: tableDef });

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "")).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        let eventClusterInviteServerError = '';
        let response = await getUserPendingEventClusterInvites(tempTableDef);

        let eventClusterInvites = [];
        let invitesTotal = 0;
        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group-dashboard");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterInviteServerError = response.data.errorMsg;
            }
            else {
                eventClusterInvites = response.data.eventClusterInvites;
                invitesTotal = response.data.total;
            }
        }
        else {
            eventClusterInviteServerError = "An unknown error occured. Please try again later";
        }

        this.setState({ ...this.state, tableDef: tableDef, eventClusterInvites: eventClusterInvites, eventClusterInviteServerError: eventClusterInviteServerError, invitesTotal: invitesTotal });
    }

    OnAcceptInviteClick = async (dataIndex: number) => {
        this.LoadingOn();

        let eventClusterInvites: any[] = this.state.eventClusterInvites;
        let eventClusterInvite: any = eventClusterInvites[dataIndex];

        let eventClusterInviteServerError = '';
        let serverSuccessMsg = '';

        var response = await acceptEventClusterInvite(eventClusterInvite.id);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group-dashboard");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterInviteServerError = response.data.errorMsg;
            }
            else {
                eventClusterInvites = Enumerable.from(eventClusterInvites).where((i: any) => i.id != eventClusterInvite.id).toArray();
                serverSuccessMsg = `Event group invite for "${eventClusterInvite.eventClusterName}" accepted successfully.`;
                this.props.refreshParent();
            }
        }
        else {
            eventClusterInviteServerError = "An unknown error occured. Please try again later";
        }

        this.setState({ ...this.state, eventClusterInvites: eventClusterInvites, eventClusterInviteServerError: eventClusterInviteServerError, serverSuccessMsg: serverSuccessMsg });
        this.LoadingOff();
    }

    OnDeclineInviteClick = (dataIndex: number) => {

        this.setState({ ...this.state, deleteInviteIndex: dataIndex, deleteInviteModalOpen: true });
    }

    CloseDeleteInviteModal = () => {
        this.setState({ ...this.state, deleteInviteIndex: -1, deleteInviteModalOpen: false });
    }

    DeclineInvite = async () => {
        this.LoadingOn();

        let eventClusterInvites: any[] = this.state.eventClusterInvites;
        let eventClusterInvite: any = eventClusterInvites[this.state.deleteInviteIndex];

        let eventClusterInviteServerError = '';
        let serverSuccessMsg = '';

        var response = await declineEventClusterInvite(eventClusterInvite.id);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group-dashboard");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterInviteServerError = response.data.errorMsg;
            }
            else {
                eventClusterInvites = Enumerable.from(eventClusterInvites).where((i: any) => i.id != eventClusterInvite.id).toArray();
                serverSuccessMsg = `Event group invite for "${eventClusterInvite.eventClusterName}" declined successfully.`;
                this.props.refreshParent();
            }
        }
        else {
            eventClusterInviteServerError = "An unknown error occured. Please try again later";
        }

        this.setState({ ...this.state, eventClusterInvites: eventClusterInvites, eventClusterInviteServerError: eventClusterInviteServerError, serverSuccessMsg: serverSuccessMsg, deleteInviteModalOpen: false });
        this.LoadingOff();
    }

    OnSortChange = async (sort: any) => {
        this.LoadingOn();
        let tableDef = this.state.tableDef;

        if(tableDef != null && tableDef.sort!.length > 0){
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.UpdateTable(tableDef);
        }
    
        this.LoadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({f, index})).firstOrDefault((fi: any) => fi.f.field == filter);

        filterDef.f.value = value;
        
        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.UpdateTable(tableDef);
    }

    PageChange = async (newPage: any) => {
        this.LoadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.UpdateTable(tableDef);
        this.LoadingOff();
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.LoadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.UpdateTable(tableDef);

        this.LoadingOff();
    };

    ToggleFilterVisibility = () => {
        this.setState({ ...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible });
    }

    LoadingOn = () => {
        this.setState({ ...this.state, loading: true });
    }

    LoadingOff = () => {
        this.setState({ ...this.state, loading: false });
    }

    render() {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "eventClusterName";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{ height: '100%' }}>
                <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Card style={{ height: '100%', backgroundColor: "#ededed" }}>
                    <CardHeader title="Your Event Group Invites" />
                    <CardContent style={{ height: '100%', boxSizing: 'border-box', marginTop: '-64px', paddingTop: '64px', paddingBottom: '16px' }}>
                        <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility} />
                        <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%'}}>
                                <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    style={{marginLeft: "0.5%", width: "99%"}}
                                    className={this.state.mobileFiltersVisible ? "table-filters-visible my-group-invites-table" : "table-filters-hidden"}
                                >
                                    <TableHead>
                                    <TableRow>
                                        {this.state.headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                            className={headCell.id == "eventClusterName" ? "table-header-border show-on-mobile" : "table-header-border no-show-on-mobile"}
                                        >
                                            <TableSortLabel
                                            active={currentSortName === headCell.id }
                                            direction={currentSortName === headCell.id  ? currentSortDir : null}
                                            hideSortIcon={currentSortName !== headCell.id}
                                            onClick={() => this.OnSortChange(headCell.id)}
                                            style={{width: "100%"}}
                                            >
                                            {headCell.label}
                                            </TableSortLabel>
                                        </TableCell>
                                        ))}
                                        <TableCell
                                            className="table-header-border no-show-on-mobile"
                                        >
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                        <TableCell className="table-header-border show-on-mobile" >
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                size="small"
                                                onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                type={headCell.field == "sortOrder" ? "number" : "string"}
                                                value={headCell.value}
                                                variant="outlined"
                                                />
                                        </TableCell>
                                        ))}
                                        <TableCell className="table-header-border no-show-on-mobile">

                                        </TableCell>
                                        <TableCell className="table-header-border no-show-on-mobile">

                                        </TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {this.state.eventClusterInvites.map((row: any, index: any) => {
                                        return (
                                            <TableRow
                                            hover
                                            >
                                            <TableCell align="right"> <GenericDisplayCell title="Event Group Name" value={row.eventClusterName} /></TableCell>
                                            <TableCell align="right">
                                                <GenericDisplayCell title="Role" value={row.eventClusterRoleName} />
                                            </TableCell>
                                            <TableCell align="right"> 
                                                <PortalUserPendingClusterInviteActionCell dataIndex={index} onAcceptInviteClick={this.OnAcceptInviteClick} onDeclineInviteClick={this.OnDeclineInviteClick} />
                                            </TableCell>                                               
                                            </TableRow>
                                        );
                                        })}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={this.state.invitesTotal}
                                rowsPerPage={this.state.tableDef.take!}
                                page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                onChangePage={(e, page) => this.PageChange(page)}
                                onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                                />
                            </Paper>
                            </Box>
                    </CardContent>
                </Card>
                <Dialog
                    onClose={this.CloseDeleteInviteModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.deleteInviteModalOpen}
                >
                    <DialogTitle disableTypography >
                        <Typography variant="h6">Decline Invite</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseDeleteInviteModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to decline this invite?
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseDeleteInviteModal} className='two-action-buttons'>
                            No
                        </Button>
                        <Button onClick={this.DeclineInvite} className='two-action-buttons'>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}