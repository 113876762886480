import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface IEventClusterRoleDropdownCellProps{
    title: any,
    dataItem: any,
    dataIndex: number,
    roles: any[],
    editMode: boolean,
    onChange: (dataIndex: number, value: any) => void;
}

export class EventClusterRoleDropdownCell extends React.Component<IEventClusterRoleDropdownCellProps> {

    
    HandleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.preventDefault();
        this.props.onChange(
            this.props.dataIndex,
            event.target.value
        );
    }

    render() {
        return (
            <td>
                <div className="genericDisplayCellTitle">
                    {this.props.title}:
                </div>
                <div className="genericDisplayCellContent">
                    {this.props.editMode == true && <Select
                        fullWidth={true}
                        value={this.props.dataItem.eventClusterRoleId}
                        onChange={(e) => this.HandleRoleChange(e)}
                        error={this.props.dataItem.roleError !== "" && this.props.dataItem.roleError != null}
                        >
                        {
                            this.props.roles.map((role: any) => (
                                <MenuItem value={role.id}>{role.name}</MenuItem>
                            ))
                        }
                        </Select>}
                    {this.props.editMode == false && <div>
                            {this.props.dataItem.eventClusterRoleName}
                        </div>
                    }
                    <div style={{color: '#f44336', fontSize: '0.75rem'}} hidden={this.props.dataItem.roleError == '' && this.props.dataItem.roleError != null}>
                        {this.props.dataItem.roleError}
                    </div>
                </div>
            </td>
        );
    }
}