import * as React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

interface IPortalUserPendingClusterInviteActionCellProps{
    dataIndex: number,
    onAcceptInviteClick: (dataIndex: number) => void;
    onDeclineInviteClick: (dataIndex: number) => void;
}

export class PortalUserPendingClusterInviteActionCell extends React.Component<IPortalUserPendingClusterInviteActionCellProps> {

    render() {
        return (
            <td style={{textAlign: "center"}}>
                <Button variant="contained" className="btn-secondary" onClick={() => this.props.onAcceptInviteClick(this.props.dataIndex)} >
                    Accept
                </Button>
                &emsp;
                <Button variant="contained" className="btn-delete" onClick={() => this.props.onDeclineInviteClick(this.props.dataIndex)}>
                    Decline
                </Button>
            </td>
        );
    }
}