/// <reference path="../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { getZipCodeSalesTaxes, updateZipCodeSalesTaxRate, addZipCodeSalesTaxRate } from '../../data/SalesTaxRepository';
import { GenericDisplayCell } from './SubComponents/GenericDisplayCell';
import { GenericErrorModal } from './GenericErrorModal';
import { NumberFieldCell } from './SubComponents/NumberFieldCell';
import { EditDeleteActionCell } from './SubComponents/EditDeleteActionCell';
import TextField from '@material-ui/core/TextField';
import { GenericSuccessModal } from './GenericSuccessModal';
import { TableFilterToggleButton } from './SubComponents/TableFilterToggleButton';
import { PortalUserRoleCode } from "../../data/Models/PortalUserRoleCode";
import { getAuthStatus } from '../../data/PortalUserRepository';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

var Enumerable = require('linq');


interface IAdminSalesTaxPageProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

interface IAdminSalesTaxPageState {
    zipCodes: any[],
    zipCodesTotal: number,
    pageable: any,
    tableDef: ITableDef,
    serverError: string,
    editZipCodes: any[],
    editMode: boolean,
    focusedId: string,
    serverSuccessMsg: string,
    newZipCodeModalOpen: boolean,
    newZipCode: string,
    newZipCodeError: string,
    newZipCodeSalesTaxAdjustment: number,
    newZipCodeSalesTaxAdjustmentError: string,
    newConditions: string,
    addSuccess: boolean,
    mobileFiltersVisible: boolean,
    editConditionsModalOpen: boolean,
    editConditionsZipCode: string,
    editConditions: string
}

const getInitTableDef = () => {
    let sortDef: ISortDef = {
        field: "ZipCode",
        dir: "asc"
    };

    let filterArray = [
        {
            field: "zipCode",
            operator: "eq",
            value: ""
        },
        {
            field: "salesTaxRateAdjustment",
            operator: "eq",
            value: ""
        },
        {
            field: "conditions",
            operator: "eq",
            value: ""
        }
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class AdminSalesTaxPage extends React.Component<IAdminSalesTaxPageProps, IAdminSalesTaxPageState> {

    state = {
        zipCodes: [],
        zipCodesTotal: 0,
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
                id: 'zipCode',
                numeric: false,
                label: 'Zip Code',
            },
            {
                id: 'salesTaxRateAdjustment',
                numeric: false,
                label: 'Sales Tax Rate Adjustment',
            },
            {
                id: 'conditions',
                numeric: false,
                label: 'Conditions',
            }
        ],
        serverError: '',
        editZipCodes: [],
        focusedId: '',
        serverSuccessMsg: '',
        editMode: false,
        newZipCodeModalOpen: false,
        newZipCode: '',
        newZipCodeError: '',
        newZipCodeSalesTaxAdjustment: 0,
        newZipCodeSalesTaxAdjustmentError: '',
        newConditions: '',
        addSuccess: false,
        mobileFiltersVisible: false,
        editConditionsModalOpen: false,
        editConditionsZipCode: '',
        editConditions: ''
    }

    async componentDidMount() {
        this.props.loadingOn();

        let role: PortalUserRoleCode = PortalUserRoleCode.None;

        const response = await getAuthStatus();

        if (response == undefined || response.status != 200) {
            this.props.changePage("/login?redirect=admin-sales-tax");
        }

        role = response.data.role;

        if (role == PortalUserRoleCode.Admin) {
            await this.LoadZipCodes(this.state.tableDef);
        }
        else {
            this.props.changePage("/dashboard");
        }

        this.props.loadingOff();
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadZipCodes(tableDef);
        }

        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        if (filter == "salesTaxRateAdjustment") {
            if (value == null || value == "") {
                filterDef.f.value = "";
            }
            else {
                filterDef.f.value = parseFloat(value);
            }
        }
        else {
            filterDef.f.value = value;
        }

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadZipCodes(tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadZipCodes(tableDef);
        this.props.loadingOff();
    }

    LoadZipCodes = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let zipCodes: any = [];
        let zipCodesTotal = 0;
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "" && f.value != '')).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getZipCodeSalesTaxes(tempTableDef);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=admin-sales-tax");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                zipCodes = response.data.zipCodes;
                zipCodesTotal = response.data.total;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }


        this.setState({ ...this.state, zipCodes: zipCodes, zipCodesTotal: zipCodesTotal, tableDef: tableDef, serverError: serverError });
    }

    ChangeSalesTaxRateAdjustment = (dataIndex: number, value: number, zipCode: string) => {
        var zipCodes: any[] = this.state.zipCodes;
        var zipCodeData: any = zipCodes[dataIndex];

        zipCodeData.salesTaxRateAdjustment = value;
        zipCodes[dataIndex] = zipCodeData;

        this.setState({ ...this.state, zipCodes: zipCodes, focusedId: "zip-code-rate-adjustment-" + dataIndex });
    }

    CheckIfInEditMode = (zipCode: string) => {
        let editZipCodes: string[] = this.state.editZipCodes;
        return editZipCodes.indexOf(zipCode) > -1;
    }

    RemoveFocusedZipCode = () => {
        this.setState({ ...this.state, focusedId: '', editMode: false })
    }

    OpenZipCodeEditMode = (dataIndex: number) => {
        let zipCodeData: any = this.state.zipCodes[dataIndex];

        let editZipCodes: any[] = this.state.editZipCodes;

        zipCodeData.salesTaxRateAdjustmentOriginal = zipCodeData.salesTaxRateAdjustment;
        zipCodeData.editMode = true;
        editZipCodes.push(zipCodeData.zipCode);

        this.setState({ ...this.state, editZipCodes: editZipCodes, editMode: true });
    }

    SaveEditedZipCode = async (dataIndex: number) => {
        this.props.loadingOn();

        let zipCodes: any[] = this.state.zipCodes;
        let zipCodeData: any = zipCodes[dataIndex];

        let errored = false;
        let serverError = "";
        let serverSuccessMsg = '';

        let editZipCodes = this.state.editZipCodes;

        if (errored == false) {
            var response: any = await updateZipCodeSalesTaxRate(zipCodeData);

            if (response != null && response.status == 401) {
                this.props.changePage("/login?redirect=admin-sales-tax");
            }
            else if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = response.data.errorMsg;
                }
                else {
                    editZipCodes = Enumerable.from(editZipCodes).where((id: any) => id != zipCodeData.zipCode).toArray();

                    serverSuccessMsg = `Zipcode "${zipCodeData.zipCode}" updated successfully.`;
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }
        }

        this.setState({ ...this.state, zipCodes: zipCodes, editZipCodes: editZipCodes, serverSuccessMsg: serverSuccessMsg, serverError: serverError, editMode: false });
        this.props.loadingOff();
    }

    CancelEditedZipCode = async (dataIndex: number) => {
        let zipCodeData: any = this.state.zipCodes[dataIndex];
        let editZipCodes: any[] = this.state.editZipCodes;
        zipCodeData.salesTaxRateAdjustment = zipCodeData.salesTaxRateAdjustmentOriginal;
        const index = editZipCodes.indexOf(zipCodeData.zipCode, 0);
        if (index > -1) {
            editZipCodes.splice(index, 1);
        }
        let editMode = editZipCodes.length > 0;
        this.setState({ ...this.state, editZipCodes: editZipCodes, editMode: editMode });
    }

    AddZipCodeSalesTax = async () => {
        this.props.loadingOn();
        let serverError = '';
        let addSuccess = false;
        let newZipCode = this.state.newZipCode;
        let newZipCodeSalesTaxAdjustment = this.state.newZipCodeSalesTaxAdjustment;
        let newConditions = this.state.newConditions;

        var response: any = await addZipCodeSalesTaxRate(newZipCode, newZipCodeSalesTaxAdjustment, newConditions);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=admin-sales-tax");
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                newZipCode = "";
                newZipCodeSalesTaxAdjustment = 0;
                newConditions = "";
                addSuccess = true;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }
        this.CloseAddZipCodeModal();
        await this.LoadZipCodes(this.state.tableDef);
        this.props.loadingOff();
        this.setState({
            ...this.state,
            serverError: serverError,
            addSuccess: addSuccess,
            newZipCode: newZipCode,
            newZipCodeSalesTaxAdjustment: newZipCodeSalesTaxAdjustment,
            newConditions: newConditions,
            focusedId: ''
        });
    }

    CloseAddZipCodeModal = () => {
        this.setState({ ...this.state, newZipCodeModalOpen: false });
    }

    OpenAddZipCodeModal = () => {
        this.setState({ ...this.state, newZipCodeModalOpen: true, focusedId: '' });
    }

    HandleNewZipCodeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, newZipCode: event.currentTarget.value });
    }

    HandeNewConditionsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, newConditions: event.currentTarget.value });
    }

    HandleNewZipCodeSalesTaxRateAdjustmentChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var value = parseFloat(event.currentTarget.value);
        if (isNaN(value))
            value = 0;
        this.setState({ ...this.state, newZipCodeSalesTaxAdjustment: value });
    }

    ToggleFilterVisibility = () => {
        this.setState({ ...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible });
    }

    CloseAddSuccessModal = () => {
        this.setState({ ...this.state, addSuccess: false });
    }

    CloseErrorModal = () => {
        this.setState({ ...this.state, serverError: '' });
    }

    EditConditionsInit = (zipCode: string) => {

        var zipCodeSalesTax = Enumerable.from(this.state.zipCodes).where((z: any) => z.zipCode == zipCode).firstOrDefault();

        this.setState({
            ...this.state,
            editConditionsModalOpen: true,
            editConditionsZipCode: zipCode,
            editConditions: zipCodeSalesTax.conditions
        });
    }

    CloseEditConditionsModal = () => {
        this.setState({
            ...this.state,
            editConditionsModalOpen: false,
            editConditionsZipCode: '',
            editConditions: ''
        });
    }

    AcceptConditionsChange = () => {
        var zipCodeSalesTax = Enumerable.from(this.state.zipCodes).select((z: any, index: number) => ({ zipCodeSalesTax: z, index: index })).where((z: any) => z.zipCodeSalesTax.zipCode == this.state.editConditionsZipCode).firstOrDefault();

        var zipCodes: any[] = this.state.zipCodes;
        var zipCodeData: any = zipCodes[zipCodeSalesTax.index];

        zipCodeData.conditions = this.state.editConditions;
        zipCodes[zipCodeSalesTax.index] = zipCodeData;

        this.setState({
            ...this.state,
            editConditionsModalOpen: false,
            editConditionsZipCode: '',
            editConditions: '',
            zipCodes: zipCodes
        });
    }


    ChangeEditConditions = (event: any) => {
        var editConditions = event.currentTarget.value;

        this.setState({
            ...this.state,
            editConditions: editConditions
        });
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadZipCodes(tableDef);

        this.props.loadingOff();
    };

    render() {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "ZipCode";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{ width: "100%" }}>
                <Card style={{ backgroundColor: "#ededed" }}>
                    <CardHeader title="Sales Tax Rates" />
                    <CardContent>
                        <Button variant="contained" className='btn-primary' style={{ marginBottom: "10px" }}
                            onClick={this.OpenAddZipCodeModal} >
                            Add zip code
                        </Button>
                        <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility} />
                        <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className={this.state.mobileFiltersVisible ? "table-filters-visible sales-tax-table" : "table-filters-hidden"}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        <TableSortLabel
                                                            active={currentSortName === headCell.id}
                                                            direction={currentSortName === headCell.id ? currentSortDir : null}
                                                            hideSortIcon={currentSortName !== headCell.id}
                                                            onClick={() => this.OnSortChange(headCell.id)}
                                                            style={{ width: "100%" }}
                                                        >
                                                            {headCell.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                                <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                                    <TableCell className="table-header-border show-on-mobile" >
                                                        <TextField
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                            value={headCell.value}
                                                            type={headCell.field == "salesTaxRateAdjustment" ? "number" : "string"}
                                                            variant="outlined"
                                                        />
                                                    </TableCell>
                                                ))}
                                                <TableCell className="table-header-border no-show-on-mobile">

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.zipCodes.map((row: any, index: any) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="right"><GenericDisplayCell title="Zip Code" value={row.zipCode} /></TableCell>
                                                        <TableCell align="right"><NumberFieldCell
                                                            title="Sales Tax Rate Adjustment"
                                                            dataIndex={index}
                                                            focusedId={this.state.focusedId}
                                                            idPrefix="zip-code-rate-adjustment-"
                                                            value={row.salesTaxRateAdjustment}
                                                            placeHolder="Sales Tax Rate Adjustment"
                                                            error={row.salesTaxRateAdjustmentError}
                                                            editMode={this.CheckIfInEditMode(row.zipCode)}
                                                            onChange={this.ChangeSalesTaxRateAdjustment}
                                                            removeFocusedId={this.RemoveFocusedZipCode}
                                                        /></TableCell>
                                                        <TableCell align="right">
                                                            {this.CheckIfInEditMode(row.zipCode) ? <a href="#" style={{ textDecoration: "underline", color: "blue" }} onClick={() => this.EditConditionsInit(row.zipCode)} >
                                                                <GenericDisplayCell title="Conditions" value={row.conditions == null || row.conditions == "" ? "No conditions" : row.conditions} />
                                                            </a>
                                                                : <GenericDisplayCell title="Conditions" value={row.conditions == null || row.conditions == "" ? "No conditions" : row.conditions} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <EditDeleteActionCell
                                                                dataIndex={index}
                                                                onEditButtonClick={this.OpenZipCodeEditMode}
                                                                onSaveButtonClick={this.SaveEditedZipCode}
                                                                onCancelButtonClick={this.CancelEditedZipCode}
                                                                hideEditButton={false}
                                                                hideDeleteButton={true}
                                                                hideCancelButton={false}
                                                                editMode={this.CheckIfInEditMode(row.zipCode)} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={this.state.zipCodesTotal}
                                    rowsPerPage={this.state.tableDef.take!}
                                    page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                    onChangePage={(e, page) => this.PageChange(page)}
                                    onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                                />
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>

                <Dialog
                    onClose={this.CloseAddZipCodeModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.newZipCodeModalOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Add Zip Code Sales Tax</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseAddZipCodeModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <TextField
                                error={this.state.newZipCodeError !== ""}
                                style={{ width: '100%' }}
                                id="newZipCode"
                                label="Zip Code"
                                placeholder="Zip Code"
                                margin="normal"
                                helperText=''
                                FormHelperTextProps={{ color: "red" }}
                                value={this.state.newZipCode}
                                variant="outlined"
                                autoFocus={this.state.newZipCodeModalOpen}
                                onChange={this.HandleNewZipCodeChange}
                            />
                        </div>
                        <div>
                            <TextField
                                error={this.state.newZipCodeSalesTaxAdjustmentError !== ""}
                                type="number"
                                style={{ width: '100%' }}
                                id="newSalesTaxAdjustment"
                                label="Sales Tax Adjustment"
                                placeholder="Sales Tax Adjustment"
                                margin="normal"
                                helperText=''
                                FormHelperTextProps={{ color: "red" }}
                                value={this.state.newZipCodeSalesTaxAdjustment}
                                onChange={this.HandleNewZipCodeSalesTaxRateAdjustmentChange}
                                variant="outlined"
                            />
                        </div>
                        <div style={{ maxWidth: "500px" }}>
                            There is a special tax of X% in addition to the Y% sales tax in your region for events that meet the following conditions. <br />
                            <TextField
                                style={{ width: '100%' }}
                                label="Conditions"
                                placeholder="Conditions"
                                margin="normal"
                                value={this.state.newConditions}
                                variant="outlined"
                                multiline={true}
                                onChange={(e: any) => this.HandeNewConditionsChange(e)}
                            />
                            <br />
                            If your event meets these conditions, please select "yes." You are liable for any sales tax inaccuracies for your event, so please verify your sales tax responsibility to the best of your ability.
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseAddZipCodeModal} className='two-action-buttons'>
                            Cancel
                        </Button>
                        <Button onClick={this.AddZipCodeSalesTax} className='two-action-buttons'>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={this.CloseEditConditionsModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.editConditionsModalOpen}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Update Conditions for {this.state.editConditionsZipCode}</Typography>
                        <IconButton aria-label="close" className='close-button' onClick={this.CloseEditConditionsModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <div style={{ maxWidth: "500px" }}>
                            There is a special tax of X% in addition to the Y% sales tax in your region for events that meet the following conditions. <br />
                            <TextField
                                style={{ width: '100%' }}
                                label="Conditions"
                                placeholder="Conditions"
                                margin="normal"
                                value={this.state.editConditions}
                                variant="outlined"
                                multiline={true}
                                onChange={(e: any) => this.ChangeEditConditions(e)}
                            />
                            <br />
                            If your event meets these conditions, please select "yes." You are liable for any sales tax inaccuracies for your event, so please verify your sales tax responsibility to the best of your ability.
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseEditConditionsModal} className='two-action-buttons'>
                            Cancel
                        </Button>
                        <Button onClick={this.AcceptConditionsChange} className='two-action-buttons'>
                            Accept Changes
                        </Button>
                    </DialogActions>
                </Dialog>

                <GenericSuccessModal
                    hidden={this.state.addSuccess == false}
                    message="Zip code and Sales Tax Rate Adjustment saved successfully."
                    closeModal={this.CloseAddSuccessModal}
                />
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />
            </div>
        )
    }
}