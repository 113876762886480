import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
/// <reference path="./models/RequestModels.d.ts" />
import AwesomeDebouncePromise from 'awesome-debounce-promise';

export const GetTokenConfig = (): AxiosRequestConfig =>
{
    var token = localStorage.getItem("accessToken");

    let axiosConfig = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      };
      
    return axiosConfig;
}

export const autocompleteAddress = async(address: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var autocompleteUrl = process.env.REACT_APP_HERE_API_AUTOCOMP_URL;
    var apiKey = process.env.REACT_APP_HERE_API_KEY;

    address = encodeURIComponent(address);

    return await axios.get(`${autocompleteUrl}?apiKey=${apiKey}&at=39.8283,-98.5795&in=countryCode:USA&q=${address}`);
}

export const geocodeAddress = async(address: string): Promise<AxiosResponse> =>
{
    axios.interceptors.response.use(
        response => response,
        error => {
            return error.response;
        }
    );

    var autocompleteUrl = process.env.REACT_APP_HERE_API_GEOCODE_URL;
    var apiKey = process.env.REACT_APP_HERE_API_KEY;

    address = encodeURIComponent(address);

    return await axios.get(`${autocompleteUrl}?apiKey=${apiKey}&in=countryCode:USA&q=${address}`);
}

export const debounceAutocompleteAddress = AwesomeDebouncePromise(autocompleteAddress, 500);                                                          