/// <reference path="../../../data/DataModels/types.d.ts" />
import '../../Styles/EventClusterRolesSection.css';
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import NumberFormat from 'react-number-format';
import { GenericDisplayTrueFalseIconCell } from '../SubComponents/GenericDisplayTrueFalseIconCell';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

var Enumerable = require('linq');

interface ITicketDetailsDialogProps {

    purchaserFirstName: string,
    purchaserLastName: string,
    scannerFirstName: string,
    scannerLastName: string,
    scanned: boolean,
    mostRecentScannedDateTimeLocal: string,
    orderNo: string,
    orderBasePrice: number,
    orderCouponCode: string,
    orderCouponCodeDiscountAmount: number,
    orderServiceFee: number,
    orderSalesTax: number,
    orderProcessingFee: number,
    orderTotalPrice: number,
    orderPayoutAmount: number,
    purchaseDateTimeLocal: string,
    ticketOptionCodeDescription: string,
    ticketOption: string,
    ticketPrice: number,
    ticketServiceFee: number,
    visible: boolean,
    onCloseDialog: () => void
}

export class TicketDetailsDialog extends React.Component<ITicketDetailsDialogProps> {

    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Dialog
                onClose={this.props.onCloseDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.visible}
                fullWidth
            >
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">Ticket Details</Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <CardContent style={{ width: "90%", fontSize: "20px" }}>
                        <strong>Order Details</strong>
                    </CardContent>
                    <CardContent style={{ width: "90%" }}>
                        <table style={{ width: "100%" }} className="ticketTable">
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Order No:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.orderNo}
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Ticket Price Total:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.orderBasePrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                            </tr>
                            <tr style={{ width: "100%"}}>
                                <td className="oddTicketCell">
                                    <strong>Coupon Code:</strong>
                                </td>
                               <td className="evenTicketCell">
                                    {this.props.orderCouponCode}
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Coupon Code Discount:</strong>
                                </td>
                               <td className="evenTicketCell">
                                    (<NumberFormat value={this.props.orderCouponCodeDiscountAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />)
                                </td>
                            </tr>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Service Fee Total:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.orderServiceFee.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Sales Tax Total:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.orderSalesTax.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                            </tr>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>ProcessingFee:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.orderProcessingFee.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Total Price:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.orderTotalPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                            </tr>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Payout Total:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.orderPayoutAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                            </tr>
                        </table>
                    </CardContent>
                    <CardContent style={{ width: "90%", fontSize: "20px" }}>
                        <strong>Ticket Details</strong>
                    </CardContent>
                    <CardContent style={{ width: "90%" }}>
                        <table style={{ width: "100%" }}>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Ticket Option Type:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.ticketOptionCodeDescription}
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Ticket Option:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.ticketOption}
                                </td>
                            </tr>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Ticket Price:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.ticketPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Ticket Service Fee:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <NumberFormat value={this.props.ticketServiceFee.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                </td>
                            </tr>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Purchaser First Name:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.purchaserFirstName}
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Purchaser Last Name:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.purchaserLastName}
                                </td>
                            </tr>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Scanned:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    <GenericDisplayTrueFalseIconCell title="" isTrue={
                                        this.props.scanned
                                    }
                                        removeTextAlign={true}
                                    />
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Last Scan Date/Time:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.mostRecentScannedDateTimeLocal}
                                </td>
                            </tr>
                            <tr style={{ width: "100%" }}>
                                <td className="oddTicketCell">
                                    <strong>Scanner First Name:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.scannerFirstName}
                                </td>
                                <td className="oddTicketCell">
                                    <strong>Scanner Last Name:</strong>
                                </td>
                                <td className="evenTicketCell">
                                    {this.props.scannerLastName}
                                </td>
                            </tr>
                        </table>
                    </CardContent>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <Button onClick={this.props.onCloseDialog} className='action-button'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}