/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import { Button, TextField, Checkbox, FormLabel} from '@material-ui/core';
import { EventPrimaryFormSection } from './EventPrimaryFormSection';
import { EventTicketingFormSection } from './EventTicketingFormSection';
import { EventTicketScansSection } from './EventTicketsSection';
import { EventAnalyticsSection } from './EventAnalyticsSection';
import { PublishSection } from './PublishSection';
import { SalesTaxFlagCode } from "../../../data/Models/SalesTaxFlagCode";
import { GenericDropdown } from '../SubComponents/GenericDropdown';
import { GenericSuccessModal } from '../../Components/GenericSuccessModal';
import { EventClusterPermissionCode } from "../../../data/Models/EventClusterPermissionCode";
import { EventClusterPermission } from '../../../data/Models/EventClusterPermission';
import { publishEvent } from '../../../data/EventRepository';
import { getPortalUserEventClusterRole, getPortalUserEventClusterRoleByEventId } from '../../../data/EventClusterRoleRepository';
import { getEventSalesTaxSettings, updateEventSalesTaxSettings } from '../../../data/EventSalesTaxRepository';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { MobileFriendlyTabs } from '../SubComponents/MobileFriendlyTabs';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { TabPanel } from '../SubComponents/TabPanel';
import '../../App.css';

var Enumerable = require('linq');

interface IEventPageProps {
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class EventPage extends React.Component<IEventPageProps> {


    state = {
        eventId: '',
        eventClusterId: '',
        selected: 0,
        selectedPending: 0,
        publishSuccess: false,
        publishValidationErrors: [],
        changeTabModalOpen: false,
        changesPending: false,
        userPermissions: [],
        isClusterOwner: false,
        serverError: '',
        createEventSuccess: false,
        eventSalesTaxSettingsModalOpen: false,
        eventSalesTaxRate: null,
        savedEventSalesTaxRateAdjustment: null,
        actualEventSalesTaxRateAdjustment: null,
        combinedSalesTaxRate: null,
        salesTaxFlagCode: SalesTaxFlagCode.None,
        selectedSalesTaxFlageCode: -1,
        selectedSalesTaxFlageCodeError: '',
        salesTaxFlagCodeDescription: '',
        salesTaxChangeDetected: false,
        salesTaxSettingSaveSuccess: false,
        salesTaxFlagConditions: '',
        salesTaxFlagCodes: [],
        salesTaxExempt: false
    }

    async componentDidMount() {
        const query = new URLSearchParams(window.location.search);

        var selected = parseInt(query.get('tab') ?? "0");

        let id = query.get('id');
        let eventClusterId: string = query.get('eventGroupId') ?? "";
        let userPermissions: any[] = [];
        let isClusterOwner: boolean = false;
        let serverError = '';
        let showSalesTaxModal = query.get('salestaxsettings') == "1";

        let createEventSuccess: boolean = query.get('createEventSuccess') == 'true' ?? false;

        if ((id == undefined || id == '') && (eventClusterId == undefined || eventClusterId == '')) {
            serverError = "Event Id and Event Group Id are required.";
        }

        var response = eventClusterId == undefined || eventClusterId == ''
            ? await getPortalUserEventClusterRoleByEventId(id)
            : await getPortalUserEventClusterRole(eventClusterId);

        if (response != null && response.status == 401) {
            this.RedirectToLogin(eventClusterId, id, selected);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                userPermissions = response.data.eventClusterRole.eventClusterPermissions;
                eventClusterId = response.data.eventClusterId;
                isClusterOwner = response.data.eventClusterRole.isOwnerRole;
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        let salesTaxFlagCodes = [
            { id: SalesTaxFlagCode.AdjustmentNotAccepted, name: "Reject Sales Tax Adjustment" },
            { id: SalesTaxFlagCode.AdjustmentAccepted, name: "Accept Sales Tax Adjustment" }
        ];

        await this.setState({
            ...this.state,
            eventId: id,
            selected: selected,
            eventClusterId: eventClusterId,
            userPermissions: userPermissions,
            isClusterOwner: isClusterOwner,
            serverError: serverError,
            createEventSuccess: createEventSuccess,
            salesTaxFlagCodes: salesTaxFlagCodes
        });

        this.GetEventSalesTaxSettings(showSalesTaxModal);
    }

    RedirectToLogin = (eventClusterId: any, eventId: any, selected: any) => {
        let eventClusterParam = eventClusterId != null && eventClusterId != ""
            ? `?eventGroupId=${eventClusterId}${encodeURIComponent("&")}tab=${selected}`
            : `?tab=${selected}`;

        if (eventId != '' && eventId != undefined) {
            eventClusterParam += `%26id=${eventId}`;
        }

        this.props.changePage("/login?redirect=event" + eventClusterParam);
    }

    HandleChange = (newValue: number) => {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname +
            `?id=${this.state.eventId}&eventGroupId=${this.state.eventClusterId}&tab=${newValue}`;
        window.history.pushState({ path: newurl }, '', newurl);
        if (this.state.changesPending) {
            this.setState({ ...this.state, changeTabModalOpen: true, selectedPending: newValue });
        }
        else {
            this.setState({ selected: newValue });
        }
    }

    HandleChangesPendingChange = (changesPending: boolean) => {
        this.setState({ ...this.state, changesPending: changesPending });
    }

    CloseChangeTabModal = () => {
        this.setState({ ...this.state, changeTabModalOpen: false, selectedPending: 0 });
    }

    AcknowledgeChangeTab = () => {
        let selectedPending = this.state.selectedPending;
        this.setState({ selected: selectedPending, changeTabModalOpen: false, selectedPending: 0, changesPending: false });
    }

    PublishEvent = async (eventId: string) => {
        this.props.loadingOn();
        var response = await publishEvent(eventId);

        let publishSuccess = false;
        let publishValidationErrors: any = [];

        if (response != null && response.status == 401) {
            this.RedirectToLogin(this.state.eventClusterId, eventId, this.state.selected);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                publishValidationErrors.push(response.data.errorMsg);

                if (response.data.validationErrors != null) {
                    publishValidationErrors = [...publishValidationErrors, ...response.data.validationErrors];
                }
            }
            else {
                publishSuccess = true;
            }
        }
        else {
            publishValidationErrors.push("An unknown error occured. Please try again later");
        }

        this.setState({ ...this.state, publishSuccess: publishSuccess, publishValidationErrors: publishValidationErrors });
        this.props.loadingOff();
    }

    RedirectToEventGroup = () => {
        this.props.changePage(`/event-group?id=${this.state.eventClusterId}`);
    }

    CloseCreateSuccessModal = () => {
        this.setState({ ...this.state, createEventSuccess: false })
    }

    ChangePage = (page: string) => {
        if (page.indexOf("/event?") > -1) {
            page += `${encodeURIComponent("&")}tab=${this.state.selected}`;
        }

        this.props.changePage(page);
    }

    GetEventSalesTaxSettings = async (openEventSalesTaxSettingsModal: boolean = true) => {
        let eventSalesTaxRate = this.state.eventSalesTaxRate;
        let savedEventSalesTaxRateAdjustment = this.state.savedEventSalesTaxRateAdjustment;
        let actualEventSalesTaxRateAdjustment: number = this.state.actualEventSalesTaxRateAdjustment ?? 0;
        let combinedSalesTaxRate = this.state.combinedSalesTaxRate;
        let salesTaxFlagCode = this.state.salesTaxFlagCode;
        let salesTaxFlagCodeDescription = this.state.salesTaxFlagCodeDescription;
        let salesTaxChangeDetected = this.state.salesTaxChangeDetected;
        let salesTaxFlagCodes: any[] = this.state.salesTaxFlagCodes;
        let salesTaxFlagConditions = this.state.salesTaxFlagConditions;
        let salesTaxExempt = this.state.salesTaxExempt;

        let serverError = "";

        var response = await getEventSalesTaxSettings(this.state.eventId);

        if (response != null && response.status == 401) {
            this.RedirectToLogin(this.state.eventClusterId, this.state.eventId, this.state.selected);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                serverError = response.data.errorMsg;
            }
            else {
                eventSalesTaxRate = response.data.salesTaxRate;
                savedEventSalesTaxRateAdjustment = response.data.savedSalesTaxRateAdjustment;
                actualEventSalesTaxRateAdjustment = response.data.actualSalesTaxRateAdjustment;
                combinedSalesTaxRate = response.data.savedCombinedSalesTaxRate;
                salesTaxFlagCode = response.data.salesTaxFlagCode;
                salesTaxExempt = response.data.salesTaxExempt;
                salesTaxFlagCodeDescription = response.data.salesTaxFlagCodeDescription;
                salesTaxChangeDetected = response.data.changeDetected;
                salesTaxFlagConditions = response.data.conditions;

                salesTaxFlagCodes = [
                    { id: -1, name: `-- Select One --` },
                    { id: SalesTaxFlagCode.AdjustmentNotAccepted, name: `Reject Sales Tax Adjustment (${actualEventSalesTaxRateAdjustment * 100}%)` },
                    { id: SalesTaxFlagCode.AdjustmentAccepted, name: `Accept Sales Tax Adjustment (${actualEventSalesTaxRateAdjustment * 100}%)` }
                ];
            }
        }
        else {
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({
            ...this.state,
            eventSalesTaxSettingsModalOpen: openEventSalesTaxSettingsModal && (serverError == null || serverError == ""),
            eventSalesTaxRate: eventSalesTaxRate,
            savedEventSalesTaxRateAdjustment: savedEventSalesTaxRateAdjustment,
            actualEventSalesTaxRateAdjustment: actualEventSalesTaxRateAdjustment,
            combinedSalesTaxRate: combinedSalesTaxRate,
            salesTaxFlagCode: salesTaxFlagCode,
            salesTaxExempt: salesTaxExempt,
            salesTaxFlagCodeDescription: salesTaxFlagCodeDescription,
            salesTaxChangeDetected: salesTaxChangeDetected,
            salesTaxFlagConditions: salesTaxFlagConditions,
            salesTaxFlagCodes: salesTaxFlagCodes
        });
    }

    UpdateSalesTaxSettings = async (salesTaxExempt:any = null) => {
        this.props.loadingOn();
        let selectedSalesTaxFlageCode = this.state.selectedSalesTaxFlageCode;
        let selectedSalesTaxFlageCodeError = this.state.selectedSalesTaxFlageCodeError;
        if(salesTaxExempt == null)
            salesTaxExempt = this.state.salesTaxExempt;
        let salesTaxSettingSaveSuccess = false;
        let errored = false;

        if (selectedSalesTaxFlageCode < 1 && salesTaxExempt == null) {
            selectedSalesTaxFlageCodeError = "You must select an acknowledgement option in order to update the sales tax settings";
            errored = true;
        }

        let serverError = "";

        if (!errored) {
            var response = await updateEventSalesTaxSettings(this.state.eventId, selectedSalesTaxFlageCode, salesTaxExempt);

            if (response != null && response.status == 401) {
                this.RedirectToLogin(this.state.eventClusterId, this.state.eventId, this.state.selected);
            }
            else if (response != null && response.status == 200) {
                if (!response.data.success) {
                    serverError = response.data.errorMsg;
                }
                else {
                    salesTaxSettingSaveSuccess = true;
                }
            }
            else {
                serverError = "An unknown error occured. Please try again later";
            }
        }

        this.props.loadingOff();

        this.setState({
            ...this.state,
            selectedSalesTaxFlageCodeError: selectedSalesTaxFlageCodeError,
            salesTaxSettingSaveSuccess: salesTaxSettingSaveSuccess,
            salesTaxExempt: salesTaxExempt,
            serverError: serverError
        });
    }

    HandleSalesTaxFlagCodeChange = (salesTaxFlagCode: any) => {

        if (salesTaxFlagCode == -1) {
            salesTaxFlagCode = this.state.selectedSalesTaxFlageCode;
        }
        this.setState({ ...this.state, selectedSalesTaxFlageCode: salesTaxFlagCode });
    }

    CloseEventSalesTaxSettingsModal = (saveSuccessful: boolean) => {

        if (saveSuccessful && this.state.selected == 0) {
            window.location.href = window.location.href.replace("salestaxsettings=1", "");
        }

        this.setState({
            ...this.state,
            selectedSalesTaxFlageCode: -1,
            selectedSalesTaxFlageCodeError: "",
            eventSalesTaxSettingsModalOpen: false,
            salesTaxSettingSaveSuccess: false
        });
    }

    OnSalesTaxExemptChange = () => {
        let salesTaxExempt = !this.state.salesTaxExempt;

        this.UpdateSalesTaxSettings(salesTaxExempt);
    }

    render() {

        var hasEventAnalyticsAccess = this.state.isClusterOwner || Enumerable.from(this.state.userPermissions).any((p: EventClusterPermission) => p.id == EventClusterPermissionCode.ViewEventAnalytics);

        var percentageMultiplyer: number = 100.00;

        var titles: string[] = ["Event Details", "Ticketing", "Analytics", "Tickets Sold", "Publish"];

        var salesTaxUpdatable = this.state.actualEventSalesTaxRateAdjustment != null && this.state.actualEventSalesTaxRateAdjustment != 0;

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <div style={{ height: "100%", width: '100%' }}>
                    {/* <div style={{paddingTop: "10px", width: "100%"}}>
                        <CardHeader title="Event" />
                    </div> */}
                    {this.state.serverError != undefined && this.state.serverError != '' &&
                        <div style={{ color: "red" }}>
                            {this.state.serverError}
                        </div>}
                    <div style={{ paddingBottom: "40px", paddingTop: "20px" }}>
                        <Button className="btn-primary" onClick={this.RedirectToEventGroup}>
                            View Event Group
                        </Button>
                        <span style={{ paddingLeft: "20px" }}>
                            <Button className="btn-secondary" onClick={() => this.GetEventSalesTaxSettings(true)} disabled={this.state.salesTaxExempt}>
                                Sales Tax Settings
                            </Button>
                        </span>
                        <span style={{ paddingLeft: "20px"}}>
                            <Checkbox checked={this.state.salesTaxExempt}
                                onChange={this.OnSalesTaxExemptChange} />
                            <FormLabel>&nbsp;Exclude Sales Tax</FormLabel>
                        </span>
                    </div>
                    {this.state.eventId != undefined && this.state.eventId != '' &&
                        this.state.eventClusterId != undefined && this.state.eventClusterId != '' &&
                        <Box style={{ width: '100%' }}>
                            <MobileFriendlyTabs
                                titles={titles}
                                value={this.state.selected}
                                handleChange={this.HandleChange}
                            />
                            <TabPanel value={this.state.selected} index={0}>
                                <EventPrimaryFormSection
                                    userPermissions={this.state.userPermissions}
                                    isClusterOwner={this.state.isClusterOwner}
                                    loadingOn={this.props.loadingOn}
                                    loadingOff={this.props.loadingOff}
                                    handleChangesPendingChange={this.HandleChangesPendingChange}
                                    changePage={this.ChangePage}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.selected} index={1}>
                                <EventTicketingFormSection
                                    userPermissions={this.state.userPermissions}
                                    isClusterOwner={this.state.isClusterOwner}
                                    loadingOn={this.props.loadingOn}
                                    loadingOff={this.props.loadingOff}
                                    handleChangesPendingChange={this.HandleChangesPendingChange}
                                    changePage={this.ChangePage}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.selected} index={2}>
                                <EventAnalyticsSection
                                    loadingOn={this.props.loadingOn}
                                    loadingOff={this.props.loadingOff}
                                    changePage={this.ChangePage}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.selected} index={3}>
                                <EventTicketScansSection
                                    loadingOn={this.props.loadingOn}
                                    loadingOff={this.props.loadingOff}
                                    changePage={this.ChangePage}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.selected} index={4}>
                                <PublishSection
                                    userPermissions={this.state.userPermissions}
                                    isClusterOwner={this.state.isClusterOwner}
                                    loadingOn={this.props.loadingOn}
                                    loadingOff={this.props.loadingOff}
                                    publishEvent={this.PublishEvent}
                                    publishValidationErrors={this.state.publishValidationErrors}
                                    publishSuccess={this.state.publishSuccess}
                                    changePage={this.ChangePage}
                                />
                            </TabPanel>
                        </Box>}
                    <Dialog
                        onClose={this.CloseChangeTabModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.changeTabModalOpen}
                    >
                        <DialogTitle disableTypography className='dialog-title-warning'>
                            <Typography variant="h6">Confirmation</Typography>
                            <IconButton aria-label="close"
                                className='close-button'
                                onClick={this.CloseChangeTabModal}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            You have pending changes on this tab. Are you sure you want to perform this action without saving first?
                        </DialogContent>
                        <DialogActions disableSpacing={true}>
                            <Button onClick={this.CloseChangeTabModal} className='two-action-buttons'>
                                No
                            </Button>
                            <Button onClick={this.AcknowledgeChangeTab} className='two-action-buttons'>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        onClose={this.CloseCreateSuccessModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        className="genericSuccessModal"
                        open={this.state.createEventSuccess}
                    >
                        <DialogTitle disableTypography className='dialog-title-success'>
                            <Typography variant="h6">Confirmation</Typography>
                            <IconButton aria-label="close"
                                className='close-button'
                                onClick={this.CloseCreateSuccessModal}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            The event was created successfully.
                        </DialogContent>
                        <DialogActions disableSpacing={true}>
                            <Button onClick={this.CloseCreateSuccessModal} className='action-button'>
                                Close
                            </Button>
                        </DialogActions>

                    </Dialog>

                    <Dialog
                        onClose={() => this.CloseEventSalesTaxSettingsModal(false)}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        className="genericSuccessModal"
                        open={this.state.eventSalesTaxSettingsModalOpen}
                    >
                        <DialogTitle disableTypography className='dialog-title-warning'>
                            <Typography variant="h6">Sales Tax Settings</Typography>
                            <IconButton aria-label="close"
                                className='close-button'
                                onClick={() => this.CloseEventSalesTaxSettingsModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers>
                            {this.state.salesTaxChangeDetected ? <div style={{ color: "red" }}>
                                Changes Detected
                            </div> :
                                <div style={{ color: "limegreen" }}>
                                    No Changes Detected
                                </div>
                            }
                            <div className="SalesTaxSettings">
                                <TextField
                                    style={{ width: '100%' }}
                                    label="Sales Tax Rate"
                                    placeholder="Sales Tax Rate"
                                    margin="normal"
                                    value={this.state.eventSalesTaxRate != null ? ((this.state.eventSalesTaxRate ?? 0) * 100).toFixed(3) + "%" : "Not Found"}
                                    inputProps={{ maxLength: 200 }}
                                    variant="outlined"
                                    disabled={true}
                                />
                                {this.state.salesTaxFlagCode != 0 && <TextField
                                    style={{ width: '100%' }}
                                    label="Saved Sales Tax Rate Adjustment"
                                    placeholder="Saved Sales Tax Rate Adjustment"
                                    margin="normal"
                                    value={this.state.savedEventSalesTaxRateAdjustment != null ? ((this.state.savedEventSalesTaxRateAdjustment ?? 0) * percentageMultiplyer).toFixed(3) + "%" : "Not Found"}
                                    inputProps={{ maxLength: 200 }}
                                    variant="outlined"
                                    disabled={true}
                                />}
                                <TextField
                                    style={{ width: '100%' }}
                                    label="Saved Sales Tax Adjustment Acknowledgement"
                                    placeholder="Saved Sales Tax Adjustment Acknowledgement"
                                    margin="normal"
                                    value={this.state.salesTaxFlagCodeDescription}
                                    inputProps={{ maxLength: 200 }}
                                    variant="outlined"
                                    disabled={true}
                                />
                                <TextField
                                    style={{ width: '100%' }}
                                    label="Sales Tax Applied to Tickets (Sales Tax Rate + Adjustment)"
                                    placeholder="Sales Tax Applied to Tickets (Sales Tax Rate + Adjustment)"
                                    margin="normal"
                                    value={this.state.combinedSalesTaxRate != null ? ((this.state.combinedSalesTaxRate ?? 0) * percentageMultiplyer).toFixed(3) + "%" : "Not Found"}
                                    inputProps={{ maxLength: 200 }}
                                    variant="outlined"
                                    disabled={true}
                                />
                                <div>
                                    {this.state.salesTaxChangeDetected && <TextField
                                        style={{ width: '100%', paddingBottom: "10px" }}
                                        label="New Sales Tax Rate Adjustment"
                                        placeholder="New Sales Tax Rate Adjustment"
                                        margin="normal"
                                        value={this.state.actualEventSalesTaxRateAdjustment != null ? ((this.state.actualEventSalesTaxRateAdjustment ?? 0) * percentageMultiplyer).toFixed(3) + "%" : "Not Found"}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                        className="actualSalesTaxRateAdjustment"
                                        disabled={true}
                                    />}
                                    {salesTaxUpdatable && <div className="acknowledgementOptions">
                                        <div>
                                            <div style={{ maxWidth: "500px", paddingBottom: "10px" }}>
                                                There is a special tax of {((this.state.actualEventSalesTaxRateAdjustment ?? 0) * 100).toFixed(3)}% in addition to the {((this.state.eventSalesTaxRate ?? 0) * 100).toFixed(3)}% sales tax in your region for events that meet the following conditions.
                                                <strong>{this.state.salesTaxFlagConditions}</strong> Please select the appropriate acknowledgement option, if you haven't already. You are liable for any sales tax inaccuracies for your event, so please verify your sales tax responsibility to the best of your ability.
                                            </div>
                                            <GenericDropdown
                                                label="Acknowledgement Options"
                                                value={this.state.selectedSalesTaxFlageCode}
                                                data={this.state.salesTaxFlagCodes}
                                                error={this.state.selectedSalesTaxFlageCodeError}
                                                onChange={(value: any) => this.HandleSalesTaxFlagCodeChange(value)}
                                            />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions disableSpacing={true}>
                            <Button onClick={() => this.CloseEventSalesTaxSettingsModal(false)} className={salesTaxUpdatable ? 'two-action-buttons' : 'action-button'}>
                                Close
                            </Button>
                            {salesTaxUpdatable &&
                                <Button className='two-action-buttons' onClick={this.UpdateSalesTaxSettings}>Update</Button>}
                        </DialogActions>
                    </Dialog>

                    <GenericSuccessModal
                        hidden={this.state.salesTaxSettingSaveSuccess == false}
                        message="Sales tax settings saved successfully."
                        closeModal={() => this.CloseEventSalesTaxSettingsModal(true)}
                    />
                </div>
            </div>
        )
    }
}