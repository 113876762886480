/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { getOrganizations, getStripeAccountTypes, getStripeStatuses, archiveOrganization } from '../../../data/OrganizationRepository';
import { GenericErrorModal } from '../GenericErrorModal';
import { GenericSuccessModal } from '../GenericSuccessModal';
import { StripeAccountStatusCode } from '../../../data/Models/StripeAccountStatusCode';
import { StripeAccountTypeCode } from '../../../data/Models/StripeAccountTypeCode';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import { TableFilterToggleButton } from '../SubComponents/TableFilterToggleButton';
import { GenericConfirmationModal } from '../GenericConfirmationModal';
import { NameWithBadgeDisplayCell } from '../SubComponents/NameWithBadgeDisplayCell';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ArchiveErrorModal } from './ArchiveErrorModal';
import { resetOnboarding } from '../../../data/OnboardingRepository';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

var Enumerable = require('linq');

interface IPortalUserOrganizationsPageProps{
    showOnboarding: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void,
    onShowOnboarding: () => void
}

interface IPortalUserOrganizationsPageState {
    organizations: any[],
    eventClusters: any[],
    organizationsTotal: number,
    pageable: any,
    tableDef: ITableDef,
    serverError: string,
    stripeStatuses: any[],
    stripeAccountTypes: any[],
    mobileFiltersVisible: boolean,
    archiveOrganizationId: string,
    archiveOrganizationDialogOpen: boolean,
    includeArchivedOrganizations: boolean,
    archiveOrganizationErrorDialogOpen: boolean,
    archiveOrganizationName: string,
    createOrganizationConfirmVisible: boolean,
    archiveOrganizationSuccessfull: boolean
}

const getInitTableDef = () =>{
    let sortDef: ISortDef = {
        field: "Name",
        dir: "desc"
    };

    let filterArray = [
        {
            field: "name",
            operator: "eq",
            value: ""
        },
        {
            field: "bankAccountNumber",
            operator: "eq",
            value: ""
        },
        {
            field: 'stripeAccountStatus',
            operator: "eq",
            value: ""
        },
        {
            field: 'stripeAccountType',
            operator: "eq",
            value: ""
        },
    ];

    let tableFilters: ITableFilters = {
        logic: "and",
        filters: filterArray
    };

    let tableDef: ITableDef = {
        skip: 0,
        take: 10,
        sort: [sortDef],
        filter: tableFilters
    };

    return tableDef;
};

const getInitPageable = () => {

    let pageable = {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
    };

    return pageable;
};

export class PortalUserOrganizationsPage extends React.Component<IPortalUserOrganizationsPageProps, IPortalUserOrganizationsPageState> {

    state = {
        organizations: [],
        eventClusters: [],
        organizationsTotal: 0,
        pageable: getInitPageable(),
        tableDef: getInitTableDef(),
        headCells: [
            {
              id: 'name',
              label: 'Name',
            },
            {
              id: 'bankAccountNumber',
              label: 'Bank Account Number',
            },
            {
              id: 'stripeAccountStatus',
              label: 'Stripe Status',
            },
            {
              id: 'stripeAccountType',
              label: 'Account Type',
            }
        ],
        serverError: '',
        stripeStatuses: [],
        stripeAccountTypes: [],
        mobileFiltersVisible: false,
        archiveOrganizationId: '',
        archiveOrganizationDialogOpen: false,
        includeArchivedOrganizations: false,
        archiveOrganizationErrorDialogOpen: false,
        archiveOrganizationName: '',
        createOrganizationConfirmVisible: false,
        archiveOrganizationSuccessfull: false
    }

    async componentDidMount(){
        this.props.loadingOn();

        await this.LoadOrganizations(this.state.tableDef);
        await this.LoadStatuses();
        await this.LoadAccountTypes();

        this.props.loadingOff();
    }

    LoadStatuses = async ()=>{
        var response = await getStripeStatuses();

        if(response != null && response.status == 200 && response.data.success)
        {
            this.setState({...this.state, stripeStatuses: response.data.values});
        }
        return new Array();
    }

    LoadAccountTypes = async ()=>{
        var response = await getStripeAccountTypes();

        if(response != null && response.status == 200 && response.data.success)
        {
            this.setState({...this.state, stripeAccountTypes: response.data.values});
        }
        return new Array();
    }

    LoadOrganizations = async (tableDef: ITableDef) => {
        this.setState({ ...this.state, tableDef: tableDef });
        let organizations: any = [];
        let organizationsTotal = 0;
        let serverError = '';

        var filters = tableDef.filter != null ? Enumerable.from(tableDef.filter!.filters).where((f: any) => (f.value != null && f.value != "") || (f.value === false || f.value === true)).toArray() : [];

        let tempTableDef = JSON.parse(JSON.stringify(tableDef));

        tempTableDef.filter.filters = JSON.parse(JSON.stringify(filters));

        var response = await getOrganizations(tempTableDef, this.state.includeArchivedOrganizations);

        if(response != null && response.status == 401){
            this.props.changePage("/login?redirect=organizations");
        }
        else if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                organizations = response.data.organizations;
                organizationsTotal = response.data.total;
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }

        this.setState({...this.state, organizations: organizations, organizationsTotal: organizationsTotal, tableDef: tableDef, serverError: serverError});
    }

    OnSortChange = async (sort: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        if (tableDef != null && tableDef.sort!.length > 0) {
            tableDef.sort![0].dir = tableDef.sort![0]!.field == sort && tableDef.sort![0].dir == "desc" ? "asc" : "desc";
            tableDef.sort![0].field = sort;

            await this.LoadOrganizations(tableDef);
        }

        this.props.loadingOff();
    }

    OnFilterChange = async (filter: any, value: any) => {
        let tableDef = this.state.tableDef;

        let filterDef = Enumerable.from(tableDef.filter!.filters).select((f: any, index: number) => ({ f, index })).firstOrDefault((fi: any) => fi.f.field == filter);

        filterDef.f.value = value;

        tableDef.filter!.filters[filterDef.index] = filterDef.f;
        tableDef.skip = 0;
        
        await this.LoadOrganizations(tableDef);
    }

    PageChange = async (newPage: any) => {
        this.props.loadingOn();
        let tableDef = this.state.tableDef;

        tableDef.skip = tableDef.take! * newPage;

        await this.LoadOrganizations(tableDef);
        this.props.loadingOff();
    }

    HandleChangeRowsPerPage = async (event: any) => {
        this.props.loadingOn();

        let tableDef = this.state.tableDef;

        tableDef.skip = 0;
        tableDef.take = parseInt(event.target.value, 10);

        await this.LoadOrganizations(tableDef);

        this.props.loadingOff();
    };

    CloseErrorModal = () => {
        this.setState({...this.state, serverError: ""});
    };

    ActivateOrganization = (url: string) => {
        window.location.href = url;
    }

    EditOrganization = (id: string) => {
        this.props.changePage("/save-organization?id=" + id);
    }

    ToggleFilterVisibility = () => {
        this.setState({...this.state, mobileFiltersVisible: !this.state.mobileFiltersVisible});
    }

    CloseArchiveOrganizationModal = () => {
        this.setState({...this.state, archiveOrganizationDialogOpen: false});
    }

    ArchiveOrganization = (id: string, name: string) => {
        this.setState({...this.state, archiveOrganizationDialogOpen: true, archiveOrganizationId: id, archiveOrganizationName: name});
    }

    OnArchiveOrganization = async () => {
        this.setState({...this.state, archiveOrganizationDialogOpen: false});
        this.props.loadingOn();

        let serverError = '';
        var response = await archiveOrganization(this.state.archiveOrganizationId);

        if(response.status == 401){
            this.props.changePage("/login?redirect=organizations");
        }
        else if(response.status == 200)
        {
            if(!response.data.success)
            {
                serverError = response.data.errorMsg;
            }
            else{
                if (response.data.eventClusters != undefined){
                    this.state.archiveOrganizationErrorDialogOpen = true;
                    this.state.eventClusters = response.data.eventClusters;
                }
                else{
                    this.state.archiveOrganizationSuccessfull = true;
                }
            }
        }
        else{
            serverError = "An unknown error occured. Please try again later";
        }
        this.setState({...this.state, serverError: serverError});
        await this.LoadOrganizations(this.state.tableDef);

        this.props.loadingOff();
    }

    HandleIncludeArchivedOrganizationsChange = async ()=>{
        this.props.loadingOn();
        this.state.includeArchivedOrganizations = !this.state.includeArchivedOrganizations;
        await this.LoadOrganizations(this.state.tableDef);
        //this.setState({...this.state, includeArchivedOrganizations: !this.state.includeArchivedOrganizations});
        this.props.loadingOff();
    }

    CLoseArchiveOrganizationErrorDialog = ()=>{
        this.setState({...this.state, archiveOrganizationErrorDialogOpen: false});
    }

    CreateOrganization = async (override: boolean = false) => {
        if(this.props.showOnboarding && override == false)
        {
            this.setState({...this.state,
                createOrganizationConfirmVisible: true
            });
        }
        else{
            this.setState({...this.state,
                createOrganizationConfirmVisible: false
            });

            var response = await resetOnboarding();
            if (response == null || response.status != 200){

            }else{
                sessionStorage.removeItem("onboardingModalClosed");
                this.props.onShowOnboarding();
            }
        }
    }

    HandleShowOnboarding = ()=>{
        sessionStorage.removeItem("onboardingModalClosed");

        this.setState({...this.state,
            createOrganizationConfirmVisible: false
        });

        this.props.onShowOnboarding();
    }

    CloseCreateOrganizationConfirmModal = () => {
        this.setState({...this.state,
            createOrganizationConfirmVisible: false
        });
    }

    CloseSuccessModal = () => {
        this.setState({...this.state, archiveOrganizationSuccessfull: false});
    }

    render()
    {
        var currentSort = Enumerable.from(this.state.tableDef.sort).firstOrDefault();

        var currentSortName = currentSort != null ? currentSort.field : "Name";
        var currentSortDir = currentSort != null ? currentSort.dir : "desc";

        return (
            <div style={{width: "100%"}}>
                <Card style={{backgroundColor: "#ededed"}}>
                    <CardHeader title="Organizations" />
                    <CardContent style={{paddingTop: '10px'}}>
                        <div>
                        <Button variant="contained" className='btn-primary' onClick={() => this.CreateOrganization(false)}>
                            Create Organization
                        </Button>

                            <FormControlLabel style={{marginLeft: '10px'}}
                                control={<Checkbox checked={this.state.includeArchivedOrganizations} 
                                onChange={this.HandleIncludeArchivedOrganizationsChange}></Checkbox>                                }
                                    label="Include Archived Organizations"
                            />
                        </div>
                    </CardContent>
                    <CardContent>
                    <TableFilterToggleButton expanded={this.state.mobileFiltersVisible} onClick={this.ToggleFilterVisibility}/>
                    <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className={this.state.mobileFiltersVisible ? "table-filters-visible organizations-table" : "table-filters-hidden"}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.headCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        sortDirection={currentSortName === headCell.id ? currentSortDir : false}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        <TableSortLabel
                                                            active={currentSortName === headCell.id}
                                                            direction={currentSortName === headCell.id ? currentSortDir : null}
                                                            hideSortIcon={currentSortName !== headCell.id}
                                                            onClick={() => this.OnSortChange(headCell.id)}
                                                            style={{ width: "100%" }}
                                                        >
                                                            {headCell.label}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                                <TableCell
                                                    className="table-header-border no-show-on-mobile"
                                                >
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {this.state.tableDef.filter!.filters.map((headCell: any) => (
                                                    <TableCell className="table-header-border show-on-mobile" >
                                                         {headCell.field != "stripeAccountStatus" && headCell.field != "stripeAccountType" && <TextField
                                                                fullWidth
                                                                margin="dense"
                                                                size="small"
                                                                onChange={(e) => this.OnFilterChange(headCell.field, e.currentTarget.value)}
                                                                value={headCell.value}
                                                                variant="outlined"
                                                            />}
                                                        {headCell.field == "stripeAccountStatus" &&
                                                            <FormControl variant="filled" size="small" fullWidth>
                                                                <Select
                                                                    value={headCell.value}
                                                                    label="Stripe Acount Status"
                                                                    onChange={(e) => this.OnFilterChange(headCell.field, e.target.value)}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    style={{ marginTop: "5px" }}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">(All)</MenuItem>
                                                                    {this.state.stripeStatuses.map((status: any) => (
                                                                    <MenuItem value={status}>{status.name}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                        {headCell.field == "stripeAccountType" &&
                                                            <FormControl variant="filled" size="small" fullWidth>
                                                                <Select
                                                                    value={headCell.value}
                                                                    label="Stripe Account Type"
                                                                    onChange={(e) => this.OnFilterChange(headCell.field, e.target.value)}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    style={{ marginTop: "5px" }}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="">(All)</MenuItem>
                                                                    {this.state.stripeAccountTypes.map((type: any) => (
                                                                    <MenuItem value={type}>{type.name}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        }
                                                    </TableCell>
                                                ))}
                                                <TableCell className="table-header-border no-show-on-mobile">

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.organizations.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                    >
                                                        <TableCell align="right"> <NameWithBadgeDisplayCell title="Name" value={row.name} badge={row.archived? "Archived": undefined} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Bank Account Number" value={row.bankAccountNumber != null ? "***" + row.bankAccountNumber: "N/A"} /></TableCell>
                                                        <TableCell align="right"><GenericDisplayCell title="Stripe Status" value={row.stripeAccountStatus} /></TableCell>
                                                        <TableCell align="right"> <GenericDisplayCell title="Account Type" value={row.stripeAccountType} /></TableCell>
                                                        <TableCell align="right">
                                                            {!row.archived && (row.stripeAccountStatusCode == StripeAccountStatusCode.Pending || row.stripeAccountStatusCode == StripeAccountStatusCode.Inactive) && row.stripeAccountSetupUrl != null && row.stripeAccountSetupUrl != "" &&
                                                            <Button variant="contained" className="btn-primary" style={{ marginRight: "5px", marginBottom: "5px" }} size="small" onClick={() => this.ActivateOrganization(row.stripeAccountSetupUrl)}>
                                                                Activate
                                                            </Button>}
                                                            {!row.archived && row.stripeAccountStatusCode == StripeAccountStatusCode.Active && row.stripeAccountUrl != null && row.stripeAccountUrl != "" &&
                                                            <Button variant="contained" className="btn-primary" style={{ marginRight: "5px", marginBottom: "5px" }} size="small" onClick={() => this.ActivateOrganization(row.stripeAccountUrl)}>
                                                                Stripe Login
                                                            </Button>}
                                                            {!row.archived && <Button variant="contained" className="btn-secondary" style={{ marginRight: "5px", marginBottom: "5px" }} size="small" onClick={() => this.EditOrganization(row.id)}>
                                                                Edit
                                                            </Button>}
                                                            {row.canBeArchived &&
                                                            <Button variant="contained" className="btn-delete" style={{ marginRight: "5px", marginBottom: "5px" }} size="small" onClick={() => this.ArchiveOrganization(row.id, row.name)}>
                                                                    Archive
                                                                </Button>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    count={this.state.organizationsTotal}
                                    rowsPerPage={this.state.tableDef.take!}
                                    page={this.state.tableDef.skip! > 0 ? this.state.tableDef.skip! / this.state.tableDef.take! : 0}
                                    onChangePage={(e, page) => this.PageChange(page)}
                                    onChangeRowsPerPage={(e) => this.HandleChangeRowsPerPage(e)}
                                />
                            </Paper>
                        </Box>
                    </CardContent>
                </Card>
                <GenericSuccessModal
                    hidden={this.state.archiveOrganizationSuccessfull == false}
                    message="The organization was archived successfully."
                    closeModal={this.CloseSuccessModal}
                />
                <GenericErrorModal hidden={this.state.serverError == ''}
                    message={this.state.serverError}
                    closeModal={this.CloseErrorModal} />   
                <GenericConfirmationModal hidden={!this.state.archiveOrganizationDialogOpen}
                    cancelButtonText = "Cancel" confirmButtonText="Ok" 
                    onConfirm = {this.OnArchiveOrganization}
                    closeModal = {this.CloseArchiveOrganizationModal}
                    message = "Are you sure that you want to archive this organization?"
                    />
                    <ArchiveErrorModal hidden={!this.state.archiveOrganizationErrorDialogOpen}
                        organizationName={this.state.archiveOrganizationName} 
                        eventClusters={this.state.eventClusters}
                        closeModal={this.CLoseArchiveOrganizationErrorDialog}
                    />
                <Dialog
                    onClose={this.CloseCreateOrganizationConfirmModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="genericConfirmationModal"
                    open={this.state.createOrganizationConfirmVisible}
                >
                    <DialogTitle disableTypography className='dialog-title-warning'>
                        <Typography variant="h6">Confirmation</Typography>
                        <IconButton aria-label="close"
                            className='close-button'
                            onClick={this.CloseCreateOrganizationConfirmModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        Are you sure that you would like to create a new organization? 
                        <br/>
                        You are already have a pending organization draft.
                        <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }}>
                            <Button className="btn-primary" onClick={this.HandleShowOnboarding}>Continue Pending</Button>
                            <span style={{ paddingLeft: "20px" }} ></span><Button className="btn-secondary" onClick={() => this.CreateOrganization(true)}>Start New</Button>
                        </div>
                    </DialogContent>
                    <DialogActions disableSpacing={true}>
                        <Button onClick={this.CloseCreateOrganizationConfirmModal} className='action-button'>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}