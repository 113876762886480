/// <reference path="../../../data/DataModels/types.d.ts" />
import '../../Styles/EventClusterRolesSection.css';
import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { CheckboxGridCell } from '../SubComponents/CheckboxGridCell';
import { getEventClusterRoles, addEventClusterRole, updateEventClusterRole, deleteEventClusterRole } from '../../../data/EventClusterRoleRepository';
import { getAllEventClusterPermissions } from '../../../data/EventClusterPermissionRepository';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { GenericDisplayCell } from '../SubComponents/GenericDisplayCell';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

var Enumerable = require('linq');

interface IAddRoleDialogProps {
    eventClusterId: string,
    visible: boolean,
    loadingOn: () => void,
    loadingOff: () => void,
    onCloseDialog: () => void,
    onRoleAdded: () => void,
    changePage: (page: string) => void
}

export class AddRoleDialog extends React.Component<IAddRoleDialogProps> {

    state = {
        eventClusterId: '',
        currentRoleName: '',
        currentRoleNameError: '',
        permissions: [],
        currentPermissions: [],
        permissionsHeadCells: [
            {
                id: 'selected',
                label: 'Selected'
            },
            {
                id: 'permission',
                label: 'Permission'
            }
        ],
        allPermissionsChecked: false,
        eventClusterRolesServerError: '',
        eventClusterPermissionsServerError: '',
        currentRoleServerError: '',
        serverSuccessMsg: ''
    }

    roles: any;

    async componentDidMount() {
        this.props.loadingOn();


        var permissions = [];


        let eventClusterPermissionsServerError = '';

        const query = new URLSearchParams(window.location.search);

        var eventClusterId: string = query.get('id') ?? "";



        var permissionsResponse = await getAllEventClusterPermissions();

        if (permissionsResponse != null && permissionsResponse.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + eventClusterId);
        }
        else if (permissionsResponse != null && permissionsResponse.status == 200) {
            if (!permissionsResponse.data.success) {
                eventClusterPermissionsServerError = permissionsResponse.data.errorMsg;
            }
            else {
                permissions = permissionsResponse.data.eventClusterPermissions;
            }
        }
        else {
            eventClusterPermissionsServerError = "An unknown error occured. Please try again later";
        }


        let currentPermissions: any = [];

        let allPermissions: any = permissions;

        allPermissions.forEach((p: any) => {
            currentPermissions.push({ id: p.id, name: p.name, selected: false });
        });
        this.setState({
            ...this.state,
            eventClusterId: eventClusterId,
            permissions: permissions,
            currentRoleName: '', currentPermissions: currentPermissions, allPermissionsChecked: false,
            eventClusterPermissionsServerError: eventClusterPermissionsServerError
        });

        await this.LoadRoles();
        this.props.loadingOff();
    }

    LoadRoles = async () => {
        var roles = [];
        let eventClusterRolesServerError = '';

        var response = await getEventClusterRoles(this.state.eventClusterId);

        if (response != null && response.status == 401) {
            this.props.changePage("/login?redirect=event-group?id=" + this.state.eventClusterId);
        }
        else if (response != null && response.status == 200) {
            if (!response.data.success) {
                eventClusterRolesServerError = response.data.errorMsg;
            }
            else {
                roles = response.data.eventClusterRoles;
            }
        }
        else {
            eventClusterRolesServerError = "An unknown error occured. Please try again later";
        }

        this.roles = roles;

        this.setState({
            ...this.state,
            roles: roles,
            eventClusterRolesServerError: eventClusterRolesServerError,
        });
    }

    OnPermissionCheckboxClick = (dataIndex: number) => {

        let currentPermissions: any[] = this.state.currentPermissions;
        let permission: any = currentPermissions[dataIndex];

        permission.selected = !permission.selected;

        currentPermissions[dataIndex] = permission;

        var allPermissionsChecked = Enumerable.from(currentPermissions).any((p: any) => p.selected == false) == false;

        this.setState({ ...this.state, currentPermissions: currentPermissions, allPermissionsChecked: allPermissionsChecked })
    }

    OnAllPermissionCheckboxClick = () => {

        let currentPermissions: any[] = this.state.currentPermissions;

        var newAllPermissionsChecked = !this.state.allPermissionsChecked;

        currentPermissions.forEach((p: any) => {
            p.selected = newAllPermissionsChecked;
        });

        this.setState({ ...this.state, currentPermissions: currentPermissions, allPermissionsChecked: newAllPermissionsChecked })
    }

    HandleRoleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ ...this.state, currentRoleName: event.currentTarget.value });
    }

    AddRoleClick = async () => {
        this.props.loadingOn();

        let currentRoleNameError = '';
        let error = false;
        let currentRoleServerError = '';
        let serverSuccessMsg = '';
        let allPermsissionsChecked = this.state.allPermissionsChecked;

        let currentRoleName = this.state.currentRoleName;

        if (currentRoleName == "") {
            currentRoleNameError = "Role name required.";
            error = true;
        }

        let currentPermissions: any = this.state.currentPermissions;

        await this.LoadRoles();

        let roles: any[] = this.roles;

        let roleExistsAlready = Enumerable.from(roles).where((r: any) => r.name == currentRoleName).any();

        if (roleExistsAlready) {
            currentRoleNameError = `The name "${currentRoleName}" exists for another role already.`;
            error = true;
        }

        if (error == false) {
            let eventClusterPermissionIds = Enumerable.from(currentPermissions).where((p: any) => p.selected == true).select((p: any) => p.id).toArray();

            let response = await addEventClusterRole(this.state.eventClusterId, currentRoleName, eventClusterPermissionIds);

            if (response != null && response.status == 401) {
                this.props.changePage("/login?redirect=event-group?id=" + this.state.eventClusterId);
            }
            else if (response != null && response.status == 200) {
                if (!response.data.success) {
                    currentRoleServerError = response.data.errorMsg;
                    error = true;
                }
                else {

                    let eventClusterPermissions = Enumerable.from(currentPermissions).where((p: any) => p.selected == true).toArray();

                    roles.push({ id: response.data.eventClusterRoleId, isOwnerRole: false, name: currentRoleName, eventClusterPermissions: eventClusterPermissions });

                    currentRoleNameError = "";
                    serverSuccessMsg = `"${currentRoleName}" role added successfully`;
                    currentRoleName = "";

                    for (var i = 0; i < currentPermissions.length; i++) {
                        var permission = currentPermissions[i];

                        permission.selected = false;

                        currentPermissions[i] = permission;
                    }

                    allPermsissionsChecked = false;
                    error = false;
                }
            }
            else {
                currentRoleServerError = "An unknown error occured. Please try again later";
            }

        }

        this.setState({
            ...this.state,
            currentRoleName: currentRoleName,
            allPermissionsChecked: allPermsissionsChecked,
            currentPermissions: currentPermissions,
            currentRoleNameError: currentRoleNameError,
            currentRoleServerError: currentRoleServerError,
            serverSuccessMsg
        });

        this.props.loadingOff();
        if (!error)
            this.props.onRoleAdded();
    }

    MockSave = () => { }

    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Dialog
                onClose={this.props.onCloseDialog}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                fullWidth
                open={true}
            >
                <DialogTitle disableTypography className='dialog-title-warning'>
                    <Typography variant="h6">Add New Role</Typography>
                    <IconButton aria-label="close"
                        className='close-button'
                        onClick={this.props.onCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers style={{padding: 0}}>
                    <CardContent>
                        <TextField
                            error={this.state.currentRoleNameError !== ""}
                            style={{ width: '100%' }}
                            id="roleName"
                            label="Role Name"
                            placeholder="Role Name"
                            margin="normal"
                            helperText={this.state.currentRoleNameError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.currentRoleName}
                            onChange={(e) => this.HandleRoleNameChange(e)}
                            variant="outlined"
                        />
                    </CardContent>
                    <CardContent>
                        <div className="select-all-permissions">
                            <FormControlLabel
                                control={
                                    <Checkbox color="default" checked={this.state.allPermissionsChecked} onClick={this.OnAllPermissionCheckboxClick} title="Select All Permissions" />
                                }
                                label="Select All Permissions"
                            />
                        </div>
                        <div >
                            <Box style={{ width: '100%' }}>
                            <Paper style={{ width: '100%' }}>
                                <TableContainer>
                                    <Table
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                        style={{ marginLeft: "0.5%", width: "99%" }}
                                        className="permission-select-table table-filters-hidden"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {this.state.permissionsHeadCells.map((headCell) => (
                                                    <TableCell
                                                        key={headCell.id}
                                                        className="table-header-border show-on-mobile"
                                                    >
                                                        {headCell.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center" className="table-header-border show-on-mobile" >
                                                    <Checkbox color="default" checked={this.state.allPermissionsChecked} onClick={this.OnAllPermissionCheckboxClick}/>
                                                </TableCell>
                                            <TableCell align="center" className="table-header-border no-show-on-mobile">
                                                <div>All Permissions Selected</div>
                                            </TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.currentPermissions.map((row: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell align="center" className="event-cluster-role-table-row"><CheckboxGridCell dataIndex={index} checked={row.selected} onClick={this.OnPermissionCheckboxClick}/></TableCell>
                                                        <TableCell align="right" className="event-cluster-role-table-row">
                                                            <GenericDisplayCell title="Name" value={row.name} />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                        </div>
                    </CardContent>
                </DialogContent>
                <DialogActions disableSpacing={true}>
                    <Button onClick={this.props.onCloseDialog} className='two-action-buttons'>
                        Close
                    </Button>
                    <Button onClick={this.AddRoleClick} className='two-action-buttons'>
                        Add Role
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}