/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import { Card, TableCell } from '@material-ui/core';
import { CardHeader } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Moment from 'moment';
import { BarChart, Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, LabelList, Label, Tooltip as ChartTooltip } from 'recharts';
import PopperPortal from '../SubComponents/PopperPortal';

var Enumerable = require('linq');

interface IEventSuccessPredictionSectionProps {
    currentDate: Date,
    adjustedProbabilityOfSuccess: number,
    keywordOccurances: any[],
    predictionCalendar: any[]
}

const renderLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    var date = Moment(value.dateLocal).format('MM/DD');;
    var score = Math.round(value.adjustedProbability * 1000);
    return (
        <g>
            <text x={x + width / 2} y={y - 20} textAnchor="middle" dominantBaseline="top"
                fill="#555" font-size="smaller">
                {date}
            </text>
            <text x={x + width / 2} y={y - 5} textAnchor="middle" dominantBaseline="top"
                fill={score > 600 ? "#008000" : "#ff0000"} font-weight="bold">
                {score}
            </text>
        </g>
    );
};

const renderTooltip = (props: any) => {
    var active = props.active;
    var payload = props.payload.length > 0 ? props.payload[0] : null;

    if (active && payload) {
        var date = Moment(payload.payload.dateLocal).format('MM/DD');
        var score = Math.round(payload.payload.adjustedProbability * 1000);
        return (
            <PopperPortal active={active}>
                <div style={{ backgroundColor: "rgba(255,255,255,0.6)", borderRadius: "3px", padding: "3px", border: "1px solid #ddd" }}>
                    <div style={{ textAlign: "center" }}>
                        <div className='prediction-tooltip-date'>{date}</div>
                        <div style={score >= 600 ? { color: "green", fontSize: "20px", fontWeight: "bold" } : { color: "red", fontSize: "20px", fontWeight: "bold" }}>{score}</div>
                    </div>
                    <table>
                        {payload.payload.keywordOccurences.map((o: any) => (
                            <tr className="keywordOccurencesRow">
                                <td><strong>{o.keyword}<span className="keywordTitleCell">(Keyword)</span>:</strong></td>
                                <td style={o.count >= 10 ? { color: "red" } : { color: "green" }}>{o.count}</td>
                                <td><strong>{o.category}<span className="categoryTitleCell">(Category)</span>:</strong></td>
                                <td>{o.categoryFrequency}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </PopperPortal>
        );
    }
    return "";
};

export class EventSuccessPredictionSection extends React.Component<IEventSuccessPredictionSectionProps> {
    state = {
        currentDate: null,
        keywordOccurences: null,
        score: null,
    }

    HandleChartClick = (props: any) => {
        if (!props)
            return;
        let activePayload = props.activePayload;
        if (activePayload && activePayload.length == 1) {
            let payload = activePayload[0].payload;
            if (payload) {
                let score = Math.round(payload.adjustedProbability * 1000);
                let currentDate = payload.dateLocal;
                let keywordOccurences = payload.keywordOccurences;

                this.setState({
                    ...this.state,
                    score: score,
                    keywordOccurences: keywordOccurences,
                    currentDate: currentDate
                });
            }
        }
    }
    render() {
        var keywordOccurences = this.state.keywordOccurences ?? this.props.keywordOccurances ?? [];
        var score = this.state.score ?? Math.round(this.props.adjustedProbabilityOfSuccess * 1000);
        var currentDate = Moment(this.state.currentDate ?? this.props.currentDate).format('MM/DD/yyyy');
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <Card>
                    <CardContent>
                        *The below "Predictive Score" and keyword occurences are based off of our repository of events and we compare the keywords in your event
                        to the keywords of other events happening within a similar time frame to yours.
                    </CardContent>
                </Card>
                <br />
                <Card style={{ height: "100%" }}>
                    <CardContent style={{ textAlign: "center", display: "table", width: "93%" }}>
                        <div style={{ textAlign: "center", fontSize: "24px" }}>{currentDate}</div>

                    </CardContent>
                </Card>
                <br />
                <table style={{ width: "100%", border: "0", borderSpacing: "0" }}>
                    <tr style={{ width: "100%", border: "0" }}>
                        <td className="leftSidePanel" style={{ width: "50%", height: "250px", padding: "0", paddingRight: "10px" }}>
                            <Card style={{ height: "100%" }}>
                                <CardContent style={{ textAlign: "center", display: "table", width: "93%" }}>
                                    <div style={{ width: "100%", fontSize: "30px", height: "215px", verticalAlign: "middle", display: "table-cell", textAlign: "center" }}>
                                        <Tooltip title="This score is a number from 0-1000 that represents how distinct the keywords to your event are among other events happening within a similar time frame to yours. The categories of each keyword are also taken into account." placement="top">
                                            <div>
                                                <div style={{ fontSize: "20px" }}>
                                                    <u>Predictive Score (BETA)</u>
                                                    <br />
                                                </div>
                                                <span style={score >= 600 ? { color: "green" } : { color: "red" }}>{score}
                                                    <span style={{ paddingLeft: "10px" }}>{score >= 600 ? <ThumbUpIcon /> : <ThumbDownIcon />}</span></span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </CardContent>
                            </Card>
                        </td>
                        <td className="rightSidePanel" style={{ width: "50%", height: "250px" }}>
                            <Card style={{ height: "100%" }}>
                                <CardContent >
                                    <table>
                                        <tr className="keywordOccurencesHeaderRow">
                                            <th>
                                                Keyword
                                            </th>
                                            <th style={{ width: "100px" }}>
                                                Occurences
                                            </th>
                                            <th>
                                                Category
                                            </th>
                                            <th>
                                                Occurences
                                            </th>
                                        </tr>
                                        {
                                            keywordOccurences.length > 0 && keywordOccurences
                                                .map((o: any) => (
                                                    <tr className="keywordOccurencesRow">
                                                        <td><strong>{o.keyword}<span className="keywordTitleCell">(Keyword)</span>:</strong></td>
                                                        <td style={o.count >= 10 ? { color: "red" } : { color: "green" }}>{o.count}</td>
                                                        <td><strong>{o.category}<span className="categoryTitleCell">(Category)</span>:</strong></td>
                                                        <td>{o.categoryFrequency}</td>
                                                    </tr>
                                                ))
                                        }
                                    </table>
                                </CardContent>
                            </Card>
                        </td>
                    </tr>
                </table>
                <br />
                <Card style={{ height: "100%" }}>
                    <CardContent >
                        <ResponsiveContainer width="100%" height={100}>
                            <BarChart style={{ backgroundColor: 'white' }}
                                className="prediction-chart"
                                data={this.props.predictionCalendar}
                                margin={{
                                    top: 35,
                                    right: 5,
                                    left: 5,
                                    bottom: 5,
                                }}
                                onClick={this.HandleChartClick}
                            >
                                <XAxis hide={true} />
                                <YAxis hide={true} tickCount={0} domain={[(dataMin: any) => Math.max(0, dataMin - 0.02), (dataMax: any) => dataMax]} />
                                <Bar dataKey="adjustedProbability" fill="#c0c0c0" isAnimationActive={false} cursor="pointer">
                                    <LabelList dataKey="labelData" content={renderLabel} />
                                </Bar>
                                <ChartTooltip content={renderTooltip} allowEscapeViewBox={{ x: true, y: true }}
                                    wrapperClassName="prediction-tooltip" cursor={false}
                                    position={{ y: 0 }} />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </div>
        )
    }
}