/// <reference path="../../../data/DataModels/types.d.ts" />
import React from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { EventPrimaryFormSection } from './EventPrimaryFormSection';

var Enumerable = require('linq');

interface ICreateEventPageProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class CreateEventPage extends React.Component<ICreateEventPageProps> {

    MockHandleChangesPendingChange = (changesPending: boolean) => {

    }
    
    render()
    {
        return (
            <div style={{ height: '100%', width: '100%' }}>
                <div style={{height: "100%"}}>
                    <div style={{paddingTop: "10px"}}>
                        <CardHeader title="Create Event" />
                    </div>
                    
                    <EventPrimaryFormSection
                        loadingOn={this.props.loadingOn}
                        loadingOff={this.props.loadingOff}
                        handleChangesPendingChange={this.MockHandleChangesPendingChange}
                        changePage={this.props.changePage}
                    />
                </div>
            </div>
        )
    }
}