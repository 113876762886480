import React from 'react';
import '../App.css';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { changeNotificationsSettings, getAccountInfo, updateAccountInfo, updateAccountInfoPassword } from '../../data/PortalUserRepository';
import validator  from 'email-validator';
import { validPhoneNumber } from '../Shared/ValidationFunctions';
import { getAuthStatus } from '../../data/PortalUserRepository';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { GenericSuccessModal } from '../Components/GenericSuccessModal';
import { StripeAccountStatusCode } from '../../data/Models/StripeAccountStatusCode';
import { isThisSecond } from 'date-fns';
import { PortalUserRoleCode } from '../../data/Models/PortalUserRoleCode';

/// <reference path="./models/RequestModels.d.ts" />

interface IAccountInfoPageProps{
    loadingOn: () => void,
    loadingOff: () => void,
    changePage: (page: string) => void
}

export class AccountInfoPage extends React.Component<IAccountInfoPageProps> {
    
    state = {
        firstName: '',
        lastName:  '',
        username: '',
        email: '',
        phoneNumber: '',
        role: PortalUserRoleCode.None,
        firstNameError: '',
        lastNameError: '',
        usernameError: '',
        emailError: '',
        phoneNumberError: '',
        serverError: '',
        password: '',
        passwordConfirm: '',
        updateSuccess: false,
        passwordError: '',
        passwordConfirmError: '',
        updatePasswordSuccess: false,
        passwordServerError: '',

        suppressPaymentConfirmationEmailToCreator: false,
        notificationsUpdateSuccess: false,
        notificationsServerError: ''
    }

    async componentDidMount()
    {
        var authStatusResponse = await getAuthStatus();

        if(authStatusResponse == undefined || authStatusResponse.status != 200)
        {
            this.props.changePage("/login?redirect=account-info");
        }        
        var role = authStatusResponse.data.role;
        this.state.role = role;

        await this.ReloadPage();
    }

    ReloadPage = async () => {
        this.props.loadingOn();
        var response = await getAccountInfo();

        let firstName = "";
        let lastName = "";
        let username = "";
        let email = "";
        let phoneNumber = "";
        let suppressPaymentConfirmationEmailToCreator = false;

        if(response != null && response.status == 200)
        {
            firstName = response.data.firstName;
            lastName = response.data.lastName;
            username = response.data.username;
            email = response.data.email;
            phoneNumber = response.data.phoneNumber;

            suppressPaymentConfirmationEmailToCreator = response.data.suppressPaymentConfirmationEmailToCreator;
        }

        this.setState({
            ...this.state,
            firstName: firstName,
            lastName: lastName,
            username: username,
            email: email,
            phoneNumber: phoneNumber,

            suppressPaymentConfirmationEmailToCreator: suppressPaymentConfirmationEmailToCreator
        });
        this.props.loadingOff();
    }

    HandleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, firstName: event.currentTarget.value})
    }

    HandleLastNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, lastName: event.currentTarget.value})
    }

    HandleUsernameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, username: event.currentTarget.value})
    }

    HandleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, email: event.currentTarget.value})
    }

    HandlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, phoneNumber: event.currentTarget.value})
    }

    HandlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, password: event.currentTarget.value})
    }

    HandlePasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({...this.state, passwordConfirm: event.currentTarget.value})
    }

    HandleUpdate = async () => 
    {
        this.props.loadingOn();

        let firstNameError = "";
        let lastNameError = "";
        let usernameError = "";
        let emailError = "";
        let phoneNumberError = "";
        let errored = false;
        let updateSuccess = false;

        if(this.state.firstName == undefined || this.state.firstName.length == 0)
        {
            firstNameError = "First name required.";
            errored = true;
        }

        if(this.state.lastName == undefined || this.state.lastName.length == 0)
        {
            lastNameError = "Last name required";
            errored = true;
        }

        if(this.state.username == undefined || this.state.username.length == 0)
        {
            usernameError = "Username required.";
            errored = true;
        }

        if(this.state.email == undefined || this.state.email.length == 0)
        {
            emailError = "Email required.";
            errored = true;
        }
        else if(validator.validate(this.state.email) == false)
        {
            emailError = `"${this.state.email}" is not a valid email address.`;
            errored = true;
        }

        if(this.state.phoneNumber == undefined || this.state.phoneNumber.length > 0  && validPhoneNumber(this.state.phoneNumber) == false)
        {
            phoneNumberError = `"${this.state.phoneNumber}" is not a valid 10 digit phone number.`;
            errored = true;
        }

        let serverErrorText = "";

        if(!errored){

            let request: IUpdateAccountInfoRequest = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                username: this.state.username,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber
            };

            var response = await updateAccountInfo(request);
            
            if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverErrorText = response.data.errorMsg;
                }
                else{
                    updateSuccess = true;
                }
            }
            else{
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            usernameError: usernameError,
            emailError: emailError,
            phoneNumberError: phoneNumberError,
            updateSuccess: updateSuccess,
            serverError: serverErrorText
        });

        this.props.loadingOff();
    }

    HandleUpdatePassword = async () =>{
        this.props.loadingOn();

        let passwordError = "";
        let passwordConfirmError = "";
        let errored = false;
        let updateSuccess = false;

        if(this.state.password.length == 0)
        {
            passwordError = "Password required.";
            errored = true;
        }

        if(this.state.passwordConfirm.length == 0)
        {
            passwordConfirmError = "Password confirmation required.";
            errored = true;
        }

        if(this.state.password.length > 0 && 
            this.state.passwordConfirm.length > 0 &&
             this.state.password != this.state.passwordConfirm)
        {
            passwordError = "Password and password confirmation must match.";
            passwordConfirmError = "Password and password confirmation must match.";
            errored = true;
        }

        let serverErrorText = "";

        if(!errored){

            var response = await updateAccountInfoPassword(this.state.password);
            
            if(response != null && response.status == 200)
            {
                if(!response.data.success)
                {
                    serverErrorText = response.data.errorMsg;
                }
                else{
                    updateSuccess = true;
                }
            }
            else{
                serverErrorText = "An unknown error occured. Please try again later";
            }
        }

        this.setState({
            ...this.state,
            passwordError: passwordError,
            passwordConfirmError: passwordConfirmError,
            updatePasswordSuccess: updateSuccess,
            passwordServerError: serverErrorText
        });

        this.props.loadingOff();
    }

    CloseUpdateSuccessModal = () => {
        this.setState({...this.state, updateSuccess: false, updatePasswordSuccess: false});
    };

    HandleSuppressPaymentConfirmationEmailToCreator = () => {
        this.setState({...this.state, suppressPaymentConfirmationEmailToCreator: !this.state.suppressPaymentConfirmationEmailToCreator});
    }

    HandleUpdateNotifications = async ()=>{
        let serverErrorText = '';
        let updateSuccess = false;

        var response = await changeNotificationsSettings(this.state.suppressPaymentConfirmationEmailToCreator);
            
        if(response != null && response.status == 200)
        {
            if(!response.data.success)
            {
                serverErrorText = response.data.errorMsg;
            }
            else{
                updateSuccess = true;
            }
        }
        else{
            serverErrorText = "An unknown error occured. Please try again later";
        }
        this.setState({
            ...this.state,
            notificationsUpdateSuccess: updateSuccess,
            notificationsServerError: serverErrorText
        });
    }

    render(){
        const classes = {
            loginBtn: {
            //marginTop: theme.spacing(2),
            flexGrow: 1
            }
          };

        return (
            <form noValidate autoComplete="off" >
                <Card style={{ backgroundColor: "#ededed"}} >
                    <CardHeader style={{  color: 'black'}} title="Account Info" />
                    <CardContent>
                    <div>
                        <TextField
                            error={this.state.firstNameError !== ""}
                            fullWidth
                            id="firstName"
                            type="text"
                            label="First Name"
                            placeholder="First Name"
                            margin="normal"
                            helperText={this.state.firstNameError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.firstName}
                            onChange={(e) => this.HandleFirstNameChange(e)}
                            variant="outlined"
                            />
                        <TextField
                            error={this.state.lastNameError !== ""}
                            fullWidth
                            id="lastName"
                            type="text"
                            label="Last Name"
                            placeholder="Last Name"
                            margin="normal"
                            helperText={this.state.lastNameError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.lastName}
                            onChange={(e) => this.HandleLastNameChange(e)}
                            variant="outlined"
                            />
                        <TextField
                            error={this.state.usernameError !== ""}
                            fullWidth
                            id="username"
                            type="text"
                            label="Username"
                            placeholder="Username"
                            margin="normal"
                            helperText={this.state.usernameError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.username}
                            onChange={(e) => this.HandleUsernameChange(e)}
                            variant="outlined"
                            />
                        <TextField
                            error={this.state.emailError !== ""}
                            fullWidth
                            id="email"
                            type="email"
                            label="Email"
                            placeholder="Email"
                            margin="normal"
                            helperText={this.state.emailError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.email}
                            onChange={(e) => this.HandleEmailChange(e)}
                            variant="outlined"
                            />
                        <TextField
                            error={this.state.phoneNumberError !== ""}
                            fullWidth
                            id="phone"
                            type="tel"
                            label="Phone Number (optional)"
                            placeholder="Phone Number (optional)"
                            margin="normal"
                            helperText={this.state.phoneNumberError}
                            FormHelperTextProps={{ color: "red" }}
                            value={this.state.phoneNumber}
                            onChange={(e) => this.HandlePhoneNumberChange(e)}
                            variant="outlined"
                            />
                    </div>
                    </CardContent>
                    <CardActions>
                    <Button
                        variant="contained"
                        size="large"
                        className='btn-primary'
                        style={classes.loginBtn}
                        onClick={this.HandleUpdate}
                        >
                        Update Account Info
                    </Button>
                    </CardActions>
                    <CardContent>
                        <div style={{color: 'red'}} hidden={this.state.serverError == ''}>
                            {this.state.serverError}
                        </div>
                        <div style={{color: 'limegreen'}} hidden={!this.state.updateSuccess} >
                            Your account info was updated successfully.
                        </div>
                    </CardContent>
                </Card>
                <Card style={{ backgroundColor: "#ededed", marginTop:"10px"}}>
                <CardHeader style={{  color: 'black'}} title="Password" />
                <CardContent>
                        <TextField
                            error={this.state.passwordError !== ""}
                            fullWidth
                            id="password"
                            type="password"
                            label="Password"
                            placeholder="Password"
                            margin="normal"
                            helperText={this.state.passwordError}
                            FormHelperTextProps={{ color: "red" }}
                            onChange={(e) => this.HandlePasswordChange(e)}
                            variant="outlined"
                            />
                        <TextField
                            error={this.state.passwordConfirmError !== ""}
                            fullWidth
                            id="passwordConfirm"
                            type="password"
                            label="Password Confirmation"
                            placeholder="Password Confirmation"
                            margin="normal"
                            helperText={this.state.passwordConfirmError}
                            FormHelperTextProps={{ color: "red" }}
                            onChange={(e) => this.HandlePasswordConfirmChange(e)}
                            variant="outlined"
                            />
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            size="large"
                            className='btn-primary'
                            style={classes.loginBtn}
                            onClick={this.HandleUpdatePassword}
                            >
                            Update Password
                        </Button>
                    </CardActions>
                    <CardContent>
                        <div style={{color: 'red'}} hidden={this.state.passwordServerError == ''}>
                            {this.state.passwordServerError}
                        </div>
                        <div style={{color: 'limegreen'}} hidden={!this.state.updatePasswordSuccess} >
                            Your password was updated successfully.
                        </div>
                    </CardContent>
                </Card>
                { (this.state.role == PortalUserRoleCode.Host || this.state.role == PortalUserRoleCode.Admin) &&
                    <Card style={{ backgroundColor: "#ededed", marginTop:"10px"}}>
                        <CardHeader style={{  color: 'black'}} title="Notifications" />
                        <CardContent>
                            <FormGroup>
                                <FormControlLabel 
                                    control={<Checkbox 
                                        checked={this.state.suppressPaymentConfirmationEmailToCreator}
                                        onChange={this.HandleSuppressPaymentConfirmationEmailToCreator} />} 
                                    label="Suppress Payment Confirmation Email" />
                            </FormGroup>
                        </CardContent>
                        <CardActions>
                        <Button
                            variant="contained"
                            size="large"
                            className='btn-primary'
                            style={classes.loginBtn}
                            onClick={this.HandleUpdateNotifications}
                            >
                            Update Nofications Settings
                        </Button>
                        </CardActions>
                        <CardContent>
                            <div style={{color: 'red'}} hidden={this.state.notificationsServerError == ''}>
                                {this.state.notificationsServerError}
                            </div>
                            <div style={{color: 'limegreen'}} hidden={!this.state.notificationsUpdateSuccess} >
                                Your notifications settings was updated successfully.
                            </div>
                        </CardContent>
                    </Card>
                }

                <GenericSuccessModal 
                    hidden={!this.state.updateSuccess}
                    message="Your account info was updated successfully."
                    closeModal={this.CloseUpdateSuccessModal}
                    />
                <GenericSuccessModal 
                    hidden={!this.state.updatePasswordSuccess}
                    message="Your password was updated successfully."
                    closeModal={this.CloseUpdateSuccessModal}
                    />
            </form>
        );
    }
}